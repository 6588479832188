import { useState } from "react";
import { Row, Col, Divider, Button, Modal } from "antd";
import SvgIcon from "@components/svg-icon/SvgIcon";

interface Props {
  schemaDescription: string;
  resultDescription?: string;
  videosrc?: string;
  isVertical?: boolean;
}

export const TemplateHead = ({
  schemaDescription,
  isVertical,
  resultDescription,
  videosrc,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Row gutter={50} className="grid">
        <Col className="template__row" span={12}>
          <div className="template-descrition template-descrition_with-border">
            <span className="template-descrition__head">Input Data</span>
            <span className="template-descrition__content">
              {schemaDescription}
            </span>
          </div>

          <Divider className="template-form-divider" />
        </Col>
        {!isVertical && (
          <Col className="template__row" span={12}>
            <div className="template-descrition">
              <span className="template-descrition__head">Result</span>
              <span className="template-descrition__content">
                {resultDescription}
              </span>
            </div>

            {Boolean(videosrc) && (
              <div className="template-descrition__help-btn">
                <Button
                  type="ghost"
                  shape="round"
                  icon={<SvgIcon type="help" />}
                  onClick={() => setIsVisible(true)}
                >
                  How it works?
                </Button>
              </div>
            )}

            <Divider className="template-form-divider" />
          </Col>
        )}
      </Row>
      {Boolean(videosrc) && (
        <Modal
          visible={isVisible}
          title="How it works"
          onCancel={() => setIsVisible(false)}
          footer={null}
          className="template__modal-hiw"
        >
          <video autoPlay muted controls className="template__modal-hiw__video">
            <source
              src={videosrc}
              type='video/mp4; codecs="avc1.4d002a, mp4a.40.2"'
            />
          </video>
        </Modal>
      )}
    </>
  );
};
