import Axios from "axios";

class SignupService {
  public static async signup(formData: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    re_password: string;
  }): Promise<any> {
    const { data } = await Axios.post("/api/v1/auth/users/", formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    localStorage.setItem("registeredEmail", formData.email);
    return data;
  }
}

export default SignupService;
