export const TEMPLATES_TABS_IGNORE_LIST = [
  "free-write",
  "sales-text-aeo",
  "sales-text-aida",
  "sales-text-bab",
  "sales-text-fab",
  "sales-text-pppp",
  "blog-post",
  "blog-post-summaries",
  "cold-email",
  "cold-email-approaching-suppliers",
  "tonality-voice-adjustment",
  "text-summary",
  "tagline",
  "sentence-length-adjustment",
  "sentence-audience-adjustment",
];

export type GROUP_TEMPLATE = {
  id: (typeof GROUPPED_IDS)[number];
  tab: string;
  childrens: string[];
};

export const GROUPPED_TEMPLATES: GROUP_TEMPLATE[] = [
  {
    id: "GROUP_BLOG_POST",
    tab: "Blog post generator",
    childrens: ["blog-post", "blog-post-summaries"],
  },
  {
    id: "GROUP_SALES_COPY_FRAMEWORKS",
    tab: "Sales copy frameworks",
    childrens: [
      "sales-text-pppp",
      "sales-text-aeo",
      "sales-text-fab",
      "sales-text-bab",
      "sales-text-aida",
    ],
  },
  {
    id: "GROUP_EMAIL_GENERATOR",
    tab: "Cold email generator",
    childrens: ["cold-email", "cold-email-approaching-suppliers"],
  },
];

export const GROUPPED_IDS = [
  "GROUP_BLOG_POST",
  "GROUP_SALES_COPY_FRAMEWORKS",
  "GROUP_EMAIL_GENERATOR",
] as const;
