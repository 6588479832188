import React from "react";
import styles from "./styles.module.scss";

interface LoadMoreButtonProps {
  onClick: () => void;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.loadMoreButton} onClick={onClick}>
      Load More
    </button>
  );
};

export default LoadMoreButton;
