export default () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84375 4.08008V6.53125H0.0976562V4.08008H9.84375ZM6.30859 0.222656V10.5742H3.64258V0.222656H6.30859Z"
        fill="#5295E0"
      />
    </svg>
  );
};
