import { ImageEnhance } from "@components/ImageEnhance";
import { useTitle } from "@hooks/useTitle";

import "./image-enhance-page.less";

export const ImageEnhancePage = () => {
  useTitle("Nyle - Image Enhance");

  return (
    <div className="image-enhance-page-container">
      <section>
        <div className="container">
          <h2 className="page-main-title">Image Enhance</h2>
          <p className="page-main-description">
            Just upload an image and improve the quality up to 16 times for your
            e-commerce project or other needs
          </p>
        </div>
      </section>

      <section className="image-enhance-page__content">
        <div className="container">
          <ImageEnhance />
        </div>
      </section>
    </div>
  );
};
