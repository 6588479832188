export default () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.164062 7.9998C0.164062 7.55798 0.522235 7.1998 0.964062 7.1998L12.9641 7.1998C13.4059 7.1998 13.7641 7.55798 13.7641 7.9998C13.7641 8.44163 13.4059 8.7998 12.9641 8.7998L0.964062 8.7998C0.522235 8.7998 0.164062 8.44163 0.164062 7.9998Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9666 13.1999C10.6542 13.5124 10.1476 13.5124 9.83522 13.1999C9.5228 12.8875 9.5228 12.381 9.83522 12.0686L13.3382 8.56563C13.6506 8.25321 13.6506 7.74667 13.3382 7.43425L9.83522 3.93131C9.5228 3.61889 9.5228 3.11236 9.83522 2.79994C10.1476 2.48752 10.6542 2.48752 10.9666 2.79994L15.6009 7.43425C15.9133 7.74667 15.9133 8.25321 15.6009 8.56562L10.9666 13.1999Z"
        fill="white"
      />
    </svg>
  );
};
