import Photo1 from "@assets/img/acontent-1.png";
import Photo2 from "@assets/img/acontent-2.png";
import Photo3 from "@assets/img/acontent-3.png";
import Photo4 from "@assets/img/acontent-4.png";

export interface AContentData {
  id: number;
  imageUrl: string;
}

export const content = [
  {
    id: 1,
    imageUrl: Photo1,
  },
  {
    id: 2,
    imageUrl: Photo2,
  },
  {
    id: 3,
    imageUrl: Photo3,
  },
  {
    id: 4,
    imageUrl: Photo4,
  },
];
