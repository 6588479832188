import { useEffect, useRef } from "react";

const Graph = ({ percentage, color }) => {
  const circleRef = useRef(null);

  useEffect(() => {
    if (circleRef.current) {
      const radius = circleRef.current.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      const arcLength = circumference * (285 / 360);
      const offset = arcLength - (percentage / 100) * arcLength;
      circleRef.current.style.strokeDasharray = `${arcLength} ${circumference}`;
      circleRef.current.style.strokeDashoffset = offset;
    }
  }, [percentage]);

  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor={color} />
          <stop offset="100%" stopColor={color} stopOpacity="0.5" />
        </linearGradient>
      </defs>
      <circle
        ref={circleRef}
        cx="60"
        cy="60"
        r="54"
        stroke="url(#gradient)"
        strokeWidth="5"
        fill="none"
        strokeLinecap="round"
        strokeDasharray="226.2 339.29"
        strokeDashoffset="226.2"
        transform="rotate(125 60 60)"
      />
    </svg>
  );
};

export default Graph;
