import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { ApiResponse } from "src/types/apiResponce";

export class GraphsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getGraph(
    asin: string,
    date_start: string,
    date_end: string,
  ): Promise<ApiResponse<any>> {
    return this.get("/graphs/review-score", { asin, date_start, date_end });
  }
}
