import { useQuery } from "react-query";

import { DocumentsService } from "@services/DocumentsService";

export const useDocumentById = (documentId: string) =>
  useQuery(
    ["document", documentId],
    ({ queryKey }) => {
      const [, documentId] = queryKey;

      return DocumentsService.getDocumentById(documentId);
    },
    {
      retryOnMount: true,
      enabled: Boolean(documentId),
    },
  );
