export const getDateItems = (currentDate: Date) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const date = currentDate.getDate();

  return [
    {
      label: "Previous period",
      dateRange: {
        startDate: new Date(year, month, date - 21),
        endDate: currentDate,
      },
    },
    {
      label: "Previous week",
      dateRange: {
        startDate: new Date(year, month, date - 7),
        endDate: currentDate,
      },
    },
    {
      label: "Previous month",
      dateRange: {
        startDate: new Date(year, month - 1, date),
        endDate: currentDate,
      },
    },
    {
      label: "Previous year",
      dateRange: {
        startDate: new Date(year - 1, month, date),
        endDate: currentDate,
      },
    },
  ];
};

export const COMPARING_TO = "Comparing to ";
