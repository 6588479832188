export const formatDateRange = (startDate: Date, endDate: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formattedStartDate = startDate
    .toLocaleDateString("default", options)
    .replace(/\//g, ".");
  const formattedEndDate = endDate
    .toLocaleDateString("default", options)
    .replace(/\//g, ".");

  return `${formattedStartDate} — ${formattedEndDate}`;
};
export function formatValue(value: string): string {
  const cleanValue = value.replace(/,/g, "").trim();

  if (cleanValue.includes("%")) {
    const numericValue = parseFloat(cleanValue.replace("%", ""));
    if (isNaN(numericValue) || numericValue < 0.01) {
      return "0%";
    }
    return `${numericValue.toFixed(2)}%`;
  }

  if (cleanValue.includes("$")) {
    const numericValue = parseFloat(cleanValue.replace(/[^0-9.-]+/g, ""));
    if (isNaN(numericValue)) {
      return value;
    }
    return `$${numericValue.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}`;
  }

  const numericValue = parseFloat(cleanValue);
  if (isNaN(numericValue)) {
    return value;
  }

  if (Number.isInteger(numericValue)) {
    return numericValue.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return numericValue.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
