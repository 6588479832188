import { useState, useEffect } from "react";
import { useProductStore } from "src/store/overviewProduct.state";
import TotalOptimization from "../../CROPage/components/Sections/TotalOptimization";
import { AspectOptimization } from "./components/DefaultOptimization/Aspect";
import { ListingOptimisation } from "./components/DefaultOptimization/Listing";
import { PriceOptimisation } from "./components/DefaultOptimization/Price";
import styles from "./styles.module.scss";
import { CROInfoType } from "@services/amazon/products/types";
import { OptimizationService } from "@services/optimization/optimization.services";

const Optimization = ({ isWhatIf }) => {
  const [croInfo, setCroInfo] = useState<CROInfoType | undefined>(undefined);
  const optimizationService = new OptimizationService();
  const { selectedProduct } = useProductStore();

  useEffect(() => {
    const fetchScore = async () => {
      if (!selectedProduct?.asin) return;
      const res = await optimizationService.getCROInfo(selectedProduct.asin);
      setCroInfo(res);
    };

    fetchScore();
  }, [selectedProduct]);

  return (
    <div className={styles.container}>
      <TotalOptimization isWhatIf={isWhatIf} croInfo={croInfo} />
      <ListingOptimisation isWhatIf={isWhatIf} croInfo={croInfo} />
      <AspectOptimization isWhatIf={isWhatIf} />
      <PriceOptimisation isWhatIf={isWhatIf} />
    </div>
  );
};

export default Optimization;
