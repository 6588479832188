import "./index.css";
import { Rating } from "@pages/Dashboard/features/Products/Rating";
import ProductCode from "@components/ProductCode";
import DataBlock from "@components/DataBlock";
import { mockDataBlock } from "./mock";

export const Description = ({ prod }) => {
  return (
    <div className="product-description">
      <picture className="product-description__picture">
        <img src={prod.image_link} alt={prod.item_name} />
      </picture>
      <div className="product-description__description">
        <h2 className="product-description__title heading-2">
          {prod.item_name}
        </h2>
        <Rating rating={4.5} />
        <ProductCode code={prod.asin} />
        <DataBlock data={mockDataBlock} />
      </div>
    </div>
  );
};
