import { useState } from "react";
import styles from "./styles.module.scss";
import Arrow from "@assets/icons/Arrow";
import { Slider } from "antd";
import "./range.scss";
import classNames from "classnames";
import { RangeCard } from "./RangeCard";
import { MetricCard } from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder/MetricCard";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { Goals, useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import ModalBox from "@components/ModalBox";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import InfoBlue from "@assets/icons/InfoBlue";
import PencilIcon from "@assets/icons/PencilIcon";

const metrics: IWidget[] = [
  {
    id: "1",
    key: "acos",
    name: "ACOS",
    value: "24%",
    originalValue: 24,
  },
  {
    id: "2",
    key: "dailySpend",
    name: "Daily Ad Spend",
    value: "$0",
    originalValue: 4.2,
  },
  {
    id: "3",
    key: "dailySales",
    name: "Daily Ad Sales",
    value: "$64,090",
    originalValue: 12,
  },
];

const getInputLabel = (metric: IWidget) => {
  switch (metric?.name) {
    case "ACOS":
      return "Enter your target ACOS, %";
    case "Daily Ad Spend":
      return "Enter your daily Ad Spend, $";
    case "Daily Ad Sales":
      return "Enter your daily Ad Sales, $";
    default:
      return "";
  }
};

const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}.${month}.${year}`;
};

export const AdSpend = () => {
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const { keys, goals, setGoals } = useAdsAnaliticsStore((state) => ({
    keys: state.keys,
    goals: state.goals,
    setGoals: state.setGoals,
  }));
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);
  const [adSpend, setAdSpend] = useState<number>(0);
  const [bids, setBids] = useState<number>(0);
  const [time, setTime] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [openedMetric, setOpenedMetric] = useState<IWidget>();
  const [goalsInputs, setGoalsInputs] = useState<Goals>({
    acos: 0,
    dailySpend: 0,
    dailySales: 0,
  });

  const handleAdSpendChange = (value: [number, number]) => {
    setAdSpend(value[0] === 0 ? value[1] : value[0]);
  };

  const handleBidsChange = (value: [number, number]) => {
    setBids(value[0] === 0 ? value[1] : value[0]);
  };

  const handleTimeChange = (value: [number, number]) => {
    setTime(value[0] === 0 ? value[1] : value[0]);
  };

  const handleTopChange = (value: [number, number]) => {
    setTop(value[0] === 0 ? value[1] : value[0]);
  };

  const handleChangeGoal = (key: string, value: number) => {
    console.log(goals[key], key, value);
    setGoalsInputs({
      ...goals,
      [key]: value,
    });
  };

  const handleSaveGoals = () => {
    setGoals(goalsInputs);
    setOpenedMetric(undefined);
  };

  return (
    <div className={styles.container}>
      {keys && (
        <div className={styles.keys}>
          <h2>Keys:</h2>
          <div className={styles.keys__list}>
            {Object.values(keys)
              .flat()
              .map((key, index) => (
                <div key={index} className={styles.keys__item}>
                  {key}
                </div>
              ))}
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.spend}>
          <h3>Ad Spend, %</h3>
          {!isAdvancedOpen && (
            <div className={styles.range}>
              <div className={styles.range__input}>
                <Slider
                  min={-100}
                  max={100}
                  step={1}
                  value={adSpend >= 0 ? [0, adSpend] : [adSpend, 0]}
                  defaultValue={[0, 0]}
                  onChange={handleAdSpendChange}
                  className={classNames(
                    "custom-range",
                    adSpend >= 0 ? "range__slider" : "range__sliderNegative",
                  )}
                  range
                />
              </div>
              <div className={styles.range__result}>
                <input
                  type="number"
                  value={adSpend}
                  onChange={(event) => setAdSpend(Number(event.target.value))}
                  className={styles.range__inputNumber}
                />
              </div>
            </div>
          )}
          <div className={styles.advanced}>
            <button
              className={classNames(
                styles.advanced__button,
                isAdvancedOpen && styles.active,
              )}
              onClick={() => {
                setIsAdvancedOpen(!isAdvancedOpen);
              }}
            >
              Advanced Options <Arrow />
            </button>
          </div>
          {isAdvancedOpen && (
            <>
              <div className={styles.cards}>
                <RangeCard
                  title="Ad Spend, %"
                  value={adSpend}
                  onChange={handleAdSpendChange}
                />
                <RangeCard
                  title="Bids, %"
                  value={bids}
                  onChange={handleBidsChange}
                />
                <RangeCard
                  title="Time, %"
                  value={time}
                  onChange={handleTimeChange}
                />
                <RangeCard
                  title="Top, %"
                  value={top}
                  onChange={handleTopChange}
                />
              </div>
              <button className={styles.apply}>APPLY changes</button>
            </>
          )}
        </div>
        <div className={styles.charts}>
          {metrics.map((metric) => (
            <div className={styles.metric}>
              <MetricCard
                key={metric.id}
                metric={metric}
                metricColor="#5295E0"
              />
              {goals[metric.key] === 0 ? (
                <button
                  className={styles.goals}
                  onClick={() => {
                    setOpenedMetric(metric);
                  }}
                >
                  Set up goals
                </button>
              ) : (
                <div className={styles.goals__container}>
                  <span>
                    Goal: {goals[metric.key]}{" "}
                    {metric.key === "acos" ? "%" : "$"}
                  </span>
                  <button
                    onClick={() => {
                      setOpenedMetric(metric);
                    }}
                  >
                    <PencilIcon />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <ModalBox
          isOpen={!!openedMetric}
          onClose={() => setOpenedMetric(undefined)}
          title={openedMetric?.name}
          className={styles.modal}
        >
          <div className={styles.input__container}>
            <span>{getInputLabel(openedMetric)}</span>
            <input
              type="number"
              value={goalsInputs[openedMetric?.key]}
              onChange={(event) =>
                handleChangeGoal(openedMetric?.key, Number(event.target.value))
              }
              className={styles.input}
            />
          </div>
          <div className={styles.date}>
            <div className={styles.date__item}>
              <span>Start date</span>
              <span>{formatDate(dateRange.startDate)}</span>
            </div>
            <div className={styles.date__item}>
              <span>End date</span>
              <span>{formatDate(dateRange.endDate)}</span>
            </div>
          </div>
          <div className={styles.info}>
            <InfoBlue />
            Affecting only selected targets
          </div>
          <button className={styles.save} onClick={handleSaveGoals}>
            Save Changes
          </button>
        </ModalBox>
      </div>
    </div>
  );
};
