export default () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646231 2.11443C0.284086 1.73321 0.284086 1.13509 0.646231 0.753867C1.03565 0.343926 1.68907 0.343926 2.07849 0.753867L6.19712 5.08953C6.60264 5.51641 7.28307 5.51641 7.68859 5.08953L11.8072 0.753867C12.1966 0.343926 12.8501 0.343926 13.2395 0.753867C13.6016 1.13509 13.6016 1.73321 13.2395 2.11443L7.68859 7.95783C7.28307 8.38471 6.60264 8.38471 6.19712 7.95783L0.646231 2.11443Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};
