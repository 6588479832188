import styles from "./syles.module.scss";
import TilesIcon from "@assets/icons/tiles.tsx";
import Table from "@assets/icons/table.tsx";
import clsx from "clsx";
import { useState } from "react";

interface Tab {
  code: string;
  icon: React.ReactNode;
}

const tabList: Tab[] = [
  {
    code: "tiles",
    icon: <TilesIcon color="red" />,
  },
  {
    code: "table",
    icon: <Table />,
  },
];

interface Props {
  setSelectedTab: (code: string) => void;
}

export const ProductTab: React.FC<Props> = ({ setSelectedTab }) => {
  const [activeTab, setActiveTab] = useState<string>("tiles");

  const toggleTab = (code: string) => {
    setActiveTab(code);
    setSelectedTab(code);
  };

  return (
    <div className={styles.tabContainer}>
      <p>Products</p>
      <div className={clsx(styles["tabContainer__tab"])}>
        {tabList.map((item) => (
          <button
            key={item.code}
            onClick={() => toggleTab(item.code)}
            className={clsx(styles.element, {
              [styles["element__active"]]: activeTab === item.code,
            })}
          >
            {item.icon}
          </button>
        ))}
      </div>
    </div>
  );
};
