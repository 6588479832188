export default () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.637326 2.16134C0.27815 1.77529 0.27815 1.17737 0.637325 0.791328C1.03507 0.363825 1.71208 0.363826 2.10982 0.791329L6.24696 5.23796C6.65378 5.67521 7.34622 5.67521 7.75305 5.23796L11.8902 0.791327C12.2879 0.363825 12.9649 0.363825 13.3627 0.791327C13.7218 1.17737 13.7219 1.77529 13.3627 2.16133L7.75305 8.19062C7.34622 8.62787 6.65378 8.62787 6.24696 8.19062L0.637326 2.16134Z"
        fill="white"
      />
    </svg>
  );
};
