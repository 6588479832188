import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="60"
      height="43"
      viewBox="0 0 60 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.1922 1.29457C59.8093 2.90436 59.8093 5.52215 58.1922 7.13194L25.2392 39.9352C22.165 42.9954 17.1957 42.9954 14.1215 39.9352L1.80701 27.6766C0.189868 26.0668 0.189867 23.449 1.80701 21.8393C3.41376 20.2398 6.0111 20.2398 7.61786 21.8393L14.1216 28.3134C17.1957 31.3736 22.1651 31.3736 25.2392 28.3134L52.3813 1.29457C53.9881 -0.304887 56.5854 -0.304888 58.1922 1.29457Z"
        fill="currentColor"
      />
    </svg>
  );
};
