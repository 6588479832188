import React from "react";
import cn from "classnames";
import "./paper.less";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Paper = ({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={cn("paper", className)}>
      {children}
    </div>
  );
};
