import { createContext, useState, useEffect, ReactNode } from "react";
import { ConfigService, IBackendConfig } from "@services/config.service";

export const NyleConfigContext = createContext<IBackendConfig | null>(null);

interface NyleConfigProviderProps {
  children: ReactNode;
}

export const NyleConfigProvider = ({ children }: NyleConfigProviderProps) => {
  const [config, setConfig] = useState<IBackendConfig | null>(null);

  useEffect(() => {
    const fetchBackendConfig = async () => {
      try {
        const fetchedConfig = await ConfigService.fetchBackendConfig();
        setConfig(fetchedConfig);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchBackendConfig();
  }, []);

  return (
    <NyleConfigContext.Provider value={config}>
      {children}
    </NyleConfigContext.Provider>
  );
};
