export default () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1843_8792)">
        <path
          d="M20.75 10C22.9591 10 24.75 8.20914 24.75 6C24.75 3.79086 22.9591 2 20.75 2C18.5409 2 16.75 3.79086 16.75 6C16.75 8.20914 18.5409 10 20.75 10Z"
          fill="white"
        />
        <path
          d="M24.8399 26.2298C24.7294 26.1555 24.6052 26.104 24.4745 26.0783C24.3438 26.0526 24.2093 26.0533 24.0789 26.0803C23.9485 26.1074 23.8248 26.1601 23.7151 26.2356C23.6054 26.3111 23.5118 26.4077 23.4399 26.5198C22.4732 27.9515 21.289 29.2234 19.9299 30.2898C19.5999 30.5398 18.3699 31.4898 17.8499 31.2898C17.4899 31.1798 17.6999 30.4698 17.7699 30.1698L18.2999 28.5998C18.5199 27.9598 22.3499 16.5998 22.7699 15.2998C23.3899 13.3998 23.1199 11.5298 20.2899 11.9798C19.5199 12.0598 11.7099 13.0698 11.5699 13.0798C11.4386 13.0884 11.3102 13.1227 11.1922 13.1808C11.0741 13.239 10.9687 13.3198 10.8819 13.4187C10.795 13.5176 10.7285 13.6326 10.6862 13.7572C10.6438 13.8818 10.6264 14.0135 10.6349 14.1448C10.6435 14.2762 10.6778 14.4045 10.7359 14.5226C10.7941 14.6406 10.8749 14.7461 10.9738 14.8329C11.0727 14.9197 11.1877 14.9862 11.3123 15.0286C11.4369 15.071 11.5686 15.0884 11.6999 15.0798C11.6999 15.0798 14.6999 14.6898 15.0299 14.6598C15.199 14.6433 15.3691 14.6761 15.52 14.7542C15.6708 14.8322 15.7958 14.9523 15.8799 15.0998C16.0611 15.66 16.0363 16.2664 15.8099 16.8098C15.5499 17.8098 11.4399 29.3898 11.3099 30.0598C11.1705 30.6205 11.2092 31.2107 11.4207 31.7483C11.6322 32.286 12.0059 32.7444 12.4899 33.0598C13.3984 33.6654 14.4802 33.9569 15.5699 33.8898C16.6295 33.8769 17.6774 33.6666 18.6599 33.2698C21.1499 32.2698 23.7499 29.6098 25.1199 27.5198C25.2379 27.3075 25.2745 27.0595 25.223 26.8221C25.1715 26.5847 25.0353 26.3742 24.8399 26.2298Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1843_8792">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
