import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="69"
      height="32"
      viewBox="0 0 69 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.6855 25.2632H0C0 25.2632 0 17.2632 0 14.9895C0 12.7158 2.17199 6.23158 10.1916 6.23158C18.2113 6.23158 20.4668 12.4632 20.4668 15.4105C20.4668 18.3579 20.4668 25.1789 20.4668 25.1789H12.8649C12.8649 25.1789 12.8649 17.1789 12.8649 15.9158C12.8649 14.6526 11.7789 13.6421 10.1916 13.6421C8.60442 13.6421 7.60197 15.0737 7.60197 15.9158C7.60197 16.7579 7.6855 25.2632 7.6855 25.2632Z"
        fill="currentColor"
      />
      <path
        d="M41.5184 6.65263L30.8256 32H23.2236L26.398 24.4211L18.8796 6.65263H26.4816L30.1572 15.2421L33.8329 6.65263H41.5184Z"
        fill="currentColor"
      />
      <path
        d="M36.4201 25.2632H44.0221L54.715 0H47.0295L36.4201 25.2632Z"
        fill="currentColor"
      />
      <path
        d="M62.5676 17.6C62.5676 17.6 61.8157 18.8632 60.7297 19.0316C59.226 19.3684 58.2236 18.7789 58.2236 18.7789L68.9165 13.0526C67.6634 9.09474 64.0713 6.31579 59.7273 6.31579C54.4644 6.31579 50.2039 10.6105 50.2039 16C50.2039 21.3895 54.4644 25.6842 59.8108 25.6842C64.1548 25.6842 67.8305 22.7368 69 18.6947L62.5676 17.6ZM58.2236 13.2211C59.9779 12.2947 61.398 13.2211 61.398 13.2211L56.7199 15.7474C56.6364 15.6632 56.6364 13.9789 58.2236 13.2211Z"
        fill="currentColor"
      />
    </svg>
  );
};
