import { create } from "zustand";
import { ICountry } from "src/types/country.types.ts";

export interface ICountriesState {
  amazonCountries: ICountry[];
  amazonCountriesLoaded: boolean;
  setAmazonCountries: (amazonCountries: ICountry[]) => void;
}
export const useCountriesStore = create<ICountriesState>((set) => ({
  amazonCountries: [],
  amazonCountriesLoaded: false,
  setAmazonCountries: (amazonCountries: ICountry[]) => {
    return set((): Partial<ICountriesState> => {
      return { amazonCountries, amazonCountriesLoaded: true };
    });
  },
}));
