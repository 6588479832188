import Chart from "chart.js/auto";
import {
  ActiveElement,
  ChartEvent,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import React, { useRef, useState, useEffect, useMemo } from "react";
import { TooltipData, tooltipPositions } from "./tooltip-types";
import { useMoveDot } from "./useMoveDot";
import { Radar } from "react-chartjs-2";
import { createOptions } from "./chart-data";
import { RadarTooltip } from "./RadarTooltip";
import { labelClickPlugin } from "./label-click-plugin";
import Arrows from "@assets/icons/arrows";
import styles from "./styles.module.scss";
import LongArrow from "@assets/icons/long-arrow";
import CloseEye from "@assets/icons/iconCloseEye";

Chart.register(
  labelClickPlugin,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
);

interface RadarChartProps {
  titles?: string[];
  productOneValues: number[];
  productTwoValues: number[];
  productOneTitles?: string[];
  productTwoTitles?: string[];
  datasets: any[];
  activeIndex: number;
  asin: string;
  blockChanging?: boolean;
  onDotClick?: (index: number) => void;
  isDragEnabled?: boolean;
  showTooltipWithArrow?: boolean;
  showTooltip?: boolean;
  width?: number;
  height?: number;
  labelFontSize?: number;
  padding?: number;
  update?: (productData: number[]) => void;
}

export const RadarChart: React.FC<RadarChartProps> = ({
  datasets,
  activeIndex,
  blockChanging,
  onDotClick,
  isDragEnabled,
  showTooltipWithArrow,
  showTooltip,
  asin,
  productOneTitles = undefined,
  productTwoTitles = undefined,
  productOneValues,
  productTwoValues,
  width = 450,
  height = 450,
  labelFontSize = 16,
  padding = 23,
  update,
}) => {
  const [selectedLabelIndex, setSelectedLabelIndex] = useState<number | null>(
    null,
  );
  const [buttonPosition, setButtonPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const initialLabels = useMemo(
    () => [
      ...new Set([
        ...(Array.isArray(productOneTitles) ? productOneTitles : []),
        ...(Array.isArray(productTwoTitles) ? productTwoTitles : []),
      ]),
    ],
    [productOneTitles, productTwoTitles],
  );

  const initialProductOneValues = useMemo(
    () => productOneValues,
    [productOneValues],
  );
  const initialProductTwoValues = useMemo(
    () => productTwoValues,
    [productTwoValues],
  );

  const [labels, setLabels] = useState(initialLabels);
  const [filteredProductOneValues, setFilteredProductOneValues] = useState(
    initialProductOneValues,
  );
  const [filteredProductTwoValues, setFilteredProductTwoValues] = useState(
    initialProductTwoValues,
  );

  useEffect(() => {
    setLabels(initialLabels);
    setFilteredProductOneValues(initialProductOneValues);
    setFilteredProductTwoValues(initialProductTwoValues);
  }, [asin, initialLabels, initialProductOneValues, initialProductTwoValues]);

  const completeData = (data: number[], labels: string[]) => {
    const dataLength = data.length;
    const labelsLength = labels.length;

    if (dataLength < labelsLength) {
      const filler = new Array(labelsLength - dataLength).fill(0);
      data = [...data, ...filler];
    }

    if (dataLength !== labelsLength + 1 || data[dataLength] !== data[0]) {
      data = [...data.slice(0, labelsLength), data[0]];
    }

    return data;
  };

  const compareDataSet = useMemo(() => {
    return datasets.map((item, index) => {
      if (index === 0 && filteredProductOneValues?.length > 0) {
        return {
          ...item,
          data: completeData(filteredProductOneValues, labels),
        };
      } else if (index === 1 && filteredProductTwoValues?.length > 0) {
        return {
          ...item,
          data: completeData(filteredProductTwoValues, labels),
        };
      }
      return item;
    });
  }, [datasets, filteredProductOneValues, filteredProductTwoValues, labels]);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipData, setTooltipData] = useState<TooltipData>(null);
  const options = useMemo(
    () => ({
      ...createOptions({ labelFontSize }),
      layout: {
        padding: {
          left: 20,
        },
      },
    }),
    [labelFontSize],
  );

  const chartRef = useRef<Chart<"radar", number[], string>>(null);

  useMoveDot(chartRef, isDragEnabled, activeIndex, update, labels);

  useEffect(() => {
    if (chartRef.current && showTooltipWithArrow) {
      const chart = chartRef.current;
      const meta = chart.getDatasetMeta(0);
      const element = meta.data[activeIndex];

      if (element) {
        chart.tooltip.setActiveElements(
          [{ datasetIndex: 0, index: activeIndex }],
          { x: element.x, y: element.y },
        );
        chart.update();
      }
    }
  }, [showTooltipWithArrow, activeIndex]);
  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;

      chart.data.datasets.forEach((dataset: any) => {
        const borderColor = dataset.borderColor;

        dataset.pointBackgroundColor = dataset.data.map(
          (_: number, index: number) =>
            index === activeIndex ? borderColor : "transparent",
        );
        dataset.pointBorderColor = dataset.data.map(
          (_: number, index: number) =>
            index === activeIndex ? borderColor : "transparent",
        );
        dataset.pointRadius = dataset.data.map((_: number, index: number) =>
          index === activeIndex ? 6 : 0,
        );
        dataset.pointHoverRadius = dataset.data.map(
          (_: number, index: number) => (index === activeIndex ? 12 : 0),
        );
      });

      chart.update();
    }
  }, [activeIndex, datasets]);

  const handleChartClick = (_: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0) {
      const firstElement = elements[0];
      const index = firstElement.index;

      if (onDotClick) {
        onDotClick(index);
      }
    }
  };

  const handleLabelClick = (index: number) => {
    if (onDotClick) {
      onDotClick(index);
    }

    setSelectedLabelIndex(index);

    if (chartRef.current) {
      const chart = chartRef.current;
      const centerX = chart.width / 2;
      const centerY = chart.height / 2;
      const scale = chart.scales.r;

      const labelRadius = scale.drawingArea + 20;
      const angle = (index / labels.length) * (2 * Math.PI) - Math.PI / 2;

      const x = centerX + labelRadius * Math.cos(angle);
      const y = centerY + labelRadius * Math.sin(angle);

      setButtonPosition({ x, y });
    }
  };

  const handleDeleteLabel = () => {
    if (selectedLabelIndex !== null) {
      setLabels((prevLabels) =>
        prevLabels.filter((_, i) => i !== selectedLabelIndex),
      );
      setFilteredProductOneValues((prevValues) =>
        prevValues.filter((_, i) => i !== selectedLabelIndex),
      );
      setFilteredProductTwoValues((prevValues) =>
        prevValues.filter((_, i) => i !== selectedLabelIndex),
      );
      setSelectedLabelIndex(null);
    }
  };
  const shouldShowDeleteButton =
    location.pathname === "/compare" || location.pathname === "/my-product";
  return (
    <div
      style={{ width, height, padding, position: "relative" }}
      className={styles.radarChart}
    >
      {showTooltipWithArrow && (
        <>
          <div className={styles.tooltipWithArrow}>
            <Arrows /> Drag selected vertex to see our predictions
          </div>
          <div className={styles.arrow}>
            <LongArrow />
          </div>
        </>
      )}
      <Radar
        ref={chartRef}
        options={{
          ...options,
          onClick: handleChartClick,
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              external: ({ tooltip }) => {
                if (!blockChanging || !isDragEnabled) {
                  const needOpen = tooltip.opacity === 1;
                  if (!needOpen) {
                    setIsTooltipOpen(false);
                    return;
                  }
                  const caretNotMove =
                    tooltipData &&
                    tooltip.caretY === tooltipData.caretY &&
                    tooltip.caretX === tooltipData.caretX;
                  if (isTooltipOpen && caretNotMove) return;
                  setTooltipData({
                    dataPoints: tooltip.dataPoints,
                    caretY: tooltip.caretY,
                    caretX: tooltip.caretX,
                    title: tooltip.title[0] ?? "",
                    index: tooltip.dataPoints[0]?.dataIndex ?? -1,
                  });
                  setIsTooltipOpen(true);
                }
              },
            },
            onLabelClick: handleLabelClick,
          },
          interaction: {
            mode: "index",
            intersect: true,
          },

          events: blockChanging
            ? []
            : ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
        }}
        data={{
          labels,
          datasets: compareDataSet,
        }}
      />
      {showTooltip && tooltipData && (
        <RadarTooltip
          data={tooltipData}
          isOpen={isTooltipOpen}
          style={tooltipPositions[tooltipData.index]}
        />
      )}
      {shouldShowDeleteButton &&
        selectedLabelIndex !== null &&
        buttonPosition && (
          <button
            onClick={handleDeleteLabel}
            className={styles.deleteButton}
            style={{
              position: "absolute",
              left: `${buttonPosition.x}px`,
              top: `${buttonPosition.y}px`,
            }}
          >
            <CloseEye />
          </button>
        )}
    </div>
  );
};
