import { useState } from "react";
import styles from "./styles.module.scss";

interface CollapseableItemProps {
  header: string;
  content: string;
}

export const CollapseableItem = ({
  header,
  content,
}: CollapseableItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <button className={styles.header} onClick={handleToggle}>
        <h2>{header}</h2>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <div className={styles.content}>{content}</div>}
    </div>
  );
};
