import { useEffect, useState } from "react";
// import { AIService } from "@services/AIService";
import SvgIcon from "@components/svg-icon/SvgIcon";
import SerpDataTable from "../serp-data-table/SerpDataTable";
import SerpDataCard from "../serp-data-card/SerpDataCard";
import "./serp-content.less";

export default function SerpContent() {
  const defaultState = {
    serpPhrase: "",
    serpTableData: [],
    serpCardListData: [],
  };

  const [state] = useState(defaultState);

  useEffect(() => {
    // getSERPData();
  }, []);

  // const getSERPData = () => {
  //   const data = {
  //     payload: {
  //       country: "us",
  //       query: state.serpPhrase,
  //     },
  //   };

  //   AIService.getAIResults("serp", data)
  //     .then((response) => {
  //       const result = response?.data?.response;
  //       setState((prev) => ({
  //         ...prev,
  //         tableData: result,
  //         serpCardListData: result["items"] || [],
  //       }));
  //     })
  //     .catch((error) => {});
  // };

  return (
    <div className="serp-content-container">
      <div className="serp-phrase">
        <span className="txt">Serp phrase</span>
        <div className="v-line"></div>
        <SvgIcon type="right" />
      </div>

      {/* @ts-ignore */}
      <SerpDataTable tableData={state.tableData} />

      {state.serpCardListData.map((card, i) => (
        <SerpDataCard cardData={card} key={"card-" + i} />
      ))}
    </div>
  );
}
