import { notification } from "antd";

import { AIService } from "@services/AIService";

export const getResult = async ({
  id,
  formData,
}: {
  id: string;
  formData: Record<any, any>;
}) => {
  try {
    if (id === "rewriting") {
      formData = {
        creativity_level: formData.creativity_level,
        original_paragraph: formData.original_paragraph,
      };
    }

    const { data } = await AIService.getAIResults(id, { payload: formData });

    return data;
  } catch (error) {
    if (error?.response?.data?.detail) {
      notification.error({
        message: error.response.data.detail,
      });
    }
  }
};
