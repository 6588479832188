import SvgIcon from "@components/svg-icon/SvgIcon";
import "./serp-data-card.less";

export default function SerpDataCard(props) {
  const { cardData } = props;

  const maxShowItem = 5;

  return (
    <div className="serp-data-card-container">
      <div className="sdc-summary-data">
        <span>{cardData?.domain}</span>
        <span>Ranking: #{cardData?.rank}</span>
        <span>DA: {cardData?.da || "-"}</span>
        <span>Word Count: {cardData?.word_count}</span>
      </div>
      <img
        src={cardData?.favicon}
        alt={cardData?.company}
        className="company-logo"
      />
      <h4 className="sdc-title">{cardData?.title}</h4>
      <h5 className="sdc-subtitle">{cardData?.subtitle || "-"}</h5>
      <div className="section-item-list">
        {(cardData?.asset || []).slice(0, maxShowItem).map((section, i) => (
          <div key={"section-data-" + i} className="section-data-item">
            <span className="section-tag-name">{section?.header_tag}</span>
            <span className="section-tag-value">{section?.header}</span>
            <SvgIcon type="down-circle" />
            <SvgIcon type="copy" />
          </div>
        ))}
      </div>
      {cardData?.asset?.length > maxShowItem ? (
        <div className="view-all-footer">
          <div className="view-all-txt">
            View all sections
            <SvgIcon type="down" />
          </div>
        </div>
      ) : null}
    </div>
  );
}
