import React, { useState } from "react";
import styles from "./styles.module.scss";
import { RadarChart } from "@components/Spider/RadarChart";
import Phases from "./Phases";
import { datasets } from "./mock";
import SwiperNavigation from "@components/ProductSwiper";
import ProductCard from "@components/ProductCard";
import { Phrase } from "src/store/insights/types";

interface AspectsAndPrasesProps {
  phrases: Phrase[];
  spiderTitles?: string[];
  spiderValues?: number[];
}

const AspectsAndPrases: React.FC<AspectsAndPrasesProps> = ({
  phrases,
  spiderTitles,
  spiderValues,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  const dynamicDataset = [{ ...datasets[0], data: spiderValues }];
  const dynamicRating = dynamicDataset[0].data[activeIndex];

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < spiderTitles.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : spiderTitles.length - 1,
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.aspects}>
        <h3>Aspects</h3>
        <div className={styles.aspectsContainer}>
          <div className={styles.radarBox}>
            <RadarChart
              productOneTitles={spiderTitles}
              datasets={dynamicDataset}
              activeIndex={activeIndex}
              blockChanging={true}
              titles={spiderTitles}
              productOneValues={[]}
              productTwoValues={[]}
            />
          </div>
          <div className={styles.infoBox}>
            <SwiperNavigation
              headerText={spiderTitles[activeIndex]}
              onNext={handleNext}
              onPrev={handlePrev}
            />
            <ProductCard
              rating={dynamicRating}
              headerText={spiderTitles[activeIndex]}
              improvementText={`Improving this aspect you will improve review score by ${improvementValue}`}
            />
          </div>
        </div>
      </div>

      <div className={styles.pharesContainer}>
        <Phases phrases={phrases} />
      </div>
    </div>
  );
};

export default AspectsAndPrases;
