import { useEffect } from "react";
import { Button } from "antd";

import { ProfileService } from "@services/ProfileService";
import { useQuery } from "react-query";

export const CancelBilling = () => {
  // TODO: change to useMutation
  const { isLoading, isRefetching, data, refetch } = useQuery(
    ["billing"],
    ProfileService.createBilling,
    {
      enabled: false,
    },
  );

  const handleCancelSubscription = async () => {
    refetch();
  };

  useEffect(() => {
    if (data?.url) {
      window.location.href = data.url;
    }
  }, [data]);

  return (
    <Button
      size="small"
      shape="round"
      type="text"
      className="plan__button"
      loading={isLoading || isRefetching}
      onClick={handleCancelSubscription}
    >
      Cancel the plan
    </Button>
  );
};
