import amazonLogo from "@assets/img/amazon-logo.svg";

// import SalesCopyFrameworks from "../ai-templates/sub-pages/sales-copy-frameworks/SalesCopyFrameworks";

export const tabList = [
  {
    title: "UNTITLED tab",
  },
  {
    title: "Fish are aquatic",
  },
];

export const templateList = [
  {
    id: "product-listing",
    title: `Product
        description
        generator`,
  },
  {
    id: "blog-post",
    title: `Blog post
        article
        generator`,
  },
  {
    id: "rewriting",
    title: `Text
        rewriter
        tool`,
  },
  {
    id: "promotion-ad",
    title: `Sales
        promotion
        generator`,
  },
  {
    id: "grammar-correction",
    title: `Grammar
        correction
        tool`,
  },
  // {
  //   id: 6,
  //   title: `Sales
  //       copy
  //       frameworks`,
  //   component: SalesCopyFrameworks,
  // },
  {
    id: "brand-names",
    title: `Brand
        name
        generator`,
  },
  {
    id: "business-insert-card",
    title: `Insert
        card
        generator`,
  },
];

export const serpCardListData = [
  {
    da: 36,
    ranking: 1,
    company: "soona",
    wordCounts: 2356,
    logo: amazonLogo,
    companySite: "soona.co",
    title:
      "The ultimate guide to Amazon product videos [+ 5 types that work best]",
    subtitle:
      "check our our thorough guide to best utilize product videos on Amazon.",
    sectionData: [
      {
        tagName: "h1",
        tagValue: "data supports using videos in your Amazon listings",
      },
      {
        tagName: "h2",
        tagValue: "who can post product videos?",
      },
      {
        tagName: "h3",
        tagValue: "Amazon product videos not available to all sellers",
      },
      {
        tagName: "h4",
        tagValue: "the pricing difference",
      },
      {
        tagName: "h5",
        tagValue: "5 Amazon product video types that are most effective",
      },
      {
        tagName: "h6",
        tagValue: "5 Amazon product video types that are most effective",
      },
    ],
  },
  {
    da: 36,
    ranking: 2,
    company: "amazon",
    wordCounts: 36958,
    logo: amazonLogo,
    companySite: "amazon.ca",
    title:
      "The Ultimate Guide to Video Game Writing and Design: Dille, Flint, Zuur Platten, John",
    subtitle: `The Ultimate Guide to Video Game Writing and Design: Dille, Flint,
            Zuur Platten, John: 9781580650663: Books - Amazon.ca`,
    sectionData: [
      {
        tagName: "h1",
        tagValue: "data supports using videos in your Amazon listings",
      },
      {
        tagName: "h2",
        tagValue: "who can post product videos?",
      },
      {
        tagName: "h3",
        tagValue: "Amazon product videos not available to all sellers",
      },
      {
        tagName: "h4",
        tagValue: "the pricing difference",
      },
      {
        tagName: "h5",
        tagValue: "5 Amazon product video types that are most effective",
      },
      {
        tagName: "h6",
        tagValue: "5 Amazon product video types that are most effective",
      },
    ],
  },
];
