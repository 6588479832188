import { Button } from "antd";
import { useRef, useState, useEffect } from "react";

type RemoveBackgroundToolSidebarProps = {
  isLoading?: boolean;
  outputImage?: string;
  isError?: boolean;
  onRetryClick?: () => void;
};

export const RemoveBackgroundToolSidebar = ({
  isError,
  isLoading,
  onRetryClick,
  outputImage,
}: RemoveBackgroundToolSidebarProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [previewImage, setPreviewImage] = useState<string>(null);

  useEffect(() => {
    const blobs = [];

    if (canvasRef.current && outputImage) {
      const ctx = canvasRef.current.getContext("2d");
      const max_width = 300;
      const max_height = 300;
      const img = new Image();
      canvasRef.current.getContext("2d").drawImage(img, 0, 0);

      img.onload = () => {
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height, // size of image
          0,
          0,
          max_width,
          max_height, // size of canvas
        );

        canvasRef.current.toBlob((blob) => {
          const objectUrl = URL.createObjectURL(blob);

          blobs.push(objectUrl);
          setPreviewImage(objectUrl);
        });
      };

      img.src = outputImage;
    }

    return () => {
      blobs.forEach((blob) => {
        if (blob) {
          URL.revokeObjectURL(blob);
        }
      });
    };
  }, [outputImage]);

  if (isError) {
    return (
      <>
        <div
          className="remove-background-tool__title"
          style={{ whiteSpace: "normal" }}
        >
          Something went wrong, try again later
        </div>
        <Button
          block
          type="primary"
          shape="round"
          className="black-btn"
          onClick={onRetryClick}
        >
          Retry
        </Button>
      </>
    );
  }

  return (
    <>
      <canvas
        height={300}
        width={300}
        style={{
          position: "fixed",
          left: "-1000000px",
          top: "-100000px",
          pointerEvents: "none",
        }}
        ref={canvasRef}
      />
      <div className="remove-background-tool__title">Get Your Assets Ready</div>

      {isLoading && (
        <>
          <div className="remove-background-tool__placeholder" />
          <div className="remove-background-tool__placeholder" />
        </>
      )}

      {!isLoading && (
        <>
          <Button
            block
            type="primary"
            shape="round"
            className="black-btn"
            href={previewImage || outputImage}
            download="transparent_preview.png"
          >
            Download
          </Button>
          <div className="remove-background-tool__legend">
            Preview image 300 x 300
          </div>
          <Button
            block
            type="ghost"
            shape="round"
            href={outputImage}
            download="transparent_high_resolution.png"
          >
            Download in high resolution
          </Button>
          <div className="remove-background-tool__legend">
            Full image 1920 x 1080
          </div>
        </>
      )}
    </>
  );
};
