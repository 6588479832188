import { Outlet, useNavigate } from "react-router-dom";
import { IUserProfile } from "src/types/user.types";
import { useCallback, useEffect } from "react";
import { ROUTES } from "src/router/routes";
import { UserServices } from "@services/user.services";
import { IUserState, useUserStore } from "src/store/user.state";
import { notification, Spin } from "antd";
import { SubscriptionModal } from "@components/SubscriptionModal";
import { PurchaseSubscriptionModal } from "@components/PurchaseSubscriptionModal";
import { AppHeader } from "@components/AppHeader";
import HelpPage from "@pages/help/HelpPage";
import { useTranslation } from "react-i18next";
import { LS } from "src/constants/localStorage.constants";
import { AmazonOnboardingStatus } from "src/constants/auth.constants";
import { CustomerProfile } from "@entities/CustomerProfile";

interface HomeProps {
  hideHeader?: boolean;
}

export const Home: React.FC<HomeProps> = ({ hideHeader }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userLoaded = useUserStore((state: IUserState) => state.userLoaded);

  const processUser = useCallback(
    (user: CustomerProfile) => {
      const status = localStorage.getItem(LS.AmazonOnboardingStatus);

      if (
        !user.is_amazon_seller &&
        status !== AmazonOnboardingStatus.Completed
      ) {
        navigate(ROUTES.AMAZON_ONBOARDING);
      } else {
        localStorage.removeItem(LS.AuthProvider);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (userLoaded) {
      const user = useUserStore.getState().user;
      processUser(user);
      UserServices.getProfile()
        .then((user: CustomerProfile) => {
          useUserStore.getState().setUser(user);
          processUser(user);
        })
        .catch((error) => {
          notification.error({
            message:
              error?.response?.data?.detail ??
              t("message.get_user_fail_message"),
          });
        });
      return;
    }
  }, [processUser, t]);

  return (
    <>
      <SubscriptionModal />
      <PurchaseSubscriptionModal />
      <div style={hideHeader && { display: "none" }}>
        <AppHeader />
      </div>
      <HelpPage />
      {userLoaded ? <Outlet /> : <Spin size="large" className="app__spinner" />}
    </>
  );
};
