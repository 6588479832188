import { Stat, IntroData } from "./types";

export const statsData: Stat[] = [
  {
    value: "400+",
    title: "Projects completed",
    description: "We’ve helped build over 400 amazing projects.",
  },
  {
    value: "600%",
    title: "Return on investment",
    description: "Our customers have reported an average of ~600% ROI.",
  },
  {
    value: "10k",
    title: "Global downloads",
    description: "Our free UI kit has been downloaded over 10k times.",
  },
];

export const introData: IntroData = {
  title: "Spread the word",
  supTitle:
    "Know a Seller or Vendor that could benefit from Nyle? Invite them and get a $250 Amazon gift card when they sign up for a paid subscription or a $1000 Amazon gift card if they spend more than $20k/month on advertising. They'll get 50% off their Nyle fees for the first two months.*",
  link: "nyle.ai/demo?referral_code=MdTR7jL0FM",
};
