import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29525 4.44434C0.845258 3.99435 0.845258 3.26477 1.29525 2.81478C1.74524 2.36479 2.47482 2.36479 2.92481 2.81478L5.65825 5.54823C6.11235 6.00232 6.84859 6.00232 7.30269 5.54823L10.0361 2.81478C10.4861 2.36479 11.2157 2.36479 11.6657 2.81478C12.1157 3.26477 12.1157 3.99435 11.6657 4.44434L7.30269 8.80734C6.84859 9.26144 6.11235 9.26144 5.65825 8.80734L1.29525 4.44434Z"
        fill="currentColor"
      />
    </svg>
  );
};
