import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.13477 22.5H28.6348" stroke="currentColor" strokeWidth="2" />
      <path d="M11.1348 26.5H19.6348" stroke="currentColor" strokeWidth="2" />
      <path
        d="M6.1352 22.5C6.1352 14.5 5.1352 3.00003 15.1352 3C25.6352 2.99997 24.6352 14.5 24.6352 22.5"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
