import React, { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import ProductCode from "@components/ProductCode";
import ProductInput from "@components/ProductInput";
import { Product } from "@services/amazon/products/types";
import { OverviewType } from "@pages/Overview";
import { useProductStore } from "src/store/overviewProduct.state";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import { GraphsService } from "@services/amazon/graph/graph.service";
import { mockDataMultySelect } from "../mock";
import classNames from "classnames";

interface MockDataType {
  type: string;
  name: string;
  price: string;
  rating: number;
  reviews: Record<string, number>;
  image: string;
  code: string;
}

interface ProductCardProps {
  productTwo?: boolean;
  product: Product | null;
  label: string;
  selectType: "your" | "competitor";
  mockData: MockDataType;
  type: OverviewType;
  showInput?: boolean;
  isSmallSize?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productTwo = false,
  product,
  label,
  selectType,
  mockData,
  type,
  isSmallSize = false,
}) => {
  const { selectedProduct, selectedProductToCompare } = useProductStore();
  const [spiders, setSpiders] = useState<any>(null);
  const [ratings, setRatings] = useState<{ [key: string]: number | null }>({});
  const spiderData = product?.spiderDots || product;
  const productPrice = spiderData?.price ?? 0;
  const productImage =
    spiderData?.picture_url ?? product?.image ?? mockData.image;
  const productAsin = spiderData?.asin ?? product?.asin ?? mockData.code;
  const [totalReviews, setTotalReviews] = useState<number>(0);
  const [scoreMain, setScoreMain] = useState<{ [key: string]: number | null }>(
    {},
  );

  const getStarReviews = (reviews: Record<string, number>) => {
    return [
      { label: "5 STAR", value: reviews["5_star"] },
      { label: "4 STAR", value: reviews["4_star"] },
      { label: "3 STAR", value: reviews["3_star"] },
      { label: "2 STAR", value: reviews["2_star"] },
      { label: "1 STAR", value: reviews["1_star"] },
    ];
  };
  const getDateRange = () => {
    const today = new Date();
    const date_end = today.toISOString().split("T")[0];

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 8);
    const date_start = oneMonthAgo.toISOString().split("T")[0];

    return { date_start, date_end };
  };
  const rat = product?.rating ?? mockData.rating;
  const reviews = mockData.reviews;
  const productsToRender = Array.isArray(product)
    ? product.flatMap((item) => item.spiderDots || [item])
    : product?.spiderDots && Array.isArray(product.spiderDots)
      ? product.spiderDots
      : [product || mockData];

  const uniqueProductsToRender = productsToRender.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.asin === item.asin),
  );

  const fetchCategories = async () => {
    const spider = new CategoriesService();
    try {
      const asins = selectedProductToCompare.flatMap((product) =>
        product.map((p) => p.asin),
      );
      if (asins.length > 1) {
        const spidersData = await spider.getCategories(selectedProduct?.asin);
        setSpiders(spidersData);
        setScoreMain(spidersData);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedProductToCompare) {
      fetchCategories();
    }
  }, [selectedProductToCompare]);

  const getScoresForAsins = (asins: string[]) => {
    const scores: { [key: string]: number | null } = {};

    asins.forEach((asin) => {
      let found = false;
      for (const spider of spiders) {
        const item = spider.items.find((item) => item.asin === asin);
        if (item) {
          scores[asin] = item.score;
          found = true;
          break;
        }
      }
      if (!found) {
        scores[asin] = null;
      }
    });

    return scores;
  };

  useEffect(() => {
    if (
      spiders &&
      Array.isArray(spiders) &&
      selectedProductToCompare &&
      selectedProductToCompare.length > 0
    ) {
      const asins = selectedProductToCompare.flat().map((p) => p.asin);
      const newRatings = getScoresForAsins(asins);
      setRatings((prevRatings) => ({
        ...prevRatings,
        ...newRatings,
      }));
    }

    if (selectedProduct && selectedProduct.asin && spiders) {
      const score = getScoresForAsins([selectedProduct.asin]);

      setRatings((prevRatings) => ({
        ...prevRatings,
        ...score,
      }));
    }
  }, [selectedProductToCompare, selectedProduct, spiders]);

  const handleReviewScore = async () => {
    const spider = new GraphsService();
    const { date_start, date_end } = getDateRange();

    const spiderDot = await spider.getGraph(
      selectedProduct.asin,
      date_start,
      date_end,
    );
    setTotalReviews(spiderDot.length);
  };
  useEffect(() => {
    if (selectedProduct) {
      handleReviewScore();
    }
  }, [selectedProduct]);

  return (
    <div className={styles.product}>
      {selectType === "your" ? (
        <>
          <div className={styles.productCode}>
            <ProductInput
              label={label}
              selectType={selectType}
              productTwo={productTwo}
              type={type}
              onProductSelect={selectedProduct}
            />
            <ProductCode code={product?.asin || mockData.code} />
          </div>
          <div
            className={classNames(
              styles.imgRateContainer,
              styles["product-borderedOne"],
            )}
          >
            <div className={styles.image}>
              <img
                src={
                  product?.image_link ?? product?.picture_url ?? mockData.image
                }
                alt={product?.title ?? mockData.name}
              />
            </div>
            <div className={styles.details}>
              <span className={styles.price}>
                ${product?.price ?? mockData.price}
              </span>
              <div className={styles.rating}>
                {[...Array(5)].map((_star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <span key={index} className={styles.star}>
                      {ratingValue <= (ratings[selectedProduct?.asin] || rat)
                        ? "★"
                        : "☆"}
                    </span>
                  );
                })}
                <span className={styles.ratingValue}>
                  {ratings[selectedProduct?.asin]
                    ? ratings[selectedProduct.asin]?.toFixed(1)
                    : rat.toFixed(1)}
                </span>
              </div>
              <span className={styles.ratingsCount}>Rating: 177</span>

              <ul className={styles.reviews}>
                {getStarReviews(reviews).map((review, index) => (
                  <li key={index} className={styles.reviewItem}>
                    <span className={styles.reviewLabel}>{review.label}</span>
                    <div className={styles.reviewBar}>
                      <div
                        className={styles.reviewFill}
                        style={{ width: `${review.value}%` }}
                      ></div>
                    </div>
                    <span className={styles.reviewPercentage}>
                      {review.value}%
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {(uniqueProductsToRender.length > 0
              ? uniqueProductsToRender
              : mockDataMultySelect
            ).map((item, index) => (
              <div
                key={index}
                className={classNames(
                  styles.comptitorProduct,
                  styles["product-bordered"],
                )}
              >
                <div
                  className={classNames({
                    [styles[
                      `productVisualRow${uniqueProductsToRender.length || mockDataMultySelect.length}`
                    ]]: true,
                  })}
                >
                  <img
                    src={
                      item?.image_link ??
                      item?.picture_url ??
                      productImage ??
                      mockData.image
                    }
                    className={classNames(
                      styles.competitorImage,
                      uniqueProductsToRender.length !== 1 &&
                        styles[
                          `competitorImage${uniqueProductsToRender.length}`
                        ],
                    )}
                  />
                  <div className={styles.productInfo}>
                    <div
                      className={classNames(styles.productBetween, {
                        [styles.productBetweenOne]:
                          uniqueProductsToRender.length === 1,
                      })}
                    >
                      <div className={styles.priceAndCode}>
                        <span
                          className={classNames({
                            [styles.priceCompetitor]:
                              uniqueProductsToRender.length === 1,
                            [styles[
                              `priceCompetitor${uniqueProductsToRender.length}`
                            ]]: uniqueProductsToRender.length !== 1,
                          })}
                        >
                          ${item.price ?? productPrice ?? mockData.price}
                        </span>
                        <ProductCode
                          code={item.asin || productAsin || mockData.code}
                        />
                      </div>
                      <div className={styles.rating}>
                        {Array.from({ length: 5 }, (_, index) => {
                          const ratingValue = index + 1;
                          return (
                            <span key={index} className={styles.star}>
                              {ratingValue <= (ratings[item.asin] || rat)
                                ? "★"
                                : "☆"}
                            </span>
                          );
                        })}
                        <div className={styles.ratingContainer}>
                          <span className={styles.ratingValue}>
                            {(ratings[item?.asin] || rat).toFixed(1)}
                          </span>
                          {uniqueProductsToRender.length !== 1 && (
                            <span className={styles.ratingScore}>
                              Rating: 177
                            </span>
                          )}
                        </div>
                      </div>
                      {uniqueProductsToRender.length === 1 && (
                        <>
                          <span className={styles.ratingScore}>
                            Rating: 177
                          </span>
                          <ul className={styles.reviews}>
                            {getStarReviews(reviews).map((review, index) => (
                              <li key={index} className={styles.reviewItem}>
                                <span className={styles.reviewLabel}>
                                  {review.label}
                                </span>
                                <div className={styles.reviewBar}>
                                  <div
                                    className={styles.reviewFill}
                                    style={{ width: `${review.value}%` }}
                                  ></div>
                                </div>
                                <span className={styles.reviewPercentage}>
                                  {review.value}%
                                </span>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductCard;
