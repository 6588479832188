import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import ChevronDown from "@assets/icons/chevron-down";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { DropdownItems } from "./renderDropdown";
import { COMPARING_TO } from "./utils";

interface CustomSelectProps {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
}

const CustomSelect = ({ dateRange }: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState("Previous week");
  const dropdownRef = useOutsideClick(() => setIsOpen(false));

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemClick = (label: string) => {
    setLabel(label);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={classNames(styles.dropdownContainer, {
          [styles.dropdownActive]: isOpen,
        })}
      >
        <button
          className={classNames(styles.customSelect, {
            [styles.active]: isOpen,
          })}
          onClick={toggleDropdown}
        >
          {COMPARING_TO + label.toLowerCase()}
          <div className={styles.arrow}>
            <ChevronDown />
          </div>
        </button>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            <DropdownItems
              handleItemClick={handleItemClick}
              currentDate={dateRange.startDate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
