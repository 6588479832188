import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavItem, createNavItems } from "./navItems";
import { ROUTES } from "src/router/routes";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { useUserStore } from "src/store/user.state";

export const HeaderNav: React.FC = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const popupRef = useOutsideClick(() => setMenuOpen(false));

  const { user } = useUserStore();

  const navItems: NavItem[] = [
    { text: t("label.dashboard"), url: `${ROUTES.ROOT}` },
    {
      text: t("Revenue"),
      onClick: () => {
        setMenuOpen(!menuOpen);
      },
    },
    { text: t("finance"), url: "/finance" },
    { text: t("Supply chain"), url: "/supply-chain" },
    { text: t("Market intelligence"), url: "/market-intelligence" },
  ];

  if (user?.is_staff) {
    navItems.push({ text: t("Customers"), url: "/customers" });
  }

  return (
    <nav className="app-header__nav" ref={popupRef}>
      {createNavItems(navItems, menuOpen, setMenuOpen, popupRef)}
    </nav>
  );
};
