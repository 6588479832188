import { OptimisationItem } from "../OptimisationItem";
import SectionContainer from "@pages/CROModule/components/CROPage/components/Sections/components/SectionContainer";
import { optimizationData } from "./mock";
import LiquidFillGauge from "react-liquid-gauge";
import styles from "./styles.module.scss";
import CustomInput from "@components/СustomInput";
import { OptimisationButton } from "../../OptimisationButton";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateCroInfo } from "src/utils/updateCROInfo";
import { CROInfoType } from "@services/amazon/products/types";

interface ListingOptimisationProps {
  croInfo: CROInfoType;
  isWhatIf: boolean;
}

export const ListingOptimisation = ({
  isWhatIf,
  croInfo,
}: ListingOptimisationProps) => {
  const nav = useNavigate();
  const [data, setData] = useState(optimizationData);

  useEffect(() => {
    if (!croInfo) {
      return;
    }
    setData((prevData) => updateCroInfo([...prevData], croInfo));
  }, [croInfo]);

  return (
    <OptimisationItem title="Listing optimization">
      <div className={styles.card__container}>
        {data.map((item) =>
          !isWhatIf ? (
            <div className={styles.card} key={item.label}>
              <div className={styles.header}>
                <span className={styles.label}>{item.label}</span>
              </div>
              {item.percentage ? (
                <LiquidFillGauge
                  value={item.percentage}
                  style={{ marginLeft: "-30px" }}
                  height={80}
                  width={400}
                  textSize={1}
                  offsetX={20}
                  textOffsetX={-110}
                  textOffsetY={20}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={3}
                  gradient
                  circleStyle={{ display: "none" }}
                  waveStyle={{
                    fill: item.color,
                    width: "400px",
                    r: "200",
                  }}
                  textStyle={{
                    className: styles.text,
                    fill: "#fff",
                    fontFamily: "Arial",
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAnchor: "start",
                    dominantBaseline: "middle",
                  }}
                  waveTextStyle={{
                    className: styles.text,
                    fill: "#fff",
                    fontFamily: "Arial",
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAnchor: "start",
                    dominantBaseline: "middle",
                  }}
                />
              ) : (
                <span className={styles.notOptimized}>{item.text}</span>
              )}
            </div>
          ) : (
            <div className={classNames(styles.card, styles.whatIf)}>
              <CustomInput
                label={item.label + " score, %"}
                value={item.percentage}
                onChange={(e) => {
                  setData(
                    data.map((el) =>
                      el.label === item.label
                        ? { ...el, percentage: Number(e.target.value) }
                        : el,
                    ),
                  );
                }}
                className={styles.input}
                blue
              />
              <OptimisationButton
                onClick={() => {
                  nav(item.route);
                }}
              />
            </div>
          ),
        )}
        {isWhatIf && <button className={styles.button}>Optimize</button>}
      </div>
    </OptimisationItem>
  );
};
