import styles from "./styles.module.scss";
import { useProductStore } from "src/store/overviewProduct.state";
import ProductCard from "./ProductCard";
import { mockData } from "./mock";
import { OverviewType } from "@pages/Overview";
import ProductInput from "@components/ProductInput";
import ProductCode from "@components/ProductCode";

function Products({ type }: { type: OverviewType }) {
  const { selectedProduct, selectedProductToCompare } = useProductStore();

  const competitorProducts = Array.isArray(selectedProductToCompare)
    ? selectedProductToCompare.flat()
    : [];

  const competitorCount = competitorProducts.length;
  const isSmallSize = competitorCount >= 5;

  return (
    <div className={styles.container}>
      <div className={`${styles.competitorStack}`}>
        <ProductCard
          product={selectedProduct}
          label="Your Product"
          selectType="your"
          mockData={mockData[0]}
          type={type}
        />
      </div>
      <div className={`${styles.competitorStack}`}>
        <div className={styles.productCode}>
          <ProductInput
            label="All Product"
            selectType="competitor"
            productTwo={true}
            type={type}
            onProductSelect={selectedProduct}
          />
          <div className={styles.productCodeList}>
            {competitorProducts.length > 0 ? (
              competitorProducts.map((product, index) => (
                <div className={styles.productCodeItem} key={index}>
                  <ProductCode code={product.asin} />
                </div>
              ))
            ) : (
              <div className={styles.productCodeItem}>
                <ProductCode code={mockData[0].code} />
              </div>
            )}
          </div>
        </div>

        <div className={styles.competitorS}>
          {Array.isArray(selectedProductToCompare) &&
          selectedProductToCompare.length > 0 ? (
            selectedProductToCompare.map((product, index) => (
              <ProductCard
                key={index}
                productTwo={true}
                product={product}
                label={`Competitor ${index + 1}`}
                selectType="competitor"
                mockData={mockData[0]}
                type={type}
                showInput={false}
                isSmallSize={isSmallSize}
              />
            ))
          ) : (
            <ProductCard
              productTwo={true}
              product={null}
              label="Competitor Product"
              selectType="competitor"
              mockData={mockData[0]}
              type={type}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Products;
