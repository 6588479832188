export default () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 6.5V5.5H11V6.5H0Z" fill="white" />
    </svg>
  );
};
