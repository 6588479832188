import ReactDOM from "react-dom/client";
import { CustomRouter, history } from "@services/router";
import "./locales";
import "./style/variables.css";
import "./style/fonts.css";
import "./style/main.less";
import App from "./App";
import { intercept } from "./utils/interceptor";
import { useConfigStore } from "./store/config.state";
import { sentryInit } from "./instrument";

intercept();

function initializeApp() {
  sentryInit();

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <CustomRouter history={history}>
      <App />
    </CustomRouter>,
  );
}

useConfigStore.getState().fetchBackendConfig().then(initializeApp);
