import { notification } from "antd";
import { useQuery } from "react-query";

import { DocumentsService } from "@services/DocumentsService";

export const DocumentsQueryKey = "allDocuments";

const emptyArr = [];
export function useDocuments(queryKeys: string[] = emptyArr) {
  return useQuery(
    [DocumentsQueryKey, ...queryKeys],
    ({ queryKey }) => {
      const [, offset, folder = ""] = queryKey;

      return DocumentsService.getDocumentsList({
        page: offset ? parseInt(offset, 10) : undefined,
        folder: folder.split(",").filter(Boolean),
      });
    },
    {
      onError: (error: any) => {
        notification.error({
          message: error?.response?.data?.detail ?? "Failed to load documents",
        });
      },
      refetchOnMount: true,
    },
  );
}
