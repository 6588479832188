import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ActivateService } from "@services/activate.service";

const Activate = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleActivation = async () => {
      const success = await ActivateService.activateUser(uid, token);
      if (success) {
        navigate("/confirmed");
      } else {
        console.error("Activation failed");
      }
    };

    handleActivation();
  }, [uid, token, navigate]);

  return null;
};

export default Activate;
