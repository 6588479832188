import MockImg1 from "@assets/img/product1.png";
import MockImg2 from "@assets/img/product2.png";

export const products = [
  { imageUrl: MockImg1, code: "B07N48MTTH", rating: 4.0, isWhiteCode: true },
  {
    imageUrl: MockImg2,
    code: "B07N48MTTH",
    rating: 4.6,
    isWhiteCode: true,
    ratingGreen: true,
  },
];

export const datasets = [
  {
    label: "Your product",
    data: [2.9, 3.5, 2.5, 3.5, 1.5, 3.8, 2.8],
    backgroundColor: "rgba(82, 149, 224, 0.08)",
    borderColor: "#5295E0",
    pointBackgroundColor: "transparent",
    pointHoverBackgroundColor: "#5295E0",
    pointBorderColor: "transparent",
    pointHoverBorderColor: "#5295E0",
    pointRadius: 10,
    pointHoverRadius: 6,
    borderWidth: 1,
  },
  {
    label: "Competitors average",
    data: [4.1, 4.7, 3.8, 3.9, 4.8, 4.2, 4.7],
    backgroundColor: "transparent",
    borderColor: "#00EEFF",
    pointBackgroundColor: "transparent",
    pointHoverBackgroundColor: "#00EEFF",
    pointBorderColor: "transparent",
    pointHoverBorderColor: "#00EEFF",
    pointRadius: 10,
    pointHoverRadius: 6,
    borderWidth: 1,
  },
];

export const headerTexts = [
  "Effectiveness",
  "Ingredients",
  "Price",
  "Value",
  "Side effects",
  "Taste",
  "Brand",
];

export const profitAmounts = [
  "$1,350.39",
  "$1,250.30",
  "$1,450.50",
  "$1,200.10",
  "$1,500.75",
  "$1,600.79",
  "$1,200.40",
];
