export const optimizationContent = [
  {
    title: "Product Optimization Score",
    description:
      "Product Optimization Score is the overall quality score for assessing success rate of a product based on customer sentiment analysis, content score and customer experience, which together define Product Market Fit",
  },
  {
    title: "Optimized",
    description:
      "Our Full Product Optimization Score indicates good quality with the potential for further improvements",
  },
];
