import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Alert, Collapse, notification, Spin, Tabs } from "antd";

import { useTitle } from "@hooks/useTitle";
import { Switcher } from "@components/Switcher";
import { ShopService } from "@services/ShopService";
import { ProfileQueryKey } from "@hooks/useProfile";
import { RecurringIntervalEnum } from "@entities/RecurringIntervalEnum";
import { CustomerProfile } from "@entities/CustomerProfile";

import { Offer } from "./ui/Offer";
import { Quota } from "./ui/Quota";
import { CancelBilling } from "./ui/CancelBilling";
import "./plan.less";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const TITLE = "My plan";

const SUBSCRIPTION_TYPE_AI = "ai-write";
const SUBSCRIPTION_TYPE_IMAGE = "image-enhance";

type SUBSCRIPTIONS_UI_TYPES =
  | typeof SUBSCRIPTION_TYPE_AI
  | typeof SUBSCRIPTION_TYPE_IMAGE;

type SUBSCRIPTION_TAB = {
  tab: string;
  id: SUBSCRIPTIONS_UI_TYPES;
};

const SUBSCRIPTIONS_LIST: SUBSCRIPTION_TAB[] = [
  { tab: "AI Writing", id: SUBSCRIPTION_TYPE_AI },
  { tab: "Image Enhance", id: SUBSCRIPTION_TYPE_IMAGE },
];

export const Plan = () => {
  useTitle(`Nyle - ${TITLE}`);

  const queryClient = useQueryClient();
  const location = useLocation();

  const [isYearMode, setYearMode] = useState(true);
  const [subscriptionType, setSubscriptionType] =
    useState<SUBSCRIPTIONS_UI_TYPES>(SUBSCRIPTION_TYPE_AI);
  const [selectedSubscriptionId, setSelectedSubscriptionId] =
    useState<number>(null);

  const profileData =
    queryClient.getQueryData<CustomerProfile>(ProfileQueryKey);

  useEffect(() => {
    // clear location state
    window.history.replaceState({}, document.title);
  }, []);

  const { isLoading, data: subscriptionsList } = useQuery(
    ["subscriptionsList"],
    ShopService.getSubscriptionsList,
  );

  const handeOfferSelect = async (subscribeId: number, priceId: number) => {
    setSelectedSubscriptionId(subscribeId);
    try {
      const { checkout_url } = await ShopService.subscriptionCheckout(
        subscribeId,
        priceId,
      );

      window.location.href = checkout_url;
    } catch (error) {
      notification.error({
        message: error.response.data.detail ?? "Something went wrong...",
      });
      setSelectedSubscriptionId(null);
    }
  };

  const handleSubscriptionTypeChange = useCallback(
    (type: SUBSCRIPTIONS_UI_TYPES) => {
      setSubscriptionType(type);
      setYearMode(false);
    },
    [],
  );

  const currentRecurringInterval: RecurringIntervalEnum = isYearMode
    ? RecurringIntervalEnum.YEAR
    : RecurringIntervalEnum.MONTH;

  const activeSubscription = profileData?.subscription;

  const needToShowErrorMessage = (
    location?.state as { showErrorMessage?: boolean }
  )?.showErrorMessage;

  const filteredSubscriptionsList = subscriptionsList
    ?.filter(({ prices }) =>
      prices.some(
        ({ recurring_interval }) =>
          recurring_interval === currentRecurringInterval,
      ),
    )
    .filter(({ benefits }) => {
      if (subscriptionType === SUBSCRIPTION_TYPE_AI) {
        return true;
      }

      return Boolean(benefits?.includes(subscriptionType));
    });

  return (
    <div className="settings-plan-page">
      <h2 className="page-main-title">{TITLE}</h2>

      <Tabs
        centered
        size="large"
        className="plan__subscription-tabs"
        onChange={handleSubscriptionTypeChange}
      >
        {SUBSCRIPTIONS_LIST.map((tabProps) => (
          <TabPane {...tabProps} key={tabProps.id} />
        ))}
      </Tabs>

      {needToShowErrorMessage && (
        <Alert
          message="You don't have active subscription"
          description="To use our service, please purchase a subscription"
          type="warning"
          showIcon
          closable
          className="settings-warning"
        />
      )}

      <Switcher
        items={[
          { label: "Monthly", active: !isYearMode },
          { label: "Yearly", active: isYearMode },
        ]}
        checked={isYearMode}
        onChange={() => setYearMode((mode) => !mode)}
      />

      {isLoading && (
        <div className="spin-wrapper">
          <Spin size="large" />
        </div>
      )}

      <div className="plan__offers-list">
        {!isLoading &&
          filteredSubscriptionsList &&
          filteredSubscriptionsList.map((subscription) => {
            const { name, description, prices, id, permanent_id } =
              subscription;
            const {
              amount,
              recurring_interval,
              discount,
              id: priceId,
            } = prices.find(
              ({ recurring_interval }) =>
                recurring_interval === currentRecurringInterval,
            ) ?? {};

            return (
              <Offer
                key={`${id}__${recurring_interval}`}
                title={name}
                subscriptionId={id}
                priceId={priceId}
                price={amount}
                interval={recurring_interval}
                benefits={(description ?? "").split(";").filter(Boolean)}
                discount={discount}
                active={
                  activeSubscription &&
                  permanent_id === activeSubscription?.permanent_id &&
                  recurring_interval === activeSubscription?.recurring_interval
                }
                onButtonClick={handeOfferSelect}
                loading={selectedSubscriptionId === id}
              />
            );
          })}
      </div>

      {filteredSubscriptionsList &&
        typeof activeSubscription?.quota === "number" &&
        typeof activeSubscription?.quota_used === "number" && (
          <div>
            <Quota
              quotaMax={activeSubscription.quota}
              quotaUsed={activeSubscription.quota_used}
            />
          </div>
        )}

      {activeSubscription && filteredSubscriptionsList && (
        <div className="plan__button-wrapper">
          <CancelBilling />
        </div>
      )}

      <div className="plan__faq">
        <Collapse className="plan__collapse" expandIconPosition="right">
          <Panel header="FAQ" key="faq">
            <Collapse
              defaultActiveKey="1"
              ghost
              accordion
              className="plan__accordion"
              expandIconPosition="right"
            >
              <Panel header="How do I change my current plan?" key="1">
                <p>
                  You can just select the plan you want to switch to and have
                  the changes being applied to your account immediately
                </p>
              </Panel>
              <Panel
                header="What is Nyle's refund and cancellation policy for AI Writing tool?"
                key="2"
              >
                <p>
                  You may cancel your subscription at any time. Cancellation
                  will take effect at the end of your billing cycle or, for paid
                  7-day trials. We will grant refunds within 7 calendar days of
                  your subscription start date (which automatically begins at
                  the end of your 7-day trial). We will not grant refunds beyond
                  that point.
                </p>
              </Panel>
              <Panel
                header="If I don't finish using 20000 words for a monthly period of time, can I get the balance transferred to the next month? "
                key="3"
              >
                <p>
                  If for some reason our product doesn't work and doesn't meet
                  your expectations, you will get the refund and up to 20,000
                  words included in your plan, but we can't transfer the unused
                  balance of words for the next month
                </p>
              </Panel>
            </Collapse>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};
