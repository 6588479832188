import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="15.6348"
        cy="15.5"
        r="12.5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M16.4067 17.8062H14.29C14.2956 17.3081 14.3343 16.8792 14.4062 16.5195C14.4837 16.1543 14.6138 15.825 14.7964 15.5317C14.9845 15.2384 15.2336 14.9479 15.5435 14.6602C15.8035 14.4277 16.0304 14.2064 16.2241 13.9961C16.4178 13.7858 16.57 13.57 16.6807 13.3486C16.7913 13.1217 16.8467 12.87 16.8467 12.5933C16.8467 12.2723 16.7969 12.0067 16.6973 11.7964C16.6032 11.5806 16.4593 11.4173 16.2656 11.3066C16.0775 11.196 15.8395 11.1406 15.5518 11.1406C15.3138 11.1406 15.0924 11.1932 14.8877 11.2983C14.6829 11.3979 14.5142 11.5529 14.3813 11.7632C14.2541 11.9735 14.1877 12.2502 14.1821 12.5933H11.7749C11.7915 11.8351 11.9658 11.2098 12.2979 10.7173C12.6354 10.2192 13.0864 9.85124 13.6509 9.61328C14.2153 9.36979 14.849 9.24805 15.5518 9.24805C16.3265 9.24805 16.9906 9.37533 17.5439 9.62988C18.0973 9.87891 18.5207 10.2469 18.814 10.7339C19.1073 11.2153 19.2539 11.8019 19.2539 12.4937C19.2539 12.9751 19.1598 13.404 18.9717 13.7803C18.7835 14.151 18.5373 14.4969 18.2329 14.8179C17.9285 15.1388 17.5938 15.4709 17.2285 15.814C16.9131 16.0962 16.6973 16.3923 16.5811 16.7021C16.4704 17.012 16.4123 17.38 16.4067 17.8062ZM14.041 20.3711C14.041 20.0169 14.1628 19.7236 14.4062 19.4912C14.6497 19.2533 14.9762 19.1343 15.3857 19.1343C15.7897 19.1343 16.1134 19.2533 16.3569 19.4912C16.606 19.7236 16.7305 20.0169 16.7305 20.3711C16.7305 20.7142 16.606 21.0047 16.3569 21.2427C16.1134 21.4806 15.7897 21.5996 15.3857 21.5996C14.9762 21.5996 14.6497 21.4806 14.4062 21.2427C14.1628 21.0047 14.041 20.7142 14.041 20.3711Z"
        fill="currentColor"
      />
    </svg>
  );
};
