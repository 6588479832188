import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";
import Arrow from "@assets/icons/leftArr.svg";
import { ITEMS_PER_PAGE } from "@pages/Overview/constants";
import classNames from "classnames";
import TopPhrases from "../../topPhrases";
import { getPaginationPages } from "src/utils/paginations";

interface PhasesProps {
  phrases: { asin: string; phrase: string; count: number }[];
}

const Phases: React.FC<PhasesProps> = ({ phrases }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(phrases.length / ITEMS_PER_PAGE);
  const maxCount = Math.max(...phrases.map((p) => p.count));

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const selectedPhrases = phrases.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  const paginationPages = useMemo(
    () => getPaginationPages(totalPages, currentPage),
    [totalPages, currentPage],
  );

  return (
    <div className={styles.phases}>
      <div className={styles.productTopPhrases}>
        <header className={styles.productTopPhrasesHeader}>
          <h3 className={`${styles.productTopPhrasesTitle} heading3`}>
            Top Phrases
          </h3>
        </header>
        <div className={styles.productTopPhrasesList}>
          <TopPhrases selectedPhrases={selectedPhrases} maxCount={maxCount} />
        </div>
        <div className={styles.pagination}>
          <button
            className={styles.pageButton}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            style={{ minWidth: "36px" }}
          >
            <img src={Arrow} height={14} width={9} />
          </button>
          {paginationPages.map((page, index) =>
            page === "..." ? (
              <span key={index} className={styles.pageEllipsis}>
                ...
              </span>
            ) : (
              <button
                key={index}
                className={classNames(styles.pageButton, {
                  [styles.active]: currentPage === page,
                })}
                onClick={() => setCurrentPage(page as number)}
              >
                {page}
              </button>
            ),
          )}
          <button
            className={styles.pageButton}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            style={{ minWidth: "36px" }}
          >
            <img src={Arrow} height={14} width={9} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Phases;
