import React from "react";
import styles from "./styles.module.scss";

interface ProgressProps {
  value: number;
  maxValue: number;
}

const Progress: React.FC<ProgressProps> = ({ value, maxValue }) => {
  const percentage = (value / maxValue) * 100;

  return (
    <div className={styles.progressBar}>
      <div
        className={styles.progress}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default Progress;
