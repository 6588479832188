import { useCallback, useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useFeatures } from "@paralleldrive/react-feature-toggles";

import { Tabs } from "@components/tabs";
import { features, FEATURE_NAME } from "@lib/features";
import { ResponsiveContext } from "@contexts/ResponsiveContext";

import "./settingsPage.less";
import { Account } from "./ui/Account";
import { Team } from "./ui/Team";
import { Plan } from "./ui/Plan";

const isTeamEnabled = features.includes(FEATURE_NAME.TEAM);
const subpagesMap = {
  account: {
    id: "account",
    title: "My Account",
  },
  plan: {
    id: "plan",
    title: "My plan",
  },
  ...(isTeamEnabled
    ? {
        team: {
          id: "team",
          title: "Team",
        },
      }
    : {}),
};

const tabs = Object.values(subpagesMap).map(({ id, title }) => ({
  id,
  tab: title,
}));

export function SettingsPage() {
  const { isMobile } = useContext(ResponsiveContext);
  const navigate = useNavigate();
  const { "*": match } = useParams();
  const feat = useFeatures();
  const isTeamPageActivated = feat.includes(FEATURE_NAME.TEAM);

  const onTabChange = useCallback(
    (key: string) => {
      if (key) {
        navigate(subpagesMap[key].id);
      }
    },
    [navigate],
  );

  return (
    <>
      <div className="container">
        <Tabs
          defaultActiveKey={match}
          onChange={onTabChange}
          tabs={tabs}
          type={isMobile ? "line" : "card"}
        />
      </div>
      <div className="settings-page">
        <div className="container">
          <Routes>
            <Route path="account" element={<Account />} />
            <Route path="plan" element={<Plan />} />
            {isTeamPageActivated && <Route path="team" element={<Team />} />}
            <Route path="*" element={<Navigate to="account" replace />} />
          </Routes>
        </div>
      </div>
    </>
  );
}
