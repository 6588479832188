import Axios from "axios";

interface LoginPayload {
  email: string;
  password: string;
}

class LoginService {
  public static async login(
    formData: LoginPayload,
  ): Promise<{ access: string; refresh: string }> {
    const { data } = await Axios.post<{ access: string; refresh: string }>(
      "/api/v1/token",
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return data;
  }
}

export default LoginService;
