import { useState } from "react";
import { Button, Divider, Form, Modal, Radio, Space } from "antd";
import { useQueryClient } from "react-query";
import { FoldersQueryKey } from "@hooks/useFolders";
import { Folder } from "@entities/Folder";
import SvgIcon from "@components/svg-icon/SvgIcon";
import "./modal-move-to-folder.less";

type ModalMoveToFolderProps = {
  onMove: (id: Folder["id"]) => void;
  onNewFolderClick: () => void;
  onClose: () => void;
};

export const ModalMoveToFolder = ({
  onMove,
  onNewFolderClick,
  onClose,
}: ModalMoveToFolderProps) => {
  const queryClient = useQueryClient();
  const folders = queryClient.getQueryData<Folder[]>(FoldersQueryKey);
  const [selectedFolder, setSelectedFolder] = useState<Folder["id"]>(null);

  const onFinish = (values: any) => {
    if (values?.["folder-to-move"]) {
      onMove(values["folder-to-move"]);
    }
  };

  return (
    <Modal
      visible
      title="Move to a folder"
      footer={false}
      onCancel={onClose}
      className="modal-move-to-folder"
    >
      <Form name="folder-to-move" onFinish={onFinish}>
        <Form.Item
          name="folder-to-move"
          rules={[{ required: true, message: "Please select a folder" }]}
        >
          <Radio.Group
            onChange={(e) => setSelectedFolder(e.target.value)}
            value={selectedFolder}
          >
            <Space direction="vertical" size={12}>
              {folders?.map(({ id, name }) => (
                <Radio key={id} value={id}>
                  {name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>

        <Button
          shape="round"
          type="primary"
          className="light-blue-btn new-folder-btn"
          block
          onClick={onNewFolderClick}
        >
          New Folder
          <SvgIcon type="plus-circle" />
        </Button>

        <Divider />

        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          className="move-btn"
        >
          move
          <SvgIcon type="arrow-right" />
        </Button>
      </Form>
    </Modal>
  );
};
