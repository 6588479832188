import { useState } from "react";
import styles from "./styles.module.scss";
import OptimisationArrow from "@assets/icons/optimisationArrow";

interface OptimisationItemProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

export const OptimisationItem = ({
  title,
  children,
}: OptimisationItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.optimisationContainer}>
      <button onClick={toggleOpen} className={styles.header}>
        <span className={styles.title}>{title}</span>
        <div className={`${styles.arrow} ${isOpen ? styles.rotated : ""}`}>
          <OptimisationArrow />
        </div>
      </button>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  );
};
