import { Api } from "src/utils/api";

import { ApiResponse } from "src/types/apiResponce";
import { SPIDER_BASE_URL } from "./spiderUrls";
import { OverviewType } from "@pages/Overview";
import {
  AnalyzeProductListing,
  AnalyzeProductPhoto,
  AnalyzeProductVideo,
} from "@services/amazon/products/types";
import { useProductsStore } from "src/store/product.state";

export class SpiderService extends Api {
  constructor() {
    super(SPIDER_BASE_URL);
  }

  public async getSpiderInfo(
    asin: string = "",
    type: OverviewType = "my-product",
  ): Promise<ApiResponse<any>> {
    const path =
      type === "my-product"
        ? "/get_product_spider_data/"
        : "/get_product_spiders_data_show_room/";

    return this.get(path, { asin });
  }

  public async getProfuctInfo(asin: string = ""): Promise<ApiResponse<any>> {
    return this.get(`/get_product_opportunities_list/`, { asin });
  }
  public async getProfuctSpiderSales(
    asin: string = "",
  ): Promise<ApiResponse<any>> {
    return this.get(`/get_product_spider_data_with_sales/`, { asin });
  }

  public async getSpiderDataList(
    ...asins: string[]
  ): Promise<ApiResponse<any>> {
    const path = "/get_product_spider_data_list_asins/";

    const params = new URLSearchParams();

    const filteredAsins = asins.filter(Boolean);
    filteredAsins.slice(0, filteredAsins.length - 1).forEach((asin) => {
      params.append("asins", asin);
    });

    const mainAsin = filteredAsins[filteredAsins.length - 1];
    params.append("main_asin", mainAsin);

    const queryString = params.toString();
    return this.get(`${path}?${queryString}`);
  }

  public async getDifferenceList(
    asins: string[],
    mainAsin: string,
  ): Promise<ApiResponse<any>> {
    const path = "/get_asins_sales_difference/";

    const params = new URLSearchParams();

    asins.forEach((asin) => {
      params.append("asins", asin);
    });

    if (mainAsin) {
      params.append("main_asin", mainAsin);
    }

    const queryString = params.toString();
    return this.get(`${path}?${queryString}`);
  }
  public async getPotentialHeight(
    asins: string[],
    mainAsin: string,
  ): Promise<ApiResponse<any>> {
    const path = "/get_asins_sales_difference_potential_height_of_sales/";

    const params = new URLSearchParams();

    asins.forEach((asin) => {
      params.append("asins", asin);
    });

    if (mainAsin) {
      params.append("main_asin", mainAsin);
    }

    const queryString = params.toString();
    return this.get(`${path}?${queryString}`);
  }
  public async getDifferenceOverallScore(
    asins: string[],
    mainAsin: string,
    aspects: string[],
  ): Promise<ApiResponse<any>> {
    const path = "/get_asins_sales_difference_overall_score/";

    const params = new URLSearchParams();

    asins.forEach((asin) => {
      params.append("asins", asin);
    });
    aspects.forEach((aspects) => {
      params.append("aspects", aspects);
    });
    if (mainAsin) {
      params.append("main_asin", mainAsin);
    }

    const queryString = params.toString();
    return this.get(`${path}?${queryString}`);
  }
  public async getAspects(aspects: string[]): Promise<ApiResponse<any>> {
    return this.get(`/get_aspects_descriptions/`, {
      aspects: aspects.join(","),
    });
  }
  public async getOpportunitiesList(
    asin: string = "",
  ): Promise<ApiResponse<any>> {
    return this.get(`/get_product_aspects_opportunities_list/`, { asin });
  }
  public async postPotentialIncreaseOverallScore(
    mainAsin: string,
    spider: object,
  ): Promise<ApiResponse<any>> {
    const path = "/post_asin_potential_increase_overall_score/";

    const body = {
      main_asin: mainAsin,
      spider: spider,
    };

    return this.post(path, body);
  }
}
