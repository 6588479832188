import { AuthGuard } from "@components/AuthGuard/AuthGuard";
import { Home } from "@pages/Home/Home";

interface PrivatePageProps {
  hideHeader?: boolean;
}

export const PrivatePage: React.FC<PrivatePageProps> = ({ hideHeader }) => {
  return (
    <AuthGuard>
      <Home hideHeader={hideHeader} />
    </AuthGuard>
  );
};
