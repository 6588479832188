import product1 from "@assets/img/product1.png";
import product2 from "@assets/img/product2.png";

export const impressionsMock = ["Impressions", "Clicks", "Units Sold", "Spend"];

export const asinMock = [
  {
    img: product1,
    id: "(B00K7GIMCU)",
    text: "NEW NORDIC Hair Volume Tablets | 3000 mcg Biotin & Biopectin Apple Extract | Hair Vitamins to Support Natural Hair Growth for Thicker, Fuller Hair | Men and Women | 90 Count (Pack of 1)",
  },
  {
    img: product2,
    id: "(B0B2HN33KQ)",
    text: "WEEM Biotin Gummies for Hair, Skin and Nails - Vegan Vitamins for Men & Women, Supports Faster Hair Growth and Stronger Nails - Extra Strength 10,000mcg",
  },
];
