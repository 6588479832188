import { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import ChevronDown from "@assets/icons/chevron-down";
import AmazonIcon from "@assets/icons/AmazonIcon.png";
import AmazonBlackIcon from "@assets/icons/AmazonBlackIcon.png";
import Combine from "@assets/img/combine.png";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { DropdownItems } from "./renderDropdown";

interface CheckedItems {
  [key: number]: boolean;
}

const CustomSelect = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showCheckboxes, setShowCheckboxes] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] =
    useState<boolean>(false);
  const dropdownRef = useOutsideClick(() => setIsOpen(false));

  useEffect(() => {
    setIsAnyCheckboxChecked(Object.values(checkedItems).some((item) => item));
  }, [checkedItems]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const toggleCheckboxes = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowCheckboxes((prevState) => !prevState);
  };

  const handleCheckboxClick = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.stopPropagation();
    setCheckedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleItemClick =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (showCheckboxes) {
        handleCheckboxClick(e, index);
      } else {
        setCheckedItems({ [index]: true });
        setShowCheckboxes(true);
      }
    };

  const handleApplyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCheckedItems({});
    setShowCheckboxes(false);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={classNames(styles.dropdownContainer, {
          [styles.dropdownActive]: isOpen,
        })}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") toggleDropdown();
        }}
        tabIndex={0}
        role="button"
      >
        <button
          className={classNames(styles.customSelect, {
            [styles.active]: isOpen,
          })}
          onClick={toggleDropdown}
        >
          <img src={isOpen ? AmazonBlackIcon : AmazonIcon} alt="Amazon" />
          <span>Amazon</span>
          <ChevronDown />
        </button>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            <DropdownItems
              showCheckboxes={showCheckboxes}
              checkedItems={checkedItems}
              handleCheckboxClick={handleCheckboxClick}
              handleItemClick={handleItemClick}
            />
            <button
              className={classNames(styles.combineButton, {
                [styles.applybtn]: showCheckboxes,
                [styles.checkedButton]: isAnyCheckboxChecked,
              })}
              onClick={showCheckboxes ? handleApplyClick : toggleCheckboxes}
            >
              {showCheckboxes ? "Apply" : "Combine"}
              {!showCheckboxes && <img src={Combine} alt="Combine" />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
