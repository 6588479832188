import { WhatIfTab } from "@pages/AdsAnalitics/components/Campaigns/components/WhatIfTable/Tabs";
import { create } from "zustand";

export type Goals = {
  acos: number;
  dailySpend: number;
  dailySales: number;
};

export interface IAdsAnaliticsState {
  isWhatIfEnabled: boolean;
  keys: {
    performance: string[];
    campaign: string[];
    targeting: string[];
  };
  sankeyTab: WhatIfTab;
  goals: Goals;
  setIsWhatIfEnabled: (isWhatIfEnabled: boolean) => void;
  setKeys: ({
    performance,
    campaign,
    targeting,
  }: {
    performance: string[];
    campaign: string[];
    targeting: string[];
  }) => void;
  setSankeyTab: (tab: WhatIfTab) => void;
  setGoals: (goal: Goals) => void;
}

export const useAdsAnaliticsStore = create<IAdsAnaliticsState>((set) => ({
  isWhatIfEnabled: false,
  keys: {
    performance: [],
    campaign: [],
    targeting: [],
  },
  sankeyTab: "Campaign",
  goals: {
    acos: 0,
    dailySpend: 0,
    dailySales: 0,
  },
  setIsWhatIfEnabled: (isWhatIfEnabled: boolean) => {
    set(() => ({ isWhatIfEnabled }));
  },
  setKeys: ({
    performance,
    campaign,
    targeting,
  }: {
    performance: string[];
    campaign: string[];
    targeting: string[];
  }) => {
    set(() => ({ keys: { performance, campaign, targeting } }));
  },
  setSankeyTab: (sankeyTab: WhatIfTab) => {
    set(() => ({ sankeyTab }));
  },
  setGoals: (goals: Goals) => {
    set(() => ({ goals }));
  },
}));
