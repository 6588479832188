export const getPaginationPages = (totalPages: number, currentPage: number) => {
  const pages = [];

  if (totalPages <= 1) {
    return pages;
  }

  const firstPage = 1;
  const lastPage = totalPages;

  pages.push(firstPage);

  const startPage = Math.max(2, currentPage - 1);
  const endPage = Math.min(totalPages - 1, currentPage + 1);

  if (startPage > 2) {
    pages.push("...");
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  if (endPage < totalPages - 1) {
    pages.push("...");
  }

  pages.push(lastPage);

  return pages;
};
