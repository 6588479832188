import { Chart } from "chart.js";
import { useEffect } from "react";
import { tooltipArrayState } from "./tooltip-types";
import { MAX_RATING, MIN_AVAILABLE_RATING } from "./chart-data";

export const useMoveDot = (
  chartRef: React.RefObject<Chart<"radar", number[], string>>,
  isDragEnabled: boolean,
  activeIndex = -1,
  update?: (productData: number[]) => void,
  labels: string[] = [],
) => {
  useEffect(() => {
    if (!chartRef.current) return;

    const chart = chartRef.current;
    const canvas = chart.canvas as HTMLCanvasElement;

    const lengthInPixelToLine = 60;
    const ratio = lengthInPixelToLine / MAX_RATING;

    canvas.style.cursor = isDragEnabled ? "pointer" : "default";

    const handleMouseDown = (e: MouseEvent) => {
      const productData = chart.data.datasets[0]?.data as number[];

      if (!productData) return;

      if (!isDragEnabled) return;

      if (chart.tooltip?.opacity === 0) return;

      const index = chart.tooltip?.dataPoints[0]?.dataIndex ?? -1;
      if (!(activeIndex === -1 || activeIndex === index)) return;

      const activeState = tooltipArrayState[index];
      if (!activeState) return;

      const dataWithoutClosingPoint = productData.slice(0, -1);

      const startX = e.clientX;
      const startY = e.clientY;
      const startData = dataWithoutClosingPoint[index];

      canvas.style.cursor = "grabbing";

      const handleMouseMove = (e: MouseEvent) => {
        const offsetX = (startX - e.clientX) / ratio;
        const offsetY = (startY - e.clientY) / ratio;
        const offset =
          (activeState.direction === "x" ? offsetX : offsetY) *
          (activeState.reverse ? -1 : 1);
        const newData = startData + offset;

        dataWithoutClosingPoint[index] = Number(
          Math.max(MIN_AVAILABLE_RATING, Math.min(5, newData)).toFixed(2),
        );

        const updatedProductData = completeData(
          dataWithoutClosingPoint,
          labels,
        );

        chart.data.datasets[0].data = updatedProductData;
        chart.update("none");

        update && update(dataWithoutClosingPoint);
      };

      const handleMouseUp = () => {
        canvas.removeEventListener("mousemove", handleMouseMove);
        canvas.style.cursor = "default";
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp, { once: true });
    };

    canvas.addEventListener("dragstart", () => false);
    canvas.addEventListener("mousedown", handleMouseDown);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
    };
  }, [chartRef, isDragEnabled, activeIndex, update, labels]);
};

const completeData = (data: number[], labels: string[]) => {
  const labelsLength = labels.length;
  const dataLength = data.length;

  if (dataLength === labelsLength + 1) {
    return data;
  }

  const firstPoint = data[0] || 0;
  return [...data, firstPoint];
};
