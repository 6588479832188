export const cookieCleaner = () => {
  return document.cookie.split(";").reduce(function (acc, cookie) {
    const eqPos = cookie.indexOf("=");
    const cleanCookie = `${cookie.substr(
      0,
      eqPos,
    )}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    return `${acc}${cleanCookie}`;
  }, "");
};
