export default () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.24922 20L7.84922 16.8C7.63255 16.7167 7.42855 16.6167 7.23722 16.5C7.04589 16.3833 6.85822 16.2583 6.67422 16.125L3.69922 17.375L0.949219 12.625L3.52422 10.675C3.50755 10.5583 3.49922 10.446 3.49922 10.338V9.663C3.49922 9.55433 3.50755 9.44167 3.52422 9.325L0.949219 7.375L3.69922 2.625L6.67422 3.875C6.85755 3.74167 7.04922 3.61667 7.24922 3.5C7.44922 3.38333 7.64922 3.28333 7.84922 3.2L8.24922 0H13.7492L14.1492 3.2C14.3659 3.28333 14.5702 3.38333 14.7622 3.5C14.9542 3.61667 15.1416 3.74167 15.3242 3.875L18.2992 2.625L21.0492 7.375L18.4742 9.325C18.4909 9.44167 18.4992 9.55433 18.4992 9.663V10.337C18.4992 10.4457 18.4826 10.5583 18.4492 10.675L21.0242 12.625L18.2742 17.375L15.3242 16.125C15.1409 16.2583 14.9492 16.3833 14.7492 16.5C14.5492 16.6167 14.3492 16.7167 14.1492 16.8L13.7492 20H8.24922ZM11.0492 13.5C12.0159 13.5 12.8409 13.1583 13.5242 12.475C14.2076 11.7917 14.5492 10.9667 14.5492 10C14.5492 9.03333 14.2076 8.20833 13.5242 7.525C12.8409 6.84167 12.0159 6.5 11.0492 6.5C10.0659 6.5 9.23655 6.84167 8.56122 7.525C7.88589 8.20833 7.54855 9.03333 7.54922 10C7.54989 10.9667 7.88755 11.7917 8.56222 12.475C9.23689 13.1583 10.0659 13.5 11.0492 13.5Z"
        fill="white"
      />
    </svg>
  );
};
