import { v4 as uuidv4 } from "uuid";
import { RadarData, Tool, Message, AgentApiResponse } from "./types";

function parseData(data: RadarData): RadarData {
  if (typeof data === "string") {
    return JSON.parse(data); // This will throw an error if data is not valid JSON
  }
  return data;
}

/**
 * Handles the response from a tool, adding messages to the chat.
 * @param tool - The tool data received from the API response.
 * @param addMessage - Function to add a message to the chat.
 * @param finalResult - The final message to be sent.
 */
const handleToolResponse = (
  tool: Tool,
  addMessage: (message: Message) => void,
  finalResult: string,
) => {
  const data = tool.data;

  addMessage({
    id: uuidv4(),
    text: finalResult || "Response received.",
    sender: "assistant",
    type: "text",
  });

  addMessage({
    id: uuidv4(),
    text: parseData(data),
    sender: "assistant",
    type: "get_product_spider",
  });
};

/**
 * Retrieves and processes the financial agent response.
 * @param responseData - The response data from the agent API.
 * @param addMessage - Function to add a message to the chat.
 */
export async function getFinantialAgent({
  responseData,
  addMessage,
}: {
  responseData: AgentApiResponse;
  addMessage: (message: Message) => void;
}) {
  const getProductSpider = responseData.tools.find(
    (tool: Tool) => tool.type === "get_product_spider",
  );

  if (getProductSpider) {
    handleToolResponse(getProductSpider, addMessage, responseData.finalMessage);
  } else {
    addMessage({
      id: uuidv4(),
      text: responseData.finalMessage || "No relevant data found.",
      sender: "assistant",
      type: "text",
    });
    console.warn(
      "No get_product_spider tool found in the response. Using finalMessage instead.",
    );
  }
}
