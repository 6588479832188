import { FC } from "react";
import "./index.css";
import classNames from "classnames";
import { ISpinnerProps } from "./index.types";

export const Spinner: FC<ISpinnerProps> = ({
  size = "m",
  variant = "primary",
  className,
}: ISpinnerProps) => {
  const rootClass: string = classNames(
    "nyle-spinner",
    `nyle-spinner--${size}`,
    `nyle-spinner--${variant}`,
    className,
  );
  const innerClass: string = classNames("nyle-spinner__inner");

  return (
    <div className={rootClass}>
      <div className={innerClass} />
    </div>
  );
};
