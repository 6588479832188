import { useState } from "react";
import styles from "./styles.module.scss";
import Camera from "@assets/icons/camera";
import { useUserStore } from "src/store/user.state";
import WelcomeArrow from "@assets/icons/welcomeArrow";
import RightArrow from "@assets/icons/sliderBtn";
import { images } from "./mock";
import CustomSelect from "./CustomSelect";
import classNames from "classnames";

interface WelcomeSectionProps {
  hideDropdown?: boolean;
}

export const WelcomeSection: React.FC<WelcomeSectionProps> = ({
  hideDropdown = false,
}) => {
  const [showContent, setShowContent] = useState(false);
  const { user } = useUserStore();
  const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);

  const imagesToShow = 4;
  const maxIndex = images.length - imagesToShow;

  const toggleContent = () => setShowContent((prev) => !prev);

  const moveRight = () => {
    setFirstVisibleIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  const moveLeft = () => {
    setFirstVisibleIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const visibleImages = images.slice(
    firstVisibleIndex,
    firstVisibleIndex + imagesToShow,
  );

  return (
    <div className={styles.container}>
      <div className={styles.welcomeBox}>
        <div className={styles.welcomeText}>
          <p>{`${user.first_name}, get ready to scale`}</p>
          <div className={styles.videoBtn}>
            <button className={styles.buttonWrapper} onClick={toggleContent}>
              {showContent ? (
                <p>
                  Hide <WelcomeArrow />
                </p>
              ) : (
                <p>
                  Learn <Camera />
                </p>
              )}
            </button>
          </div>
        </div>
        {!hideDropdown && (
          <div className={styles.dropdown}>
            <CustomSelect />
          </div>
        )}
      </div>
      {showContent && (
        <div
          className={classNames(styles.additionalContent, {
            [styles.withLeft]: firstVisibleIndex,
            [styles.withRight]: !(firstVisibleIndex < maxIndex),
          })}
        >
          <div className={styles.cardsWrapper}>
            {visibleImages.map((image, index) => (
              <div
                key={`Card ${firstVisibleIndex + index + 1}`}
                className={styles.card}
              >
                <img
                  src={image.src}
                  alt={`Card ${firstVisibleIndex + index + 1}`}
                />
              </div>
            ))}
          </div>
          {firstVisibleIndex > 0 && (
            <button className={styles.leftArrow} onClick={moveLeft}>
              <RightArrow />
            </button>
          )}
          {firstVisibleIndex < maxIndex && (
            <button className={styles.rightArrow} onClick={moveRight}>
              <RightArrow />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
