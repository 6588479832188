import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      {...props}
    >
      <path
        d="M8.70808 19.2631H0.799805C0.799805 19.2631 0.799805 11.2631 0.799805 8.98946C0.799805 6.71578 3.03475 0.231567 11.2869 0.231567C19.539 0.231567 21.8599 6.46314 21.8599 9.41051C21.8599 12.3579 21.8599 19.1789 21.8599 19.1789H14.0376C14.0376 19.1789 14.0376 11.1789 14.0376 9.91578C14.0376 8.65262 12.9201 7.64209 11.2869 7.64209C9.65363 7.64209 8.62212 9.07367 8.62212 9.91578C8.62212 10.7579 8.70808 19.2631 8.70808 19.2631Z"
        fill="white"
      />
    </svg>
  );
};
