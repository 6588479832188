import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { ProblemDetails } from "./ProblemDetails";
import { AspectOptimization } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Aspects";
import ProblemSolution from "@pages/Compare/components/ProductComparison/ProblemSolution";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";

interface ProblemsBlockProps {
  topProblems: Problem[];
}

interface Problem {
  title: string;
  description: string;
}

const ProblemsBlock: React.FC<ProblemsBlockProps> = ({ topProblems }) => {
  const [selectedProblem, setSelectedProblem] = useState<Problem | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [spiderDots, setSpiderDots] = useState([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [activeProblemIndex, setActiveProblemIndex] = useState(0);
  const [problems, setProblems] = useState([]);
  const { selectedProduct } = useProductStore();

  const handleProblemClick = (problem: Problem, index: number) => {
    if (selectedProblem?.title === problem.title) {
      setSelectedProblem(null);
      setSelectedIndex(null);
    } else {
      setSelectedProblem(problem);
      setSelectedIndex(index);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    problem: Problem,
    index: number,
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      handleProblemClick(problem, index);
    }
  };
  const handlePredictionInsightClick = () => {
    if (!isDragEnabled) {
      setIsTooltipVisible(true);
    } else {
      setIsTooltipVisible(false);
    }
    setIsDragEnabled(!isDragEnabled);
  };
  const handleProductSelect = async () => {
    const spider = new SpiderService();
    const spiderDotsResponse = await spider.getProfuctInfo(selectedProduct);

    setSpiderDots(Array.isArray(spiderDotsResponse) ? spiderDotsResponse : []);
    setProblems(Array.isArray(spiderDotsResponse) ? spiderDotsResponse : []);
  };

  useEffect(() => {
    handleProductSelect();
  }, []);
  return (
    <div className={styles.expandedContainer}>
      <div className={styles.expandedContent}>
        <h3>Opportunities</h3>
        <div className={styles.comparisonBetween}>
          <div className={styles.comparison}>
            <ProblemSolution
              problems={spiderDots}
              onPredictionInsightClick={handlePredictionInsightClick}
              activeProblemIndex={activeProblemIndex}
            />
          </div>
          <div className={styles.aspects}>
            <AspectOptimization isWhatIf={true} />
          </div>
        </div>
        {selectedProblem && (
          <div className={styles.problemMoreInfo}>
            <ProblemDetails problem={selectedProblem} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProblemsBlock;
