import React, { useEffect, useState } from "react";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import Header from "./components/Header";
import ProductInfo from "./components/ProductInfo";
import TopProblems from "./components/TopProblems";
import Reviews from "./components/Reviews";
import TopPerformance from "./components/TopPerformance";
import ReviewScore from "./components/ReviewScore";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import AspectsAndPrases from "./components/AspectsAndPrases";
import { loadInsideProductData } from "src/utils/insides";
import { AmazonService } from "@services/amazon/amazon.service";
import { SpiderService } from "@services/spider/spider.services";

const formattedKeys = (spiderDots) =>
  Object.keys(spiderDots).map((key) =>
    key
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
  );

export type OverviewType = "my-product" | "show-room";

export const Overview: React.FC<{ type?: OverviewType }> = ({
  type = "my-product",
}) => {
  const [visibleReviews, setVisibleReviews] = useState<number>(10);
  const { selectedProduct } = useProductStore();
  const loadMoreReviews = async () => {
    const resReviews = await AmazonService.reviews.getOwnProducts(
      selectedProduct.asin,
      visibleReviews,
      10,
    );
    setProductOneReviews([...productOne.reviews, ...resReviews]);
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 10);
  };

  const {
    productOne,
    setProductOneAspects,
    setProductOnePhrases,
    setProductOneProblems,
    setProductOneReviews,
    setProductOneSpiderTitles,
    setProductOneSpiderValues,
    clearProductOne,
    setProductOneHypotheses,
  } = useInsightsStore();

  useEffect(() => {
    if (selectedProduct && selectedProduct.asin) {
      clearProductOne();

      const fetchInsights = async () => {
        try {
          const { hypotheses, phrases, problems, reviews } =
            await loadInsideProductData(selectedProduct);
          const spider = new SpiderService();

          const spiderDots = await spider.getSpiderInfo(
            selectedProduct.asin,
            type,
          );

          setProductOneSpiderTitles(formattedKeys(spiderDots));
          setProductOneSpiderValues(
            Object.values(spiderDots).map((value) =>
              parseFloat(value.toFixed(1)),
            ),
          );

          setProductOneHypotheses(hypotheses);
          setProductOnePhrases(phrases);
          setProductOneProblems(problems);
          setProductOneReviews(reviews);
        } catch (error) {
          console.error("Error fetching insights:", error);
        }
      };

      fetchInsights();
    }
  }, [
    selectedProduct,
    setProductOneHypotheses,
    setProductOneAspects,
    setProductOnePhrases,
    setProductOneProblems,
    clearProductOne,
  ]);

  return (
    <PageWrapper>
      <Header title={type === "my-product" ? "My product" : "Overview mode"} />
      <ProductInfo type={type} />
      {selectedProduct && <ReviewScore />}
      {productOne.phrases.length > 0 && (
        <AspectsAndPrases
          spiderTitles={productOne.spiderTitles}
          spiderValues={productOne.spiderValues}
          phrases={productOne.phrases}
        />
      )}
      {productOne.problems.length > 0 && (
        <TopProblems problems={productOne.problems} />
      )}
      {selectedProduct && productOne.problems.length > 0 && (
        <Reviews
          reviews={productOne.reviews}
          visibleReviews={visibleReviews}
          loadMoreReviews={loadMoreReviews}
        />
      )}
      {/* {selectedProduct && productOne.problems.length > 0 && <TopPerformance />} */}
    </PageWrapper>
  );
};
