import { useCallback, useEffect, useState } from "react";
import { Checkbox, Card, Divider } from "antd";

import "./user-checkbox-group.less";

import "./user-checkbox.less";
import { UserCheckbox, UserCheckboxProps } from "./UserCheckbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

type Option = UserCheckboxProps & { id: string };

interface Props {
  title: string;
  options?: Option[];
}

function mapOptions(options: Option[]): Record<string, Option> {
  return options.reduce((acc, option) => {
    acc[option.id] = option;

    return acc;
  }, {});
}

export const UserCheckboxGroup = ({ title, options }: Props) => {
  const [mappedOtions, setClonedOptions] = useState(mapOptions(options));
  const [selectedAll, setSelectedAll] = useState(
    Object.values(mappedOtions).every(({ checked }) => checked),
  );

  useEffect(() => {
    setClonedOptions(mapOptions(options));
  }, [options]);

  useEffect(() => {
    setSelectedAll(Object.values(mappedOtions).every(({ checked }) => checked));
  }, [mappedOtions]);

  const onCheckboxChange = useCallback((event: CheckboxChangeEvent) => {
    setClonedOptions((clonedOptions) => {
      const clonnedOptions = { ...clonedOptions };

      clonnedOptions[event.target.id].checked = event.target.checked;

      return clonnedOptions;
    });
  }, []);

  const onChangeSelectAll = (event: CheckboxChangeEvent) => {
    setClonedOptions(
      options.reduce((acc, option) => {
        acc[option.id] = { ...option, checked: event.target.checked };

        return acc;
      }, {}),
    );

    setSelectedAll(event.target.checked);
  };

  return (
    <Card title={title} className="user-checkbox-group">
      <Checkbox
        className="user-checkbox-group__checkbox-all"
        onChange={onChangeSelectAll}
        checked={selectedAll}
      >
        Select All
      </Checkbox>

      <Divider />

      {Object.values(mappedOtions)?.map((option) => (
        <UserCheckbox {...option} key={option.id} onChange={onCheckboxChange} />
      ))}

      <Divider />
    </Card>
  );
};
