export default () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 6.5V5.5H11V6.5H0Z" fill="#7C7C78" />
      <path d="M6 11.5L5 11.5L5 0.5L6 0.5L6 11.5Z" fill="#7C7C78" />
    </svg>
  );
};
