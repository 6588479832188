import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { useMutation } from "react-query";
import { Row, Col, Button } from "antd";
import { useParams } from "react-router-dom";

import { useTitle } from "@hooks/useTitle";
import { AiEntity } from "@entities/AiEntity";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { useDocumentById } from "@hooks/useDocumentById";
import { SaveModal } from "@components/save-modal/SaveModal";
import { MAPPED_TEMPLATES_LIST } from "@pages/ai-templates/constants";

import { getResult } from "./lib/queryResult";
import { createUiSchema } from "./lib/schema";
import { TemplateForm } from "./ui/TemplateForm";
import { TemplateHead } from "./ui/TemplateHead";
import { TemplateResultCTA } from "./ui/TemplateResultCTA";
import { TemplateResultPlaceholder } from "./ui/TemplateResultPlaceholder";
import "./template.less";
import { exportAsCSV, exportAsHTML, exportAsPDF } from "./lib/exportAsFile";

interface Props {
  schema: AiEntity;
  isVertical?: boolean;
  onAddToDocument?: (result: Record<string, string>) => void;
}

type FormData = Record<string, any>;
export type FILE_TYPE = "PDF" | "CSV" | "HTML";

export const Template = ({ schema, isVertical, onAddToDocument }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formInputData, setInputFormData] = useState<FormData>({});
  const [formOutputData, setFormOutputData] = useState<FormData>({});
  const prevFormData = useRef<FormData>({});
  const {
    mutate: generateResult,
    isLoading: isResultLoading,
    status,
  } = useMutation([schema.id, "generated"], getResult, {
    onSuccess: (data) => {
      if (data.response) {
        setFormOutputData(data.response);
      }
    },
  });

  const { documentId } = useParams<{ documentId?: string }>();

  const { data: document, isLoading: isDocumentLoading } =
    useDocumentById(documentId);

  useEffect(() => {
    if (document) {
      setInputFormData(document.data.payload.input);
      setFormOutputData(document.data.payload.output);
    }
  }, [document]);

  const isLoading = isDocumentLoading || isResultLoading;
  const title = schema.title;
  useTitle(`Nyle - ${title}`);

  useEffect(() => {
    if (
      Object.values(formInputData).length &&
      prevFormData.current !== formInputData
    ) {
      prevFormData.current = formInputData;
    }
  }, [formInputData]);

  const handleChange = useCallback((e) => {
    setInputFormData(e.formData);
  }, []);
  const handleOutputChange = useCallback((e) => {
    setFormOutputData(e.formData);
  }, []);
  const onClear = useCallback(() => {
    setInputFormData({});
  }, []);

  const handleSubmit = useCallback(
    ({ formData }, _nativeEvent) => {
      generateResult({
        id: schema.id,
        formData,
      });
    },
    [generateResult, schema.id],
  );

  // data to insert into free form writing page
  const formattedData = useMemo(() => {
    if (!formOutputData || !schema?.response_schema?.["200"]?.properties) {
      return {};
    }

    return Object.entries(formOutputData).reduce((acc, [key, value]) => {
      const hasDescription =
        schema.response_schema?.["200"]?.properties?.[key]?.description;

      acc[
        hasDescription
          ? schema.response_schema["200"].properties[key].description
          : key
      ] = value;

      return acc;
    }, {});
  }, [formOutputData, schema.response_schema]);

  const dataToDocument = useMemo(
    () => ({
      input: formInputData,
      output: formOutputData,
    }),
    [formInputData, formOutputData],
  );

  const uiSchemaRequest = useMemo(
    () =>
      createUiSchema({
        toolId: schema.id,
        schema: schema.request_schema.properties,
        forceTextarea: Boolean(documentId),
      }),
    [documentId, schema.id, schema.request_schema.properties],
  );

  // Force all string inputs looks like textarea
  const uiSchemaResponse = useMemo(
    () =>
      createUiSchema({
        toolId: schema.id,
        schema: schema.response_schema["200"].properties,
        forceTextarea: true,
      }),
    [schema.id, schema.response_schema],
  );

  const handleAddDataToDocument = () => {
    if (onAddToDocument) {
      onAddToDocument(formattedData);
    }
  };

  const handleDownload = (type: FILE_TYPE) => {
    switch (type) {
      case "CSV":
        exportAsCSV(title, formattedData);
        break;

      case "PDF":
        exportAsPDF(title, formattedData);
        break;

      case "HTML":
        exportAsHTML(title, ".template__form_results #root");
        break;
    }
  };

  return (
    <div className={cn("template", { template_isVertical: isVertical })}>
      <h2 className="page-main-title">{title}</h2>

      <div className="template__content">
        <TemplateHead
          schemaDescription={schema.description}
          resultDescription={
            MAPPED_TEMPLATES_LIST?.[schema.id]?.descriptionResult
          }
          isVertical={isVertical}
          videosrc={MAPPED_TEMPLATES_LIST?.[schema.id]?.videosrc}
        />

        <Row gutter={[50, 48]} className="grid">
          <Col className="gutter-row" span={12}>
            <TemplateForm
              key={schema.id}
              className="template__form"
              formData={formInputData}
              schema={schema.request_schema}
              uiSchema={uiSchemaRequest}
              onSubmit={handleSubmit}
              onChange={handleChange}
              disabled={isLoading}
              onClearButton={onClear}
              status={status}
              isVertical={isVertical}
              liveValidate
            />
          </Col>

          <Col className="gutter-row" span={12}>
            {!isVertical &&
              (isLoading || !Object.values(formOutputData).length) && (
                <TemplateResultPlaceholder isFetching={isLoading} />
              )}

            {Boolean(Object.values(formOutputData).length) && !isLoading && (
              <TemplateForm
                onChange={handleOutputChange}
                className="template__form template__form_results"
                uiSchema={uiSchemaResponse}
                formData={formOutputData}
                schema={schema.response_schema["200"]}
                formContext={{ inputData: formInputData }}
              >
                {isVertical ? (
                  <div className="action-btns">
                    <Button
                      type="primary"
                      className="pill-btn black-btn"
                      shape="round"
                      size="large"
                      onClick={handleAddDataToDocument}
                    >
                      Add the result to the document
                      <SvgIcon type="plus-circle" />
                    </Button>
                  </div>
                ) : (
                  <TemplateResultCTA
                    onDownload={handleDownload}
                    onSaveDocument={() => {
                      setModalVisible(true);
                    }}
                  />
                )}
              </TemplateForm>
            )}
          </Col>
        </Row>
      </div>
      {modalVisible && (
        <SaveModal
          visible
          isExisted={Boolean(document?.data)}
          initialTitle={document?.data?.title}
          currentFolder={document?.folder}
          documentId={document?.id}
          data={dataToDocument}
          toolId={schema.id}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
    </div>
  );
};
