import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { scatterData } from "../mock.tsx";
import { tooltipFormatter } from "../utils.ts";

export const ScatterPlot: React.FC = () => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);
      const option: echarts.EChartsOption = {
        tooltip: {
          trigger: "item",
          borderColor: "#ffffff",
          formatter: tooltipFormatter,
          position: function (point, params, dom, rect, size) {
            const x = point[0] + 45;
            const y = point[1] - size.contentSize[1] / 2;
            return [x, y];
          },
        },
        xAxis: {
          name: "Ad Spend, $",
          type: "value",
          min: 0,
          max: 1000,
          nameLocation: "middle",
          nameGap: 60,
          axisLabel: {
            color: "#fff",
          },
          nameTextStyle: {
            color: "#fff",
            fontWeight: 700,
            fontSize: 12,
          },
          splitLine: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },
        },
        yAxis: {
          name: "ACOS, %",
          type: "value",
          min: 0,
          max: 100,
          nameLocation: "middle",
          nameGap: 75,
          axisLabel: {
            color: "#fff",
          },
          nameTextStyle: {
            color: "#fff",
            fontWeight: 700,
            fontSize: 12,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "#666",
            },
          },
          axisTick: { show: false },
          axisLine: { show: false },
        },
        series: [
          {
            symbolSize: 15,
            data: scatterData,
            type: "scatter",
            itemStyle: {
              color: function (params) {
                if (params.value[1] > 50) {
                  return "#FE5858";
                } else if (params.value[1] > 20) {
                  return "#C8A857";
                } else {
                  return "#80C67A";
                }
              },
              opacity: 0.6,
            },
          },
        ],
        graphic: [
          {
            type: "line",
            shape: { x1: 129, y1: 250, x2: 1179, y2: 250 },
            style: {
              stroke: "#fff",
              lineWidth: 1,
              lineDash: [8, 8],
            },
          },
          {
            type: "text",
            left: "80%",
            top: "47%",
            style: {
              text: "Breakeven ACOS, 43%",
              fill: "#fff",
            },
            z: 100,
          },
        ],
        dataZoom: [
          {
            type: "slider",
            xAxisIndex: 0,
            height: 14,
            handleSize: "100%",
            handleStyle: {
              color: "#fff",
              borderColor: "#5295E0",
              borderWidth: 2,
              borderRadius: "50%",
            },
            fillerColor: "#2E2E2E",
            backgroundColor: "#2E2E2E",
            handleIcon: "path://M8,1 A7,7 0 1,1 8,15 A7,7 0 1,1 8,1 Z",
            labelFormatter: function (value) {
              return value.toFixed(2);
            },
          },
          {
            type: "slider",
            yAxisIndex: 0,
            left: "5%",
            width: 14,
            handleSize: "100%",
            handleStyle: {
              color: "#fff",
              borderColor: "#5295E0",
              borderWidth: 2,
              borderRadius: "50%",
            },
            fillerColor: "#2E2E2E",
            backgroundColor: "#2E2E2E",
            handleIcon: "path://M8,1 A7,7 0 1,1 8,15 A7,7 0 1,1 8,1 Z",
            labelFormatter: function (value) {
              return value.toFixed(2);
            },
          },
        ],
      };

      myChart.setOption(option);

      const handleResize = () => {
        myChart.resize();
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
        myChart.dispose();
      };
    }
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};
