export default () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C12 0.447715 12.4477 0 13 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H13C12.4477 2 12 1.55228 12 1ZM12 6C12 5.44772 12.4477 5 13 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H13C12.4477 7 12 6.55228 12 6Z"
        fill=""
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C6 0.447715 6.44772 0 7 0H9C9.55228 0 10 0.447715 10 1C10 1.55228 9.55228 2 9 2H7C6.44772 2 6 1.55228 6 1ZM6 6C6 5.44772 6.44772 5 7 5H9C9.55228 5 10 5.44772 10 6C10 6.55228 9.55228 7 9 7H7C6.44772 7 6 6.55228 6 6Z"
        fill=""
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1C4 1.55228 3.55228 2 3 2H1C0.447715 2 0 1.55228 0 1ZM0 6C0 5.44772 0.447715 5 1 5H3C3.55228 5 4 5.44772 4 6C4 6.55228 3.55228 7 3 7H1C0.447715 7 0 6.55228 0 6Z"
        fill=""
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 5.44772 12.4477 5 13 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H13C12.4477 7 12 6.55228 12 6ZM12 11C12 10.4477 12.4477 10 13 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H13C12.4477 12 12 11.5523 12 11Z"
        fill=""
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6C6 5.44772 6.44772 5 7 5H9C9.55228 5 10 5.44772 10 6C10 6.55228 9.55228 7 9 7H7C6.44772 7 6 6.55228 6 6ZM6 11C6 10.4477 6.44772 10 7 10H9C9.55228 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12H7C6.44772 12 6 11.5523 6 11Z"
        fill=""
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 5.44772 0.447715 5 1 5H3C3.55228 5 4 5.44772 4 6C4 6.55228 3.55228 7 3 7H1C0.447715 7 0 6.55228 0 6ZM0 11C0 10.4477 0.447715 10 1 10H3C3.55228 10 4 10.4477 4 11C4 11.5523 3.55228 12 3 12H1C0.447715 12 0 11.5523 0 11Z"
        fill=""
      />
    </svg>
  );
};
