export const getCookieData = (type: string): string => {
  const cookie: string = document.cookie;
  const cookieData: Record<string, string> = {};
  const cookieParts: string[] = cookie.split(";");
  cookieParts.forEach((part: string) => {
    const data: string[] = part.split("=");
    const key: string = data[0].trim();
    const value: string = (data[1] || "").trim();
    cookieData[key] = value;
  });

  return cookieData[type];
};
