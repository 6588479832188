import React from "react";
import InfoItem from "./InfoItem";
import { VisibleDescriptions } from "./types";
import styles from "./styles.module.scss";

interface InfoSectionProps {
  type: "video" | "image";
  description: string;
  critique: string;
  hypothesis: string;
  howToTest: string;
  percentageImpact: string;
  visibleDescriptions: VisibleDescriptions;
  toggleVisibility: (field: keyof VisibleDescriptions) => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  type,
  description,
  critique,
  hypothesis,
  howToTest,
  percentageImpact,
  visibleDescriptions,
  toggleVisibility,
}) => (
  <div className={styles.infoContainer}>
    {description && (
      <InfoItem
        title={type === "video" ? "Video description" : "Image description"}
        content={description}
        isVisible={visibleDescriptions.description}
        toggleVisibility={() => toggleVisibility("description")}
      />
    )}
    {critique && (
      <InfoItem
        title={type === "video" ? "Recommendations" : "Image critique"}
        content={critique}
        isVisible={visibleDescriptions.critique}
        toggleVisibility={() => toggleVisibility("critique")}
      />
    )}
    {hypothesis && (
      <InfoItem
        title={type === "video" ? "Hypotheses for improving" : "Hypothesis"}
        content={hypothesis}
        isVisible={visibleDescriptions.hypothesis}
        toggleVisibility={() => toggleVisibility("hypothesis")}
      />
    )}
    {howToTest && (
      <InfoItem
        title={
          type === "video" ? "Text description of the video" : "How to test"
        }
        content={howToTest}
        isVisible={visibleDescriptions.howToTest}
        toggleVisibility={() => toggleVisibility("howToTest")}
      />
    )}
    {percentageImpact && (
      <InfoItem
        title="Percentage impact"
        content={percentageImpact}
        isVisible={visibleDescriptions.percentageImpact}
        toggleVisibility={() => toggleVisibility("percentageImpact")}
      />
    )}
  </div>
);

export default InfoSection;
