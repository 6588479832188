export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <rect
        x="0.5"
        y="1"
        width="31"
        height="31"
        rx="15.5"
        fill="#141414"
        fill-opacity="0.7"
      />
      <rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#5C5C5A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8627 10.676C17.2016 10.3541 17.7332 10.3541 18.0721 10.676C18.4365 11.0221 18.4365 11.603 18.0721 11.9491L14.2182 15.6101C13.8387 15.9706 13.8387 16.5754 14.2182 16.9359L18.0721 20.5969C18.4365 20.943 18.4365 21.5238 18.0721 21.87C17.7332 22.1919 17.2016 22.1919 16.8627 21.87L11.6686 16.9359C11.2891 16.5754 11.2891 15.9706 11.6686 15.6101L16.8627 10.676Z"
        fill="white"
      />
    </svg>
  );
};
