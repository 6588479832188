import Axios from "axios";

export class ActivateService {
  public static async activateUser(
    uid: string,
    token: string,
  ): Promise<boolean> {
    try {
      const response = await Axios.post(
        "/api/v1/auth/users/activation/",
        {
          uid,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      return response.status === 204;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }
}
