import React from "react";
import styles from "./styles.module.scss";
import CroBackArrow from "@assets/icons/croBackArrow";

interface OptimisationBackButtonProps {
  onClick?: () => void;
}

export const OptimisationBackButton: React.FC<OptimisationBackButtonProps> = ({
  onClick,
}) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <CroBackArrow />
      <span className={styles.text}>Back</span>
    </button>
  );
};

export default OptimisationBackButton;
