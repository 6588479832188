import React, { useState } from "react";
import styles from "./styles.module.scss";
import Arrow from "@assets/icons/Arrow";
import Star from "@assets/icons/Star";
import { Button, Popover } from "antd";
import InfoIcon from "@assets/icons/i.svg";
import { SpiderService } from "@services/spider/spider.services";

interface SwiperNavigationProps {
  onNext: () => void;
  onPrev: () => void;
  headerText: string;
  improvementValue?: string;
  showText?: boolean;
}

const SwiperNavigation: React.FC<SwiperNavigationProps> = ({
  onNext,
  onPrev,
  headerText,
  improvementValue,
  showText = false,
}) => {
  const [content, setContent] = useState<string>("");

  const handleAspects = async () => {
    const spider = new SpiderService();
    const spiderD = await spider.getAspects([headerText]);
    const description =
      Array.isArray(spiderD) && spiderD.length > 0
        ? spiderD
            .map((aspect) =>
              Object.entries(aspect)
                .map(([key, value]) => `${key}: ${value}`)
                .join("\n"),
            )
            .join("\n\n")
        : "No description available";

    setContent(description);
  };

  return (
    <div className={styles.swiperNavigation}>
      <div className={styles.buttonContainer}>
        <button className={styles.backButton} onClick={onPrev}>
          <Arrow />
        </button>
        <button className={styles.forwardButton} onClick={onNext}>
          <Arrow />
        </button>
      </div>
      <h3 className={styles.headerText}>
        {headerText}
        <Popover
          content={content}
          overlayClassName={styles.customPopover}
          trigger="click"
        >
          <Button
            type="link"
            className={styles.infoButton}
            icon={
              <img
                src={InfoIcon}
                alt="Info"
                className={styles.infoIcon}
                width={14}
                height={14}
              />
            }
            onClick={handleAspects}
          />
        </Popover>
      </h3>
      {showText && (
        <p className={styles.improvementText}>
          Improving this aspect you will improve review score by{" "}
          {improvementValue}
          <Star />
        </p>
      )}
    </div>
  );
};

export default SwiperNavigation;
