import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import UserPopupArrow from "@assets/icons/userPopupArrow";

import { useAuthStore } from "src/store/auth.state";
import { User, menuItems, users } from "./mocks";

interface UserPopupProps {
  closeMenu: () => void;
}

export const UserPopup: React.FC<UserPopupProps> = ({ closeMenu }) => {
  const [activeUser, setActiveUser] = useState<User>(users[0]);
  const navigate = useNavigate();
  const { logout } = useAuthStore();

  const handleNavigation = (path: string) => {
    navigate(path);
    closeMenu();
  };

  const handleLogout = () => {
    logout();
    closeMenu();
  };

  const isActiveUser = (user: User) =>
    activeUser.name === user.name && activeUser.title === user.title;

  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <div className={styles.section}>
          {menuItems.map((item) => (
            <button
              key={item.route}
              className={styles.menuItem}
              onClick={() => handleNavigation(item.route)}
            >
              <span>{item.label}</span>
              <UserPopupArrow />
            </button>
          ))}
          <button className={styles.menuItem} onClick={handleLogout}>
            <span>Log Out</span>
            <UserPopupArrow />
          </button>
        </div>
        <div className={styles.profileSection}>
          {users.map((user) => (
            <button
              key={user.name}
              className={`${styles.profileItem} ${
                isActiveUser(user) ? styles.activeUser : ""
              }`}
              onClick={() => setActiveUser(user)}
            >
              <img
                src={user.photo}
                alt={user.name}
                className={styles.profileImage}
              />
              <div className={styles.profileDetails}>
                <span className={styles.profileName}>{user.name}</span>
                <span className={styles.profileTitle}>{user.title}</span>
              </div>
              <input
                type="radio"
                name="profile"
                checked={isActiveUser(user)}
                readOnly
                className={styles.radioButton}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
