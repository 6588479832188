import React from "react";
import styles from "./styles.module.scss";

interface OptimisationButtonProps {
  onClick?: () => void;
}

export const OptimisationButton: React.FC<OptimisationButtonProps> = ({
  onClick,
}) => {
  return (
    <button className={styles.button} onClick={onClick}>
      See details
    </button>
  );
};
