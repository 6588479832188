import React from "react";
import styles from "./styles.module.scss";
import { mockData } from "./mock";
import CloseKeyword from "@assets/icons/closeKeyword";
import AddKeywords from "@assets/icons/addKeywords";

interface Image {
  link: string;
  variant: string;
  width: number;
  height: number;
}

interface GenerateProps {
  onGenerateClick: () => void;
  title: string | null;
  points: string[] | null;
  images: Image[] | null;
}

const Generate: React.FC<GenerateProps> = ({
  onGenerateClick,
  title,
  points,
  images,
}) => {
  const pointsText = points ? points.join("\n") : "";

  return (
    <div className={styles.generate}>
      <div className={styles.title}>
        <label htmlFor="title">Title</label>
        <input type="text" placeholder="Title" name="title" value={title} />
      </div>
      <div className={styles.bulletPoints}>
        <label htmlFor="bulletPoints">Bullet Points</label>
        <textarea
          placeholder="Bullet Points"
          name="bulletPoints"
          value={!points ? mockData.EmptyBulletPoints : pointsText}
        />
      </div>

      {/* <div className={styles.keywords}>
        <div className={styles.header}>
          <p>Keywords</p>
          <button className={styles.downloadBtn}>DOWNLOAD CSV</button>
        </div>
        <div>
          {mockData.keywords.map((keyword) => (
            <span key={keyword}>
              {keyword} <CloseKeyword />
            </span>
          ))}
          <button className={styles.addIcon} onClick={onGenerateClick}>
            <AddKeywords />
          </button>
        </div>
      </div> */}
      <div className={styles.images}>
        <p>Images</p>
        <div className={styles.box}>
          {/* {mockData.images.map((image) => (
            <img key={image.alt} src={image.src} alt={image.alt} />
          ))} */}
          {images
            ?.filter((image) => image.width <= 100 && image.height <= 100)
            .map((image, index) => (
              <img
                key={index}
                src={image.link}
                alt={image.variant || "Image"}
              />
            ))}
          <div className={styles.addImgIcon}>
            <AddKeywords />
          </div>
        </div>
      </div>
      {/* <div className={styles.searchTerms}>
        <p>Search terms</p>
        <input type="text" value={mockData.searchTerms} />
      </div>
      <button className={styles.generateButton} onClick={onGenerateClick}>
        GENERATE
      </button> */}
    </div>
  );
};

export default Generate;
