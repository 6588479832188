export default () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.13733 7.33866C0.77815 7.72471 0.77815 8.32263 1.13733 8.70867C1.53507 9.13617 2.21208 9.13617 2.60982 8.70867L6.74696 4.26204C7.15378 3.82479 7.84622 3.82479 8.25305 4.26204L12.3902 8.70867C12.7879 9.13618 13.4649 9.13618 13.8627 8.70867C14.2218 8.32263 14.2219 7.72471 13.8627 7.33867L8.25305 1.30938C7.84622 0.872125 7.15378 0.872125 6.74696 1.30938L1.13733 7.33866Z"
        fill="white"
      />
    </svg>
  );
};
