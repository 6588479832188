import React, { useState, useMemo, useCallback } from "react";
import { useTable, Column } from "react-table";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { keywordSearchData } from "./mock";
import CloseKeyword from "@assets/icons/closeKeyword";
import ProgressIcon from "@assets/icons/progress";
import CustomCheckbox from "@components/CustomCheckbox";
import Progress from "./compoents/Progress";
import { Star } from "./compoents/Star";
import AuthButtonArrow from "@assets/icons/authButtonArrow";

interface KeywordSearchProps {
  onBackClick: () => void;
  isReturning: boolean;
}

interface SearchTerm {
  text: string;
  relevanceScore: string;
  searchVolume: string;
  checked?: boolean;
}

const KeywordSearch: React.FC<KeywordSearchProps> = ({
  onBackClick,
  isReturning,
}) => {
  const [checkedTerms, setCheckedTerms] = useState<boolean[]>(
    keywordSearchData.searchTerms.map(
      (term: SearchTerm) => term.checked || false,
    ),
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      event.stopPropagation();
      setCheckedTerms((prevCheckedTerms) => {
        const newCheckedTerms = [...prevCheckedTerms];
        newCheckedTerms[index] = event.target.checked;
        return newCheckedTerms;
      });
    },
    [],
  );

  const formattedSearchTerms = useMemo(
    () =>
      keywordSearchData.searchTerms.map((term: SearchTerm, index: number) => ({
        ...term,
        searchVolume: parseInt(term.searchVolume.replace(/,/g, ""), 10),
        checked: checkedTerms[index],
      })),
    [checkedTerms],
  );

  const maxValue = useMemo(
    () => Math.max(...formattedSearchTerms.map((term) => term.searchVolume)),
    [formattedSearchTerms],
  );

  const data = useMemo(() => formattedSearchTerms, [formattedSearchTerms]);

  const columns: Column<SearchTerm>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "checkbox",
        Cell: ({ row }) => (
          <button
            className={styles.inputsContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <CustomCheckbox
              lightCheckbox={true}
              checked={row.original.checked}
              onChange={(event) => handleCheckboxChange(event, row.index)}
            />
            <Star />
          </button>
        ),
      },
      {
        Header: "Search term",
        accessor: "text",
        Cell: ({ value }) => <span className={styles.termText}>{value}</span>,
      },
      {
        Header: "Relevance Score",
        accessor: "relevanceScore",
        Cell: ({ value }) => (
          <span className={styles.relevanceScore}>{value}</span>
        ),
      },
      {
        Header: "Monthly Search Volume",
        accessor: "searchVolume",
        Cell: ({ value }) => (
          <div className={styles.searchVolumeWrapper}>
            <span>{value.toLocaleString()}</span>
            <ProgressIcon />
            <Progress value={value} maxValue={maxValue} />
          </div>
        ),
      },
    ],
    [maxValue, handleCheckboxChange],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<SearchTerm>({ columns, data });

  return (
    <div
      className={classNames(
        styles.keywordSearchContainer,
        { [styles.slideOutRight]: isReturning },
        { [styles.slideInLeft]: !isReturning },
      )}
    >
      <div className={styles.keywordsSection}>
        <label className={styles.label}>Keywords</label>
        <div className={styles.keywords}>
          {keywordSearchData.keywords.map((keyword, index) => (
            <span key={keyword} className={styles.keyword}>
              <div>
                {keyword}
                <span className={styles.removeIcon}>
                  <CloseKeyword />
                </span>
              </div>
            </span>
          ))}
        </div>
      </div>
      <div className={styles.searchTermsSection}>
        <table {...getTableProps()} className={styles.searchTable}>
          <thead className={styles.searchTableHeader}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={styles.termRow}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <button className={styles.applyChangesButton}>
          APPLY CHANGES <AuthButtonArrow />
        </button>
      </div>
    </div>
  );
};

export default KeywordSearch;
