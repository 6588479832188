import { Fragment } from "react";

export default function NewLineText(props) {
  const string = props?.string || "";

  const addLineBreaks = (textString) => {
    return textString.split("\n").map((str, idx) => (
      <Fragment key={"txt-" + idx}>
        {idx === 0 ? null : <br />}
        {str}
      </Fragment>
    ));
  };

  return addLineBreaks(string);
}
