import { CSSProperties, FC } from "react";
import "./index.css";
import { ISkeletonProps } from "./index.types";
import classnames from "classnames";

export const Skeleton: FC<ISkeletonProps> = (props: ISkeletonProps) => {
  const {
    width = "200px",
    height = `${16 * 1.43}px`,
    shape = "rounded",
    className,
    style: styles,
  } = props;

  const customStyle: CSSProperties = styles || {};

  const style: CSSProperties = {
    ...customStyle,
    width,
    height,
    borderRadius: shape === "round" ? height : "4px",
  };

  const rootClass: string = classnames("nyle-skeleton", className);

  return <div className={rootClass} style={style} />;
};
