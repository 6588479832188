import { create } from "zustand";
import { CustomerProfile } from "@entities/CustomerProfile";

export interface IUserState {
  user: CustomerProfile | null;
  userLoaded: boolean;
  setUser: (user: CustomerProfile | null) => void;
}

export const useUserStore = create<IUserState>((set) => ({
  user: null,
  userLoaded: false,
  setUser: (user: CustomerProfile | null) => {
    return set((): Partial<IUserState> => {
      return { user, userLoaded: !!user };
    });
  },
}));
