export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M17.7188 3V4.83333H4.27431V3H2.59375V10.3333H4.27431V8.5C7.76986 8.5 9.24875 10.4342 10.8033 12.4783C12.3578 14.5225 14.0635 16.75 17.7188 16.75V18.5833H19.3993V13.0833H17.7188V14.9167C14.8534 14.9167 13.5762 13.2392 12.0973 11.305C10.4419 9.1325 8.55972 6.66667 4.27431 6.66667H17.7188V8.5H19.3993V3H17.7188Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
