import React from "react";
import styles from "./styles.module.scss";

interface ImageSectionProps {
  imageUrl: string;
  newPhoto: string;
}

const ImageSection: React.FC<ImageSectionProps> = ({ imageUrl, newPhoto }) => {
  return (
    <div className={styles.imageSection}>
      {newPhoto ? (
        <img
          src={newPhoto?.image_link}
          alt="New Photo"
          className={styles.newImage}
        />
      ) : (
        <img src={imageUrl} alt="Product" className={styles.productImage} />
      )}
    </div>
  );
};

export default ImageSection;
