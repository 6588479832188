import { useState } from "react";

export const Star = () => {
  const [isFilled, setIsFilled] = useState(false);

  const handleClick = () => {
    setIsFilled(!isFilled);
  };

  return (
    <button onClick={handleClick}>
      {isFilled ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.0422 1.64937C9.394 0.783622 10.5899 0.783622 10.9417 1.64937L12.7946 6.65714C12.9441 7.02517 13.2947 7.2649 13.6835 7.2649H18.9923C19.8883 7.2649 20.337 8.37578 19.7034 9.02548L15.3263 13.0671C15.079 13.3207 14.9853 13.6918 15.0813 14.0367L16.6442 19.6464C16.9081 20.5936 15.8782 21.3647 15.0789 20.8182L10.5261 17.7055C10.2026 17.4843 9.7812 17.4843 9.4577 17.7056L4.9125 20.8131C4.1107 21.3612 3.0774 20.5877 3.3421 19.6375L4.9025 14.0367C4.9986 13.6918 4.9048 13.3207 4.6575 13.0671L0.2999 9.04545C-0.3407 8.38838 0.11298 7.2649 1.01909 7.2649H6.30035C6.68912 7.2649 7.03976 7.02517 7.18929 6.65714L9.0422 1.64937Z"
            fill="#C8A857"
            transform="scale(0.9)"
            transform-origin="center"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8568 6.00417L11.8622 6.01888L11.8681 6.03342C12.1663 6.76772 12.8762 7.2649 13.6835 7.2649H18.9886C18.9903 7.26763 18.9923 7.2713 18.9942 7.27605C19.0006 7.29178 19.0004 7.30456 18.9995 7.3097C18.9992 7.31157 18.9989 7.31251 18.9984 7.31346C18.9982 7.31382 18.9974 7.31538 18.9954 7.31808L14.6479 11.3324L14.6287 11.3502L14.6104 11.3689C14.1109 11.8811 13.9277 12.6216 14.118 13.3049L14.118 13.3051L15.6809 18.9148C15.6809 18.9148 15.6809 18.9148 15.6809 18.9148C15.6864 18.9344 15.6865 18.9459 15.6863 18.9508C15.6862 18.9531 15.686 18.9552 15.6857 18.957C15.6853 18.9592 15.6847 18.9611 15.6841 18.9629C15.6813 18.9706 15.674 18.9822 15.661 18.9919C15.6572 18.9947 15.654 18.9966 15.6515 18.9979C15.6493 18.9966 15.6466 18.9949 15.6433 18.9927C15.6433 18.9927 15.6433 18.9927 15.6433 18.9927L11.0905 15.88L11.0905 15.8799C10.4267 15.4262 9.55705 15.4262 8.89324 15.8801L4.34815 18.9875C4.34814 18.9875 4.34812 18.9876 4.34811 18.9876C4.34404 18.9903 4.34074 18.9923 4.33821 18.9937C4.33534 18.9924 4.33139 18.9902 4.32663 18.9866C4.31309 18.9765 4.30538 18.9643 4.30236 18.956C4.30107 18.9525 4.30013 18.9485 4.29991 18.943C4.29969 18.9377 4.29989 18.9259 4.30545 18.9059L4.30545 18.9059L5.86584 13.3051C6.05629 12.6215 5.87278 11.881 5.3735 11.369L5.3551 11.3501L5.33574 11.3322L1.00743 7.33763C1.00461 7.33391 1.00334 7.3315 1.00283 7.3305C1.00183 7.3285 1.0011 7.32646 1.0006 7.32348C0.999355 7.31611 0.999493 7.30105 1.00667 7.28328C1.01031 7.27426 1.01419 7.26837 1.01689 7.26492C1.01757 7.26491 1.0183 7.2649 1.01909 7.2649H6.30035C7.10766 7.2649 7.81745 6.76771 8.11574 6.03356L8.12167 6.01895L8.12715 6.00416L9.97211 1.01786C9.9769 1.00767 9.98052 1.00318 9.98132 1.00221C9.98151 1.00199 9.98166 1.00181 9.98179 1.00166C9.98296 1.00119 9.98629 1.00006 9.99193 1.00006C9.99758 1.00006 10.0009 1.00119 10.0021 1.00166"
            fill="none"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
          />
        </svg>
      )}
    </button>
  );
};
