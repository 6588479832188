import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import BottomArrow from "@assets/icons/bottomArrow";
import LoadMoreButton from "@components/LoadMoreButton";
import { Products } from "@pages/Dashboard/mocks/products";
import Settings from "@assets/img/settings";
import ProductCode from "@components/ProductCode";
import ProblemsBlock from "@components/ProblemsBlock";
import { useModalStore } from "src/store/modal.state";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";

const Table: React.FC = () => {
  const [visibleProducts, setVisibleProducts] = useState(10);
  const [expandedProductId, setExpandedProductId] = useState<number | null>(
    null,
  );
  const [products, setProducts] = useState<ProductWithImageLink[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const productsService = new ProductsService();
  const openModal = useModalStore((state) => state.openModal);

  const handleLoadMore = () => {
    setLoadingMore(true);
    setTimeout(() => {
      setLoadingMore(false);
      setVisibleProducts((prev) => Math.min(prev + 10, products.length));
    }, 1000);
  };

  const toggleExpand = (productId: number) => {
    setExpandedProductId((prev) => (prev === productId ? null : productId));
  };

  const fetchProducts = async (page: number, limit: number = 10) => {
    try {
      const params = { limit, page: 1 };
      const response = await productsService.getOwnProducts(params);

      if (response.length > 0) {
        setProducts(response);

        if (response.length === limit && limit < 32) {
          fetchProducts(page, limit === 8 ? 16 : 32);
        }
      }
    } catch (err) {
      console.error("Error fetching products:", err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0 && visibleProducts === 10) {
      setVisibleProducts(Math.min(10, products.length));
    }
  }, [products]);

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.headerCell}>PRODUCT</th>
            <th className={styles.headerCell}>SESSION TOTAL</th>
            <th className={styles.headerCell}>PAGE VIEWS TOTAL</th>
            <th className={styles.headerCell}>
              featured offer <br /> (buy box) <br />
              percentage
            </th>
            <th className={styles.headerCell}>UNITS ORDERED</th>
            <th className={styles.headerCell}>
              UNITS SESSION <br /> PERCENTAGE
            </th>
            <th className={styles.headerCell}>
              ORDERED PRODUCT <br />
              SALES
            </th>
            <th className={styles.headerCell}>TOTAL ORDER ITEMS</th>
            <th className={styles.headerCell}>
              <button onClick={openModal}>
                <Settings />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {products.slice(0, visibleProducts).map((product, index) => {
            const mockData = Products[index % Products.length];

            return (
              <React.Fragment key={index}>
                <tr
                  className={`${styles.row} ${
                    expandedProductId === index ? styles.expanded : ""
                  }`}
                >
                  <td className={styles.cell}>
                    <div className={styles.productInfo}>
                      <img
                        src={
                          product.image_link || "/src/assets/img/noImage.jpg"
                        }
                        alt="Product Image"
                        className={styles.productImage}
                      />
                      <div className={styles.productDetails}>
                        <p>{product.item_name || "No description "}</p>
                        <ProductCode code={product.asin || "N/A"} />
                      </div>
                    </div>
                  </td>

                  <td className={styles.cell}>
                    <div>{mockData.sessionTotal}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.pageViewsTotal}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.featuredOffer}%</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.unitsOrdered}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.unitsSessionPercentage}%</div>
                  </td>
                  <td className={styles.cell}>
                    <div>${mockData.orderedProductSales}</div>
                  </td>
                  <td className={styles.cell}>
                    <div>{mockData.totalOrderItems}</div>
                  </td>
                  <td className={styles.cell}>
                    <button
                      className={`${styles.expandButton} ${
                        expandedProductId === index ? styles.rotated : ""
                      }`}
                      onClick={() => toggleExpand(index)}
                    >
                      <BottomArrow />
                    </button>
                  </td>
                </tr>
                {expandedProductId === index && (
                  <tr className={styles.expandedRow}>
                    <td colSpan={9} className={styles.expandedCell}>
                      <ProblemsBlock topProblems={mockData.topProblems || []} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {visibleProducts < products.length && (
        <LoadMoreButton onClick={handleLoadMore} />
      )}
    </div>
  );
};

export default Table;
