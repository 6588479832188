import styles from "src/pages/Auth/Auth.module.scss";
import { useTranslation } from "react-i18next";
import Banner from "@assets/img/banner.png";

export const AuthIntro = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.authPageIntro}>
      <h2 className={styles.authPageIntroHeading}>
        {t("message.welcome_title")}
      </h2>
      <p className={styles.authPageIntroText}>{t("message.welcome_message")}</p>
      <img
        src={Banner}
        alt="Auth Banner"
        className={styles.authPageIntroBanner}
      />
    </div>
  );
};
