import { SalesPerformanceApi } from "@services/sales-performance/sales-performance.api";
import { format, isPast } from "date-fns";

export type Checks = {
  sales: boolean;
  spend: boolean;
  impressions: boolean;
  ctr: boolean;
  cvr: boolean;
  cpc: boolean;
  acos: boolean;
  roas: boolean;
  orders: boolean;
  unitSolds: boolean;
  clicks: boolean;
};

export const lineStyle = {
  strokeWidth: 2,
  dot: false,
  activeDot: false,
  xAxisId: "lineAxis",
};

export const areaStyle = {
  strokeWidth: 2,
  strokeDasharray: "3 3",
};

export const formatThousands = (tick) => {
  if (tick < 1000) return tick;
  return `${(tick / 1000).toFixed(1)}k`;
};

export const getData = async (
  dateRange: { startDate: Date; endDate: Date },
  checks: Checks,
) => {
  const { startDate, endDate } = dateRange;
  const startDateString = startDate.toISOString().split("T")[0];
  const endDateString = endDate.toISOString().split("T")[0];

  const salesPerformanceApi = new SalesPerformanceApi();

  const data = {
    sales: null,
    spend: null,
    impressions: null,
    ctr: null,
    cvr: null,
    cpc: null,
    acos: null,
    roas: null,
    unitSolds: null,
    clicks: null,
  };

  const promises: Promise<any>[] = [];
  const keys: string[] = [];

  for (const key of Object.keys(checks)) {
    if (checks[key]) {
      let promise: Promise<any> | null = null;
      switch (key) {
        case "sales":
          promise = salesPerformanceApi.getTotalSales(
            startDateString,
            endDateString,
          );
          break;
        case "spend":
          promise = salesPerformanceApi.getAdSpend(
            startDateString,
            endDateString,
          );
          break;
        case "impressions":
          promise = salesPerformanceApi.getAdImpressions(
            startDateString,
            endDateString,
          );
          break;
        case "ctr":
          promise = salesPerformanceApi.getAdCtr(
            startDateString,
            endDateString,
          );
          break;
        case "cvr":
          promise = salesPerformanceApi.getAdCvr(
            startDateString,
            endDateString,
          );
          break;
        case "cpc":
          promise = salesPerformanceApi.getAdCpc(
            startDateString,
            endDateString,
          );
          break;
        case "acos":
          promise = salesPerformanceApi.getAdAcos(
            startDateString,
            endDateString,
          );
          break;
        case "roas":
          promise = salesPerformanceApi.getAdRoas(
            startDateString,
            endDateString,
          );
          break;
        case "unitSolds":
          promise = salesPerformanceApi.getAdUnitsSold(
            startDateString,
            endDateString,
          );
          break;
        case "clicks":
          promise = salesPerformanceApi.getAdClicks(
            startDateString,
            endDateString,
          );
          break;
        default:
          break;
      }
      if (promise) {
        promises.push(promise);
        keys.push(key);
      }
    }
  }

  const results = await Promise.all(promises);

  keys.forEach((key, index) => {
    data[key] = results[index];
  });

  return {
    date: generateDays(startDate, endDate),
    ...data,
  };
};

export const generateDays = (startDate, endDate) => {
  const days = (endDate - startDate) / (1000 * 60 * 60 * 24);
  const data = [];
  for (let i = 0; i < days; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);

    data.push(format(currentDate, "d MMM"));
  }
  return data;
};

export const transformData = (rawData: any) => {
  return rawData.date.map((date: string, index: number) => ({
    date,
    isForecast: false,
    isPastData: false,
    sales: rawData.sales[index] || 0,
    spend: rawData.spend[index] || 0,
    impressions: rawData.impressions[index] || 0,
    ctr: Number(rawData.ctr[index]) * 100 || 0,
    cvr: Number(rawData.cvr[index]) * 100 || 0,
    cpc: rawData.cpc[index] || 0,
    acos: Number(rawData.acos[index]) * 100 || 0,
    roas: Number(rawData.roas[index]) || 0,
    unitSolds: rawData.unitSolds[index] || 0,
    orders: 0,
  }));
};

export const generatePastData = (
  data: any,
  numDays: number,
  firstDataPoint: any,
  startDate: Date,
): any[] => {
  const result = [];
  for (let i = 0; i <= data.length; i++) {
    const current = data[i];
    if (!current) break;
    if (i >= numDays) {
      result.push({
        ...current,
        isPastData: false,
      });
      continue;
    }

    const date = new Date(startDate);
    date.setDate(date.getDate() + i);

    if (current.sales) {
      current.pastSales = generatePastValue(current.sales, i);
    }
    if (current.spend) {
      current.pastSpend = generatePastValue(current.spend, i);
    }
    if (current.impressions) {
      current.pastImpressions = generatePastValue(current.impressions, i);
    }
    if (current.ctr) {
      current.pastCtr = generatePastValue(current.ctr, i);
    }
    if (current.cvr) {
      current.pastCvr = generatePastValue(current.cvr, i);
    }
    if (current.cpc) {
      current.pastCpc = generatePastValue(current.cpc, i);
    }
    if (current.acos) {
      current.pastAcos = generatePastValue(current.acos, i);
    }
    if (current.roas) {
      current.pastRoas = generatePastValue(current.roas, i);
    }
    if (current.unitSolds) {
      current.pastUnitSolds = generatePastValue(current.unitSolds, i);
    }

    result.push({
      ...current,
      isPastData: true,
    });
  }

  result.push({
    ...firstDataPoint,
    isPastData: true,
  });

  return result;
};

export const generatePastValue = (lastValue: number, index: number): number => {
  return Math.random() * 10 * (1 + 0.02 * (index + 1));
};

export const generateForecastData = (
  numDays: number,
  lastDataPoint: any,
  startDate: string,
): any[] => {
  const dates = [];
  for (let i = 0; i < numDays; i++) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    dates.push(format(date, "d MMM"));
  }

  return dates.map((date: string, index: number) => ({
    date,
    isForecast: true,
    sales: generateForecastValue(lastDataPoint.sales, index),
    spend: generateForecastValue(lastDataPoint.spend, index),
    impressions: generateForecastValue(lastDataPoint.impressions, index),
    ctr: generateForecastValue(lastDataPoint.ctr, index),
    cvr: generateForecastValue(lastDataPoint.cvr, index),
    cpc: generateForecastValue(lastDataPoint.cpc, index),
    acos: generateForecastValue(lastDataPoint.acos, index),
    roas: generateForecastValue(lastDataPoint.roas, index),
    forecastUnitSolds: generateForecastValue(lastDataPoint.unitSolds, index),
  }));
};

export const generateForecastValue = (
  lastValue: number,
  index: number,
): number => {
  console.log("lastValue", lastValue);
  if (index === 0) {
    return lastValue;
  }
  return Math.random() * 10 * (1 + 0.02 * (index + 1));
};
