export const scatterData = [
  [100, 85, "[Campaign Name]"],
  [200, 70, "[Campaign Name]"],
  [150, 65, "[Campaign Name]"],
  [500, 60, "[Campaign Name]"],
  [600, 25, "[Campaign Name]"],
  [100, 35, "[Campaign Name]"],
  [400, 40, "[Campaign Name]"],
  [100, 25, "[Campaign Name]"],
  [700, 20, "[Campaign Name]"],
  [800, 18, "[Campaign Name]"],
  [200, 18, "[Campaign Name]"],
  [100, 15, "[Campaign Name]"],
  [900, 15, "[Campaign Name]"],
];

export const getScatterDataTable = (scatterData) => {
  return scatterData.map((item, index) => {
    const newItem = { ...item };

    let color;
    switch (index) {
      case 0:
        color = "green";
        break;
      case 1:
        color = "yellow";
        break;
      case 2:
        color = "darkRed";
        break;
      case 3:
        color = "red";
        break;
      default:
        color = "default";
    }

    Object.keys(newItem).forEach((key) => {
      if (typeof newItem[key] !== "object" && key !== "key") {
        newItem[key] = <div className={color}>{item[key]}</div>;
      }
    });

    return newItem;
  });
};

export const scatterColumns = [
  {
    title: "Type Ad",
    dataIndex: "typeAd",
    key: "typeAd",
    fixed: "left",
    width: 414,
  },
  {
    title: "Ad Spend",
    dataIndex: "adSpend",
    key: "adSpend",
    width: 400,
  },
  { title: "ACOS", dataIndex: "acos", key: "acos", width: 400 },
];

export const scatterDataTable = [
  {
    key: "1",
    typeAd: "Good",
    adSpend: "$15,249",
    acos: "18%",
    children: [
      {
        key: "1-1",
        typeAd: "Lorem Campaign",
        adSpend: "$5,988",
        acos: "20%",
      },
      {
        key: "1-2",
        typeAd: "Lorem Campaign",
        adSpend: "$2,051",
        acos: "15%",
      },
      {
        key: "1-3",
        typeAd: "Lorem Campaign",
        adSpend: "$3,245",
        acos: "14%",
      },
      {
        key: "1-4",
        typeAd: "Lorem Campaign",
        adSpend: "$2,655",
        acos: "17%",
      },
      {
        key: "1-5",
        typeAd: "Lorem Campaign",
        adSpend: "$1,001",
        acos: "19%",
      },
    ],
  },
  {
    key: "2",
    typeAd: "Mid",
    adSpend: "$2,051",
    acos: "34%",
    children: [
      {
        key: "2-1",
        typeAd: "Lorem Campaign",
        adSpend: "$5,988",
        acos: "20%",
      },
      {
        key: "2-2",
        typeAd: "Lorem Campaign",
        adSpend: "$2,051",
        acos: "15%",
      },
      {
        key: "2-3",
        typeAd: "Lorem Campaign",
        adSpend: "$3,245",
        acos: "14%",
      },
      {
        key: "2-4",
        typeAd: "Lorem Campaign",
        adSpend: "$2,655",
        acos: "17%",
      },
      {
        key: "2-5",
        typeAd: "Lorem Campaign",
        adSpend: "$1,001",
        acos: "19%",
      },
    ],
  },
  {
    key: "3",
    typeAd: "Poor",
    adSpend: "$4,246",
    acos: "72%",
    children: [
      {
        key: "3-1",
        typeAd: "Lorem Campaign",
        adSpend: "$5,988",
        acos: "20%",
      },
      {
        key: "3-2",
        typeAd: "Lorem Campaign",
        adSpend: "$2,051",
        acos: "15%",
      },
      {
        key: "3-3",
        typeAd: "Lorem Campaign",
        adSpend: "$3,245",
        acos: "14%",
      },
      {
        key: "3-4",
        typeAd: "Lorem Campaign",
        adSpend: "$2,655",
        acos: "17%",
      },
      {
        key: "3-5",
        typeAd: "Lorem Campaign",
        adSpend: "$1,001",
        acos: "19%",
      },
    ],
  },
  {
    key: "4",
    typeAd: "No Sales",
    adSpend: "$308",
    acos: "0%",
    children: [
      {
        key: "4-1",
        typeAd: "Lorem Campaign",
        adSpend: "$5,988",
        acos: "20%",
      },
      {
        key: "4-2",
        typeAd: "Lorem Campaign",
        adSpend: "$2,051",
        acos: "15%",
      },
      {
        key: "4-3",
        typeAd: "Lorem Campaign",
        adSpend: "$3,245",
        acos: "14%",
      },
      {
        key: "4-4",
        typeAd: "Lorem Campaign",
        adSpend: "$2,655",
        acos: "17%",
      },
      {
        key: "4-5",
        typeAd: "Lorem Campaign",
        adSpend: "$1,001",
        acos: "19%",
      },
    ],
  },
  {
    key: "5",
    typeAd: "Total",
    adSpend: "$15,605",
    acos: "24%",
  },
];
