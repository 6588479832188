import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import { NavigateFunction, Params, Location } from "react-router-dom";
import { withRouter } from "@lib/withRouter";
import { ErrorLayout } from "./ui/ErrorLayout";

type Props = {
  children: React.ReactNode;
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<Params<string>>;
  };
};

type State = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    _prevState: Readonly<State>,
    _snapshot?: any,
  ): void {
    if (
      prevProps.router.location.pathname !== this.props.router.location.pathname
    ) {
      this.setState({
        error: null,
        errorInfo: null,
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    // @ts-ignore
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      return <ErrorLayout />;
    }

    return this.props.children;
  }
}

const HistoryErrorBoundary = withRouter(ErrorBoundary);

export { HistoryErrorBoundary as ErrorBoundary };
