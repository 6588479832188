import { useCallback, useMemo } from "react";
import moment from "moment";
import { Button, Table } from "antd";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import type { TableRowSelection } from "antd/lib/table/interface";

import { DocumentsQueryKey } from "@hooks/useDocuments";
import { Folder } from "@entities/Folder";
import { Document } from "@entities/Document";
import { DocumentsService } from "@services/DocumentsService";
import { DocumentResponse } from "@entities/DocumentResponse";
import SvgIcon from "@components/svg-icon/SvgIcon";
import NewLineText from "@components/new-line-text/NewLineText";

import "./document-table.less";

type DocumentTableProps = {
  loading: boolean;
  dataList: DocumentResponse;
  folderById: Record<Folder["id"], Folder>;
  selectedList: Document["id"][];
  onRowSelection: TableRowSelection<object>["onChange"];
};

export const DocumentTable = (props: DocumentTableProps) => {
  const { loading, dataList, folderById, selectedList, onRowSelection } = props;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleOpen = useCallback(
    (document) => {
      if (document) {
        if (document.data.tool === "free-write") {
          navigate(`/free-form-writing/${document.id}`);
        } else {
          navigate(`/ai-templates/${document.data.tool}/${document.id}`);
        }
      }
    },
    [navigate],
  );

  const handleDelete = useCallback(
    async (id) => {
      await DocumentsService.deleteDocument(id);

      queryClient.invalidateQueries(DocumentsQueryKey);
    },
    [queryClient],
  );

  const tableColumns = useMemo(
    () => [
      {
        title: "preview",
        dataIndex: ["data", "payload", "output"],
        className: "preview-cell",
        // in case with ffw, it will be string
        render: (data = {}) => {
          if (typeof data === "string") {
            return (
              <div
                className="preview-cell-content"
                dangerouslySetInnerHTML={{ __html: data }}
              />
            );
          }

          return (
            <div className="preview-cell-content">
              <span>
                <NewLineText string={Object.values(data).join("\n")} />
              </span>
            </div>
          );
        },
      },
      {
        title: "title",
        className: "title-cell",
        render: (row) => {
          return (
            <div className="title-cell-content">
              <h4 className="title-cell-value">{row?.data?.title || "-"}</h4>
              <div className="action-btns">
                <Button
                  shape="circle"
                  className="edit-btn"
                  icon={<SvgIcon type="new-tab" />}
                  onClick={() => handleOpen(row)}
                />
                <Button
                  shape="circle"
                  className="delete-btn"
                  icon={<SvgIcon type="trash" />}
                  onClick={() => handleDelete(row.id)}
                />
              </div>
            </div>
          );
        },
      },
      {
        title: "date of change",
        dataIndex: "updated_at",
        render: (data) =>
          data ? (
            <span className="data-cell date-cell-content">
              <SvgIcon type="clock" />
              <span>{moment(moment.utc(data)).fromNow()}</span>
            </span>
          ) : (
            "-"
          ),
      },
      {
        title: "folder",
        dataIndex: "folder",
        render: (data) => {
          return data ? (
            <span className="data-cell folder-cell-content">
              <SvgIcon type="folder" />
              <span>{folderById?.[data]?.name || data}</span>
            </span>
          ) : (
            "-"
          );
        },
      },
    ],
    [folderById, handleDelete, handleOpen],
  );

  return (
    <div className="document-table-container">
      <Table
        rowKey="id"
        loading={loading}
        dataSource={dataList?.results}
        columns={tableColumns}
        className="document-table"
        pagination={{
          hideOnSinglePage: true,
        }}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          selectedRowKeys: selectedList,
          onChange: onRowSelection,
        }}
      />
    </div>
  );
};
