import "./nyle-field.less";
import { memo, ReactNode } from "react";

interface Props {
  label: string;
  error?: string;
  description?: string;
  children: ReactNode;
}

export const NyleField = memo(
  ({ label, children, description, error }: Props) => {
    return (
      <div className="nyle-field">
        <div className="nyle-field__label">
          <span className="nyle-field__title">{label}</span>
          {description && (
            <span className="nyle-field__description">{description}</span>
          )}
          {children}
          {error && <span className="nyle-field__error">{error}</span>}
        </div>
      </div>
    );
  },
);

NyleField.displayName = "NyleInput";
