import { CheckWidgetData } from "@components/CheckWidget";

export const cardData: CheckWidgetData[] = [
  {
    title: "Sales",
    value: "$172,336.90",
    increase: "+3.33%",
    increaseDirection: "up",
    color: "#80C67A",
  },
  {
    title: "Spend",
    value: "$41,381.80",
    increase: "-2.14%",
    increaseDirection: "down",
    color: "#BF4C46",
  },
  {
    title: "Impressions",
    value: "1,371,797",
    increase: "-2.12%",
    increaseDirection: "down",
    color: "#5C55D5",
  },
  {
    title: "CTR",
    value: "0.61%",
    increase: "+0.03%",
    increaseDirection: "up",
    color: "#67AC9B",
  },
  {
    title: "CVR",
    value: "4.09%",
    increase: "+1.22%",
    increaseDirection: "up",
    color: "#9C8437",
  },
  {
    title: "CPC",
    value: "$1.11",
    increase: "-0.10%",
    increaseDirection: "down",
    color: "#A8A03D",
  },
  {
    title: "ACOS",
    value: "27.80%",
    increase: "+3.01%",
    increaseDirection: "up",
    color: "#9E44CC",
  },
  {
    title: "ROAS",
    value: "3.60",
    increase: "+0.50%",
    increaseDirection: "up",
    color: "#F19867",
  },
  {
    title: "Orders",
    value: "8,891",
    increase: "+12.11%",
    increaseDirection: "up",
    color: "#5295E0",
  },
  {
    title: "Units Sold",
    value: "1,131",
    increase: "-0.98%",
    increaseDirection: "down",
    color: "#255FA0",
  },
];
