import React from "react";
import styles from "./styles.module.scss";
import AuthButtonArrow from "@assets/icons/authButtonArrow";

interface ButtonProps {
  text: string;
  onClick: () => void;
  className?: string;
}

const AuthButton: React.FC<ButtonProps> = ({ text, onClick, className }) => {
  return (
    <button className={`${styles.button} ${className}`} onClick={onClick}>
      {text} <AuthButtonArrow />
    </button>
  );
};

export default AuthButton;
