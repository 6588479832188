import { Api } from "src/utils/api";
import { CUSTOMERS_API_BASE_URL } from "./customers.constant";
import { CustomerProfile } from "@entities/CustomerProfile";

export class CustomersApi extends Api {
  constructor() {
    super(CUSTOMERS_API_BASE_URL);
  }

  public getCustomers = async (): Promise<CustomerProfile[]> => {
    return await this.get("");
  };

  public selectCustomer = async (
    customerId: string,
  ): Promise<{
    access: string;
    refresh: string;
  }> => {
    return await this.post(`/${customerId}/impersonate`, {});
  };
}
