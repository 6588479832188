import { TooltipData } from "@components/TreeChart/chart.types";

export const mockTooltipData: Omit<TooltipData, "name"> = {
  sales: {
    value: "$1,371.60",
    increase: "4.7%",
    increaseIcon: "trendUp",
  },
  spend: {
    value: "$891",
    increase: "1.2%",
    increaseIcon: "trendUp",
  },
  impressions: {
    value: "21,292",
    increase: "0.2%",
    increaseIcon: "trendDown",
  },
  ctr: {
    value: "0.31%",
    increase: "0.8%",
    increaseIcon: "trendDown",
  },
  cvr: {
    value: "3.50%",
    increase: "5.2%",
    increaseIcon: "trendUp",
  },
  cpc: {
    value: "$1.11",
    increase: "1.8%",
    increaseIcon: "trendDown",
  },
  acos: {
    value: "25.45%",
    increase: "3.2%",
    increaseIcon: "trendUp",
  },
  roas: {
    value: "2.11",
    increase: "0.1%",
    increaseIcon: "trendDown",
  },
  orders: {
    value: "132",
    increase: "0.5%",
    increaseIcon: "trendDown",
  },
  ntbOrders: {
    value: "88",
    increase: "1.8%",
    increaseIcon: "trendUp",
  },
};
