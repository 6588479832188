import SvgIcon from "@components/svg-icon/SvgIcon";
import { Button, Input } from "antd";
import "./add-users.less";

export const AddUsers = () => {
  return (
    <div className="add-users">
      <h3 className="add-users__title">Add new users</h3>

      <p className="add-users__text">ENTER E-MAIL (COMMA SEPARATED)</p>

      <Input />

      <div className="add-users__button-wrapper">
        <Button type="primary" shape="round" size="middle">
          SEND INVITE <SvgIcon type="arrow-right" />
        </Button>
      </div>
    </div>
  );
};
