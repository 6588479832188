import { useOutsideClick } from "src/utils/useOutsideClick";
import styles from "./styles.module.scss";
import TrendUp from "@assets/icons/trendUp";
import TrendDown from "@assets/icons/trendDown";
import classNames from "classnames";
import productImg from "@assets/img/product1.png";
import { TooltipData } from "@components/TreeChart/chart.types";
import ReactEcharts from "echarts-for-react";
import { getChartOptions, getTooltipValueName } from "./utils";

export type TooltipGrapgicsDataValue = {
  valueToChild: number;
  valueFromChild: number;
};

export interface TooltipGrapgicsData {
  nodeName: string;
  children: {
    [key: string]: TooltipGrapgicsDataValue;
  };
}

interface TooltipProps {
  data: TooltipData;
  clearStyles: () => void;
  position: number;
  withImg?: boolean;
  withButton?: boolean;
  graphics?: TooltipGrapgicsData;
}

export const Tooltip = ({
  data,
  clearStyles,
  position,
  withImg,
  withButton,
  graphics,
}: TooltipProps) => {
  const tooltipRef = useOutsideClick(clearStyles);

  return (
    <div
      className={classNames(
        styles.tooltip,
        withImg && styles.withImg,
        graphics && styles.withGraph,
      )}
      ref={tooltipRef}
      style={{
        top: `${position}px`,
        position: "absolute",
      }}
    >
      <div className={styles.title}>
        <h4>{data.name}</h4>
        {withButton && <button onClick={clearStyles}>VIEW PLACEMENT</button>}
      </div>
      <div className={styles.info__container}>
        {withImg && (
          <div className={styles.img}>
            <img src={productImg} alt="product" />
          </div>
        )}
        <div className={styles.info}>
          {Object.entries(data).map(([key, value]) => {
            if (key === "name") {
              return null;
            }
            return (
              <div key={key} className={styles.row}>
                <div className={styles.key}>{getTooltipValueName(key)}</div>
                <div className={styles.value}>
                  <span>{value.value}</span>
                  {value.increaseIcon === "trendUp" ? (
                    <TrendUp />
                  ) : (
                    <TrendDown />
                  )}
                  <span
                    className={
                      value.increaseIcon === "trendUp"
                        ? styles.increaseUp
                        : styles.increaseDown
                    }
                  >
                    {value.increase}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        {graphics && (
          <div className={styles.graph}>
            {Object.entries(graphics.children).map(([key, value]) => (
              <ReactEcharts
                key={key}
                option={getChartOptions(key, value)}
                style={{ height: "180px", width: "259px" }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
