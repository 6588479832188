import Axios from "axios";

export class ConfirmNewPassword {
  public static async resetPasswordConfirm(
    uid: string,
    token: string,
    newPassword: string,
    reNewPassword: string,
  ) {
    const { data } = await Axios.post(
      "/api/v1/auth/users/reset_password_confirm/",
      {
        uid,
        token,
        new_password: newPassword,
        re_new_password: reNewPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return data;
  }
}
