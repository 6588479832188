import { useState } from "react";
import { useQuery } from "react-query";
import { Modal, Radio, RadioChangeEvent } from "antd";

import { DocumentsService } from "@services/DocumentsService";

import { SaveDocument } from "./ui/SaveDocument";
import "./save-modal.less";

type DataToSave = {
  input?: Record<any, any>;
  output: Record<any, any>;
};

type Props = {
  isExisted?: boolean;
  currentFolder?: number;
  documentId?: number;
  initialTitle?: string;
  data: DataToSave;
  toolId: string;
  visible: boolean;
  onClose?: () => void;
};

type SaveType = "update" | "create";

export const SaveModal = ({
  visible,
  onClose,
  data,
  toolId,
  isExisted,
  initialTitle,
  documentId,
  currentFolder,
}: Props) => {
  const [saveType, setSaveType] = useState<SaveType>(
    isExisted ? "update" : "create",
  );
  const queryFolders = useQuery(["folders_list"], () =>
    DocumentsService.getFoldersList(),
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleFiledChange = (e: RadioChangeEvent) => {
    setSaveType(e.target.value);
  };

  return (
    <Modal
      width={640}
      footer={null}
      visible={visible}
      onCancel={handleClose}
      className="save-doc-modal"
    >
      <div className="save-doc-modal-content">
        <h4 className="save-doc-modal-title">Save result</h4>

        {isExisted && (
          <div className="field-item save-type">
            <Radio.Group value={saveType} onChange={handleFiledChange}>
              <Radio value="update">Update document</Radio>
              <Radio value="create">Save to a new document</Radio>
            </Radio.Group>
          </div>
        )}

        <SaveDocument
          documentId={documentId}
          data={data}
          toolId={toolId}
          initialTitle={initialTitle}
          folders={queryFolders.data}
          currentFolder={currentFolder}
          onDocumentCreated={handleClose}
          isUpdating={saveType === "update"}
        />
      </div>
    </Modal>
  );
};
