import "./index.css";
import Star from "./Star.tsx";
import { FC } from "react";

interface IProps {
  rating: number;
}

export const Rating: FC<IProps> = ({ rating }: IProps) => {
  const width = `${(rating / 5) * 100}%`;

  return (
    <div className="rating">
      <div className="rating__stars">
        <div className="rating-underlay">
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
        </div>
        <div className="rating-overlay" style={{ width }}>
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
          <div className="rating__star">
            <Star />
          </div>
        </div>
      </div>

      <p className="rating__value heading-3">{rating}</p>
    </div>
  );
};
