import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { ApiResponse } from "src/types/apiResponce";

export class CategoriesService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getCategories(asin = ""): Promise<ApiResponse<any>> {
    return this.get("/categories", { asin });
  }
}
