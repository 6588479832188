export const updateCroInfo = (prevData, res) => {
  if (!res) return prevData;

  return prevData.map((item) => {
    switch (item.id) {
      case 1:
        return {
          ...item,
          percentage: res?.total_listing_os ?? item.percentage,
        };
      case 2:
        return { ...item, percentage: res?.total_photo_os ?? item.percentage };
      case 3:
        return { ...item, percentage: res?.total_video_os ?? item.percentage };
      case 4:
        return { ...item, percentage: res?.total_a_plus_os ?? item.percentage };
      default:
        return item;
    }
  });
};
