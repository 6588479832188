import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import styles from "./style.module.scss";
import { SpiderService } from "@services/spider/spider.services";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import { useOutsideClick } from "src/utils/useOutsideClick";
import dropDownArrow from "@assets/icons/inputArrow.svg";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import ProductCode from "@components/ProductCode";
import ModalBox from "@components/ModalBox";
import classNames from "classnames";
import { ProductTab } from "@pages/Overview/components/ProductSelectionModal";
import { ProductName } from "@components/ProductName";
import SearchBar from "@components/Search";

export interface Product {
  id?: number;
  name?: string;
  title: string;
  asin: string;
  images?: string[];
  benefits?: string[];
  image_link?: string;
  picture_url?: string;
  item_name?: string;
  score?: number;
}

interface ProductSelectionDropdownProps {
  isOpen: boolean;
  onSelect: (selectedProducts: Product[]) => void;
  onClose: () => void;
  productTwo: boolean;
}

const MultiSelectModal: React.FC<ProductSelectionDropdownProps> = ({
  isOpen,
  onSelect,
  onClose,
  productTwo = false,
}) => {
  const tabs = productTwo
    ? [{ tab: "allProducts", label: "Other Products" }]
    : [{ tab: "allProducts", label: "Other Products" }];

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activeTab, setActiveTab] = useState<ProductTab>(
    tabs[0].tab as ProductTab,
  );
  const [spiders, setSpiders] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    selectedProduct,
    selectedProductToCompare,
    selectedProductToCompareMultiselect,
    setSelectedProductToCompare,
  } = useProductStore();
  const { setProductTwoSpiderValues, setProductTwoSpiderTitles } =
    useInsightsStore();
  const modalRef = useOutsideClick(() => {
    onClose();
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<Product[]>([]);

  const isCompareRoute = location.pathname.includes("/compare");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const params = {
          asin: searchTerm,
          limit: itemsPerPage,
        };
        if (searchTerm) {
          if (activeTab === "myProducts") {
            await AmazonService.products.searchOwnProducts(params);
          } else {
            const response =
              await AmazonService.products.searchPublicProducts(params);
            setSearchResults(response);
          }
        } else {
          setSearchResults([]);
          if (activeTab === "myProducts") {
            await AmazonService.products.getOwnProducts(params);
          } else {
            await AmazonService.products.getPublicProducts(params);
          }
        }
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };

    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen, activeTab, itemsPerPage, searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleProductSelection = (asin: string) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.includes(asin)) {
        return prevSelected.filter((item) => item !== asin);
      } else {
        if (prevSelected.length < 6) {
          return [...prevSelected, asin];
        } else {
          return prevSelected;
        }
      }
    });
  };

  const capitalizeWords = (string: string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const paginatedItems = selectedItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleApply = async () => {
    if (selectedProducts.length > 0) {
      const apiResponses = [];
      const spider = new SpiderService();
      const additionalAsins = selectedProductToCompare
        .slice(0, 1)
        .map((product) => product.asin)
        .filter(Boolean);

      const allAsinsSet = new Set([...selectedProducts]);
      const allAsinSet = new Set([
        additionalAsins,
        ...selectedProducts,
        selectedProduct?.asin,
      ]);

      const allAsins = Array.from(allAsinsSet);
      const allAsin = Array.from(allAsinSet);

      let spiderDotsCached = null;
      let spiderDataCached = null;

      try {
        setSelectedProductToCompare([]);
        if (allAsins.length > 0) {
          spiderDotsCached = await AmazonService.products.postPublicProduct(
            ...allAsins,
          );
        }

        if (allAsins.length > 0) {
          spiderDataCached = await spider.getSpiderDataList(...allAsin);
        }
        const keys = Object.keys(spiderDataCached);

        await handleOverallScore(keys);
        const spiderValues = Object.values(spiderDataCached).map((value) =>
          typeof value === "number" ? parseFloat(value.toFixed(1)) : value,
        );

        const spiderTitles = Object.keys(spiderDataCached).map((key) =>
          capitalizeWords(key.replace(/_/g, " ")),
        );

        apiResponses.push(
          ...apiResponses,
          ...selectedProducts.map((asin) => ({
            asin,
            spiderDots: spiderDotsCached,
            spiderData: spiderDataCached,
            spiderValues,
            spiderTitles,
          })),
        );
      } catch (error) {
        console.error("Error fetching data for ASINs:", error);
      }

      if (apiResponses.length > 0) {
        setProductTwoSpiderTitles(apiResponses[0].spiderTitles);
        setProductTwoSpiderValues(apiResponses[0].spiderValues);
        selectedProductToCompareMultiselect(apiResponses);
        onSelect(apiResponses);
        onClose();
      }
    } else {
      console.log("No products selected");
    }
  };

  const fetchCategories = async () => {
    const spider = new CategoriesService();
    if (isCompareRoute) {
      const spidersData = await spider.getCategories(selectedProduct?.asin);
      setSpiders(spidersData);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);
  const handleOverallScore = async (keys) => {
    const spider = new SpiderService();

    try {
      let allAsin;
      if (Array.isArray(selectedProductToCompare)) {
        allAsin = selectedProductToCompare
          .map((product) => product.asin)
          .filter(Boolean);
      } else if (selectedProductToCompare && selectedProductToCompare.asin) {
        allAsin = [selectedProductToCompare.asin];
      }
      const overall = await spider.getDifferenceOverallScore(
        allAsin,
        selectedProduct.asin,
        keys,
      );
    } catch (error) {
      console.error("Error in handleProdu:", error);
    }
  };
  const handleCategoryClick = (index: number) => {
    setSelectedItems(spiders[index].items);
    setIsCategorySelected(true);
    setCurrentPage(1);
  };

  const handleBackClick = () => {
    setIsCategorySelected(false);
  };

  const renderTabButton = (tab: ProductTab, label: string) => (
    <button
      key={tab}
      className={classNames(styles.tabButton, {
        [styles.active]: activeTab === tab,
      })}
      onClick={() => setActiveTab(tab as ProductTab)}
    >
      {label.toUpperCase()}
    </button>
  );

  const renderSwitchButton = (value: number) => (
    <button
      key={value}
      className={classNames(styles.switchButton, {
        [styles.active]: itemsPerPage === value,
      })}
      onClick={() => setItemsPerPage(value)}
    >
      {value}
    </button>
  );

  const itemsToDisplay = searchTerm ? searchResults : paginatedItems;

  if (!isOpen) return null;

  return (
    <ModalBox
      isOpen={isOpen}
      onClose={onClose ? onClose : () => {}}
      title="Choose ASIN"
      hideCloseButton={!onClose}
    >
      <div className={styles.tabButtons}>
        <div className={styles.tabButtonsWrapper}>
          {tabs.map(({ tab, label }) =>
            renderTabButton(tab as ProductTab, label),
          )}
        </div>
      </div>

      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <div className={styles.sortSection}>
        <div className={styles.sortSelect}>
          <span className={styles.sortText}>SORT ALPHABETICALLY</span>
          <img
            src={dropDownArrow}
            alt="Sort"
            className={styles.dropDownArrow}
          />
        </div>
        <div className={styles.switchButtons}>
          {[8, 16, 32, 60].map(renderSwitchButton)}
        </div>
      </div>

      {activeTab === "allProducts" ? (
        <div className={styles.productGridContainer}>
          <div className={styles.backButton}>
            {isCategorySelected && (
              <button onClick={handleBackClick} className={styles.bcButton}>
                Back to Categories
              </button>
            )}
          </div>
          {isCategorySelected ? (
            <div className={styles.productGridContainer}>
              <div className={styles.productGrid}>
                {itemsToDisplay && itemsToDisplay.length > 0 ? (
                  itemsToDisplay.map((item: Product, itemIndex: number) => (
                    <div
                      key={`${activeTab}-${item.asin}-${itemIndex}`}
                      className={styles.productCard}
                    >
                      <img
                        src={item.picture_url || item.image || ""}
                        alt={item.title}
                        className={styles.productImage}
                        width={124}
                        height={124}
                      />
                      <ProductName name={item.title} />
                      <div className={styles.productDetails}>
                        <ProductCode code={item.asin} />
                      </div>
                      <div className={styles.productDetails}>
                        <span>⭐ {item.score}</span>
                      </div>
                      <Checkbox
                        checked={selectedProducts.includes(item.asin)}
                        disabled={
                          selectedProducts.length >= 5 &&
                          !selectedProducts.includes(item.asin)
                        }
                        onChange={() => handleProductSelection(item.asin)}
                      >
                        SELECT
                      </Checkbox>
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.categoryListWrapper}>
              {spiders && spiders.length > 0 ? (
                spiders.map((spider: any, index: number) => (
                  <div
                    className={styles.categoryItem}
                    key={index}
                    onClick={() => handleCategoryClick(index)}
                  >
                    <span className={styles.categoryText}>
                      {spider.Sub_category || "N/A"}
                    </span>
                    <span className={styles.categoryArrow}>›</span>
                  </div>
                ))
              ) : (
                <p></p>
              )}
            </div>
          )}
        </div>
      ) : (
        <p></p>
      )}

      <button className={styles.applyButton} onClick={handleApply}>
        Apply
      </button>
    </ModalBox>
  );
};

export default MultiSelectModal;
