import React, { useEffect } from "react";
import "./index.css";
import { Description } from "../Description";
import ProblemsBlock from "@components/ProblemsBlock";
import { Products } from "@pages/Dashboard/mocks/products";

export interface Product {
  asin: string;
  item_name: string;
  item_description: string;
  image_link: string;
  price: number;
  bullet_points?: string[] | null;
  link: string;
}
interface ProductProps {
  prod: Product;
}
export const Product: React.FC<ProductProps> = ({ prod }) => {
  const product = Products[0];

  return (
    <div className="product">
      <Description prod={prod} />
      <div className="product-widgets">
        <div className="problems-block">
          <ProblemsBlock topProblems={product.topProblems} />
        </div>
      </div>
    </div>
  );
};
