import { useCallback, useState } from "react";

import { ImageEnhancePreview } from "./ui/ImageEnhancePreview";
import { ImageEnhanceToolbar } from "./ui/ImageEnhanceToolbar";

import "./image-enhance.less";
import { ImageEnhanceSlider } from "./ui/ImageEnhanceSlider";
import {
  useEnhanceImage,
  useUploadFile,
} from "@components/ImageUploader/hooks/useImageServices";
import { useProfile } from "@hooks/useProfile";
import { useLocation, useNavigate } from "react-router-dom";

export const ImageEnhance = () => {
  const [entryFile, setEntryFile] = useState<File>(null);
  const [outputFile, setOutputFile] = useState<File>(null);
  const [sizeMode, setSizeMode] = useState<"enlarge" | "custom">("enlarge");
  const [enlargeSlider, setEnlargeSlider] = useState(1);
  const { data: profile } = useProfile();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile({
    onSuccess: ({ data }) => {
      enchanceImage({
        id: data.id,
        factor: enlargeSlider,
      });
    },
  });
  const { mutate: enchanceImage, isLoading: isEnhancing } = useEnhanceImage({
    onSuccess: (data) => {
      setOutputFile(data);
    },
  });

  const handleSubmitToolbarForm = useCallback(() => {
    if (!profile?.subscription) {
      navigate(location, {
        state: {
          needToShowPurchaseModal: true,
        },
      });

      return;
    }

    uploadFile({ img: entryFile });
  }, [entryFile, location, navigate, profile?.subscription, uploadFile]);

  const handleToolbarClear = useCallback(() => {
    setEntryFile(null);
    setOutputFile(null);
  }, []);

  const isLoading = isUploading || isEnhancing;

  return (
    <div className="image-enhance">
      {!entryFile && <ImageEnhancePreview />}

      {entryFile && (
        <ImageEnhanceSlider
          imageBefore={URL.createObjectURL(entryFile)}
          imageAfter={outputFile ? URL.createObjectURL(outputFile) : undefined}
          isLoading={isLoading}
        />
      )}

      <ImageEnhanceToolbar
        file={entryFile}
        sizeMode={sizeMode}
        isLoading={isLoading}
        enlargeSlider={enlargeSlider}
        onDrop={setEntryFile}
        setSizeMode={setSizeMode}
        onClear={handleToolbarClear}
        onStart={handleSubmitToolbarForm}
        setEnlargeSlider={setEnlargeSlider}
      />
    </div>
  );
};
