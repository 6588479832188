import { create } from "zustand";
import { InsightsState } from "./types";

export const useInsightsStore = create<InsightsState>((set) => ({
  productOne: {
    asin: "",
    hypotheses: [],
    aspects: [],
    phrases: [],
    problems: [],
    reviews: [],
    spiderTitles: [],
    spiderValues: [],
  },
  productTwo: {
    asin: "",
    hypotheses: [],
    aspects: [],
    phrases: [],
    problems: [],
    reviews: [],
    spiderTitles: [],
    spiderValues: [],
  },

  setProductOneHypotheses: (hypotheses) =>
    set((state) => ({
      productOne: { ...state.productOne, hypotheses },
    })),
  setProductOneAspects: (aspects) =>
    set((state) => ({
      productOne: { ...state.productOne, aspects },
    })),
  setProductOnePhrases: (phrases) =>
    set((state) => ({
      productOne: { ...state.productOne, phrases },
    })),
  setProductOneProblems: (problems) =>
    set((state) => ({
      productOne: { ...state.productOne, problems },
    })),
  setProductOneReviews: (reviews) =>
    set((state) => ({
      productOne: { ...state.productOne, reviews },
    })),
  setProductOneSpiderTitles: (spiderTitles) =>
    set((state) => ({
      productOne: { ...state.productOne, spiderTitles },
    })),
  setProductOneSpiderValues: (spiderValues) =>
    set((state) => ({
      productOne: { ...state.productOne, spiderValues },
    })),
  setProductOneAsin: (asin) =>
    set((state) => ({
      productOne: { ...state.productOne, asin },
    })),

  setProductTwoHypotheses: (hypotheses) =>
    set((state) => ({
      productTwo: { ...state.productTwo, hypotheses },
    })),
  setProductTwoAspects: (aspects) =>
    set((state) => ({
      productTwo: { ...state.productTwo, aspects },
    })),
  setProductTwoPhrases: (phrases) =>
    set((state) => ({
      productTwo: { ...state.productTwo, phrases },
    })),
  setProductTwoProblems: (problems) =>
    set((state) => ({
      productTwo: { ...state.productTwo, problems },
    })),
  setProductTwoReviews: (reviews) =>
    set((state) => ({
      productTwo: { ...state.productTwo, reviews },
    })),
  setProductTwoSpiderTitles: (spiderTitles) =>
    set((state) => ({
      productTwo: { ...state.productTwo, spiderTitles },
    })),
  setProductTwoSpiderValues: (spiderValues) =>
    set((state) => ({
      productTwo: { ...state.productTwo, spiderValues },
    })),
  setProductTwoAsin: (asin) =>
    set((state) => ({
      productOne: { ...state.productOne, asin },
    })),
  clearProductOne: () =>
    set(() => ({
      productOne: {
        hypotheses: [],
        aspects: [],
        phrases: [],
        problems: [],
        reviews: [],
        spiderTitles: [],
        spiderValues: [],
        asin: "",
      },
    })),
  clearProductTwo: () =>
    set(() => ({
      productTwo: {
        hypotheses: [],
        aspects: [],
        phrases: [],
        problems: [],
        reviews: [],
        spiderTitles: [],
        spiderValues: [],
        asin: "",
      },
    })),
}));
