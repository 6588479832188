import axios from "axios";
import { Country } from "src/entities/Country";

export class MetaService {
  static readonly URL = "/api/v1/countries";

  public static async getCountries(): Promise<Country[]> {
    const { data } = await axios.get<Country[]>(MetaService.URL);

    return data;
  }
}
