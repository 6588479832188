import { useMemo, useState } from "react";
import axios from "axios";
import { useQueryClient } from "react-query";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Button, Checkbox, notification, Pagination, Space } from "antd";

import { Folder } from "@entities/Folder";
import { Document } from "@entities/Document";
import { useTitle } from "@hooks/useTitle";
import { useFolders } from "@hooks/useFolders";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { DocumentsQueryKey, useDocuments } from "@hooks/useDocuments";

import { DocumentsService } from "@services/DocumentsService";
import { FolderSelect } from "./components/folder-select/FolderSelect";
import { DocumentTable } from "./components/document-table/DocumentTable";
import "./document-page.less";
import { ModalMoveToFolder } from "./components/ModalMoveToFolder";

const DOCUMENTS_PER_PAGE: number = 10;

export const DocumentPage = () => {
  useTitle("Nyle - Documents");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFolders, setCurrentFolders] = useState<Folder["id"][]>([]);
  const { data: folderList, isLoading: isFoldersLoading } = useFolders();
  const {
    data: documentList,
    isLoading: isDocumentsLoading,
    isRefetching,
  } = useDocuments([String(currentPage), currentFolders.join(",")]);
  const queryClient = useQueryClient();
  const [selectedList, setSelectedList] = useState<Document["id"][]>([]);
  const [hasModalToMoveDocuments, setHasModalToMoveDocuments] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const mappedDocuments = useMemo(
    () =>
      documentList?.results?.reduce(
        (acc, document) => ({ ...acc, [document.id]: document }),
        {},
      ),
    [documentList?.results],
  );

  const normalizedFolders = useMemo(
    () =>
      folderList?.reduce(
        (acc, folder) => ({ ...acc, [folder.id]: folder }),
        {},
      ),
    [folderList],
  );
  const isLoading = isFoldersLoading || isDocumentsLoading || isRefetching;

  const handleSelectAll = (event: CheckboxChangeEvent) => {
    setSelectedList(
      event.target.checked ? documentList.results.map(({ id }) => id) : [],
    );
  };

  const handleRowSelection = (selectedRowKeys) => {
    setSelectedList(selectedRowKeys);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFolderChange = (folders: number[]) => {
    setCurrentFolders(folders);
    setSelectedList([]);
  };

  const handleMassDelete = async () => {
    try {
      await Promise.all(
        selectedList.map((documentId) =>
          DocumentsService.deleteDocument(documentId),
        ),
      );
      queryClient.invalidateQueries([
        DocumentsQueryKey,
        String(currentPage),
        currentFolders.join(","),
      ]);
      setSelectedList([]);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        notification.error({
          message: error.response?.data?.detail ?? "Failed to delete document",
        });
      }
    }
  };

  const handleMassMove = async (folderId: number) => {
    try {
      await Promise.all(
        selectedList.map((documentId) =>
          DocumentsService.updateDocument(documentId, {
            ...mappedDocuments[documentId],
            folder: folderId,
          }),
        ),
      );

      queryClient.invalidateQueries([
        DocumentsQueryKey,
        String(currentPage),
        currentFolders.join(","),
      ]);
      setSelectedList([]);
      setHasModalToMoveDocuments(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        notification.error({
          message: error.response?.data?.detail ?? "Failed to move document",
        });
      }
    }
  };

  return (
    <div className="document-page-container">
      <section>
        <div className="container">
          <h2 className="page-main-title">Documents</h2>
        </div>
      </section>

      <section className="table-header-action-section">
        <div className="container">
          <div className="table-header-action">
            <Checkbox
              className="select-all"
              checked={Boolean(selectedList.length)}
              onChange={handleSelectAll}
            >
              select all
            </Checkbox>

            {Boolean(selectedList.length) && (
              <Space>
                <span>OPERATIONS WITH SELECTED:</span>
                <Button
                  shape="round"
                  size="small"
                  onClick={handleMassDelete}
                  className="delete-all-btn"
                >
                  DELETE
                  <SvgIcon type="trash" />
                </Button>
                <Button
                  shape="round"
                  size="small"
                  type="primary"
                  className="light-blue-btn"
                  onClick={() => setHasModalToMoveDocuments(true)}
                >
                  MOVE TO A FOLDER
                  <SvgIcon type="folder" />
                </Button>
              </Space>
            )}

            <FolderSelect
              folderList={folderList}
              currentFolders={currentFolders}
              setCurrentFolders={handleFolderChange}
              showCreateModal={showCreateModal}
              setShowCreateModal={setShowCreateModal}
            />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <DocumentTable
            loading={isLoading}
            folderById={normalizedFolders}
            dataList={documentList}
            selectedList={selectedList}
            onRowSelection={handleRowSelection}
          />

          {Boolean(documentList?.count && documentList.count > 10) && (
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              pageSize={DOCUMENTS_PER_PAGE}
              total={documentList.count}
              className="documents-pagination"
              onChange={onPageChange}
            />
          )}
        </div>
      </section>

      {hasModalToMoveDocuments && (
        <ModalMoveToFolder
          onMove={handleMassMove}
          onNewFolderClick={() => {
            setShowCreateModal(true);
            setHasModalToMoveDocuments(false);
          }}
          onClose={() => {
            setHasModalToMoveDocuments(false);
          }}
        />
      )}
    </div>
  );
};
