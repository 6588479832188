import axios from "axios";
import { Image } from "@entities/Image";
import { getCookieData } from "./tokenService";

const IMAGE_URL = "/api/v1/images";

const getAllImages = () => {
  return axios.get(IMAGE_URL);
};

const getImageDetails = (id) => {
  return axios.get(`${IMAGE_URL}/${id}`);
};

const uploadImage = (data) => {
  return axios.post<Image>(IMAGE_URL, data);
};

const getImageTags = (id) => {
  return axios.post<{ features: string[] }>(`${IMAGE_URL}/${id}/features`);
};

const enhanceImage = (id, { factor }: { factor: number }) => {
  return fetch(`${IMAGE_URL}/${id}/enhance?factor=${factor}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "image/*, application/json",
      "Content-Type": "image/*",
      "X-CSRFTOKEN": getCookieData("csrftoken"),
    },
  });
};

const removeBackground = (id) => {
  return fetch(`${IMAGE_URL}/${id}/remove-background`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "image/*, application/json",
      "Content-Type": "image/*",
      "X-CSRFTOKEN": getCookieData("csrftoken"),
    },
  });
};

const deleteImage = (id) => {
  return axios.delete(`${IMAGE_URL}/${id}`);
};

export const ImagesService = {
  enhanceImage,
  uploadImage,
  deleteImage,
  getAllImages,
  getImageTags,
  getImageDetails,
  removeBackground,
};
