import { cloneElement, FC, ReactElement, ReactNode } from "react";
import { IButtonSize } from "./index.types";

interface IProps {
  icon: ReactNode;
  size: IButtonSize;
}

export const ButtonAdornmentIcon: FC<IProps> = ({
  icon,
  size = "s",
}: IProps) => {
  const iconSizeMap: Record<IButtonSize, number> = {
    xxs: 16,
    xs: 16,
    s: 20,
    m: 20,
    l: 24,
  };

  if (!icon) {
    return null;
  }

  return cloneElement(icon as ReactElement, { size: iconSizeMap[size] });
};
