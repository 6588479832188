import { Tag } from "react-tag-input";

export const convertTextToTag = (value?: string): Tag[] =>
  (value ?? "")
    .split(",")
    .filter(Boolean)
    .map((item) => ({ id: item, text: item }));

export const convertTagToText = (tags: Tag[]) =>
  tags.map(({ text }) => text).join(",");
