export default () => (
  <svg
    width="74"
    height="55"
    viewBox="0 0 74 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1936 51.2476L8.8012 47.4509L12.5909 38.0557"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0371 47.1875L37.1646 35.6662C55.8928 27.7122 67.5738 13.0231 63.2558 2.85609L62.4739 1.01524"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
