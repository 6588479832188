import Video from "@assets/video/templates/default.jobtemplate.mp4.480.mp4";

export interface VideoCardData {
  videoUrl: string;
  optimizationScore: string;
  ctrChange: string;
  salesChange: string;
  ctrValue: string;
  salesValue: string;
  id: number;
  videoDescription: string;
  videoCritique: string;
  hypothesis: string;
  howToTest: string;
  percentageImpact: string;
}

export const videoCardMock: VideoCardData = {
  videoUrl: Video,
  optimizationScore: "+10%",
  ctrChange: "+4%",
  salesChange: "+6%",
  ctrValue: "4%",
  salesValue: "6%",
  id: 1,
  videoDescription:
    "This video showcases the product's main features and benefits, aimed at attracting the target audience...",
  videoCritique:
    "The main critique of this video is that it lacks a clear call-to-action, which may reduce the overall conversion rate...",
  hypothesis:
    "Adding a clear and compelling call-to-action at the end of the video could improve viewer engagement and conversion...",
  howToTest:
    "Create two versions of the video: one with the current content and another with the added call-to-action. Perform A/B testing to measure the impact...",
  percentageImpact:
    "Expected to see a 5 to 10 percent increase in conversion rate (CVR) with the updated video...",
};
