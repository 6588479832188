export default () => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6348 2.85938H10.1511V0.859375H14.8515H15.8515V1.85938V6.55982H13.8515V4.47104L9.67728 8.64531L8.97018 9.35241L8.26307 8.64531L5.66066 6.04289L1.56461 10.139L0.150391 8.72476L4.95355 3.92157L5.66065 3.21446L6.36776 3.92157L8.97018 6.52399L12.6348 2.85938Z"
        fill="white"
      />
    </svg>
  );
};
