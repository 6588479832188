import React, { useEffect, useState } from "react";
import RecommendationSection from "@pages/CROModule/components/Product/Optimisation/components/RecommendationSection";
import { ApiResponse } from "src/types/apiResponce";
import { AnalyzeProductListing } from "@services/amazon/products/types";
import { OptimizationService } from "@services/optimization/optimization.services";

interface OptionsProps {
  asin: string;
}

const Options: React.FC<OptionsProps> = ({ asin }) => {
  const [analyzeData, setAnalyzeData] =
    useState<ApiResponse<AnalyzeProductListing> | null>(null);
  const [recommendationData, setRecommendationData] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (!asin) return;

    const fetchPhotoAnalysis = async () => {
      try {
        const optimizationService = new OptimizationService();
        const res =
          await optimizationService.getAnalyzeProductListingByAsin(asin);
        setAnalyzeData(res as any);
      } catch (error) {
        console.error("Error fetching product analysis:", error);
      }
    };

    fetchPhotoAnalysis();
  }, [asin]);

  const fetchAnalyzeSuggestions = async () => {
    try {
      if (!asin) return;

      const optimizationService = new OptimizationService();
      const res =
        await optimizationService.getAnalyzeProductListingSuggestionsByAsin(
          asin,
          100,
        );
      setRecommendationData(res as any);
    } catch (error) {
      console.error("Error fetching product analysis:", error);
    }
  };

  return (
    <RecommendationSection
      analyzeData={analyzeData}
      recommendationData={recommendationData}
      onToggleRecommendation={fetchAnalyzeSuggestions}
    />
  );
};

export default Options;
