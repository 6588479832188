import MockImg from "@assets/img/product1.png";

export const datasets = [
  {
    label: "Your product",
    data: [2.9, 3.5, 2.5, 3.5, 1.5, 3.8, 2.8],
    backgroundColor: "rgba(82, 149, 224, 0.08)",
    borderColor: "#5295E0",
    pointBackgroundColor: "transparent",
    pointHoverBackgroundColor: "#5295E0",
    pointBorderColor: "transparent",
    pointHoverBorderColor: "#5295E0",
    pointRadius: 10,
    pointHoverRadius: 6,
    borderWidth: 1,
  },
];

export const products = [
  { imageUrl: MockImg, code: "B07GWVP5RC", rating: 3.2, isWhiteCode: true },
];

export const headerTexts = [
  "Effectiveness",
  "Ingredients",
  "Price",
  "Value",
  "Side effects",
  "Taste",
  "Brand",
];

export const profitAmounts = [
  "$1,350.39",
  "$1,100.00",
  "$950.20",
  "$1,250.50",
  "$1,000.99",
  "$1,500.60",
  "$1,300.85",
];
