import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import InfoIcon from "@assets/icons/i.svg";
import { Button, Popover } from "antd";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";
import classNames from "classnames";

interface DynamicComparisonBlockProps {
  productScore: number;
  competitorScore: number;
  difference: string;
  potentialSalesIncrease: string | number;
}

const DynamicComparisonBlock: React.FC<DynamicComparisonBlockProps> = ({
  productScore,
  competitorScore,
  difference,
  potentialSalesIncrease,
}) => {
  const rootStyles = getComputedStyle(document.documentElement);
  const greenColor = rootStyles.getPropertyValue("--nyle-green").trim();
  const redColor = rootStyles.getPropertyValue("--nyle-red").trim();
  const { selectedProduct, selectedProductToCompare } = useProductStore();

  const determineScoreColor = (score1: number, score2: number): string => {
    return score1 > score2 ? greenColor : redColor;
  };

  const getTextColor = (value: number): string => {
    return value >= 0 ? greenColor : redColor;
  };

  const [sp, setSp] = useState<number | null>(null);
  const productScoreColor = determineScoreColor(productScore, competitorScore);
  const competitorScoreColor = determineScoreColor(
    competitorScore,
    productScore,
  );

  const content = (
    <div>
      <p>Estimated sales lift</p>
    </div>
  );

  const determineDifference = () => {
    if (productScore > competitorScore) {
      return "higher";
    }
    if (productScore < competitorScore) {
      return "lower";
    }
    return "equal";
  };

  useEffect(() => {
    const spider = new SpiderService();

    const fetchData = async () => {
      if (selectedProductToCompare) {
        const allAsin = selectedProductToCompare
          .flatMap((innerArray) => innerArray.map((product) => product.asin))
          .filter(Boolean);

        const mainAsin = selectedProduct?.asin;

        const uniqueAsins = Array.from(new Set(allAsin)).filter(
          (asin) => asin !== mainAsin,
        );

        try {
          const differenceList = await spider.getDifferenceList(
            uniqueAsins,
            mainAsin,
          );

          setSp(Math.round(differenceList.difference_sales));
        } catch (error) {
          console.error("Error fetching difference list:", error);
        }
      }
    };

    fetchData();
  }, [selectedProductToCompare]);

  const differenceColorClass =
    productScore > competitorScore
      ? styles.differenceGreen
      : styles.differenceRed;

  const highlightColorClass =
    productScore > competitorScore
      ? styles.highlightGreen
      : styles.highlightRed;

  return (
    <>
      <div className={styles.comparisonBlock}>
        <div className={styles.scores}>
          <div className={styles.scoreBox}>
            <div className={styles.scoreGap}>
              <p className={`${styles.scoreLabel}  ${styles.firstScoreLabe}`}>
                Score
              </p>
            </div>
            <div className={styles.scoreRow}>
              <span
                className={styles.ratingValue}
                style={{ color: productScoreColor }}
              >
                {productScore}
              </span>
              <div className={styles.lockedContainer}>
                <div className={styles.lockedData}>
                  <span
                    className={styles.lockedText}
                    style={{ color: getTextColor(sp || 0) }}
                  >
                    {potentialSalesIncrease !== null
                      ? `$${potentialSalesIncrease}`
                      : "Loading..."}
                  </span>
                  <Popover
                    content={content}
                    overlayClassName={styles.customPopover}
                    trigger="click"
                  >
                    <Button
                      type="link"
                      className={styles.infoButton}
                      icon={
                        <img
                          src={InfoIcon}
                          alt="Info"
                          className={styles.infoIcon}
                          width={14}
                          height={14}
                        />
                      }
                    />
                  </Popover>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames(styles.difference, differenceColorClass)}>
            <p>
              Your aspect score is
              <span
                className={classNames(styles.highlight, highlightColorClass)}
              >
                {difference} {determineDifference()}
              </span>
            </p>
          </div>

          <div className={styles.scoreBox}>
            <p>Score</p>
            <span
              className={styles.ratingValue}
              style={{ color: competitorScoreColor }}
            >
              {competitorScore}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicComparisonBlock;
