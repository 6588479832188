import React, { forwardRef } from "react";
import { IButtonProps } from "./index.types";
import { BaseButton } from "./index";

export const Button = forwardRef(
  (props: IButtonProps, buttonRef: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <BaseButton {...props} ref={buttonRef} isIcon={false}>
        {props.children}
      </BaseButton>
    );
  },
);
