import Axios from "axios";
import { IGetRedirectUrlResponse, ILoginPayload } from "../@types/auth.types";
import {
  IConnectAmazonSellerPayload,
  IUserProfile,
} from "src/types/user.types.ts";

export class AuthServices {
  public static async getRedirectUrl(
    provider: string,
  ): Promise<IGetRedirectUrlResponse> {
    const { data } = await Axios.post("/api/v1/providers/redirect-url", {
      provider,
    });

    return data;
  }

  public static async loginSocial(body: ILoginPayload): Promise<IUserProfile> {
    const { data: tokens } = await Axios.post<{
      access: string;
      refresh: string;
    }>("/api/v1/login/social", body);

    localStorage.setItem("access_token", tokens.access);
    localStorage.setItem("refresh_token", tokens.refresh);

    const { data: userProfile } = await Axios.get<IUserProfile>(
      "/api/v1/profile",
      {
        headers: {
          Authorization: `Bearer ${tokens.access}`,
        },
      },
    );

    return userProfile;
  }

  public static async getAmazonSellerRedirectUrl(): Promise<IGetRedirectUrlResponse> {
    const { data } = await Axios.get("/api/v1/amazon/redirect-url");

    return data;
  }

  public static async connectAmazonSellerAccount(
    body: IConnectAmazonSellerPayload,
  ): Promise<IUserProfile> {
    const { data } = await Axios.post<IUserProfile>(
      "/api/v1/amazon/auth",
      body,
    );

    return data;
  }
}
