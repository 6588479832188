import styles from "./styles.module.scss";
import PhotoCard from "./components/PhotoCard";
// import { photoCardMocks } from "./mock";
import OptimisationPageWrapper from "../../components/OptimisationPageWrapper";
import { useEffect, useState } from "react";
import { useProductStore } from "src/store/overviewProduct.state";
import { ProductMaterials } from "@services/amazon/products/types";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductsStore } from "src/store/product.state";

function PhotoOptimisation() {
  const { selectedProduct } = useProductStore();
  const { productMaterials, setProductMaterials } = useProductsStore();

  const isInRange = (value, min, max) => value >= min && value <= max;
  const isLargerImage = (img, variant) =>
    img.variant === variant && img.width > 600 && img.height > 600;

  return (
    <OptimisationPageWrapper>
      <div className={styles.gridContainer}>
        {productMaterials &&
          productMaterials?.images
            .map((image) => {
              const largerImage = productMaterials?.images.find((img) =>
                isLargerImage(img, image?.variant),
              );
              return {
                ...image,
                largerImageLink: largerImage ? largerImage?.link : image.link,
              };
            })
            .filter(
              ({ width, height }) =>
                isInRange(width, 500, 600) && isInRange(height, 500, 600),
            )
            .map((image, index) => (
              <PhotoCard
                key={index}
                imageUrl={image.link}
                fullVariant={image?.largerImageLink}
                variant={image.variant}
                asin={selectedProduct?.asin}
                // ctrChange={mock.ctrChange}
                // salesChange={mock.salesChange}
                // ctrValue={mock.ctrValue}
                // salesValue={mock.salesValue}
                id={index + 1}
                // imageDescription={mock.imageDescription}
                // imageCritique={mock.imageCritique}
                // hypothesis={mock.hypothesis}
                // howToTest={mock.howToTest}
                // percentageImpact={mock.percentageImpact}
              />
            ))}
      </div>
    </OptimisationPageWrapper>
  );
}

export default PhotoOptimisation;
