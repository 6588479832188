import React, { useEffect, useState } from "react";
import CustomCheckbox from "@components/CustomCheckbox";
import {
  availableMetrics,
  mapCheckKeyToMetricKey,
  Metric,
} from "@pages/Dashboard/mocks/mockMetricsData";
import InfoIcon from "@assets/icons/i.svg";
import ModalBox from "@components/ModalBox";
import styles from "./styles.module.scss";
import { Checks } from "../types";

interface MetricModalProps {
  isOpen: boolean;
  onClose: () => void;
  setChecks: (checks: Checks) => void;
  checks: Checks;
}

const MetricModal: React.FC<MetricModalProps> = ({
  isOpen,
  onClose,
  setChecks,
  checks,
}) => {
  if (!checks) return null;
  const [choosedChecks, setChoosedChecks] = useState<Checks>(checks);
  const checkedCount = Object.values(choosedChecks).filter(
    (check) => check,
  ).length;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleChecks = (key: string) => {
    setChoosedChecks((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleApply = () => {
    setChecks(choosedChecks);
    onClose();
  };

  const handleReset = () => {
    const resetChecks = Object.keys(choosedChecks).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as Checks);
    setChoosedChecks(resetChecks);
    setChecks(resetChecks);
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} title="Choose Metrics">
      <p className={styles.modalDescription}>
        Choose which metrics you want to show in the «Sales Performance»
      </p>
      <div className={styles.modalMetricsOptions}>
        {Object.keys(choosedChecks).map((key, index) => (
          <label
            key={index}
            className={`${styles.metricLabel} ${
              checkedCount >= 6 && !choosedChecks[key] ? styles.disabled : ""
            }`}
          >
            <CustomCheckbox
              checked={choosedChecks[key]}
              onChange={() => {
                if (checkedCount >= 6 && !choosedChecks[key]) return;
                handleChecks(key);
              }}
            />
            {mapCheckKeyToMetricKey(key)}
            <img src={InfoIcon} alt="Info" className={styles.infoIcon} />
          </label>
        ))}
      </div>
      <div className={styles.modalActions}>
        <button className={styles.modalButton} onClick={handleReset}>
          Reset to default
        </button>
        <button className={styles.modalButtonApply} onClick={handleApply}>
          Apply
        </button>
      </div>
    </ModalBox>
  );
};

export default MetricModal;
