import { ReactElement } from "react";

import { Divider, Typography, Spin, Row, Col, Input } from "antd";

import { useTitle } from "@hooks/useTitle";
import { useProfile } from "@hooks/useProfile";
import { NyleField } from "@components/NyleField";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { SocialAccount } from "@components/SocialAccount";

import { EditProfile } from "./ui/EditProfile";
import "./account.less";

const { Paragraph } = Typography;

const TITLE = "My account (setting)";

export function Account(): ReactElement {
  useTitle(`Nyle - ${TITLE}`);
  const { isLoading, data: profile, isError } = useProfile();

  return (
    <div className="settings-account-page">
      <h2 className="page-main-title">{TITLE}</h2>
      {isLoading ? (
        <div className="spin-wrapper">
          <Spin size="large" />
        </div>
      ) : (
        <div className="field-data-item">
          <NyleField label="E-mail">
            <Input
              disabled
              placeholder="email@google.com"
              defaultValue={profile?.email}
            />
          </NyleField>

          <EditProfile
            disabled={isError}
            firstName={profile?.first_name}
            lastName={profile?.last_name}
            country={profile?.country?.code}
          />

          <Divider style={{ margin: "30px 0" }} />

          <Paragraph strong className="account-info-paragraph">
            LINKED ACCOUNTS FROM SOCIAL NETWORKS
          </Paragraph>

          <div style={{ paddingBottom: "30px" }}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <SocialAccount
                  design="green"
                  isConnected={profile?.is_google_connected}
                >
                  Google <SvgIcon type="google" />
                </SocialAccount>
              </Col>
              <Col className="gutter-row" span={12}>
                <SocialAccount
                  design="blue"
                  isConnected={profile?.is_fb_connected}
                >
                  Facebook
                  <SvgIcon type="facebook-outlined" />
                </SocialAccount>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}
