import "./index.css";
import { Header } from "./Header";
import WidgetsList from "./WidgetsList";
import { useWidgets } from "@hooks/useWidgets";

export const Widgets = () => {
  const { updatedWidgets, handleApply } = useWidgets();

  return (
    <div className="dashboard-widgets">
      <Header onApply={handleApply} />
      <WidgetsList widgets={updatedWidgets} />
    </div>
  );
};
