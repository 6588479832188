export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M19.25 4.125H2.75C2.56766 4.125 2.3928 4.19743 2.26386 4.32636C2.13493 4.4553 2.0625 4.63016 2.0625 4.8125V16.5C2.0625 16.8647 2.20737 17.2144 2.46523 17.4723C2.72309 17.7301 3.07283 17.875 3.4375 17.875H18.5625C18.9272 17.875 19.2769 17.7301 19.5348 17.4723C19.7926 17.2144 19.9375 16.8647 19.9375 16.5V4.8125C19.9375 4.63016 19.8651 4.4553 19.7361 4.32636C19.6072 4.19743 19.4323 4.125 19.25 4.125ZM3.4375 9.625H6.875V12.375H3.4375V9.625ZM8.25 9.625H18.5625V12.375H8.25V9.625ZM18.5625 5.5V8.25H3.4375V5.5H18.5625ZM3.4375 13.75H6.875V16.5H3.4375V13.75ZM18.5625 16.5H8.25V13.75H18.5625V16.5Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
