import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { TabButton, TabsTypeList, viewTabs } from "./utils";
import { TypeTab, ViewTab, Chart } from "./Chart";
import { impressionsMock } from "./mock";
import { Option, Select } from "@components/Select";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";

export const SanKey = () => {
  const [selectedView, setSelectedView] = useState<ViewTab>("Tree");
  const [selectedTab, setSelectedTab] = useState<TypeTab>("Campaigns");
  const [asin, setAsin] = useState<string>();
  const [impressions, setImpressions] = useState<string>();

  const [asinOptions, setAsinOptions] = useState<Option[]>();

  useEffect(() => {
    const fetchData = async () => {
      const productsService = new ProductsService();
      productsService.getOwnProducts().then((data) => {
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_description,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      });
    };

    fetchData();
  }, [asin]);

  return (
    <div className={styles.container}>
      <div className={styles.selectors}>
        <Select
          value={asin}
          placeholder="Select asin"
          options={asinOptions}
          onChange={(value: string) => {
            setAsin(value);
          }}
          className={styles.asin}
        />
      </div>
      <div className={styles.header}>
        <h2>Sankey View</h2>
        <div className={styles.tabs}>
          {viewTabs.map((viewTab) => (
            <TabButton
              key={viewTab.label}
              label={viewTab.label}
              isSelected={selectedView === viewTab.label}
              onClick={() => setSelectedView(viewTab.label)}
              icon={viewTab.icon}
            />
          ))}
        </div>
      </div>
      <div className={styles.tabs}>
        <TabsTypeList
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <Chart type={selectedTab} view={selectedView} asin={asin} />
    </div>
  );
};
