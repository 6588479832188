import axios from "axios";
import { CustomerBillingURL } from "src/entities/CustomerBillingURL";
import { CustomerProfile } from "src/entities/CustomerProfile";
import { CustomerProfileUpdate } from "src/entities/CustomerProfileUpdate";
import { PatchedCustomerProfileUpdateRequest } from "src/entities/PatchedCustomerProfileUpdateRequest";
import { useUserStore } from "src/store/user.state";

export class ProfileService {
  static readonly URL = "/api/v1/profile";

  public static async get(): Promise<CustomerProfile | null> {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      const { data } = await axios.get<CustomerProfile>(ProfileService.URL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //@ts-ignore
      useUserStore.getState().setUser(data);

      return data;
    }
    return null;
  }

  public static async update(
    requestBody?: PatchedCustomerProfileUpdateRequest,
  ): Promise<CustomerProfileUpdate> {
    const { data } = await axios.patch<CustomerProfileUpdate>(
      ProfileService.URL,
      requestBody,
    );

    return data;
  }

  public static async createBilling(): Promise<CustomerBillingURL> {
    const { data } = await axios.post<CustomerBillingURL>(
      `${ProfileService.URL}/billing`,
    );

    return data;
  }
}
