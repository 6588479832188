import SvgIcon from "@components/svg-icon/SvgIcon";

export const ImageEnhancePreview = () => (
  <div className="image-enhance__preview">
    <SvgIcon type="circlePlay" className="image-enhance__preview-icon" />
    <div className="image-enhance__preview-title">Preview</div>
    <div className="image-enhance__preview-description">
      Upload an image and get it improved up to 16 times its original size,
      without losing any details or clarity
    </div>
  </div>
);
