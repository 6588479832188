import { memo, useCallback } from "react";
import { Button, Checkbox, Select } from "antd";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { CRUDService } from "@services/CRUDService";
import CreateFolderModal from "../create-folder-modal/CreateFolderModal";
import "./folder-select.less";
import { useQueryClient } from "react-query";
import { FoldersQueryKey } from "@hooks/useFolders";

const selectFieldText = (
  <span className="select-field-text">
    <SvgIcon type="folder" />
    all folders
  </span>
);

const folderCRUD = "folders";

export const FolderSelect = memo((props: any) => {
  const {
    folderList,
    currentFolders,
    setCurrentFolders,
    showCreateModal,
    setShowCreateModal,
  } = props;

  const queryClient = useQueryClient();

  const handleChangeValue = useCallback(
    (value) => {
      setCurrentFolders(value);
    },
    [setCurrentFolders],
  );

  const createNewFolder = useCallback(
    async (folderName) => {
      await CRUDService.createNewEntity(folderCRUD, { name: folderName });
      queryClient.invalidateQueries(FoldersQueryKey);
      setShowCreateModal(false);
    },
    [queryClient, setShowCreateModal],
  );

  return (
    <>
      <Select
        showArrow
        mode="multiple"
        showSearch={false}
        optionLabelProp="label"
        value={currentFolders}
        onChange={handleChangeValue}
        maxTagCount={0}
        placeholder={selectFieldText}
        maxTagPlaceholder={(omittedValues) => (
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {omittedValues?.map(({ label }) => label).join(", ")}
          </div>
        )}
        dropdownAlign={{ offset: [0, 0] }}
        className="folder-select-field"
        dropdownClassName="folder-select-field-drp"
        dropdownRender={(menu) => (
          <div className="menu-wrapper">
            {menu}
            <div className="menu-footer">
              <Button
                shape="round"
                type="primary"
                className="light-blue-btn"
                onClick={() => setShowCreateModal(true)}
              >
                new folder
                <SvgIcon type="plus-circle" />
              </Button>
            </div>
          </div>
        )}
      >
        {folderList?.map((folder) => (
          <Select.Option
            value={folder.id}
            label={folder.name}
            key={`folder-${folder.id}`}
          >
            <div className="folder-select-option">
              <Checkbox
                checked={currentFolders.includes(folder.id)}
                style={{ pointerEvents: "none" }}
              >
                {folder.name}
              </Checkbox>
              <SvgIcon className="close-icon" type="close" />
            </div>
          </Select.Option>
        ))}
      </Select>

      <CreateFolderModal
        onCreate={createNewFolder}
        visible={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </>
  );
});
