import cn from "classnames";
import { Switch, SwitchProps } from "antd";
import "./switcher.less";

type Item = {
  label: string;
  active?: boolean;
};

type Props = {
  items: [Item, Item];
  checked: boolean;
} & Pick<SwitchProps, "onChange">;

export const Switcher = ({ items, checked, onChange }: Props) => {
  const [left, right] = items;

  return (
    <div className="switcher">
      <label className="switcher__label">
        <span className={cn({ "is-active": left.active })}>{left.label}</span>

        <Switch checked={checked} onChange={onChange} />

        <span className={cn({ "is-active": right.active })}>{right.label}</span>
      </label>
    </div>
  );
};
