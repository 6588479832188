import ShareIcon from "@assets/icons/shareIcon";
import styles from "./styles.module.scss";
import Illustration from "@assets/img/Illustration.png";
import { introData, statsData } from "./mock";
import InfoIcon from "@assets/icons/infoIcon";
import { Stat } from "./types";

const Intro: React.FC = () => {
  const handleShareClick = async () => {
    navigator.clipboard.writeText(introData.link);
  };

  return (
    <div className={styles.introContainer}>
      <div className={styles.infoBlock}>
        <div>
          <div className={styles.title}>
            <h3>{introData.title}</h3>
            <p className={styles.supTitle}>{introData.supTitle}</p>
          </div>
          <div className={styles.link}>
            <input type="text" value={introData.link} readOnly />
            <div className={styles.iconWrapper} onClick={handleShareClick}>
              <ShareIcon />
            </div>
          </div>
        </div>
        <div className={styles.stats}>
          {statsData.map((stat: Stat, index: number) => (
            <div key={index} className={styles.statItem}>
              <h3 className={styles.statTitle}>{stat.value}</h3>
              <h4 className={styles.statSupTitle}>{stat.title}</h4>
              <p className={styles.statDescription}>{stat.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.illustration}>
        <img
          className={styles.illustrationImg}
          src={Illustration}
          alt="Illustration"
        />
      </div>
      <div className={styles.infoContainer}>
        <InfoIcon />
      </div>
    </div>
  );
};

export default Intro;
