export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.0003 8.00028V12.0003C15.0003 12.1329 14.9476 12.2601 14.8538 12.3538C14.7601 12.4476 14.6329 12.5003 14.5003 12.5003H10.5003C10.3677 12.5003 10.2405 12.4476 10.1467 12.3538C10.053 12.2601 10.0003 12.1329 10.0003 12.0003C10.0003 11.8677 10.053 11.7405 10.1467 11.6467C10.2405 11.553 10.3677 11.5003 10.5003 11.5003H13.2934L8.50028 6.70715L6.35403 8.85403C6.30759 8.90052 6.25245 8.9374 6.19175 8.96256C6.13105 8.98772 6.06599 9.00067 6.00028 9.00067C5.93457 9.00067 5.86951 8.98772 5.80881 8.96256C5.74811 8.9374 5.69296 8.90052 5.64653 8.85403L1.14653 4.35403C1.05271 4.26021 1 4.13296 1 4.00028C1 3.8676 1.05271 3.74035 1.14653 3.64653C1.24035 3.55271 1.3676 3.5 1.50028 3.5C1.63296 3.5 1.76021 3.55271 1.85403 3.64653L6.00028 7.7934L8.14653 5.64653C8.19296 5.60004 8.24811 5.56316 8.30881 5.538C8.36951 5.51284 8.43457 5.49988 8.50028 5.49988C8.56599 5.49988 8.63105 5.51284 8.69175 5.538C8.75245 5.56316 8.80759 5.60004 8.85403 5.64653L14.0003 10.7934V8.00028C14.0003 7.86767 14.053 7.74049 14.1467 7.64672C14.2405 7.55296 14.3677 7.50028 14.5003 7.50028C14.6329 7.50028 14.7601 7.55296 14.8538 7.64672C14.9476 7.74049 15.0003 7.86767 15.0003 8.00028Z"
        fill="#FE5858"
      />
    </svg>
  );
};
