import * as Sentry from "@sentry/react";

import { useEffect } from "react";

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { useConfigStore } from "./store/config.state";

export const sentryInit = () => {
  const config = useConfigStore.getState().frontendConfig;
  const sentryDsn = config.sentry_dsn;
  const envRun = config.env_run;
  const version = config.version;

  if (sentryDsn) {
    Sentry.init({
      environment: envRun,
      release: version,
      dsn: sentryDsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],

      tracesSampleRate: 1.0,

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
