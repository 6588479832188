export default () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0885 7.41066L10.4218 0.743989C10.3053 0.627481 10.1568 0.548143 9.99521 0.516003C9.83358 0.483863 9.66605 0.500366 9.5138 0.563424C9.36155 0.626483 9.23142 0.733265 9.13985 0.870274C9.04828 1.00728 8.99938 1.16836 8.99935 1.33316V4.28732C6.72292 4.49798 4.60704 5.55054 3.06576 7.23901C1.52448 8.92748 0.668742 11.1303 0.666016 13.4165L0.666016 14.6665C0.666147 14.8395 0.720107 15.0081 0.820411 15.1491C0.920714 15.29 1.06239 15.3962 1.22579 15.453C1.38918 15.5098 1.5662 15.5143 1.73229 15.466C1.89838 15.4176 2.0453 15.3188 2.15268 15.1832C2.9691 14.2123 3.97081 13.4139 5.0993 12.8345C6.22779 12.2552 7.46043 11.9065 8.72518 11.809C8.76685 11.804 8.87102 11.7957 8.99935 11.7873V14.6665C8.99938 14.8313 9.04828 14.9924 9.13985 15.1294C9.23142 15.2664 9.36155 15.3732 9.5138 15.4362C9.66605 15.4993 9.83358 15.5158 9.99521 15.4836C10.1568 15.4515 10.3053 15.3722 10.4218 15.2557L17.0885 8.58899C17.2447 8.43272 17.3325 8.22079 17.3325 7.99982C17.3325 7.77885 17.2447 7.56693 17.0885 7.41066ZM10.666 12.6548V10.9165C10.666 10.6955 10.5782 10.4835 10.4219 10.3272C10.2657 10.171 10.0537 10.0832 9.83268 10.0832C9.62018 10.0832 8.75268 10.1248 8.53102 10.154C6.28507 10.3609 4.1472 11.2142 2.37602 12.6107C2.57705 10.7727 3.44897 9.07344 4.82481 7.83829C6.20066 6.60313 7.98375 5.91886 9.83268 5.91649C10.0537 5.91649 10.2657 5.82869 10.4219 5.67241C10.5782 5.51613 10.666 5.30417 10.666 5.08316V3.34482L15.321 7.99982L10.666 12.6548Z"
        fill="white"
      />
    </svg>
  );
};
