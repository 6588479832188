import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3265 7.18659C13.3265 5.97897 14.2859 5 15.4694 5H18.2245C19.408 5 20.3673 5.97897 20.3673 7.18659C20.3673 8.39421 19.408 9.37318 18.2245 9.37318H15.4694C14.2859 9.37318 13.3265 8.39421 13.3265 7.18659ZM13.3265 15.0583C13.3265 13.8507 14.2859 12.8717 15.4694 12.8717H18.2245C19.408 12.8717 20.3673 13.8507 20.3673 15.0583C20.3673 16.2659 19.408 17.2449 18.2245 17.2449H15.4694C14.2859 17.2449 13.3265 16.2659 13.3265 15.0583Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.18659C2 5.97897 2.95939 5 4.14286 5H6.89796C8.08143 5 9.04082 5.97897 9.04082 7.18659C9.04082 8.39421 8.08143 9.37318 6.89796 9.37318H4.14286C2.95939 9.37318 2 8.39421 2 7.18659ZM2 15.0583C2 13.8507 2.95939 12.8717 4.14286 12.8717H6.89796C8.08143 12.8717 9.04082 13.8507 9.04082 15.0583C9.04082 16.2659 8.08143 17.2449 6.89796 17.2449H4.14286C2.95939 17.2449 2 16.2659 2 15.0583Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3265 15.0583C13.3265 13.8507 14.2859 12.8717 15.4694 12.8717H18.2245C19.408 12.8717 20.3673 13.8507 20.3673 15.0583C20.3673 16.2659 19.408 17.2449 18.2245 17.2449H15.4694C14.2859 17.2449 13.3265 16.2659 13.3265 15.0583Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 15.0583C2 13.8507 2.95939 12.8717 4.14286 12.8717H6.89796C8.08143 12.8717 9.04082 13.8507 9.04082 15.0583C9.04082 16.2659 8.08143 17.2449 6.89796 17.2449H4.14286C2.95939 17.2449 2 16.2659 2 15.0583Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
