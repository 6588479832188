import { Pagination } from "antd";
import "./customPagination.scss";

interface CustomPaginationProps {
  current: number;
  onChange: (page: number) => void;
  total: number;
  pageSize: number;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  current,
  onChange,
  total,
  pageSize,
}) => {
  return (
    <Pagination
      current={current}
      onChange={onChange}
      total={total}
      pageSize={pageSize}
      className="pagination"
    />
  );
};

export default CustomPagination;
