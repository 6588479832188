import { useEffect, useState } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

import { Button } from "antd";
import SvgIcon from "@components/svg-icon/SvgIcon";

type ImageEnhanceSliderProps = {
  imageBefore: string;
  imageAfter?: string;
  isLoading?: boolean;
};

export const ImageEnhanceSlider = ({
  imageBefore,
  imageAfter,
  isLoading,
}: ImageEnhanceSliderProps) => {
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [rand, setRandom] = useState(Math.random());

  useEffect(() => {
    setShowSaveButton(false);
  }, [imageBefore, isLoading]);

  useEffect(() => {
    if (imageAfter) {
      setShowSaveButton(true);
    }
  }, [imageAfter]);

  return (
    <div className="image-enhance-slider">
      <div
        className="image-enhance-slider__content"
        data-isloading={isLoading}
        data-isPreviewMode={!imageAfter}
      >
        <ReactCompareSlider
          onMouseLeave={() => {
            setRandom(Math.random());
          }}
          key={rand}
          changePositionOnHover
          itemOne={
            <ReactCompareSliderImage src={imageBefore} alt="Image one" />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={imageAfter ?? imageBefore}
              alt="Image two"
            />
          }
        />
        {showSaveButton && Boolean(imageAfter) && (
          <div className="image-enhance-slider__btn">
            <Button
              type="primary"
              shape="round"
              size="large"
              href={imageAfter}
              download="enchanceImaged.png"
            >
              Download
              <SvgIcon type="arrow-right" />
            </Button>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="image-enhance-slider__loading">
          <div className="image-enhance-slider__indicator">
            {new Array(4).fill(null).map((_, i) => (
              <div key={i} />
            ))}
          </div>
          <span>Processing...</span>
        </div>
      )}
    </div>
  );
};
