import create from "zustand";

export interface MetricData {
  difference: string;
  name: string;
  value: number | null;
}

interface MathState {
  aovData: MetricData;
  salesTotalData: MetricData;
  adsImpressionsData: MetricData;
  adsClicksData: MetricData;
  adsOrdersData: MetricData;
  adsUnitsSoldData: MetricData;
  adsSalesData: MetricData;
  adsSpendData: MetricData;
  adsAcosData: MetricData;
  adsRoasData: MetricData;
  adsCpcData: MetricData;
  adsCvrData: MetricData;
  adsCtrData: MetricData;
  organicImpressionsData: MetricData;
  organicClicksData: MetricData;
  organicCartAddsData: MetricData;
  organicUnitsSoldData: MetricData;
  organicCvrData: MetricData;
  organicCtrData: MetricData;

  setAovData: (data: number) => void;
  setSalesTotalData: (data: number) => void;
  setAdsImpressionsData: (data: number) => void;
  setAdsClicksData: (data: number) => void;
  setAdsOrdersData: (data: number) => void;
  setAdsUnitsSoldData: (data: number) => void;
  setAdsSalesData: (data: number) => void;
  setAdsSpendData: (data: number) => void;
  setAdsAcosData: (data: number) => void;
  setAdsRoasData: (data: number) => void;
  setAdsCpcData: (data: number) => void;
  setAdsCvrData: (data: number) => void;
  setAdsCtrData: (data: number) => void;

  // Organic-specific setters
  setOrganicImpressionsData: (data: number) => void;
  setOrganicClicksData: (data: number) => void;
  setOrganicCartAddsData: (data: number) => void;
  setOrganicUnitsSoldData: (data: number) => void;
  setOrganicCvrData: (data: number) => void;
  setOrganicCtrData: (data: number) => void;
}

export const useMathStore = create<MathState>((set) => ({
  aovData: {
    name: "AOV",
    value: null,
    difference: "+0.02",
  },
  salesTotalData: {
    name: "Sales Total",
    value: null,
    difference: "+0.02",
  },
  adsImpressionsData: {
    name: "Ad Impressions",
    value: null,
    difference: "+0.02",
  },
  adsClicksData: {
    name: "Ad Clicks",
    value: null,
    difference: "+0.02",
  },
  adsOrdersData: {
    name: "Ad Orders",
    value: null,
    difference: "-0.02",
  },
  adsUnitsSoldData: {
    name: "Ad Units Sold",
    value: null,
    difference: "+0.02",
  },
  adsSalesData: {
    name: "Ad Sales",
    value: null,
    difference: "-0.02",
  },
  adsSpendData: {
    name: "Ad Spend",
    value: null,
    difference: "+0.02",
  },
  adsAcosData: {
    name: "ACOS",
    value: null,
    difference: "+0.02",
  },
  adsRoasData: {
    name: "ROAS",
    value: null,
    difference: "+0.02",
  },
  adsCpcData: {
    name: "CPC",
    value: null,
    difference: "-0.02",
  },
  adsCvrData: {
    name: "Ad CVR",
    value: null,
    difference: "+0.02",
  },
  adsCtrData: {
    name: "Ad CTR",
    value: null,
    difference: "+0.02",
  },

  // Organic data
  organicImpressionsData: {
    name: "Organic Impressions",
    value: null,
    difference: "+0.02",
  },
  organicClicksData: {
    name: "Organic Clicks",
    value: null,
    difference: "+0.02",
  },
  organicCartAddsData: {
    name: "Organic Cart Adds",
    value: null,
    difference: "+0.02",
  },
  organicUnitsSoldData: {
    name: "Organic Units Sold",
    value: null,
    difference: "+0.02",
  },
  organicCvrData: {
    name: "Organic CVR",
    value: null,
    difference: "+0.02",
  },
  organicCtrData: {
    name: "Organic CTR",
    value: null,
    difference: "+0.02",
  },

  setAovData: (data) =>
    set((state) => ({
      aovData: { ...state.aovData, value: data },
    })),
  setSalesTotalData: (data) =>
    set((state) => ({
      salesTotalData: { ...state.salesTotalData, value: data },
    })),
  setAdsImpressionsData: (data) =>
    set((state) => ({
      adsImpressionsData: { ...state.adsImpressionsData, value: data },
    })),
  setAdsClicksData: (data) =>
    set((state) => ({
      adsClicksData: { ...state.adsClicksData, value: data },
    })),
  setAdsOrdersData: (data) =>
    set((state) => ({
      adsOrdersData: { ...state.adsOrdersData, value: data },
    })),
  setAdsUnitsSoldData: (data) =>
    set((state) => ({
      adsUnitsSoldData: { ...state.adsUnitsSoldData, value: data },
    })),
  setAdsSalesData: (data) =>
    set((state) => ({
      adsSalesData: { ...state.adsSalesData, value: data },
    })),
  setAdsSpendData: (data) =>
    set((state) => ({
      adsSpendData: { ...state.adsSpendData, value: data },
    })),
  setAdsAcosData: (data) =>
    set((state) => ({
      adsAcosData: { ...state.adsAcosData, value: data },
    })),
  setAdsRoasData: (data) =>
    set((state) => ({
      adsRoasData: { ...state.adsRoasData, value: data },
    })),
  setAdsCpcData: (data) =>
    set((state) => ({
      adsCpcData: { ...state.adsCpcData, value: data },
    })),
  setAdsCvrData: (data) =>
    set((state) => ({
      adsCvrData: { ...state.adsCvrData, value: data },
    })),
  setAdsCtrData: (data) =>
    set((state) => ({
      adsCtrData: { ...state.adsCtrData, value: data },
    })),
  setOrganicImpressionsData: (data) =>
    set((state) => ({
      organicImpressionsData: { ...state.organicImpressionsData, value: data },
    })),
  setOrganicClicksData: (data) =>
    set((state) => ({
      organicClicksData: { ...state.organicClicksData, value: data },
    })),
  setOrganicCartAddsData: (data) =>
    set((state) => ({
      organicCartAddsData: { ...state.organicCartAddsData, value: data },
    })),
  setOrganicUnitsSoldData: (data) =>
    set((state) => ({
      organicUnitsSoldData: { ...state.organicUnitsSoldData, value: data },
    })),
  setOrganicCvrData: (data) =>
    set((state) => ({
      organicCvrData: { ...state.organicCvrData, value: data },
    })),
  setOrganicCtrData: (data) =>
    set((state) => ({
      organicCtrData: { ...state.organicCtrData, value: data },
    })),
}));
