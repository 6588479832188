import { useMemo, useRef } from "react";
import { Button } from "antd";
import { QueryStatus } from "react-query";
import Form, { FormProps, FieldTemplateProps, AjvError } from "@rjsf/antd";

import { NyleField } from "@components/NyleField";
import SvgIcon from "@components/svg-icon/SvgIcon";

import { FakeTextarea } from "./FakeTextarea";
import { TextDiff } from "./TextDiff";
import { TagInputWidget } from "@components/TagInput/TagInputWidget";
import { RadioGroupWidget } from "./RadioGroupWidget";

type TemplateFormProps = {
  onClearButton?: () => void;
  hideButtons?: boolean;
  status?: QueryStatus;
  isVertical?: boolean;
};

export enum CustomWidgetsKeys {
  FakeTextarea = "fakeTextarea",
  TextDiff = "textDiff",
  TagInput = "tagInput",
  RadioGroup = "radioGroup",
}

const customWidgets = {
  [CustomWidgetsKeys.FakeTextarea]: FakeTextarea,
  [CustomWidgetsKeys.TextDiff]: TextDiff,
  [CustomWidgetsKeys.TagInput]: TagInputWidget,
  [CustomWidgetsKeys.RadioGroup]: RadioGroupWidget,
};

type FunctionType<T> = (value: FormProps<T> & TemplateFormProps) => JSX.Element;

function transformErrors(form: Form<any>) {
  // @ts-ignore
  const formData = form?.state?.formData ?? {};

  return (errors: AjvError[]) =>
    errors.reduce((errorsList, error) => {
      const isPropDirty = error.property.slice(1) in formData;

      return isPropDirty ? [...errorsList, error] : errorsList;
    }, []);
}

export const TemplateForm: FunctionType<Record<string, any>> = ({
  hideButtons,
  onClearButton,
  status,
  isVertical,
  ...formProps
}) => {
  const formRef = useRef<Form<any>>();

  const isAnyFieldFilled = useMemo(
    () => Object.values(formProps?.formData ?? {}).some((item) => item?.length),
    [formProps.formData],
  );

  const allRequiredFieldsFilled = formProps.schema?.required
    ?.map((key) => {
      if (formProps.schema.properties[key]) {
        return Boolean(formProps.formData[key]);
      }

      return false;
    })
    .every(Boolean);

  const wasDataLoaded = status === "success";

  // @ts-ignore
  const hasValidationErrors = Boolean(formRef.current?.state?.errors?.length);

  return (
    <Form
      ref={formRef}
      {...formProps}
      FieldTemplate={FieldTemplate}
      showErrorList={false}
      // @ts-ignore
      widgets={customWidgets}
      transformErrors={transformErrors(formRef.current)}
    >
      {hideButtons ? (
        <div />
      ) : (
        <>
          {formProps.children ? (
            formProps.children
          ) : (
            <div>
              <div className="template-button__action-buttons">
                {isAnyFieldFilled && (
                  <Button
                    danger
                    size="small"
                    className="template-button__clear"
                    shape="round"
                    onClick={onClearButton}
                    disabled={status === "loading"}
                  >
                    CLEAR ALL
                    <SvgIcon type="trash" />
                  </Button>
                )}
                <Button
                  disabled={
                    formProps.disabled ||
                    !allRequiredFieldsFilled ||
                    hasValidationErrors
                  }
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  className="template-button__submit"
                  size="large"
                >
                  {wasDataLoaded ? "Update the result" : "Get the result"}
                  <SvgIcon type="arrow-right" />
                </Button>
              </div>

              {isVertical && !allRequiredFieldsFilled && (
                <div className="template-button__submit-hint">
                  <span>* Fill in the fields</span>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </Form>
  );
};

const FieldTemplate = (props: FieldTemplateProps) => {
  const { schema, children, rawDescription, rawErrors = [], label } = props;

  // simply return children, we don't want an object is wrapped
  // every property should have their own wrapper
  if (schema.type === "object") {
    return <>{children}</>;
  }

  const error = rawErrors[0]; //.filter((item) => !(/.+required.+/gm.test(item)))?.[0];

  return (
    <NyleField label={label} error={error} description={rawDescription}>
      {children}
    </NyleField>
  );
};
