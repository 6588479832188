import React from "react";
import styles from "./styles.module.scss";
import Notification from "@assets/icons/notification";

interface NotificationPopupProps {
  notifications: { id: number; title: string; message: string; time: string }[];
}

export const NotificationPopup: React.FC<NotificationPopupProps> = ({
  notifications,
}) => {
  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <h4 className={styles.notificationTitle}>Notification</h4>
        <ul className={styles.notificationList}>
          {notifications.map((notification) => (
            <li key={notification.id} className={styles.notificationItem}>
              <div className={styles.notificatinIcon}>
                <Notification />
              </div>
              <div className={styles.message}>
                <h6 className={styles.messageHeader}>{notification.title}</h6>
                <p className={styles.messageText}>{notification.message}</p>
                <span className={styles.messageDate}>{notification.time}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
