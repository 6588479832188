import axios from "axios";
import { AiEntity } from "src/entities/AiEntity";

const AI_URL = "/api/v1/ai";

const getAllAI = () => {
  return axios.get<AiEntity[]>(AI_URL);
};

const getAIDetails = (id) => {
  return axios.get(`${AI_URL}/${id}`);
};

const getAIResults = (id, data) => {
  return axios.post<Record<string, any>>(`${AI_URL}/${id}/fetch`, data);
};

export const AIService = {
  getAllAI,
  getAIDetails,
  getAIResults,
};
