import { NavLink } from "react-router-dom";

import SvgIcon from "@components/svg-icon/SvgIcon";

import "./error-card.less";

type ErrorCardProps = {
  to: string;
  title: string;
};

export const ErrorCard = ({ to, title }: ErrorCardProps) => {
  return (
    <NavLink to={to} className="error-card">
      <div className="error-card__title">{title}</div>

      <SvgIcon type="arrow-right" className="error-card__arrow" />
    </NavLink>
  );
};
