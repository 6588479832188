import style from "./style.module.scss";
import { PROMPTS } from "./prompts.constants";
import { Message } from "@services/chat/chat.service";

interface ChatPromptsProps {
  setShowPrompts: (value: boolean) => void;
  addMessage: (message: Message) => void;
  sendMessage: (value: string) => void;
}

export const ChatPrompts = ({
  setShowPrompts,
  addMessage,
  sendMessage,
}: ChatPromptsProps) => (
  <div className={style.chat__prompts}>
    {PROMPTS.map((prompt) => (
      <button
        key={prompt}
        onClick={() => {
          addMessage({
            id: new Date().toISOString(),
            text: prompt,
            sender: "user",
          });
          sendMessage(prompt);
          setShowPrompts(false);
        }}
      >
        {prompt}
      </button>
    ))}
  </div>
);
