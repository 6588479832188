import styles from "./styles.module.scss";
import Logo from "@assets/img/logo";
import AuthButtonArrow from "@assets/icons/authButtonArrow";

function RedirectToSeller() {
  return (
    <>
      <div className={styles.logo}>
        <Logo width="67" height="30" />
      </div>
      <div className={styles.messageContainer}>
        <h1>
          Your email <br /> address has been <br />{" "}
          <span className={styles.confirmed}>confirmed!</span>
        </h1>
        <button className={styles.button}>
          Go to your account <AuthButtonArrow />
        </button>
      </div>
    </>
  );
}

export default RedirectToSeller;
