import { useEffect, useState } from "react";
import Axios, { AxiosResponse } from "axios";
import { useAuthStore } from "src/store/auth.state";
import { LS } from "src/constants/localStorage.constants.ts";

export const useCheckSession = () => {
  const [loading, setLoading] = useState<boolean>(() => {
    const redirecting = window.location.pathname === "/auth/callback";
    const authenticated = localStorage.getItem(LS.Authenticated);
    return !authenticated && !redirecting;
  });

  useEffect(() => {
    if (!loading) {
      return;
    }

    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      Axios.get("/api/v1/profile", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            localStorage.setItem(LS.Authenticated, "true");
            useAuthStore.getState().setAuthenticated(true);
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            useAuthStore.getState().logout();
            localStorage.removeItem(LS.Authenticated);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [loading]);

  return loading;
};
