import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M13.6431 6.17741L10.8306 8.60429L11.6875 12.2337C11.7347 12.4307 11.7226 12.6373 11.6525 12.8274C11.5824 13.0175 11.4575 13.1826 11.2937 13.3018C11.1298 13.4209 10.9343 13.4889 10.7319 13.497C10.5294 13.5051 10.3291 13.4531 10.1562 13.3474L6.99996 11.4049L3.84184 13.3474C3.66898 13.4525 3.4689 13.504 3.2668 13.4956C3.06469 13.4872 2.8696 13.4191 2.70609 13.3001C2.54257 13.181 2.41795 13.0162 2.3479 12.8264C2.27786 12.6367 2.26553 12.4304 2.31246 12.2337L3.17246 8.60429L0.359961 6.17741C0.207022 6.04523 0.0964141 5.87092 0.0419501 5.67625C-0.0125139 5.48159 -0.00842004 5.27519 0.0537205 5.08283C0.115861 4.89048 0.233294 4.72069 0.391354 4.59468C0.549414 4.46867 0.741096 4.39201 0.942461 4.37429L4.62996 4.07679L6.05246 0.634288C6.12946 0.446674 6.26051 0.286193 6.42894 0.173249C6.59738 0.0603055 6.7956 0 6.9984 0C7.2012 0 7.39942 0.0603055 7.56785 0.173249C7.73629 0.286193 7.86734 0.446674 7.94434 0.634288L9.36621 4.07679L13.0537 4.37429C13.2555 4.39135 13.4477 4.46758 13.6064 4.59342C13.765 4.71925 13.883 4.88911 13.9455 5.0817C14.008 5.27429 14.0123 5.48104 13.9579 5.67607C13.9034 5.87109 13.7926 6.04571 13.6393 6.17804L13.6431 6.17741Z"
        fill={props.fill || "#7C7C78"}
      />
    </svg>
  );
};
