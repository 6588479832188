import React from "react";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Input, Select, Button, Divider, notification } from "antd";

import { Folder } from "@entities/Folder";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { simulateMouseClick } from "@lib/simulateMouseClick";
import { DocumentsService } from "@services/DocumentsService";
import { DocumentRequest } from "@entities/DocumentRequest";

const { Option } = Select;

type CreateNewDocumentProps = {
  documentId?: number;
  data: any;
  toolId: string;
  folders: Folder[];
  isUpdating: boolean;
  initialTitle: string;
  currentFolder?: number;
  onDocumentCreated: () => void;
};

export const SaveDocument = ({
  documentId,
  data,
  toolId,
  folders,
  isUpdating,
  initialTitle,
  currentFolder,
  onDocumentCreated,
}: CreateNewDocumentProps) => {
  const queryClient = useQueryClient();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [newFolderName, setNewFolderName] = useState<string>("");
  const [documentTitle, setDocumentTitle] = useState<string>(initialTitle);
  const [selectedFolderId, setSelectedFolderId] =
    useState<Folder["id"]>(currentFolder);

  useEffect(() => {
    if (isUpdating) {
      setDocumentTitle(initialTitle);
      setSelectedFolderId(currentFolder);
    } else {
      setDocumentTitle(null);
      setSelectedFolderId(null);
    }
  }, [currentFolder, initialTitle, isUpdating]);
  const { mutate: saveDocument, isLoading } = useMutation(
    (data: DocumentRequest) => {
      if (isUpdating && documentId) {
        return DocumentsService.updateDocument(documentId, data);
      }

      return DocumentsService.createDocument(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("document");
        notification.success({
          message: "Saved",
        });
        onDocumentCreated();
      },
    },
  );

  const { mutate: createNewFolder, isLoading: isFolderCreating } = useMutation(
    DocumentsService.createFolder,
    {
      onSuccess: async (data) => {
        if (data?.id) {
          setNewFolderName("");
          setSelectedFolderId(data.id);
          // Antd select api for blur is breaking
          simulateMouseClick(wrapperRef.current);

          queryClient.setQueryData("folders_list", (prevData: Folder[]) => [
            ...prevData,
            data,
          ]);
          await queryClient.invalidateQueries(["folders_list"]);
        }
      },
    },
  );

  const handleDocumentTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDocumentTitle(event.target.value);
  };

  const handleSaveButton = () => {
    saveDocument({
      folder: selectedFolderId,
      format_version: 1,
      data: {
        title: documentTitle ?? "Untitled",
        tool: toolId,
        payload: data,
      },
    });
  };

  const handleInputFolderChange = (event) => {
    setNewFolderName(event.target.value);
  };

  return (
    <>
      <div className="new-doc-fields" ref={wrapperRef}>
        <div className="field-item doc-data">
          <div className="doc-name-field">
            <label className="field-label">
              Enter the name of the document
            </label>
            <Input value={documentTitle} onChange={handleDocumentTitleChange} />
          </div>
          <div className="doc-folder-field">
            <label className="field-label">Folder for the document</label>
            <Select
              showSearch
              value={selectedFolderId}
              dropdownAlign={{ offset: [0, 0] }}
              onChange={setSelectedFolderId}
              filterOption={(input, option) =>
                (option.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      padding: 8,
                      flexDirection: "column",
                    }}
                  >
                    <Input
                      size="small"
                      style={{ fontSize: 16 }}
                      value={newFolderName}
                      onChange={handleInputFolderChange}
                    />
                    <Button
                      block
                      type="link"
                      loading={isFolderCreating}
                      disabled={!newFolderName.length}
                      onClick={() => createNewFolder({ name: newFolderName })}
                    >
                      <SvgIcon type="plus" style={{ marginRight: 4 }} />
                      Add new folder
                    </Button>
                  </div>
                </div>
              )}
            >
              {folders?.map(({ id, name }) => (
                <Option value={id} label={name} key={id}>
                  <SvgIcon type="folder" className="doc-folder-icon" />
                  <span>{name}</span>
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <Button
        size="large"
        shape="round"
        type="primary"
        className="black-btn"
        onClick={handleSaveButton}
        loading={isLoading}
      >
        Save
        <SvgIcon type="arrow-right" />
      </Button>
    </>
  );
};
