import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";

export const ProductName: React.FC<{ name: string }> = ({ name }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const productNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const element = productNameRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [name]);

  return (
    <p
      ref={productNameRef}
      className={classNames(styles.productName, {
        [styles.truncated]: isTruncated,
      })}
    >
      {name}
    </p>
  );
};
