import { Chart, ChartEvent, Plugin } from "chart.js";

type LabelCoords = { x: number; y: number };

export const labelClickPlugin: Plugin = {
  id: "labelClick",
  afterEvent: (
    chart: Chart,
    args: {
      event: ChartEvent;
    },
  ) => {
    const event = args.event;
    const canvas = chart.canvas;

    const { x, y } = event;
    const labelCoords = chart.scales.r._pointLabelItems;
    const radius = 60;

    if (event.type === "mousemove") {
      let cursorChanged = false;

      labelCoords.forEach((labelCoord: LabelCoords) => {
        const { x: labelX, y: labelY } = labelCoord;

        if (Math.sqrt((x - labelX) ** 2 + (y - labelY) ** 2) < radius) {
          canvas.style.cursor = "pointer";
          cursorChanged = true;
        }
      });

      if (!cursorChanged) {
        canvas.style.cursor = "default";
      }
    }

    if (event.type === "click") {
      labelCoords.forEach((labelCoord: LabelCoords, i: number) => {
        const { x: labelX, y: labelY } = labelCoord;

        if (Math.sqrt((x - labelX) ** 2 + (y - labelY) ** 2) < radius) {
          if (chart.config.options.plugins?.onLabelClick) {
            chart.config.options.plugins.onLabelClick(i);
          }
        }
      });
    }
  },
};
