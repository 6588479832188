import axios from "axios";
import { Checkout } from "src/entities/Checkout";
import { CustomerSubscription } from "src/entities/CustomerSubscription";
import { Product } from "src/entities/Product";
import { SessionRequest } from "src/entities/SessionRequest";

export class ShopService {
  static readonly PRODUCTS_URL = "/api/v1/products";
  static readonly SUBSCRIPTIONS_URL = "/api/v1/subscriptions";

  public static async getProducts(): Promise<Array<Product>> {
    const { data } = await axios.request({
      method: "GET",
      url: ShopService.PRODUCTS_URL,
    });
    return data;
  }

  public static async getProductById(id: number): Promise<Product> {
    const { data } = await axios.request({
      method: "GET",
      url: `${ShopService.PRODUCTS_URL}/${id}`,
    });
    return data;
  }

  public static async productCheckout(
    id: number,
    priceId: number,
  ): Promise<Checkout> {
    const { data } = await axios.request({
      method: "POST",
      url: `${ShopService.PRODUCTS_URL}/${id}/checkout/price/${priceId}`,
    });
    return data;
  }

  // доступные планы
  public static async getSubscriptionsList(): Promise<Array<Product>> {
    const { data } = await axios.request({
      method: "GET",
      url: ShopService.SUBSCRIPTIONS_URL,
    });
    return data;
  }

  public static async getSubscriptionById(id: number): Promise<Product> {
    const { data } = await axios.request({
      method: "GET",
      url: `${ShopService.SUBSCRIPTIONS_URL}/${id}`,
    });
    return data;
  }

  public static async subscriptionCheckout(
    id: number,
    priceId: number,
  ): Promise<Checkout> {
    const { data } = await axios.request({
      method: "POST",
      url: `${ShopService.SUBSCRIPTIONS_URL}/${id}/checkout/price/${priceId}`,
    });
    return data;
  }

  public static async subscriptionCheckoutSuccess(
    id: number,
    requestBody: SessionRequest,
  ): Promise<CustomerSubscription> {
    const { data } = await axios.request({
      method: "POST",
      url: `${ShopService.SUBSCRIPTIONS_URL}/${id}/checkout/success`,
      data: requestBody,
    });
    return data;
  }
}
