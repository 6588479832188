export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <rect width="14" height="14" rx="7" fill="#7C7C78" />
      <path
        d="M7.3087 5.78261L7.29891 11.1641H6.18587L6.19565 5.78261H7.3087ZM6 3.69565C6 3.49638 6.06522 3.33152 6.19565 3.20109C6.32971 3.06703 6.51449 3 6.75 3C6.98188 3 7.16486 3.06703 7.29891 3.20109C7.43297 3.33152 7.5 3.49638 7.5 3.69565C7.5 3.8913 7.43297 4.05435 7.29891 4.18478C7.16486 4.31522 6.98188 4.38043 6.75 4.38043C6.51449 4.38043 6.32971 4.31522 6.19565 4.18478C6.06522 4.05435 6 3.8913 6 3.69565Z"
        fill="white"
      />
    </svg>
  );
};
