import { create } from "zustand";

export type Keyword = {
  name: string;
  keywords: string[];
};

interface KeywordState {
  keyword: Keyword;
  setKeywords: (keywords: Keyword) => void;
}

export const useKeywordStore = create<KeywordState>((set) => ({
  keyword: {
    name: "",
    keywords: [],
  },
  setKeywords: (keyword) => set({ keyword }),
}));
