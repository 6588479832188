import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ConfigImage from "@assets/icons/config.svg";
import CalendarImage from "@assets/icons/calendar.svg";
import { ConfigModal } from "@components/config-modal";
import { CalendarModal } from "@components/calendar-modal";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { fetchWidgets } from "src/utils/fetchWidgets";
import { formatDateRange } from "src/utils/formatDate";
import classNames from "classnames";

interface TabProps {
  onApply: (
    widgets: IWidget[],
    dateRange?: { startDate: Date; endDate: Date },
  ) => void;
  maxSelectedWidgets?: number;
  showTabIds?: number[];
  bgNearItem?: string;
}

interface TabItem {
  label: string;
  code: string;
  icon: string;
}

const tabList: TabItem[] = [
  {
    label: "Configure",
    code: "config",
    icon: ConfigImage,
  },
  {
    label: "01.01.24 — 07.01.24",
    code: "calendar",
    icon: CalendarImage,
  },
];

export const Tab: React.FC<TabProps> = ({
  onApply,
  showTabIds = [0, 1],
  maxSelectedWidgets = 10,
  bgNearItem,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({
    x: 0,
    y: 0,
    width: 0,
  });

  const { dateRange, setDateRange, widgets, setWidgets } = useDashboardStore(
    (state) => ({
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      widgets: state.widgets,
      setWidgets: state.setWidgets,
    }),
  );

  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );
  const modalRef = useOutsideClick(() => setActiveTab(null), "click");

  useEffect(() => {
    setSelectedDateRange(
      formatDateRange(dateRange.startDate, dateRange.endDate),
    );
  }, [dateRange]);

  const handleTabToggle = (
    code: string,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    setActiveTab((prev) => (prev === code ? null : code));

    if (activeTab !== code) {
      const { right, bottom, width } =
        event.currentTarget.getBoundingClientRect();
      setModalPosition({ x: right, y: bottom, width });
    }
  };

  const handleDateChange = async (startDate: Date, endDate: Date) => {
    setDateRange({ startDate, endDate });

    try {
      const newData = await fetchWidgets(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
      );
      const updatedWidgets = widgets.map((widget, index) => ({
        ...widget,
        data: newData[index],
      }));
      setWidgets(updatedWidgets);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  return (
    <div className={styles.tab}>
      {tabList
        .filter((_, i) => showTabIds.includes(i))
        .map((tabItem) => (
          <div
            key={tabItem.code}
            onClick={(e) => handleTabToggle(tabItem.code, e)}
            className={classNames(styles.element, {
              [styles["element--active"]]: activeTab === tabItem.code,
            })}
          >
            <img
              src={tabItem.icon}
              alt={t(tabItem.label)}
              className={styles.icon}
            />
            <p
              className={classNames(styles.tabItem)}
              style={
                bgNearItem
                  ? ({ "--bgNearItem": bgNearItem } as React.CSSProperties)
                  : {}
              }
            >
              {tabItem.code === "calendar"
                ? selectedDateRange
                : t(tabItem.label)}
            </p>

            {activeTab === tabItem.code && (
              <div
                ref={modalRef}
                className={styles.modalContainer}
                onClick={(e) => e.stopPropagation()}
              >
                {tabItem.code === "config" && (
                  <ConfigModal
                    buttonWidth={modalPosition.width}
                    closeModal={() => setActiveTab(null)}
                    initialWidgets={widgets}
                    setWidgets={setWidgets}
                    onApply={onApply}
                    maxSelectedWidgets={maxSelectedWidgets}
                  />
                )}
                {tabItem.code === "calendar" && (
                  <CalendarModal
                    buttonWidth={modalPosition.width}
                    closeModal={() => setActiveTab(null)}
                    onDateChange={handleDateChange}
                    initialRange={dateRange}
                  />
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
