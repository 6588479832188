import { useCallback, useMemo } from "react";
import { Spin, Tabs as AntdTabs } from "antd";
import { useQuery } from "react-query";
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";

import { Tabs } from "@components/tabs";
import { AiEntity } from "@entities/AiEntity";
import { AIService } from "@services/AIService";
import { mutateInputSchema } from "@lib/schemas/mutateInputSchema";
import { Template } from "@components/Template";

import "./ai-templates.less";
import {
  GROUP_TEMPLATE,
  GROUPPED_TEMPLATES,
  TEMPLATES_TABS_IGNORE_LIST,
  GROUPPED_IDS,
} from "./lib/utils";

const { TabPane } = AntdTabs;

export const AiTemplates = () => {
  const { isLoading, data: aiTemplates } = useQuery(
    ["ai-templates"],
    AIService.getAllAI,
  );
  const navigate = useNavigate();
  const { "*": match } = useParams();

  const toolId = match?.split("/")[0];

  const group: GROUP_TEMPLATE | null = useMemo(
    () =>
      GROUPPED_TEMPLATES.find(({ childrens }) =>
        childrens.includes(toolId ?? ""),
      ),
    [toolId],
  );

  const normalizedTemplates = useMemo(
    () =>
      aiTemplates?.data.reduce(
        (acc, template) => ({ ...acc, [template.id]: template }),
        {},
      ),
    [aiTemplates?.data],
  );

  const filteredQueryData = useMemo(
    () => mutateInputSchema(aiTemplates?.data ?? []),
    [aiTemplates?.data],
  );

  const primaryTabs = useMemo(() => {
    if (aiTemplates?.data) {
      const aiTabs = aiTemplates.data
        .filter(({ id }) => !TEMPLATES_TABS_IGNORE_LIST.includes(id))
        .map(({ id, title }) => ({
          id,
          tab: title,
        }));

      aiTabs.splice(0, 0, ...GROUPPED_TEMPLATES);

      return aiTabs;
    }

    return [];
  }, [aiTemplates]);

  const secondaryTabs = useMemo(
    () =>
      group?.childrens.map((templateId: string) => ({
        id: normalizedTemplates?.[templateId]?.id,
        tab: normalizedTemplates?.[templateId]?.title.replace("Sales text", ""),
      })),
    [group?.childrens, normalizedTemplates],
  );

  const mappedTemplates = useMemo(
    () =>
      (filteredQueryData ?? []).reduce<Record<string, AiEntity>>(
        (acc, template) => {
          acc[template.id] = template;

          return acc;
        },
        {},
      ),
    [filteredQueryData],
  );

  const onTabChange = useCallback(
    (key: string) => {
      if (key) {
        if (GROUPPED_IDS.includes(key as (typeof GROUPPED_IDS)[number])) {
          const group = GROUPPED_TEMPLATES.find(({ id }) => id === key);

          if (group) {
            // by default choose first children
            navigate(group.childrens[0]);
          }
        } else {
          navigate(key);
        }
      }
    },
    [navigate],
  );

  if (isLoading) {
    return (
      <div className="ai-templates-page">
        <div className="spin-wrapper">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="ai-templates-page">
      <Tabs
        centered
        tabs={primaryTabs}
        activeKey={group?.id || match || toolId}
        onChange={onTabChange}
        destroyInactiveTabPane
      />
      <div className="container">
        {Boolean(secondaryTabs?.length) && (
          <AntdTabs
            activeKey={toolId}
            onChange={onTabChange}
            className="ai-templates-page__secondary-tabs"
          >
            {secondaryTabs.map((tabProps) => (
              <TabPane {...tabProps} key={tabProps.id} />
            ))}
          </AntdTabs>
        )}

        {filteredQueryData && filteredQueryData.length > 0 && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to={filteredQueryData[0].id} replace />}
            />
            {filteredQueryData.map(({ id }) => (
              <Route
                key={id}
                path={id}
                element={<Template key={id} schema={mappedTemplates[id]} />}
              >
                <Route
                  key={id}
                  path=":documentId"
                  element={<Template key={id} schema={mappedTemplates[id]} />}
                />
              </Route>
            ))}
            <Route path="*" element={<Navigate to={"/404"} replace />} />
          </Routes>
        )}
      </div>
    </div>
  );
};
