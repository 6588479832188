import React from "react";
import styles from "./styles.module.scss";
import { AContentData } from "./mock";

interface AContentProps {
  data: AContentData[];
}

const AContent: React.FC<AContentProps> = React.memo(({ data }) => {
  return (
    <div className={styles.aContent}>
      {data.map(({ id, imageUrl }) => (
        <img
          key={id}
          src={imageUrl}
          alt={`A+ Content image ${id}`}
          className={styles.image}
        />
      ))}
    </div>
  );
});

export default AContent;
