import { useState } from "react";
import { Link } from "react-router-dom";
import AuthInput from "@components/AuthInput";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import ResetPasswordService from "@services/forgotPassword";
import BackAuthIcon from "@assets/icons/backAuthIcon";
import styles from "./styles.module.scss";
import CongratulationsButton from "@components/CongratulationsButton";

function ForgotPassword() {
  const [formData, setFormData] = useState({ email: "" });
  const [status, setStatus] = useState({ success: null, message: "" });

  const input = {
    title: "your e-mail",
    type: "email",
    value: formData.email,
    onChange: (e) => setFormData({ ...formData, email: e.target.value }),
  };

  const handleResetPassword = async () => {
    try {
      await ResetPasswordService.resetPassword(formData.email);
      setStatus({
        success: true,
        message: "Password reset link has been sent to your email.",
      });
    } catch (error) {
      setStatus({
        success: false,
        message: "Failed to send password reset link.",
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.navBar}>
        <Link to="/login" className={styles.backButton}>
          <BackAuthIcon /> back to log in
        </Link>
      </div>
      <div className={styles.content}>
        <h1 className={styles.contentHeader}>Password reset</h1>
        <div className={styles.fullWidthInput}>
          <AuthInput {...input} />
        </div>
        <CongratulationsButton onClick={handleResetPassword}>
          send me a new password <AuthButtonArrow />
        </CongratulationsButton>
        {status.message && (
          <p
            className={
              status.success ? styles.successMessage : styles.errorMessage
            }
          >
            {status.message}
          </p>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
