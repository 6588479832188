export default () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37207 0.627279C5.82206 0.177289 6.55164 0.177289 7.00163 0.627279C7.45162 1.07727 7.45162 1.80685 7.00163 2.25684L4.26818 4.99028C3.81408 5.44438 3.81408 6.18062 4.26818 6.63472L7.00163 9.36816C7.45162 9.81815 7.45162 10.5477 7.00163 10.9977C6.55164 11.4477 5.82206 11.4477 5.37207 10.9977L1.00907 6.63472C0.554967 6.18062 0.554968 5.44438 1.00907 4.99028L5.37207 0.627279Z"
        fill="white"
      />
    </svg>
  );
};
