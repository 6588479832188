import React, {
  forwardRef,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import "./index.css";
import classNames from "classnames";
import {
  IButtonBaseProps,
  IButtonSize,
  IButtonSpinnerPosition,
  IButtonVariant,
} from "./index.types";
import { Spinner } from "../Spinner";
import { ISpinnerProps, ISpinnerSize } from "../Spinner/index.types";
import { ButtonAdornmentIcon } from "./ButtonAdornmentIcon";

export const BaseButton = forwardRef(
  (
    props: IButtonBaseProps,
    buttonRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const localButtonRef = useRef<HTMLButtonElement>(null);
    const ref = (buttonRef || localButtonRef) as RefObject<HTMLButtonElement>;

    const type = props.type || "button";
    const variant: IButtonVariant = props.variant || "primary";
    const isIcon = !!props.isIcon;
    const size: IButtonSize = props.size || "l";
    const spinnerDefaultSize: ISpinnerSize = size === "xxs" ? "xs" : "s";
    const spinnerPosition: IButtonSpinnerPosition = isIcon
      ? "center"
      : props.spinnerPosition || "center";

    const rootClass: string = classNames(
      "nyle-button",
      `nyle-button--${variant}`,
      `nyle-button--${size}`,
      isIcon && "nyle-button--icon",
      props.loading && spinnerPosition === "center" && "nyle-button--loading",
      props.disabled && "nyle-button--disabled",
      props.fullWidth && "nyle-button--fullWidth",
      props.className,
    );

    const textClassSize: Record<IButtonSize, string> = {
      xxs: "text-button",
      xs: "text-button",
      s: "text-button",
      m: "text-button",
      l: "text-button",
    };

    const textClass: string = isIcon
      ? "nyle-button__icon"
      : `nyle-button__text ${textClassSize[size]}`;

    const nativeProps: IButtonBaseProps = useMemo(() => {
      const nativeProps: IButtonBaseProps = { ...props };
      delete nativeProps.loading;
      delete nativeProps.spinnerPosition;
      delete nativeProps.variant;
      delete nativeProps.size;
      delete nativeProps.startIcon;
      delete nativeProps.endIcon;
      delete nativeProps.freezeWidth;
      delete nativeProps.fullWidth;
      delete nativeProps.allowClickOnLoading;
      delete nativeProps.isIcon;
      return nativeProps;
    }, [props]);

    const spinnerProps: Record<IButtonVariant, ISpinnerProps> = {
      primary: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "white",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      outlinePrimary: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "primary",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      outlineSecondary: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "white",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      translucentBlue: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "primary",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      translucentGray: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "white",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      text: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "primary",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      green: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "green",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      blue: {
        variant: props.disabled
          ? "neutral"
          : props.spinnerProps?.variant || "blue",
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
    };

    useLayoutEffect(() => {
      setTimeout(() => {
        if (!ref.current || !props.freezeWidth) {
          return;
        }

        const { width } = ref.current.getBoundingClientRect();
        ref.current.style.minWidth = `${width}px`;
        ref.current.style.maxWidth = `${width}px`;
      });
    }, [props, ref]);

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!props.allowClickOnLoading && props.loading) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }

        props.onClick && props.onClick(e);
      },
      [props],
    );

    const showStartIcon: boolean =
      !isIcon &&
      props.startIcon !== undefined &&
      !(spinnerPosition === "start" && props.loading === true);
    const showEndIcon: boolean =
      !isIcon &&
      props.endIcon !== undefined &&
      !(spinnerPosition === "end" && props.loading === true);
    const showStartSpinner: boolean =
      spinnerPosition === "start" && props.loading === true;
    const showCenterSpinner: boolean =
      spinnerPosition === "center" && props.loading === true;
    const showEndSpinner: boolean =
      spinnerPosition === "end" && props.loading === true;

    return (
      <button
        {...nativeProps}
        type={type}
        className={rootClass}
        ref={ref}
        onClick={handleClick}
      >
        {showStartSpinner && (
          <div className="nyle-button__spinner">
            <Spinner {...spinnerProps[variant]} />
          </div>
        )}
        {showStartIcon && (
          <span className="nyle-button__icon nyle-button__startIcon">
            <ButtonAdornmentIcon icon={props.startIcon} size={size} />
          </span>
        )}
        <span className={textClass}>{props.children}</span>
        {showEndIcon && (
          <span className="nyle-button__icon nyle-button__endIcon">
            <ButtonAdornmentIcon icon={props.endIcon} size={size} />
          </span>
        )}
        {showCenterSpinner && <Spinner {...spinnerProps[variant]} />}
        {showEndSpinner && (
          <div className="nyle-button__spinner">
            <Spinner {...spinnerProps[variant]} />
          </div>
        )}
      </button>
    );
  },
);

export * from "./index.types";
