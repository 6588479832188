import { Tab } from "../Tab";
import "./index.css";
import { useTranslation } from "react-i18next";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";

interface HeaderProps {
  onApply: (
    widgets: IWidget[],
    dateRange?: { startDate: Date; endDate: Date },
  ) => void;
}

export const Header = ({ onApply }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <header className="dashboard-widgets__header">
      <h1 className="dashboard-widgets__header-title heading-1">
        {t("label.build_ecommerce_of_the_future")}
      </h1>
      <Tab onApply={onApply} />
    </header>
  );
};
