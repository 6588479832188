export default () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7778 4.77778L16.2114 3.31759C16.9605 2.86814 17.335 2.64342 17.6432 2.67215C17.9119 2.6972 18.1571 2.836 18.3168 3.05353C18.5 3.30301 18.5 3.73979 18.5 4.61335V8.71998C18.5 9.5935 18.5 10.0303 18.3168 10.2798C18.1571 10.4973 17.9119 10.6362 17.6432 10.6612C17.335 10.6899 16.9605 10.4652 16.2114 10.0158L13.7778 8.55556M4.52222 12.3333H10.7556C11.8134 12.3333 12.3424 12.3333 12.7464 12.1274C13.1018 11.9464 13.3908 11.6574 13.5719 11.302C13.7778 10.898 13.7778 10.369 13.7778 9.31111V4.02222C13.7778 2.96435 13.7778 2.4354 13.5719 2.03135C13.3908 1.67593 13.1018 1.38697 12.7464 1.20588C12.3424 1 11.8134 1 10.7556 1H4.52222C3.46435 1 2.9354 1 2.53135 1.20588C2.17593 1.38697 1.88697 1.67593 1.70588 2.03135C1.5 2.4354 1.5 2.96434 1.5 4.02222V9.31111C1.5 10.369 1.5 10.898 1.70588 11.302C1.88697 11.6574 2.17593 11.9464 2.53135 12.1274C2.9354 12.3333 3.46434 12.3333 4.52222 12.3333Z"
        stroke="white"
        strokeWidth="1.88889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
