import { useState } from "react";
import "./serp-data-table.less";

const defaultTabList = [
  {
    title: "SERP",
  },
  {
    title: "optimize",
  },
  {
    title: "questions",
  },
];

export default function SerpDataTable(props) {
  const {
    onChange,
    tableData,
    tabList = defaultTabList,
    defaultActiveKey = "tab-item-0",
  } = props;

  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  const serpTableData = [
    {
      title: "Word Count",
      avg: tableData?.word_count_avg,
      your: null,
    },
    {
      title: "Headers",
      avg: tableData?.header_count_avg,
      your: null,
    },
    {
      title: "Links",
      avg: tableData?.link_count_avg,
      your: null,
    },
    {
      title: "Images",
      avg: tableData?.image_count_avg,
      your: null,
    },
    {
      title: "Domain Authority",
      avg: tableData?.domain_count_avg,
      your: null,
    },
    {
      title: "Backlinks",
      avg: tableData?.backlink_count_avg,
      your: null,
    },
  ];

  const numberSuffix = ["", "k", "M", "G", "T"];
  const tabItemDataMap = {
    "tab-item-0": serpTableData,
    "tab-item-1": [],
    "tab-item-2": [],
  };
  const activeTableData = tabItemDataMap[activeKey];

  const onTabClick = (idx) => {
    const key = perfromTabKey(idx);
    setActiveKey(key);

    if (onChange) {
      onChange(key);
    }
  };

  const perfromTabKey = (idx) => `tab-item-${idx}`;

  const performNumberR = (num, step = 0) => {
    if (num < 1000) {
      if (step === 0) {
        return num;
      }
      return `${num.toFixed(1)}${numberSuffix[step]}`;
    } else {
      return performNumberR(num / 1000, ++step);
    }
  };

  const performNumber = (num) => {
    // @ts-ignore
    if (isNaN(String(num))) {
      return num;
    }

    const result = performNumberR(Number(num));
    return result;
  };

  return (
    <div className="serp-data-table-container">
      <div className="serp-data-table-tabs">
        {tabList.map((item, i) => (
          <div
            key={perfromTabKey(i)}
            className={
              "sdt-tab-item" +
              (activeKey === perfromTabKey(i) ? " active-tab" : "")
            }
            onClick={() => onTabClick(i)}
          >
            <span className="txt">{item.title}</span>
          </div>
        ))}
      </div>

      <div className="sdt-table-wrapper">
        <table className="sdt-table">
          <thead>
            <tr>
              <th>20/20 results processed</th>
              <th>
                <span className="gray-txt">Avg.</span>
              </th>
              <th>
                <span className="gray-txt">Your</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {activeTableData.map((row, i) => (
              <tr key={"row-" + i}>
                <td>{row.title}</td>
                <td>{performNumber(row.avg) || "-"}</td>
                <td>{performNumber(row.your) || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
