import PageWrapper from "@components/PageWrapper/PageWrapper";
import { Header } from "./components/Header";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import styles from "./styles.module.scss";

export const AdsAnalitics: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <PageWrapper>
      <Header setIsOpenMenu={setIsMenuOpen} />
      <Outlet />
      {isMenuOpen && <div className={styles.bg}></div>}
    </PageWrapper>
  );
};
