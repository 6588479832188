export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9984 21.6004C17.3004 21.6004 21.5984 17.3023 21.5984 12.0004C21.5984 6.69846 17.3004 2.40039 11.9984 2.40039C6.6965 2.40039 2.39844 6.69846 2.39844 12.0004C2.39844 17.3023 6.6965 21.6004 11.9984 21.6004ZM13.1984 7.20039C13.1984 6.53765 12.6612 6.00039 11.9984 6.00039C11.3357 6.00039 10.7984 6.53765 10.7984 7.20039C10.7984 7.86313 11.3357 8.40039 11.9984 8.40039C12.6612 8.40039 13.1984 7.86313 13.1984 7.20039ZM13.1984 11.4004C13.1984 10.7376 12.6612 10.2004 11.9984 10.2004C11.3357 10.2004 10.7984 10.7376 10.7984 11.4004V16.2004C10.7984 16.8631 11.3357 17.4004 11.9984 17.4004C12.6612 17.4004 13.1984 16.8631 13.1984 16.2004V11.4004Z"
        fill="#5295E0"
      />
    </svg>
  );
};
