import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import croProduct from "@assets/img/croProduct.png";
import SliderImg1 from "@assets/img/sliderImg1.png";
import SliderImg2 from "@assets/img/sliderImg2.png";
import SliderImg3 from "@assets/img/sliderImg3.png";
import SliderImg4 from "@assets/img/sliderImg4.png";
import SliderImg6 from "@assets/img/sliderImg6.png";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useProductsStore } from "src/store/product.state";

const imagesStatic = [
  croProduct,
  SliderImg1,
  SliderImg2,
  SliderImg3,
  SliderImg4,
  SliderImg6,
];

interface Image {
  link: string;
  variant: string;
  width: number;
  height: number;
}

interface ProductImageProps {
  images: Image[];
}

const ProductImage: React.FC<ProductImageProps> = ({ images }) => {
  const { productDataByAsin } = useProductsStore();

  const [activeImage, setActiveImage] = useState<Image | undefined>();

  useEffect(() => {
    setActiveImage(undefined);
  }, [productDataByAsin]);

  const mainImageSrc =
    activeImage?.link || productDataByAsin?.image_link || imagesStatic[0];

  const handleThumbnailClick = (image: Image) => {
    const { variant } = image;

    const selectedImage =
      images.find((img) => isLargerImage(img, variant)) || image;

    setActiveImage(selectedImage);
  };

  const isLargerImage = (img: Image, variant: string) =>
    img.variant === variant && img.width >= 600 && img.height >= 600;

  return (
    <div className={styles.productImage}>
      <div className={styles.imageContainer}>
        <TransformWrapper>
          <TransformComponent
            wrapperClass={styles.content__wrapper}
            contentClass={styles.content}
          >
            <img
              src={mainImageSrc}
              alt="Product"
              className={styles.zoomImage}
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
      <div className={styles.thumbnailSlider}>
        {images &&
          images
            .filter((image) => image.width <= 100 && image.height <= 100)
            .map((image, index) => (
              <div
                key={index}
                className={styles.thumbnailImage}
                onClick={() => handleThumbnailClick(image)}
              >
                <img src={image.link} alt={`Thumbnail ${index + 1}`} />
              </div>
            ))}
      </div>
    </div>
  );
};

export default ProductImage;
