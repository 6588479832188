import { TooltipItem } from "chart.js";

export type TooltipData = {
  dataPoints: TooltipItem<"radar">[];
  caretX: number;
  caretY: number;
  title: string;
  index: number;
};

export type TooltopState = {
  direction: "x" | "y";
  reverse: boolean;
};

export const tooltipArrayState: TooltopState[] = [
  { direction: "y", reverse: false },
  { direction: "y", reverse: false },
  { direction: "x", reverse: true },
  { direction: "y", reverse: true },
  { direction: "y", reverse: true },
  { direction: "x", reverse: false },
  { direction: "y", reverse: false },
];

export const tooltipPositions = [
  {
    top: "30%",
    left: "100%",
    transform: "translate(-30px, 0)",
  },
  {
    top: "30%",
    left: "100%",
    transform: "translate(-45px, 0)",
  },
  {
    top: "100%",
    left: "50%",
    transform: "translate(-60px, -30px)",
  },
  {
    top: "100%",
    left: "50%",
    transform: "translate(-90px, -30px)",
  },
  {
    top: "50%",
    left: "0",
    transform: "translate(-100%, -50%)",
  },
  {
    top: "50%",
    left: "0",
    transform: "translate(-100%, 0)",
  },
  {
    top: "50%",
    left: "0",
    transform: "translate(-100%, -50%)",
  },
];
