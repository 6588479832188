export default () => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.834635 1.51366L1.68797 0.666992L12.8346 11.8137L11.988 12.667L9.93464 10.6137C9.16797 10.867 8.35464 11.0003 7.5013 11.0003C4.16797 11.0003 1.3213 8.92699 0.167969 6.00033C0.627969 4.82699 1.3613 3.79366 2.29464 2.97366L0.834635 1.51366ZM7.5013 4.00033C8.03174 4.00033 8.54044 4.21104 8.91552 4.58611C9.29059 4.96118 9.5013 5.46989 9.5013 6.00033C9.50164 6.22737 9.46331 6.45281 9.38797 6.66699L6.83464 4.11366C7.04881 4.03831 7.27426 3.99999 7.5013 4.00033ZM7.5013 1.00033C10.8346 1.00033 13.6813 3.07366 14.8346 6.00033C14.2902 7.38228 13.3657 8.58187 12.168 9.46033L11.2213 8.50699C12.1433 7.86929 12.8868 7.0064 13.3813 6.00033C12.8424 4.90024 12.0057 3.97341 10.9662 3.32523C9.92676 2.67704 8.7263 2.3335 7.5013 2.33366C6.77464 2.33366 6.0613 2.45366 5.39464 2.66699L4.36797 1.64699C5.32797 1.23366 6.38797 1.00033 7.5013 1.00033ZM1.6213 6.00033C2.1602 7.10042 2.99694 8.02724 4.03639 8.67542C5.07585 9.32361 6.27631 9.66715 7.5013 9.66699C7.9613 9.66699 8.41464 9.62033 8.83464 9.52699L7.31464 8.00033C6.85074 7.9506 6.41785 7.74358 6.08795 7.41368C5.75805 7.08378 5.55103 6.65088 5.5013 6.18699L3.23464 3.91366C2.57464 4.48033 2.0213 5.18699 1.6213 6.00033Z"
        fill="#141414"
      />
    </svg>
  );
};
