import { create } from "zustand";
import Axios from "axios";
import { cookieCleaner } from "@lib/cookieHelper";
import { useUserStore } from "src/store/user.state.ts";
import { LS } from "src/constants/localStorage.constants.ts";

export interface IAuthState {
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  logout: () => void;
}

export const useAuthStore = create<IAuthState>((set) => ({
  authenticated: !!localStorage.getItem(LS.Authenticated),
  setAuthenticated: (authenticated: boolean) => {
    localStorage.setItem(LS.Authenticated, authenticated.toString());
    set(() => ({ authenticated }));
  },
  logout: async () => {
    cookieCleaner();
    const refresh_token = localStorage.getItem("refresh_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem(LS.Authenticated);

    useUserStore.getState().setUser(null);

    if (refresh_token) {
      try {
        await Axios.post("/api/v1/token/blacklist", {
          refresh: refresh_token,
        });
      } catch (error) {
        console.error("Failed to blacklist refresh token:", error);
      }
    }

    set(() => ({
      authenticated: false,
    }));
  },
}));
