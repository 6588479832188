import { colorMap } from "@components/HorizontalChart/chart.class";
import { TooltipGrapgicsDataValue } from "./tooltip";

export type ColorPallete = {
  color: string;
  itemColor: string;
  decalColor: string;
};

export const getTooltipValueName = (name: string) => {
  switch (name) {
    case "ctr":
      return "CTR";
    case "cpc":
      return "CPC";
    case "acos":
      return "ACOS";
    case "cvr":
      return "CVR";
    case "roas":
      return "ROAS";
    case "ntbOrders":
      return "NTB Orders";
    default:
      return name;
  }
};

const getItemStyle = (itemColor: string, decalColor: string) => {
  return {
    color: itemColor,
    borderRadius: 16,
    decal: {
      symbol: "line",
      dashArrayX: [1, 0],
      dashArrayY: [1, 4],
      rotation: -1,
      color: decalColor,
    },
  };
};

const labelFormatter = (params) => {
  const name =
    params.name === "SKUs not covered" ? "SKUs\nnot covered" : "SKUs\nCovered";
  const value = params.value;
  const percentage = Math.round(params.percent);
  return `${name}\n{c|${value}} ({d|${percentage}%})`;
};

const labelRich = {
  c: {
    color: "#A6A7A8",
    fontSize: 12,
    lineHeight: 16,
  },
  d: {
    color: "#A6A7A8",
    fontSize: 12,
    lineHeight: 22,
  },
};

const getData = (value: TooltipGrapgicsDataValue, color: ColorPallete) => {
  return [
    {
      name: "SKUs Covered",
      value: value.valueFromChild,
      itemStyle: { color: color.color, borderRadius: 16 },
      label: {
        formatter: labelFormatter,
        position: "outside",
        alignTo: "labelLine",
        align: "left",
        rich: labelRich,
      },
    },
    {
      name: "SKUs not covered",
      value: value.valueToChild - value.valueFromChild,
      itemStyle: getItemStyle(color.itemColor, color.decalColor),
      label: {
        formatter: labelFormatter,
        position: "outside",
        alignTo: "labelLine",
        align: "left",
        rich: labelRich,
      },
    },
  ];
};

const shaderColor = (color, percent) => {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

const getSeries = (value: TooltipGrapgicsDataValue, color: string) => {
  const colorPallete = {
    color: color,
    itemColor: shaderColor(color, -24),
    decalColor: color,
  };

  return {
    type: "pie",
    radius: ["46%", "70%"],
    avoidLabelOverlap: false,
    top: 24,
    labelLine: {
      show: true,
      lineStyle: {
        color: "#000",
      },
      length: 4,
    },
    label: {
      position: "outside",
    },
    data: getData(value, colorPallete),
  };
};

export const getChartOptions = (
  titleText: string,
  value: TooltipGrapgicsDataValue,
) => {
  return {
    title: {
      left: "center",
      text: titleText,
      textStyle: {
        color: "#141414",
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: 12,
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
    },
    series: [getSeries(value, colorMap[titleText])],
  };
};
