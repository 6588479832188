import { Line, ResponsiveContainer, Area, AreaChart } from "recharts";
import style from "./styles.module.scss";

interface CardWithChartProps {
  title?: string;
  value?: string;
  chartData?: { name: string; value: number }[];
}

const mockChartData = [
  { name: "Day 1", value: 1000 },
  { name: "Day 2", value: 1100 },
  { name: "Day 3", value: 1200 },
  { name: "Day 4", value: 1600 },
  { name: "Day 5", value: 1700 },
  { name: "Day 6", value: 1700 },
  { name: "Day 7", value: 1800 },
  { name: "Day 8", value: 1800 },
  { name: "Day 9", value: 1600 },
  { name: "Day 10", value: 1650 },
  { name: "Day 11", value: 1650 },
  { name: "Day 12", value: 2280 },
];

export const CardWithChart = ({
  title = "Name metric",
  value = "$24,470.1",
  chartData = mockChartData,
}: CardWithChartProps) => {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <p className={style.title}>{title}</p>
        <p className={style.value}>{value}</p>
      </div>
      <div className={style.chart}>
        <ResponsiveContainer width="100%" height={80}>
          <AreaChart
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#5295E0" stopOpacity={0.15} />
                <stop offset="100%" stopColor="#5295E0" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="value"
              stroke="#5295E0"
              fill="url(#colorGradient)"
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#5295E0"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
