export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.0003 3.5V7.5C15.0003 7.63261 14.9476 7.75979 14.8538 7.85355C14.7601 7.94732 14.6329 8 14.5003 8C14.3677 8 14.2405 7.94732 14.1467 7.85355C14.053 7.75979 14.0003 7.63261 14.0003 7.5V4.70687L8.85403 9.85375C8.80759 9.90024 8.75245 9.93712 8.69175 9.96228C8.63105 9.98744 8.56599 10.0004 8.50028 10.0004C8.43457 10.0004 8.36951 9.98744 8.30881 9.96228C8.24811 9.93712 8.19296 9.90024 8.14653 9.85375L6.00028 7.70687L1.85403 11.8538C1.76021 11.9476 1.63296 12.0003 1.50028 12.0003C1.3676 12.0003 1.24035 11.9476 1.14653 11.8538C1.05271 11.7599 1 11.6327 1 11.5C1 11.3673 1.05271 11.2401 1.14653 11.1462L5.64653 6.64625C5.69296 6.59976 5.74811 6.56288 5.80881 6.53772C5.86951 6.51256 5.93457 6.49961 6.00028 6.49961C6.06599 6.49961 6.13105 6.51256 6.19175 6.53772C6.25245 6.56288 6.30759 6.59976 6.35403 6.64625L8.50028 8.79313L13.2934 4H10.5003C10.3677 4 10.2405 3.94732 10.1467 3.85355C10.053 3.75979 10.0003 3.63261 10.0003 3.5C10.0003 3.36739 10.053 3.24021 10.1467 3.14645C10.2405 3.05268 10.3677 3 10.5003 3H14.5003C14.6329 3 14.7601 3.05268 14.8538 3.14645C14.9476 3.24021 15.0003 3.36739 15.0003 3.5Z"
        fill="#80C67A"
      />
    </svg>
  );
};
