import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";

const safeNumber = (value: any) => (typeof value === "number" ? value : 0);

export const createWidgets = (data: any): IWidget[] => {
  const {
    aovData,
    salesTotalData,
    adsImpressionsData,
    adsClicksData,
    adsOrdersData,
    adsUnitsSoldData,
    adsSalesData,
    adsSpendData,
    adsAcosData,
    adsRoasData,
    adsCpcData,
    adsCvrData,
    adsCtrData,
    organicImpressionsData,
    organicClicksData,
    organicCartAddsData,
    organicUnitsSoldData,
    organicCvrData,
    organicCtrData,
  } = data;

  return [
    {
      id: "2",
      name: salesTotalData.name,
      value:
        salesTotalData.value !== null
          ? `$${Math.floor(salesTotalData.value)}`
          : "N/A",
      originalValue: safeNumber(aovData.value),
      difference: salesTotalData.difference,
    },
    {
      id: "3",
      name: adsImpressionsData.name,
      value:
        adsImpressionsData.value !== null
          ? adsImpressionsData.value.toString()
          : "N/A",
      originalValue: safeNumber(adsImpressionsData.value),
      difference: adsImpressionsData.difference,
    },
    {
      id: "4",
      name: adsClicksData.name,
      value:
        adsClicksData.value !== null ? adsClicksData.value.toString() : "N/A",
      originalValue: safeNumber(adsClicksData.value),
      difference: adsClicksData.difference,
    },
    {
      id: "5",
      name: adsOrdersData.name,
      value:
        adsOrdersData.value !== null ? adsOrdersData.value.toString() : "N/A",
      originalValue: safeNumber(adsOrdersData.value),
      difference: adsOrdersData.difference,
    },
    {
      id: "6",
      name: adsUnitsSoldData.name,
      value:
        adsUnitsSoldData.value !== null
          ? adsUnitsSoldData.value.toString()
          : "N/A",
      originalValue: safeNumber(adsUnitsSoldData.value),
      difference: adsUnitsSoldData.difference,
    },
    {
      id: "7",
      name: adsSalesData.name,
      value:
        adsSalesData.value !== null
          ? `$${Math.floor(adsSalesData.value)}`
          : "N/A",
      originalValue: safeNumber(adsSalesData.value),
      difference: adsSalesData.difference,
    },
    {
      id: "8",
      name: adsSpendData.name,
      value:
        adsSpendData.value !== null
          ? `$${Math.floor(adsSpendData.value)}`
          : "N/A",
      originalValue: safeNumber(adsSpendData.value),
      difference: adsSpendData.difference,
    },
    {
      id: "9",
      name: adsAcosData.name,
      value: adsAcosData.value !== null ? `${adsAcosData.value}%` : "N/A",
      originalValue: safeNumber(adsAcosData.value),
      difference: adsAcosData.difference,
    },
    {
      id: "10",
      name: adsRoasData.name,
      value: adsRoasData.value !== null ? adsRoasData.value.toString() : "N/A",
      originalValue: safeNumber(adsRoasData.value),
      difference: adsRoasData.difference,
    },
    {
      id: "11",
      name: adsCpcData.name,
      value: adsCpcData.value !== null ? `$${adsCpcData.value}` : "N/A",
      originalValue: safeNumber(adsCpcData.value),
      difference: adsCpcData.difference,
    },
    {
      id: "12",
      name: adsCvrData.name,
      value: adsCvrData.value !== null ? `${adsCvrData.value}%` : "N/A",
      originalValue: safeNumber(adsCvrData.value),
      difference: adsCvrData.difference,
    },
    {
      id: "13",
      name: adsCtrData.name,
      value: adsCtrData.value !== null ? `${adsCtrData.value}%` : "N/A",
      originalValue: safeNumber(adsCtrData.value),
      difference: adsCtrData.difference,
    },
    {
      id: "14",
      name: organicImpressionsData.name,
      value:
        organicImpressionsData.value !== null
          ? organicImpressionsData.value.toString()
          : "N/A",
      originalValue: safeNumber(organicImpressionsData.value),
      difference: organicImpressionsData.difference,
    },
    {
      id: "15",
      name: organicClicksData.name,
      value:
        organicClicksData.value !== null
          ? organicClicksData.value.toString()
          : "N/A",
      originalValue: safeNumber(organicClicksData.value),
      difference: organicClicksData.difference,
    },
    {
      id: "16",
      name: organicCartAddsData.name,
      value:
        organicCartAddsData.value !== null
          ? organicCartAddsData.value.toString()
          : "N/A",
      originalValue: safeNumber(organicCartAddsData.value),
      difference: organicCartAddsData.difference,
    },
    {
      id: "17",
      name: organicUnitsSoldData.name,
      value:
        organicUnitsSoldData.value !== null
          ? organicUnitsSoldData.value.toString()
          : "N/A",
      originalValue: safeNumber(organicUnitsSoldData.value),
      difference: organicUnitsSoldData.difference,
    },
    {
      id: "18",
      name: organicCvrData.name,
      value: organicCvrData.value !== null ? `${organicCvrData.value}%` : "N/A",
      originalValue: safeNumber(organicCvrData.value),
      difference: organicCvrData.difference,
    },
    {
      id: "19",
      name: organicCtrData.name,
      value: organicCtrData.value !== null ? `${organicCtrData.value}%` : "N/A",
      originalValue: safeNumber(organicCtrData.value),
      difference: organicCtrData.difference,
    },
  ];
};
