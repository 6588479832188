import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Button, Tabs } from "antd";
import "./remove-background.less";
import { RemoveBackgroundToolSidebar } from "./RemoveBackgroundToolSidebar";

type RemoveBackgroundToolProps = {
  isLoading: boolean;
  isError: boolean;
  originalFile?: File;
  outputFile?: File;
  currentTab: "original" | "output";
  setCurrentTab: Dispatch<SetStateAction<"output" | "original">>;
  onRetryClick?: () => void;
  clearData?: () => void;
};

export const RemoveBackgroundTool = ({
  isLoading,
  originalFile,
  outputFile,
  currentTab,
  setCurrentTab,
  isError,
  onRetryClick,
  clearData,
}: RemoveBackgroundToolProps) => {
  const entryImage = useMemo(
    () => originalFile && URL.createObjectURL(originalFile),
    [originalFile],
  );
  const outputImage = useMemo(
    () => outputFile && URL.createObjectURL(outputFile),
    [outputFile],
  );

  useEffect(() => {
    // clear Blobs from memory
    return () => {
      if (outputImage) {
        URL.revokeObjectURL(outputImage);
      }
    };
  }, [outputImage]);

  useEffect(() => {
    // clear Blobs from memory
    return () => {
      if (entryImage) {
        URL.revokeObjectURL(entryImage);
      }
    };
  }, [entryImage]);

  return (
    <div className="remove-background-tool">
      <div className="remove-background-tool__main">
        <div className="remove-background-tool__header">
          <Tabs
            activeKey={currentTab}
            onChange={(tab: "original" | "output") => {
              setCurrentTab(tab);
            }}
          >
            {[
              {
                id: "original",
                tab: "Original",
              },
              {
                id: "output",
                tab: "Updated Image",
                disabled: !outputFile,
              },
            ].map((tabProps) => (
              <Tabs.TabPane {...tabProps} key={tabProps.id} />
            ))}
          </Tabs>

          <Button type="primary" shape="round" size="small" onClick={clearData}>
            Clear
          </Button>
        </div>
        <div className="remove-background-tool__result">
          {currentTab === "original" && entryImage && (
            <img src={entryImage} alt="result" />
          )}
          {currentTab === "output" && outputImage && (
            <img src={outputImage} alt="result" />
          )}
          {isLoading && (
            <div className="remove-background-tool__result-loader">
              <div className="loader loadingCircle" />
              <span>refining the edges...</span>
            </div>
          )}
        </div>
      </div>
      <div className="remove-background-tool__sidebar">
        <RemoveBackgroundToolSidebar
          isError={isError}
          isLoading={isLoading}
          onRetryClick={onRetryClick}
          outputImage={outputImage}
        />
      </div>
    </div>
  );
};
