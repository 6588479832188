// @ts-nocheck
import { useRef, useEffect } from "react";
import { message } from "antd";
import ReactDOMServer from "react-dom/server";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import { AIService } from "@services/AIService";
import SvgIcon from "@components/svg-icon/SvgIcon";
import "suneditor/dist/css/suneditor.min.css";
import "./rich-text-editor.less";

const removeTools = (
  <span className="clear-style-item">
    Clear styles
    <SvgIcon type="clear" />
  </span>
);

const toolbarIcons = {
  undo: ReactDOMServer.renderToString(<SvgIcon type="undo" />),
  redo: ReactDOMServer.renderToString(<SvgIcon type="redo" />),
  bold: ReactDOMServer.renderToString(<SvgIcon type="bold" />),
  italic: ReactDOMServer.renderToString(<SvgIcon type="italic" />),
  underline: ReactDOMServer.renderToString(<SvgIcon type="underline" />),
  image: ReactDOMServer.renderToString(<SvgIcon type="image" />),
  video: ReactDOMServer.renderToString(<SvgIcon type="video" />),
  link: ReactDOMServer.renderToString(<SvgIcon type="chain-o" />),
  font_color: ReactDOMServer.renderToString(<SvgIcon type="text-color" />),
  highlight_color: ReactDOMServer.renderToString(<SvgIcon type="bg-color" />),
  align_left: ReactDOMServer.renderToString(<SvgIcon type="align-left" />),
  align_center: ReactDOMServer.renderToString(<SvgIcon type="align-center" />),
  align_right: ReactDOMServer.renderToString(<SvgIcon type="align-right" />),
  align_justify: ReactDOMServer.renderToString(
    <SvgIcon type="align-justify" />,
  ),
  list_number: ReactDOMServer.renderToString(<SvgIcon type="ordered" />),
  list_bullets: ReactDOMServer.renderToString(<SvgIcon type="unordered" />),
  erase: ReactDOMServer.renderToString(removeTools),
};

const toolbarButtonList = [
  ["undo", "redo"],
  ["formatBlock"],
  ["bold", "italic", "underline"],
  ["fontColor", "hiliteColor"],
  ["align"],
  ["list"],
  ["link", "image", "video"],
  ["removeFormat"],
];

export default function RichTextEditor(props) {
  const { templateResult, editorState, updateEditorState } = props;

  const editorRef = useRef();

  // insert template result to editor
  useEffect(() => {
    if (!templateResult) {
      return;
    }

    // perform results as html
    let htmlString = "";
    Object.entries(templateResult).forEach(([key, value]) => {
      htmlString += `<h3>${key}</h3>`;
      htmlString += `<p>${value}</p>`;
    });

    // insert to editor
    document.querySelector(".sun-editor-editable").focus();
    setTimeout(() => {
      editorRef?.current?.insertHTML(htmlString);
    }, 100);
  }, [templateResult]);

  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  const habdleWriterClick = () => {
    const htmlString = editorRef.current?.getContents() || "";
    const content = htmlString.replace(/<\/p>/g, "\n").replace(/<[^>]+>/g, "");
    getAIGeneratorResults(content);
  };

  const getAIGeneratorResults = (content) => {
    const data = {
      payload: {
        prompt: content,
      },
    };
    const writerBtn = document.querySelector(".writer-btn-wrapper .writer-btn");
    writerBtn.classList.add("ant-btn-loading");

    AIService.getAIResults("free-write", data)
      .then((response) => {
        // insert data to editor
        const data = response?.data?.response;
        const newContent = data.completion;
        const performedContent =
          "<span> </span> " + newContent.split("\n").join("</br>");
        editorRef?.current?.insertHTML(performedContent);
        writerBtn.classList.remove("ant-btn-loading");
      })
      .catch((error) => {
        message.error("Get AI results failed!");
        writerBtn.classList.remove("ant-btn-loading");
      });
  };

  const customPlugin = {
    name: "ai_writer",
    display: "command",
    add: () => {},
    action: habdleWriterClick,
  };

  const customToolBtn = {
    name: "ai_writer",
    title: "AI Writer",
    dataDisplay: "command",
    dataCommand: "ai_writer",
    buttonClass: "writer-btn-wrapper",
    innerHTML: `<button type="button" class="ant-btn ant-btn-primary ant-btn-round ant-btn-sm writer-btn">ai writer</button>`,
  };

  return (
    <div className="rich-text-editor-container">
      <div className="sun-editor sun-editor-toolbar-container"></div>
      <SunEditor
        setContents={editorState}
        onChange={updateEditorState}
        getSunEditorInstance={getSunEditorInstance}
        setOptions={{
          minHeight: 780,
          icons: toolbarIcons,
          toolbarContainer: ".sun-editor-toolbar-container",
          formats: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
          buttonList: [...toolbarButtonList, [customToolBtn]],
          plugins: {
            ...plugins,
            customPlugin,
          },
        }}
      />
    </div>
  );
}
