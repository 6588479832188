import React from "react";
import cn from "classnames";
import { Avatar } from "antd";

import SvgIcon from "@components/svg-icon/SvgIcon";
import "./user-avatar.less";

type Props = {
  username?: string;
  avatarSrc?: string;
  className?: string;
  children?: React.ReactNode;
  // size?: AvatarProps["size"];
};

export const UserAvatar = ({
  username,
  avatarSrc,
  className,
  children,
}: // size,
Props) => {
  return (
    <span className={cn("user-avatar", className)}>
      <Avatar
        // size={size ?? 30}
        className="user-avatar__img"
        icon={<SvgIcon type="user" />}
        alt={username}
        src={avatarSrc}
      />
      {username && <span className="user-avatar__name">{username}</span>}
      {children}
    </span>
  );
};
