export default () => {
  return (
    <svg
      width="17"
      height="27"
      viewBox="0 0 17 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8886 1.25656C13.6299 0.55239 14.7929 0.552389 15.5341 1.25656C16.3313 2.01377 16.3313 3.2843 15.5341 4.04151L7.1037 12.05C6.27364 12.8385 6.27364 14.1615 7.1037 14.95L15.5341 22.9585C16.3313 23.7157 16.3313 24.9862 15.5341 25.7434C14.7929 26.4476 13.6299 26.4476 12.8886 25.7434L1.52645 14.95C0.69639 14.1615 0.696388 12.8385 1.52645 12.05L12.8886 1.25656Z"
        fill="white"
      />
    </svg>
  );
};
