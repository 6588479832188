import React, { ReactNode } from "react";
import styles from "./PageWrapper.module.scss";
import classNames from "classnames";

interface PageWrapperProps {
  children: ReactNode;
  className?: string;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children, className }) => {
  return (
    <div className={classNames(styles.pageWrapper, className)}>
      <div className={styles.pageWrapperInner}>{children}</div>
    </div>
  );
};

export default PageWrapper;
