import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Flag from "@assets/img/flag.svg";
import CopyIcon from "@assets/icons/CopyIcon";

interface ProductCodeProps {
  code: string;
  isWhiteCode?: boolean;
}

const ProductCode: React.FC<ProductCodeProps> = ({ code, isWhiteCode }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
  };

  return (
    <div className={styles.productCode} onClick={copyToClipboard}>
      <img
        src={Flag}
        alt="USA Flag"
        className={classNames(styles.flag, { [styles.whiteIcon]: isWhiteCode })}
        width={13}
        height={11}
      />
      <span
        className={classNames(styles.code, { [styles.white]: isWhiteCode })}
      >
        {code}
      </span>
      <CopyIcon fill={isWhiteCode ? "#fff" : "#7C7C78"} />
    </div>
  );
};

export default ProductCode;
