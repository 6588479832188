import cn from "classnames";
import { Button } from "antd";
import { DropzoneProps, useDropzone } from "react-dropzone";

import "./remove-background.less";

type RemoveBackgroundProps = {
  onDrop: DropzoneProps["onDrop"];
};

export const RemoveBackground = ({ onDrop }: RemoveBackgroundProps) => {
  const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg",
    maxFiles: 1,
    multiple: false,
  });
  return (
    <div className="remove-background">
      <div
        {...getRootProps({
          className: cn("remove-background__dropzone", {
            isFocused,
            isDragActive,
          }),
        })}
      >
        <input {...getInputProps()} />
        <Button
          type="primary"
          shape="round"
          size="large"
          className="remove-background__btn"
        >
          Upload Image
        </Button>
        <div className="remove-background__legend">or Drag and Drop</div>
      </div>
    </div>
  );
};
