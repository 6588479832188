import {
  campaignsColumns,
  campaignsData,
  performanceColumns,
  performanceData,
  targetData,
  targetingColumns,
} from "./mock";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Minus from "@assets/icons/minus";
import TrendUp from "@assets/icons/trendUp";
import TrendDown from "@assets/icons/trendDown";
import { WhatIfTab } from "./Tabs";
import { getScatterDataTable } from "../WhatIfCharts/Charts/DotPlot/mock";

export const getColumns = (type: WhatIfTab) => {
  switch (type) {
    case "Campaign":
      return campaignsColumns;
    case "Performance":
      return performanceColumns;
    case "Targeting":
      return targetingColumns;
    default:
      return [];
  }
};

export const getData = (type: WhatIfTab) => {
  let data = [];
  switch (type) {
    case "Campaign":
      data = campaignsData;
      break;
    case "Performance":
      data = performanceData;
      break;
    case "Targeting":
      data = targetData;
      break;
    default:
      return [];
  }

  if (type === "Campaign" || type === "Targeting") {
    data = recoursiveMapData(data);
  } else {
    data = getScatterDataTable(data);
  }

  return data;
};

const recoursiveMapData = (data) => {
  return data.map((item) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      if (
        typeof newItem[key] === "object" &&
        newItem[key].value &&
        newItem[key].percentage
      ) {
        const { value, percentage } = newItem[key];

        let trend;
        if (percentage.startsWith("+")) {
          trend = "up";
        } else if (percentage.startsWith("-")) {
          trend = "down";
        } else {
          trend = "neutral";
        }

        newItem[key] = (
          <div className={styles.cell}>
            <span>{value}</span>
            <span className={classNames(styles.percentage, styles[trend])}>
              {trend === "neutral" && <Minus />}
              {trend === "up" && <TrendUp />}
              {trend === "down" && <TrendDown />}
              {percentage.replace(/[+-]/g, "")}
            </span>
          </div>
        );
      }
    });

    if (newItem.children) {
      newItem.children = recoursiveMapData(newItem.children);
    }

    return newItem;
  });
};
