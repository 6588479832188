import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Button, Skeleton, Spin } from "antd";
import RichTextEditor from "./components/rich-text-editor/RichTextEditor";
import TemplateList from "./components/template-list/TemplateList";
import SerpContent from "./components/serp-content/SerpContent";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { AIService } from "@services/AIService";
import { tabList, templateList } from "./fakeData";
import { Template } from "@components/Template";
import { mutateInputSchema } from "@lib/schemas/mutateInputSchema";
import { SaveModal } from "@components/save-modal/SaveModal";
import { DocumentsService } from "@services/DocumentsService";
import { useTitle } from "@hooks/useTitle";
import "./free-form-writing.less";

export default function FreeFormWriting() {
  const defaultState = {
    tabList,
    focused: false,
    templateResult: null,
    activeTemplateIdx: null,
  };
  const { data: queryData } = useQuery(["ai-templates"], AIService.getAllAI);
  const { documentId } = useParams();
  useTitle("Nyle - Free Form Writing");

  const { isLoading: isDocumentLoading, data: document } = useQuery(
    ["document", documentId],
    ({ queryKey }) => {
      const [, documentId] = queryKey;

      return DocumentsService.getDocumentById(documentId);
    },
    {
      enabled: Boolean(documentId),
      retryOnMount: true,
    },
  );

  useEffect(() => {
    if (document?.data?.payload?.output) {
      setEditorState(document.data.payload.output);
    }
  }, [document]);

  const [state, setState] = useState(defaultState);
  const [isSaveModalVisible, setSaveModalVisible] = useState(false);
  const [editorState, setEditorState] = useState("");

  // const addNewTab = () => {
  //   setState((prev) => ({
  //     ...prev,
  //     tabList: [...prev.tabList, { title: "untitled tab" }],
  //   }));
  // };

  // const closeTab = (idx) => {
  //   setState((prev) => ({
  //     ...prev,
  //     tabList: prev.tabList.filter((_, i) => i !== idx),
  //   }));
  // };
  //
  // const changeTabTitle = (newVal, idx) => {
  //   setState((prev) => ({
  //     ...prev,
  //     tabList: prev.tabList.map((tab, i) =>
  //       i === idx ? { ...tab, title: newVal } : tab
  //     ),
  //   }));
  // };

  // const renderTab = (title, idx) => (
  //   <EditableTab
  //     title={title}
  //     onClose={() => closeTab(idx)}
  //     onSaveEdit={(newVal) => changeTabTitle(newVal, idx)}
  //   />
  // );

  const focuseOnEditor = () => {
    setState((prev) => ({
      ...prev,
      focused: true,
    }));
  };

  const setTemplateTools = (_item = null, idx = null) => {
    setState((prev) => ({
      ...prev,
      activeTemplateIdx: idx,
    }));
  };

  const handleOutsideClick = (e) => {
    e.stopPropagation();
  };

  const addTemplateResult = (result) => {
    setState((prev) => ({
      ...prev,
      templateResult: result,
      activeTemplateIdx: null,
    }));
  };

  const foundedSchema =
    typeof state.activeTemplateIdx === "number" &&
    queryData.data &&
    mutateInputSchema(queryData.data).find(
      ({ id }) => id === templateList[state.activeTemplateIdx].id,
    );

  // @ts-ignore
  const ActiveTemplate = templateList[state.activeTemplateIdx]?.component;

  return (
    <div className="free-form-writing-container">
      <div className="container">
        <h2 className="page-main-title">
          The Leading Content Generation Platform
        </h2>

        <div className="tabs-nav-content">
          {/*{false ? (*/}
          {/*  <SlideTab*/}
          {/*    addable*/}
          {/*    cardMode*/}
          {/*    onAddTab={addNewTab}*/}
          {/*    tabRender={renderTab}*/}
          {/*    tabTitles={state.tabList.map((item) => item.title)}*/}
          {/*  />*/}
          {/*) : null}*/}
          <div className="extra-action-btns">
            <Button
              ghost
              shape="round"
              type="primary"
              className="black-btn"
              onClick={() => {
                setSaveModalVisible(true);
              }}
            >
              save
              <SvgIcon type="plus-circle" />
            </Button>
          </div>
        </div>
      </div>

      <div className="tabs-content">
        <div className="container">
          <div className="ffw-main-content">
            {/* template */}
            {queryData?.data ? (
              <div className="template-list-side">
                <TemplateList
                  templateList={templateList}
                  onItemClick={setTemplateTools}
                  activeIdx={state.activeTemplateIdx}
                />
              </div>
            ) : (
              <Skeleton
                active
                paragraph={{ rows: 23 }}
                style={{ width: 206, padding: "0 16px" }}
              />
            )}

            {/* text content */}
            <div className="ffw-text-content" onClick={focuseOnEditor}>
              {isDocumentLoading && (
                <div className="ffw-text-content__loader">
                  <Spin size="large" />
                </div>
              )}
              <RichTextEditor
                editorState={editorState}
                updateEditorState={setEditorState}
                focused={state.focused}
                templateResult={state.templateResult}
              />
              {foundedSchema || ActiveTemplate ? (
                <div
                  onClick={handleOutsideClick}
                  className="template-tool-container"
                >
                  <div className="template-tool-header">
                    <span
                      onClick={() => setTemplateTools()}
                      className="template-tool-close-btn"
                    >
                      close the template
                      <SvgIcon type="close" />
                    </span>
                  </div>
                  {ActiveTemplate ? (
                    <ActiveTemplate
                      isVertical
                      onAddToDocument={addTemplateResult}
                    />
                  ) : (
                    <Template
                      schema={foundedSchema}
                      isVertical
                      onAddToDocument={addTemplateResult}
                    />
                  )}
                </div>
              ) : null}
            </div>

            {/* serp content */}
            <div className="serp-side">
              <SerpContent />
            </div>
          </div>
        </div>
      </div>
      {isSaveModalVisible && (
        <SaveModal
          // @ts-ignore
          data={{ output: editorState }}
          toolId={"free-write"}
          visible={isSaveModalVisible}
          onClose={() => setSaveModalVisible(false)}
          isExisted={Boolean(document?.data)}
          initialTitle={document?.data?.title}
          currentFolder={document?.folder}
          documentId={document?.id}
        />
      )}
    </div>
  );
}
