interface Range {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface Preset {
  label: string;
  range: Range;
}

const getStartOfWeek = (date: Date): Date => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 0);
  return new Date(date.setDate(diff));
};

const getEndOfWeek = (date: Date): Date => {
  const startOfWeek = getStartOfWeek(date);
  return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
};

export const presets: Preset[] = [
  {
    label: "Today",
    range: { startDate: new Date(), endDate: new Date(), key: "selection" },
  },
  {
    label: "Yesterday",
    range: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      key: "selection",
    },
  },
  {
    label: "This week",
    range: {
      startDate: getStartOfWeek(new Date()),
      endDate: getEndOfWeek(new Date()),
      key: "selection",
    },
  },
  {
    label: "Last week",
    range: {
      startDate: getStartOfWeek(
        new Date(new Date().setDate(new Date().getDate() - 7)),
      ),
      endDate: getEndOfWeek(
        new Date(new Date().setDate(new Date().getDate() - 7)),
      ),
      key: "selection",
    },
  },
  {
    label: "Last month",
    range: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1,
      ),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      key: "selection",
    },
  },
  {
    label: "MTD",
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  },
  {
    label: "Last year",
    range: {
      startDate: new Date(new Date().getFullYear() - 1, 0, 1), // 1 Jan
      endDate: new Date(new Date().getFullYear() - 1, 11, 31), // 31 Dec
      key: "selection",
    },
  },
  {
    label: "YTD",
    range: {
      startDate: new Date(new Date().getFullYear(), 0, 1), // 1 Jan
      endDate: new Date(), // текущая дата
      key: "selection",
    },
  },
];

export type { Preset, Range };
