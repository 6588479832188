import classNames from "classnames";
import styles from "./topPhrases.module.scss";

interface ISelectedPhrasesProps {
  selectedPhrases: {
    asin: string;
    phrase: string;
    count: number;
  }[];
  maxCount: number;
}

const TopPhrases = ({ selectedPhrases, maxCount }: ISelectedPhrasesProps) => {
  return selectedPhrases.map((phraseItem) => {
    const width = `${(phraseItem.count / maxCount) * 100}%`;
    return (
      <div key={phraseItem.phrase} className={styles.productPhrase}>
        <div className={styles.productPhraseHeader}>
          <span className={classNames(styles.productPhraseText, "bodySmall")}>
            {phraseItem.phrase}
          </span>
          <span className={classNames(styles.productPhraseRate, "bodySmall")}>
            {phraseItem.count}
          </span>
        </div>
        <div className={styles.productPhraseProgress} style={{ width }} />
      </div>
    );
  });
};

export default TopPhrases;
