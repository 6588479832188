import styles from "src/pages/Auth/Auth.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { ROUTES } from "src/router/routes.ts";
import cn from "classnames";
import { useTranslation } from "react-i18next";

export const AuthNavigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isLogin = pathname.includes(ROUTES.AUTH_LOGIN);

  return (
    <header className={styles.authPageButtons}>
      <NavLink
        to={`${ROUTES.AUTH}/${ROUTES.AUTH_LOGIN}`}
        className={cn(styles.authPageButton, {
          [styles.authPageButtonActive]: isLogin,
        })}
      >
        {t("action.login")}
      </NavLink>
      <NavLink
        to={`${ROUTES.AUTH}/${ROUTES.AUTH_SIGNUP}`}
        className={cn(styles.authPageButton, {
          [styles.authPageButtonActive]: !isLogin,
        })}
      >
        {t("action.signup")}
      </NavLink>
    </header>
  );
};
