import styles from "./styles.module.scss";

interface Problem {
  title: string;
  description: string;
}

export const ProblemDetails: React.FC<{ problem: Problem }> = ({ problem }) => {
  return (
    <div className={styles.problemDetails}>
      <div className={styles.opportunities}>
        <p>{problem.title}</p>
        <br />
        <p>{problem.description}</p>
      </div>
      <div className={styles.aspects}>
        <div className={styles.formulaBlock}>
          <h4>Aspects</h4>
          <span>Formula</span>
        </div>
        <div className={styles.aspectRow}>
          <div>
            <span className={styles.positive}>positive:</span>
            <span className={styles.positive}>40.7%</span>
          </div>
          <div>
            <span className={styles.neutral}>neutral:</span>
            <span className={styles.neutral}>57.4%</span>
          </div>
          <div>
            <span className={styles.negative}>negative:</span>
            <span className={styles.negative}>1.9%</span>
          </div>
        </div>
      </div>
    </div>
  );
};
