import "./AmazonOnboarding.css";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { CountryServices } from "@services/country.services.ts";
import { ICountry } from "src/types/country.types.ts";
import { notification } from "antd";
import {
  ICountriesState,
  useCountriesStore,
} from "src/store/countries.state.ts";
import { LS } from "src/constants/localStorage.constants.ts";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes.ts";
import { useTranslation } from "react-i18next";
import { UserServices } from "@services/user.services.ts";
import { AuthServices } from "@services/auth.services.ts";
import {
  AmazonOnboardingStatus,
  AuthProvider,
  DEFAULT_AMAZON_SELLER_COUNTRY,
} from "src/constants/auth.constants.ts";
import Logo from "@assets/img/logo.tsx";
import { CA, MX, US } from "country-flag-icons/react/3x2";
import classnames from "classnames";
import { Check } from "lucide-react";
import { Skeleton } from "@components/ui-kit/Skeleton";
import { Button } from "@components/ui-kit/Button/Button.tsx";

export const AmazonOnboarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const amazonCountries: ICountry[] = useCountriesStore(
    (state: ICountriesState) => state.amazonCountries,
  );
  const amazonCountriesLoaded: boolean = useCountriesStore(
    (state: ICountriesState) => state.amazonCountriesLoaded,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>(
    DEFAULT_AMAZON_SELLER_COUNTRY,
  );

  const countryFlagMap: Record<string, ReactNode> = useMemo(() => {
    return {
      CA: <CA />,
      MX: <MX />,
      US: <US />,
    };
  }, []);

  const sortCountries = (countries: ICountry[]) => {
    return countries.sort((a, b) => {
      if (a.code === "US") return -1;
      if (b.code === "US") return 1;
      return 0;
    });
  };

  useEffect(() => {
    CountryServices.getAmazonCountries()
      .then((countries: ICountry[]) => {
        const sortedCountries = sortCountries(countries);
        useCountriesStore.getState().setAmazonCountries(sortedCountries);
      })
      .catch((error) => {
        notification.error({
          message:
            error?.response?.data?.detail ??
            t("message.get_amazon_countries_fail_message"),
        });
      });
  }, [t]);

  const onCountryClick = useCallback(
    (country: ICountry) => () => {
      setSelectedCountry(country);
    },
    [],
  );

  const onSkipClick = useCallback(() => {
    localStorage.setItem(
      LS.AmazonOnboardingStatus,
      AmazonOnboardingStatus.Completed,
    );
    navigate(ROUTES.ROOT);
  }, []);

  const onContinue = useCallback(() => {
    if (loading || !amazonCountriesLoaded) {
      return;
    }
    setLoading(true);

    UserServices.updateProfile({
      amazon_seller_country: selectedCountry.code,
    })
      .then(() => {
        AuthServices.getAmazonSellerRedirectUrl()
          .then(({ url }) => {
            localStorage.setItem(
              LS.AuthProvider,
              AuthProvider.AmazonSellerAccount,
            );
            localStorage.setItem(
              LS.AmazonOnboardingStatus,
              AmazonOnboardingStatus.Started,
            );
            window.location.replace(url);
          })
          .catch((error) => {
            notification.error({
              message:
                error?.response?.data?.detail ??
                t("message.get_amazon_seller_redirect_url_fail_message"),
            });
            setLoading(false);
          });
      })
      .catch((error) => {
        notification.error({
          message:
            error?.response?.data?.detail ??
            t("message.update_profile_fail_message"),
        });
        setLoading(false);
      });
  }, [selectedCountry, loading, amazonCountriesLoaded]);

  const amazonCountriesJSX = amazonCountriesLoaded ? (
    amazonCountries.map((country: ICountry) => {
      const selected = selectedCountry.code === country.code;
      const rootClass = classnames(
        "amazon-onboarding__country-button",
        "heading-3",
        selected && "amazon-onboarding__country-button--selected",
      );

      return (
        <button
          key={country.code}
          className={rootClass}
          onClick={onCountryClick(country)}
        >
          <span className="amazon-onboarding__country-flag">
            {countryFlagMap[country.code]}
          </span>
          <span>{country.name}</span>
          <span className="amazon-onboarding__country-buttonRadio">
            {selected && <Check size={14} />}
          </span>
        </button>
      );
    })
  ) : (
    <>
      <Skeleton width="300px" height="40px" shape="round" />
      <Skeleton width="300px" height="40px" shape="round" />
      <Skeleton width="300px" height="40px" shape="round" />
    </>
  );

  return (
    <div className="amazon-onboarding__wrapper">
      <div className="amazon-onboarding">
        <Logo />
        <h1 className="amazon-onboarding__title heading-1">
          {t("label.connect_to_seller_account")}
        </h1>
        <h2 className="amazon-onboarding__subtitle heading-2">
          {t("label.choose_amazon_marketplace_region")}
        </h2>
        <div className="amazon-onboarding__countries">{amazonCountriesJSX}</div>
        <div className="amazon-onboarding__actions">
          <button className="amazon-onboarding__skip" onClick={onSkipClick}>
            {t("action.skip")}
          </button>
          <Button onClick={onContinue} loading={loading}>
            {t("action.connect_to_seller_central")}
          </Button>
        </div>
      </div>
    </div>
  );
};
