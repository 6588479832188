import React from "react";
import styles from "./styles.module.scss";
import Settings from "@assets/img/settings";
import { useModalStore } from "src/store/modal.state";
import { useWidgets } from "@hooks/useWidgets";
import { formatValue } from "src/utils/formatDate";

interface DataBlockProps {
  data: {
    sessionTotal: string;
    pageViewsTotal: string;
    featuredOffer: string;
    unitsOrdered: string;
    unitsSessionPercentage: string;
    orderedProductSales: string;
    totalOrderItems: string;
  };
}

const DataBlock: React.FC<DataBlockProps> = ({ data }) => {
  const { updatedWidgets } = useWidgets();

  const openModal = useModalStore((state) => state.openModal);

  const mapApiDataToDataBlockProps = (apiData) => {
    const dataLookup = {};
    apiData.forEach((item) => {
      dataLookup[item.name] = item;
    });

    return {
      sessionTotal: dataLookup["Sales Total"]?.value || "N/A",
      pageViewsTotal: dataLookup["Ad Impressions"]?.value || "N/A",
      featuredOffer: dataLookup["Organic Clicks"]?.value || "N/A",
      unitsOrdered: dataLookup["Ad Orders"]?.value || "N/A",
      unitsSessionPercentage: dataLookup["Ad CVR"]?.value || "N/A",
      orderedProductSales: dataLookup["Ad Sales"]?.value || "N/A",
      totalOrderItems: dataLookup["Ad Units Sold"]?.value || "N/A",
      totalCTR: dataLookup["Ad CTR"]?.value || "N/A",
      totalCVR: dataLookup["Ad CVR"]?.value || "N/A",
    };
  };
  const dataBlockProps = mapApiDataToDataBlockProps(updatedWidgets);
  return (
    <div className={styles.dataBlock}>
      <table className={styles.table}>
        <thead className={styles.headerRow}>
          <tr>
            <th className={styles.headerCell}>Total sales</th>
            <th className={styles.headerCell}>Total CVR</th>
            <th className={styles.headerCell}>Total CTR</th>
            <th className={styles.headerCell}>Total impressions</th>
            <th className={styles.headerCell}>Total Clicks</th>
            <th className={styles.headerCell}>Sales difference</th>
            <th className={styles.headerCell}>Sales with Nyle</th>
            <th className={styles.headerCell}>Total POS</th>
            <th className={styles.settingsCell}>
              <Settings />
              <button onClick={openModal}></button>
            </th>
          </tr>
        </thead>
        <tbody className={styles.dataRow}>
          <tr>
            <td className={styles.dataCell}>
              {" "}
              {formatValue(dataBlockProps.sessionTotal)}
            </td>
            <td className={styles.dataCell}>
              {formatValue(dataBlockProps.totalCVR)}
            </td>
            <td className={styles.dataCell} style={{ color: "#80c67a" }}>
              {formatValue(dataBlockProps.totalCTR)}
            </td>
            <td className={styles.dataCell}>
              {" "}
              {formatValue(dataBlockProps.pageViewsTotal)}
            </td>
            <td className={styles.dataCell} style={{ color: "#fe5858" }}>
              {formatValue(dataBlockProps.featuredOffer)}
            </td>
            <td className={styles.dataCell}>
              {" "}
              {formatValue(dataBlockProps.orderedProductSales)}
            </td>
            <td className={styles.dataCell}>
              {formatValue(dataBlockProps.totalOrderItems)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DataBlock;
