import style from "./style.module.scss";
import classNames from "classnames";
import Logo from "@assets/icons/logo";
import { Message as MessageType } from "@components/ChatBot/types";
import { renderMessage } from "./utils";

interface MessageProps {
  message: MessageType;
}

export const Message = ({ message }: MessageProps) => {
  const isAssistant = message.sender === "assistant";
  const messageClass = classNames(style.message, {
    [style.left]: isAssistant,
    [style.right]: !isAssistant,
  });

  return (
    <div className={messageClass}>
      {isAssistant && (
        <div className={style.message__avatar}>
          <Logo />
        </div>
      )}
      {renderMessage(message)}
    </div>
  );
};
