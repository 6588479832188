const Pointer = ({ angle, color }) => {
  const transform = `rotate(${angle}deg)`;

  return (
    <svg
      style={{ transform, transformOrigin: "99.5px 103.25px" }}
      width="199"
      height="205"
      viewBox="0 0 199 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.975 107.278L72.6757 94.6162L102.207 107.505L110.975 107.278Z"
        fill={color}
      />
      <path
        d="M110.975 107.278L72.6757 94.6162L104.238 101.925L110.975 107.278Z"
        fill={color}
      />
      <g filter="url(#filter0_d_1926_22952)">
        <ellipse
          cx="99.5002"
          cy="103.254"
          rx="4.38691"
          ry="4.5195"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default Pointer;
