import React from "react";
import styles from "./styles.module.scss";
import Minus from "@assets/icons/minus";
import Plus from "@assets/icons/plus";

interface InfoItemProps {
  title: string;
  content: string;
  isVisible: boolean;
  toggleVisibility: () => void;
}

const InfoItem: React.FC<InfoItemProps> = ({
  title,
  content,
  isVisible,
  toggleVisibility,
}) => (
  <div className={styles.infoItem}>
    <button
      className={`${styles.infoHeader} ${isVisible ? styles.active : ""}`}
      onClick={toggleVisibility}
    >
      <h3>{title}</h3>
      <span>{isVisible ? <Minus /> : <Plus />}</span>
    </button>
    {isVisible && <p>{content}</p>}
  </div>
);

export default InfoItem;
