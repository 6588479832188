export const keywordSearchData = {
  backButtonText: "← BACK",
  keywords: [
    "hair growth vitamins",
    "hair growth for women",
    "hair supplements for hair growth women",
    "hair gummies",
    "biotin chewables",
  ],
  searchTerms: [
    {
      text: "Hair growth vitamins",
      relevanceScore: "95%",
      searchVolume: "24,414",
      checked: true,
    },
    {
      text: "Hair growth for women",
      relevanceScore: "82%",
      searchVolume: "14,966",
      checked: true,
    },
    {
      text: "Hair supplements for hair growth women",
      relevanceScore: "95%",
      searchVolume: "5,771",
      checked: true,
    },
    {
      text: "Hair gummies",
      relevanceScore: "100%",
      searchVolume: "4,616",
      checked: false,
    },
    {
      text: "Biotin chewables",
      relevanceScore: "75%",
      searchVolume: "1,254",
      checked: false,
    },
    {
      text: "Hair growth",
      relevanceScore: "80%",
      searchVolume: "52,455",
      checked: true,
    },
  ],
  applyChangesButtonText: "APPLY CHANGES",
};
