import { createContext, useEffect, useRef, useState } from "react";
import responsiveObserve from "antd/lib/_util/responsiveObserve";

const initialConfig = {
  isMobile: false,
};

type ReponsiveType = { isMobile: boolean };

export const ResponsiveContext = createContext<ReponsiveType>(initialConfig);

export const ResponsiveProvider = ({ children }) => {
  // Using ref for optimize rerender calls
  const configRef = useRef<ReponsiveType>(initialConfig);
  const [config, setConfig] = useState<ReponsiveType>(initialConfig);

  useEffect(() => {
    const token = responsiveObserve.subscribe((screen) => {
      // detect all that is smaller 768px (xs 0 > 575 and md < 768)
      const isMobileScreen = Boolean(screen.xs || screen.md === false);

      if (configRef.current.isMobile !== isMobileScreen) {
        configRef.current.isMobile = isMobileScreen;
        setConfig({ isMobile: isMobileScreen });
      }
    });
    return () => responsiveObserve.unsubscribe(token);
  }, []);

  return (
    <ResponsiveContext.Provider value={config}>
      {children}
    </ResponsiveContext.Provider>
  );
};
