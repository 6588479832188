export default () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.14623 6.62775C0.784086 7.00898 0.784086 7.60709 1.14623 7.98832C1.53565 8.39826 2.18907 8.39826 2.57849 7.98832L6.69712 3.65266C7.10264 3.22577 7.78307 3.22577 8.18859 3.65266L12.3072 7.98832C12.6966 8.39826 13.3501 8.39826 13.7395 7.98832C14.1016 7.60709 14.1016 7.00898 13.7395 6.62775L8.18859 0.784361C7.78307 0.357473 7.10264 0.357473 6.69712 0.784361L1.14623 6.62775Z"
        fill="white"
      />
    </svg>
  );
};
