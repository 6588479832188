import { Slider } from "antd";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface RangeCardProps {
  title: string;
  value: number;
  onChange: (value: [number, number]) => void;
  min?: number;
  max?: number;
  startFrom?: number;
}

export const RangeCard = ({
  title,
  value,
  onChange,
  min = -100,
  max = 100,
  startFrom = 0,
}: RangeCardProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) < min) {
      onChange([Number(event.target.value), startFrom]);
    } else {
      onChange([startFrom, Number(event.target.value)]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.value}>
        <h4>{title}</h4>
        <input
          type="number"
          value={value}
          onChange={handleInputChange}
          className={styles.input}
        />
      </div>
      <div className={styles.range__input}>
        <Slider
          min={-100}
          max={100}
          step={1}
          value={value >= 0 ? [0, value] : [value, 0]}
          defaultValue={[0, 0]}
          onChange={onChange}
          className={classNames(
            "custom-range",
            value >= 0 ? "range__slider" : "range__sliderNegative",
          )}
          range
        />
      </div>
    </div>
  );
};
