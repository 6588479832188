import { CustomWidgetsKeys } from "../ui/TemplateForm";
import deepmerge from "deepmerge";

type Options = {
  toolId?: string;
  schema: Record<string, any>;
  forceTextarea?: boolean;
};

const globalParams = {
  "ui:order": ["company_name", "title", "about", "*"],
};

export const createUiSchema = ({ toolId, schema, forceTextarea }: Options) => {
  const uiSchema = Object.entries(schema).reduce<Record<string, any>>(
    (acc, [propName, propValues]) => {
      const result: Record<string, any> = {
        "ui:options": {},
      };

      if (propValues?.enum && propValues.enum.length <= 3) {
        result["ui:widget"] = CustomWidgetsKeys.RadioGroup;
      }

      if (propValues.type === "string") {
        const isTextarea = propValues?.maxLength > 250;

        if (isTextarea || forceTextarea) {
          result["ui:widget"] = "textarea";
        }
      }

      if (propValues.type === "number" || propValues.type === "integer") {
        result["ui:widget"] = "updown";
      }

      if (propValues.example) {
        result["ui:options"].placeholder = propValues.example;
      }

      acc[propName] = result;
      return { ...acc, ...globalParams };
    },
    {},
  );

  // rewrite by toolId
  switch (toolId) {
    case "grammar-correction":
      return deepmerge(uiSchema, {
        original_sentence: {
          "ui:widget": "textarea",
          "ui:options": {
            rows: 18,
          },
        },
        corrected_sentence: {
          "ui:widget": CustomWidgetsKeys.TextDiff,
        },
      });

    case "blog-post":
      return deepmerge(uiSchema, {
        about: {
          "ui:widget": "textarea",
          "ui:options": {
            rows: 7,
          },
        },
      });

    case "product-listing":
      return deepmerge(uiSchema, {
        keywords: {
          "ui:widget": CustomWidgetsKeys.TagInput,
          "ui:options": {
            hasImageUploader: true,
          },
        },
      });

    case "instagram-post":
      return deepmerge(uiSchema, {
        keywords: {
          "ui:widget": CustomWidgetsKeys.TagInput,
        },
      });

    case "product-review":
      return deepmerge(
        uiSchema,
        {
          "ui:order": ["*", "review_rating"],
        },
        {
          arrayMerge: (_, source) => source,
        },
      );

    case "rewriting":
      return deepmerge(
        uiSchema,
        {
          creativity_level: {
            "ui:widget": CustomWidgetsKeys.RadioGroup,
          },
          audience: {
            "ui:widget": CustomWidgetsKeys.RadioGroup,
          },
          length_adjustment: {
            "ui:widget": CustomWidgetsKeys.RadioGroup,
          },
          "ui:order": ["*", "creativity_level"],
        },
        {
          arrayMerge: (_, source) => source,
        },
      );
  }

  return uiSchema;
};
