import SvgIcon from "@components/svg-icon/SvgIcon";
import NewLineText from "@components/new-line-text/NewLineText";
import "./template-list.less";

export default function TemplateList(props) {
  const { activeIdx, onItemClick, templateList } = props;

  const handleItemClick = (item, idx) => {
    if (onItemClick) {
      onItemClick(item, idx);
    }
  };

  return (
    <div className="template-list-container">
      {templateList.map((item, i) => (
        <div
          key={"template-item-" + i}
          onClick={() => handleItemClick(item, i)}
          className={
            "template-list-item" + (activeIdx === i ? " is-active" : "")
          }
        >
          <div className="template-list-item-content">
            <NewLineText string={item.title} />
            <SvgIcon type="arrow-right" />
          </div>
        </div>
      ))}
    </div>
  );
}
