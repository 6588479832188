export const updatedMaterials = (materials, variantId, newPhotoLink) => {
  if (!materials || !materials.images || !variantId || !newPhotoLink) {
    return materials;
  }

  const updatedImages = materials.images.map((image) => {
    if (image.variant === variantId) {
      return { ...image, link: newPhotoLink };
    }
    return image;
  });

  return {
    ...materials,
    images: updatedImages,
  };
};
