import { CategoriesService } from "./categories/categories.service";
import { GraphsService } from "./graph/graph.service";
import { InsightsService } from "./insights/insights.service";
import { ProductsService } from "./products/products.service";
import { ReviewsService } from "./reviews/reviews.service";

export class AmazonService {
  static categories = new CategoriesService();
  static graphs = new GraphsService();
  static insights = new InsightsService();
  static products = new ProductsService();
  static reviews = new ReviewsService();
}
