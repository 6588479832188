import { WidgetProps } from "react-jsonschema-form";
import { StringDiff } from "react-string-diff";

import "./text-diff.less";

export const TextDiff = (props: WidgetProps) => {
  if (!(props?.formContext?.inputData?.original_sentence && props?.value)) {
    return null;
  }

  return (
    <div className="text-diff">
      <div className="text-diff__info">
        <div className="text-diff__added">Added</div>
        <div className="text-diff__removed">Removed</div>
      </div>
      <StringDiff
        oldValue={props?.formContext?.inputData?.original_sentence}
        newValue={props?.value}
        styles={{
          added: { backgroundColor: "#F8DA80" },
          removed: { backgroundColor: "#FF8383" },
          default: {},
        }}
      />
    </div>
  );
};
