import { useState } from "react";
import styles from "./styles.module.scss";
import Plus from "@assets/icons/plus";
import Minus from "@assets/icons/minus";
import { Problem } from "src/store/insights/types";

interface IProblemComponentProps {
  problemsYour: Problem[];
  problemsCompare: Problem[];
}

const ProblemComponent: React.FC<IProblemComponentProps> = ({
  problemsYour,
  problemsCompare,
}) => {
  const [activeProblemIndexSet1, setActiveProblemIndexSet1] = useState(null);
  const [activeProblemIndexSet2, setActiveProblemIndexSet2] = useState(null);

  const toggleProblemSet1 = (index) => {
    setActiveProblemIndexSet1(index === activeProblemIndexSet1 ? null : index);
  };

  const toggleProblemSet2 = (index) => {
    setActiveProblemIndexSet2(index === activeProblemIndexSet2 ? null : index);
  };

  return (
    <div className={styles.problemContainer}>
      <h3>Top 5 Problems</h3>
      <div className={styles.problemsList}>
        <div className={styles.problemColumn}>
          {problemsYour.slice(0, 5).map((problem, index) => (
            <div
              key={index}
              className={`${styles.problemItem} ${activeProblemIndexSet1 === index ? styles.active : ""}`}
            >
              <div
                className={styles.problemTitle}
                onClick={() => toggleProblemSet1(index)}
              >
                <h2>{problem.problem}</h2>
                <span>
                  {activeProblemIndexSet1 === index ? <Minus /> : <Plus />}
                </span>
              </div>
              {activeProblemIndexSet1 === index && <p>{problem.description}</p>}
            </div>
          ))}
        </div>
        <div className={styles.problemColumn}>
          {problemsCompare.slice(0, 5).map((problem, index) => (
            <div
              key={index}
              className={`${styles.problemItem} ${activeProblemIndexSet2 === index ? styles.active : ""}`}
            >
              <div
                className={styles.problemTitle}
                onClick={() => toggleProblemSet2(index)}
              >
                <h2>{problem.problem}</h2>
                <span>
                  {activeProblemIndexSet2 === index ? <Minus /> : <Plus />}
                </span>
              </div>
              {activeProblemIndexSet2 === index && <p>{problem.description}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProblemComponent;
