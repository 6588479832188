export default () => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93813 5.4283C9.05813 5.4283 9.11856 5.35802 9.14898 5.24745C9.46013 3.57002 9.44041 3.52973 11.1881 3.19845C11.3081 3.1783 11.3788 3.10845 11.3788 2.98759C11.3788 2.86716 11.3086 2.79688 11.1877 2.77673C9.45027 2.4253 9.50041 2.38502 9.14898 0.727304C9.11899 0.617161 9.05856 0.546875 8.93813 0.546875C8.8177 0.546875 8.75727 0.617161 8.72684 0.727304C8.37541 2.38502 8.43541 2.42488 6.68813 2.77673C6.57756 2.79645 6.49698 2.86673 6.49698 2.98759C6.49698 3.10802 6.57756 3.1783 6.6877 3.19845C8.43584 3.54988 8.4157 3.57002 8.72684 5.24745C8.75684 5.35802 8.8177 5.4283 8.93813 5.4283ZM4.07641 12.339C4.26713 12.339 4.39784 12.2186 4.41798 12.0377C4.77927 9.35573 4.8697 9.35573 7.64213 8.82345C7.82298 8.79345 7.9537 8.67259 7.9537 8.48188C7.9537 8.30102 7.82298 8.17073 7.64213 8.1403C4.8697 7.75888 4.76941 7.66845 4.41798 4.9363C4.39784 4.75545 4.26713 4.62473 4.07641 4.62473C3.89556 4.62473 3.76484 4.75545 3.7447 4.94616C3.41341 7.63802 3.27284 7.62816 0.520556 8.1403C0.339699 8.18059 0.208984 8.30102 0.208984 8.48188C0.208984 8.68288 0.339699 8.79345 0.560413 8.82345C3.29298 9.2653 3.41341 9.33559 3.7447 12.0176C3.76484 12.2186 3.89556 12.339 4.07641 12.339ZM10.8864 23.4587C11.1478 23.4587 11.3386 23.2676 11.3887 22.9963C12.1018 17.4922 12.8754 16.6582 18.3196 16.0556C18.6007 16.0256 18.7918 15.8147 18.7918 15.5533C18.7918 15.2919 18.6007 15.0913 18.3196 15.051C12.8754 14.4484 12.1018 13.6149 11.3887 8.1103C11.3386 7.83902 11.1478 7.65816 10.8864 7.65816C10.625 7.65816 10.4347 7.83902 10.3944 8.1103C9.68127 13.6149 8.89784 14.4484 3.46356 15.051C3.17213 15.0913 2.98141 15.2923 2.98141 15.5533C2.98141 15.8147 3.17213 16.0256 3.46356 16.0556C8.88756 16.7687 9.64098 17.502 10.3944 22.9963C10.4347 23.2676 10.6254 23.4587 10.8864 23.4587Z"
        fill="#C8A857"
      />
    </svg>
  );
};
