export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        d="M14.6531 9.97539C14.6531 9.82289 14.7137 9.67664 14.8215 9.5688C14.9294 9.46097 15.0756 9.40039 15.2281 9.40039H16.3781C16.5306 9.40039 16.6769 9.46097 16.7847 9.5688C16.8925 9.67664 16.9531 9.82289 16.9531 9.97539V11.1254C16.9531 11.2779 16.8925 11.4241 16.7847 11.532C16.6769 11.6398 16.5306 11.7004 16.3781 11.7004H15.2281C15.0756 11.7004 14.9294 11.6398 14.8215 11.532C14.7137 11.4241 14.6531 11.2779 14.6531 11.1254V9.97539ZM11.2031 9.97539C11.2031 9.82289 11.2637 9.67664 11.3715 9.5688C11.4794 9.46097 11.6256 9.40039 11.7781 9.40039H12.9281C13.0806 9.40039 13.2269 9.46097 13.3347 9.5688C13.4425 9.67664 13.5031 9.82289 13.5031 9.97539V11.1254C13.5031 11.2779 13.4425 11.4241 13.3347 11.532C13.2269 11.6398 13.0806 11.7004 12.9281 11.7004H11.7781C11.6256 11.7004 11.4794 11.6398 11.3715 11.532C11.2637 11.4241 11.2031 11.2779 11.2031 11.1254V9.97539ZM5.45312 13.4254C5.45312 13.2729 5.51371 13.1266 5.62154 13.0188C5.72937 12.911 5.87563 12.8504 6.02812 12.8504H7.17812C7.33062 12.8504 7.47688 12.911 7.58471 13.0188C7.69254 13.1266 7.75312 13.2729 7.75312 13.4254V14.5754C7.75312 14.7279 7.69254 14.8741 7.58471 14.982C7.47688 15.0898 7.33062 15.1504 7.17812 15.1504H6.02812C5.87563 15.1504 5.72937 15.0898 5.62154 14.982C5.51371 14.8741 5.45312 14.7279 5.45312 14.5754V13.4254ZM8.90312 13.4254C8.90312 13.2729 8.96371 13.1266 9.07154 13.0188C9.17937 12.911 9.32563 12.8504 9.47813 12.8504H10.6281C10.7806 12.8504 10.9269 12.911 11.0347 13.0188C11.1425 13.1266 11.2031 13.2729 11.2031 13.4254V14.5754C11.2031 14.7279 11.1425 14.8741 11.0347 14.982C10.9269 15.0898 10.7806 15.1504 10.6281 15.1504H9.47813C9.32563 15.1504 9.17937 15.0898 9.07154 14.982C8.96371 14.8741 8.90312 14.7279 8.90312 14.5754V13.4254Z"
        fill="#7C7C78"
      />
      <path
        d="M6.025 2.5C6.1775 2.5 6.32375 2.56058 6.43159 2.66841C6.53942 2.77625 6.6 2.9225 6.6 3.075V3.65H15.8V3.075C15.8 2.9225 15.8606 2.77625 15.9684 2.66841C16.0762 2.56058 16.2225 2.5 16.375 2.5C16.5275 2.5 16.6738 2.56058 16.7816 2.66841C16.8894 2.77625 16.95 2.9225 16.95 3.075V3.65H18.1C18.71 3.65 19.295 3.89232 19.7263 4.32365C20.1577 4.75499 20.4 5.34 20.4 5.95V18.6C20.4 19.21 20.1577 19.795 19.7263 20.2263C19.295 20.6577 18.71 20.9 18.1 20.9H4.3C3.69 20.9 3.10499 20.6577 2.67365 20.2263C2.24232 19.795 2 19.21 2 18.6V5.95C2 5.34 2.24232 4.75499 2.67365 4.32365C3.10499 3.89232 3.69 3.65 4.3 3.65H5.45V3.075C5.45 2.9225 5.51058 2.77625 5.61841 2.66841C5.72625 2.56058 5.8725 2.5 6.025 2.5V2.5ZM3.15 7.1V18.6C3.15 18.905 3.27116 19.1975 3.48683 19.4132C3.70249 19.6288 3.995 19.75 4.3 19.75H18.1C18.405 19.75 18.6975 19.6288 18.9132 19.4132C19.1288 19.1975 19.25 18.905 19.25 18.6V7.1H3.15Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
