import { create } from "zustand";
import {
  ConfigService,
  IBackendConfig,
  IFrontendConfig,
} from "@services/config.service";

interface ConfigState {
  backendConfig: IBackendConfig | null;
  frontendConfig: IFrontendConfig;
  fetchBackendConfig: () => Promise<void>;
}

const unsortedFrontendConfig: IFrontendConfig = {
  env_run: import.meta.env.BUILD_ENV_RUN,
  version: import.meta.env.BUILD_VERSION,
  sentry_dsn: import.meta.env.BUILD_SENTRY_DSN,
  env_config: import.meta.env.BUILD_ENV_CONFIG,
  commit_hash: import.meta.env.BUILD_COMMIT_HASH,
};

const frontendConfig: IFrontendConfig = Object.keys(unsortedFrontendConfig)
  .sort()
  .reduce((obj, key) => {
    obj[key] = unsortedFrontendConfig[key];
    return obj;
  }, {} as IFrontendConfig);

// Creates a zustand store to manage the application's configuration state.
export const useConfigStore = create<ConfigState>((set) => ({
  backendConfig: null,
  frontendConfig,
  fetchBackendConfig: async () => {
    try {
      const backendConfig = await ConfigService.fetchBackendConfig();
      set({ backendConfig });
    } catch (error) {
      console.error("Error fetching backend config:", error);
    }
  },
}));
