export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect
        x="-0.5"
        y="0.5"
        width="31"
        height="31"
        rx="15.5"
        transform="matrix(-1 0 0 1 31 0)"
        fill="#141414"
        fill-opacity="0.7"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="31"
        height="31"
        rx="15.5"
        transform="matrix(-1 0 0 1 31 0)"
        stroke="#5C5C5A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1373 10.176C14.7984 9.85408 14.2668 9.85408 13.9279 10.176C13.5635 10.5221 13.5635 11.103 13.9279 11.4491L17.7818 15.1101C18.1613 15.4706 18.1613 16.0754 17.7818 16.4359L13.9279 20.0969C13.5635 20.443 13.5635 21.0238 13.9279 21.37C14.2668 21.6919 14.7984 21.6919 15.1373 21.37L20.3314 16.4359C20.7109 16.0754 20.7109 15.4706 20.3314 15.1101L15.1373 10.176Z"
        fill="white"
      />
    </svg>
  );
};
