export const tooltipFormatter = (params: any) => {
  const { value, data, color } = params;

  return `
    <div style="width: 228px; position: relative;">
      <div style="
        position: absolute;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 14px solid #ffffff;
      "></div>
      <div style="color: #2E2E2E; font-weight: 500; font-size: 18px;">${data[2]}</div>
      <div style="display: flex; justify-content: space-between; padding-top: 12px;">
        <span style="color: #7C7C78; font-weight: 500; font-size: 16px;">Ad Sales:</span>
        <span style="color: #2E2E2E; font-weight: 500; font-size: 16px;">$${value[1]}</span>
      </div>
      <div style="display: flex; justify-content: space-between; padding-top: 8px;">
        <span style="color: #7C7C78; font-weight: 500; font-size: 16px;">ACOS:</span>
        <span style="color: #2E2E2E; font-weight: 500; font-size: 16px;">${value[0]}%</span>
      </div>
    </div>
  `;
};
