import US from "@assets/icons/US.png";
import FR from "@assets/icons/France.png";
import Shopify from "@assets/icons/Shopify.png";
import Brasil from "@assets/icons/Brasil.png";
import Canada from "@assets/icons/Canada.png";
import Mexico from "@assets/icons/Mexico.png";
import tik from "@assets/icons/tiktok.png";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface DropdownProps {
  showCheckboxes: boolean;
  checkedItems: Record<number, boolean>;
  handleCheckboxClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  handleItemClick: (
    index: number,
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DropdownItems = ({
  showCheckboxes,
  checkedItems,
  handleCheckboxClick,
  handleItemClick,
}: DropdownProps) => {
  const items = [
    { src: US, alt: "US", label: "www.amazon.com" },
    // { src: Brasil, alt: "Brasil", label: "www.amazon.com.br" },
    { src: Canada, alt: "Canada", label: "www.amazon.ca" },
    { src: Mexico, alt: "Mexico", label: "www.amazon.ca" },
    // { src: FR, alt: "France", label: "www.amazon.com.fr" },
    { src: Shopify, alt: "Shopify", label: "www.shopify.com" },
    { src: tik, alt: "tiktok", label: "www.tiktok.com" },
  ];

  return items.map((item, index) => (
    <button
      key={item.label}
      className={styles.dropdownItem}
      onClick={handleItemClick(index)}
    >
      {showCheckboxes && (
        <div>
          <input
            type="checkbox"
            id={`checkbox-${item.label}`}
            className={styles["customCheckbox"]}
            checked={!!checkedItems[index]}
            onChange={(e) => handleCheckboxClick(e, index)}
          />
          <label
            htmlFor={`checkbox-${item.label}`}
            className={classNames(
              styles["customCheckboxLabel"],
              checkedItems[index] ? styles.checked : "",
            )}
          />
        </div>
      )}
      <img src={item.src} alt={item.alt} />
      <span>{item.label}</span>
    </button>
  ));
};
