export const campaignsData = {
  nodes: [
    { name: "Nyl - SP - B0B5HN65QQ" },
    { name: "Nyl - SP - B0B2HN33KQ" },
    { name: "Nyl - SP - B0B5HN65KO" },
    { name: "Nyl - SP - B0B5IP83AA" },
    { name: "Nyl - SP - B0B5JK13OP" },
    { name: "Nyl - SP - B0B5II01OQ" },
    { name: "Nyl - SP - B0B5ER88KP" },
    { name: "Hair growth gummies" },
    { name: "Hair gummies" },
    { name: "Hair growth for women" },
    { name: "Hair growth vitamins" },
    { name: "Hair supplements for hair growth women" },
    { name: "Biotin chewables" },
  ],
  links: [
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair growth vitamins",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65QQ",
      target: "Hair supplements for hair growth women",
      value: 1,
    },
    { source: "Nyl - SP - B0B2HN33KQ", target: "Hair gummies", value: 12 },
    {
      source: "Nyl - SP - B0B2HN33KQ",
      target: "Biotin chewables",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair growth for women",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair gummies",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5HN65KO",
      target: "Hair growth vitamins",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth vitamins",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth vitamins",
      value: 1,
    },
    { source: "Nyl - SP - B0B5IP83AA", target: "Biotin chewables", value: 6 },
    {
      source: "Nyl - SP - B0B5IP83AA",
      target: "Hair growth gummies",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5JK13OP",
      target: "Hair gummies",
      value: 10,
    },
    {
      source: "Nyl - SP - B0B5II01OQ",
      target: "Hair growth for women",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5II01OQ",
      target: "Hair gummies",
      value: 1,
    },
    {
      source: "Nyl - SP - B0B5ER88KP",
      target: "Hair growth vitamins",
      value: 1,
    },
  ],
};

export const performanceData = {
  nodes: [
    { name: "Good" },
    { name: "Mid" },
    { name: "Poor" },
    { name: "No Sales" },
    { name: "Sponsored Products" },
    { name: "Sponsored Brands" },
    { name: "Sponsored Display" },
  ],
  links: [
    { source: "Good", target: "Sponsored Products", value: 1 },
    { source: "Mid", target: "Sponsored Products", value: 1 },
    { source: "Poor", target: "Sponsored Products", value: 1 },
    { source: "No Sales", target: "Sponsored Products", value: 1 },
    { source: "Good", target: "Sponsored Brands", value: 1 },
    { source: "Mid", target: "Sponsored Brands", value: 1 },
    { source: "Poor", target: "Sponsored Brands", value: 1 },
    { source: "No Sales", target: "Sponsored Brands", value: 1 },
    { source: "Good", target: "Sponsored Display", value: 1 },
    { source: "Mid", target: "Sponsored Display", value: 1 },
    { source: "Poor", target: "Sponsored Display", value: 1 },
    { source: "No Sales", target: "Sponsored Display", value: 1 },
  ],
};

export const targetingData = {
  nodes: [
    { name: "asin = 'B0B5HN65QQ'" },
    { name: "asin = 'B0B2HN33KQ'" },
    { name: "theeth whiting strips" },
    { name: "category = 'Theeth whiting strips'" },
    { name: "Whiting Strip" },
    { name: "asin = 'B0B5II01OP'" },
    { name: "natural whiting strips" },
    { name: "Nyl - SP - B0B5HN65QQ" },
    { name: "Nyl - SP - B0B5HN65KO" },
    { name: "Nyl - SP - B0B2HN33KQ" },
    { name: "Nyl - SP - B0B5JK13OP" },
    { name: "Nyl - SP - B0B5II01OQ" },
    { name: "Nyl - SP - B0B5ER88KP" },
  ],
  links: [
    {
      source: "asin = 'B0B5HN65QQ'",
      target: "Nyl - SP - B0B5HN65QQ",
      value: 15,
    },
    {
      source: "asin = 'B0B2HN33KQ'",
      target: "Nyl - SP - B0B5HN65KO",
      value: 12,
    },
    {
      source: "theeth whiting strips",
      target: "Nyl - SP - B0B5HN65KO",
      value: 8,
    },
    {
      source: "category = 'Theeth whiting strips'",
      target: "Nyl - SP - B0B2HN33KQ",
      value: 10,
    },
    { source: "Whiting Strip", target: "Nyl - SP - B0B2HN33KQ", value: 14 },
    {
      source: "asin = 'B0B5II01OP'",
      target: "Nyl - SP - B0B5JK13OP",
      value: 10,
    },
    {
      source: "natural whiting strips",
      target: "Nyl - SP - B0B5II01OQ",
      value: 8,
    },
    {
      target: "Nyl - SP - B0B5HN65QQ",
      source: "Whiting Strip",
      value: 12,
    },
    {
      target: "Nyl - SP - B0B2HN33KQ",
      source: "asin= “B0B5II01OP”",
      value: 6,
    },
  ],
};
