export const campaignsColumns = [
  {
    title: "Campaign",
    dataIndex: "campaign",
    key: "campaign",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    width: 160,
  },
  {
    title: "Share",
    dataIndex: "share",
    key: "share",
    width: 160,
  },
  {
    title: "Spend with Nyle",
    dataIndex: "spendWithNyle",
    key: "spendWithNyle",
    width: 160,
  },
  {
    title: "Sales",
    dataIndex: "sales",
    key: "sales",
    width: 160,
  },
  {
    title: "Sales with Nyle",
    dataIndex: "salesWithNyle",
    key: "salesWithNyle",
    width: 160,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 160,
  },
  {
    title: "ACOS with Nyle",
    dataIndex: "acosWithNyle",
    key: "acosWithNyle",
    width: 160,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 160,
  },
];

export const performanceColumns = [
  {
    title: "Ad Type",
    dataIndex: "performance",
    key: "performance",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    width: 160,
  },
  {
    title: "Share",
    dataIndex: "share",
    key: "share",
    width: 160,
  },
  {
    title: "Spend with Nyle",
    dataIndex: "spendWithNyle",
    key: "spendWithNyle",
    width: 160,
  },
  {
    title: "Sales",
    dataIndex: "sales",
    key: "sales",
    width: 160,
  },
  {
    title: "Sales with Nyle",
    dataIndex: "salesWithNyle",
    key: "salesWithNyle",
    width: 160,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 160,
  },
  {
    title: "ACOS with Nyle",
    dataIndex: "acosWithNyle",
    key: "acosWithNyle",
    width: 160,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 160,
  },
];

export const targetingColumns = [
  {
    title: "Targeting",
    dataIndex: "targeting",
    key: "targeting",
    fixed: "left" as const,
    width: 278,
  },
  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    width: 160,
  },
  {
    title: "Share",
    dataIndex: "share",
    key: "share",
    width: 160,
  },
  {
    title: "Spend with Nyle",
    dataIndex: "spendWithNyle",
    key: "spendWithNyle",
    width: 160,
  },
  {
    title: "Sales",
    dataIndex: "sales",
    key: "sales",
    width: 160,
  },
  {
    title: "Sales with Nyle",
    dataIndex: "salesWithNyle",
    key: "salesWithNyle",
    width: 160,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 160,
  },
  {
    title: "ACOS with Nyle",
    dataIndex: "acosWithNyle",
    key: "acosWithNyle",
    width: 160,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 160,
  },
];

export const campaignsData = [
  {
    key: "1",
    campaign: "Lorem Campaign Name",
    spend: {
      value: "$2,343",
      percentage: "-3.2%",
    },
    share: {
      value: "34%",
      percentage: "-3.2%",
    },
    spendWithNyle: {
      value: "$4,920",
      percentage: "-3.2%",
    },
    sales: {
      value: "$18,776",
      percentage: "+3.4%",
    },
    salesWithNyle: {
      value: "$37,459",
      percentage: "+3.4%",
    },
    acos: {
      value: "12%",
      percentage: "-3.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-3.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "+0.3%",
    },

    children: [
      {
        key: "1-1",
        campaign: "Lorem Campaign Name",
        spend: {
          value: "$2,343",
          percentage: "-3.2%",
        },
        share: {
          value: "34%",
          percentage: "-3.2%",
        },
        spendWithNyle: {
          value: "$4,920",
          percentage: "-3.2%",
        },
        sales: {
          value: "$18,776",
          percentage: "+3.4%",
        },
        salesWithNyle: {
          value: "$37,459",
          percentage: "+3.4%",
        },
        acos: {
          value: "12%",
          percentage: "-3.2%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-3.2%",
        },
        cpc: {
          value: "1.06",
          percentage: "+0.3%",
        },
      },
    ],
  },
  {
    key: "2",
    campaign: "Lorem Campaign Name",
    spend: {
      value: "$2,100",
      percentage: "+0.8%",
    },
    share: {
      value: "22%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$4,409",
      percentage: "+0.8%",
    },
    sales: {
      value: "$13,472",
      percentage: "+0.8%",
    },
    salesWithNyle: {
      value: "$26,877",
      percentage: "+0.8%",
    },
    acos: {
      value: "16%",
      percentage: "+0.8%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "+0.8%",
    },
    cpc: {
      value: "1.06",
      percentage: "+0.8%",
    },

    children: [
      {
        key: "2-1",
        campaign: "Lorem Campaign Name",
        spend: {
          value: "$2,343",
          percentage: "-3.2%",
        },
        share: {
          value: "34%",
          percentage: "-3.2%",
        },
        spendWithNyle: {
          value: "$4,920",
          percentage: "-3.2%",
        },
        sales: {
          value: "$18,776",
          percentage: "+3.4%",
        },
        salesWithNyle: {
          value: "$37,459",
          percentage: "+3.4%",
        },
        acos: {
          value: "12%",
          percentage: "-3.2%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-3.2%",
        },
        cpc: {
          value: "1.06",
          percentage: "+0.3%",
        },
      },
    ],
  },
  {
    key: "3",
    campaign: "Lorem Campaign Name",
    spend: {
      value: "$1,861",
      percentage: "-7.1%",
    },
    share: {
      value: "17%",
      percentage: "-7.1%",
    },
    spendWithNyle: {
      value: "$3,908",
      percentage: "-7.1%",
    },
    sales: {
      value: "$6,588",
      percentage: "-7.1%",
    },
    salesWithNyle: {
      value: "$13,143",
      percentage: "-7.1%",
    },
    acos: {
      value: "28%",
      percentage: "-7.1%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-7.1%",
    },
    cpc: {
      value: "1.06",
      percentage: "-7.1%",
    },

    children: [
      {
        key: "3-1",
        campaign: "Lorem Campaign Name",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "4",
    campaign: "Lorem Campaign Name",
    spend: {
      value: "$1,071",
      percentage: "+0.8%",
    },
    share: {
      value: "15%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$3,908",
      percentage: "-7.1%",
    },
    sales: {
      value: "$3,073",
      percentage: "-8.2%",
    },
    salesWithNyle: {
      value: "$6,131",
      percentage: "-8.2%",
    },
    acos: {
      value: "35%",
      percentage: "-8.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-8.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "-8.2%",
    },

    children: [
      {
        key: "4-1",
        campaign: "Lorem Campaign Name",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "5",
    campaign: "Lorem Campaign Name",
    spend: {
      value: "$910",
      percentage: "+0.8%",
    },
    share: {
      value: "12%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$1,911",
      percentage: "-7.1%",
    },
    sales: {
      value: "$3,060",
      percentage: "-8.2%",
    },
    salesWithNyle: {
      value: "$6,106",
      percentage: "-8.2%",
    },
    acos: {
      value: "30%",
      percentage: "-8.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-8.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "-8.2%",
    },

    children: [
      {
        key: "5-1",
        campaign: "Lorem Campaign Name",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "6",
    campaign: "Grand Total",
    spend: "$9,728",
    share: "100%",
    spendWithNyle: "$17,298",
    sales: "$17,298",
    salesWithNyle: "$45,829",
    acos: "24%",
    acosWithNyle: "24%",
    cpc: "1.06",
  },
];

export const performanceData = [
  {
    key: "1",
    performance: "Good",
    spend: "$15,249",
    share: "68%",
    spendWithNyle: "$23,825",
    sales: "$64,090",
    salesWithNyle: "$120,273",
    acos: "24%",
    acosWithNyle: "20%",
    cpc: "$1.35",
    children: [
      {
        key: "1-1",
        performance: "Sponsored Products",
        spend: "$8,643",
        share: "32%",
        spendWithNyle: "$18,150",
        sales: "$49,892",
        salesWithNyle: "$99,535",
        acos: "17%",
        acosWithNyle: "18%",
        cpc: "$1.42",
      },
      {
        key: "1-2",
        performance: "Sponsored Brands",
        spend: "$2,051",
        share: "24%",
        spendWithNyle: "$4,308",
        sales: "$6,079",
        salesWithNyle: "$12,128",
        acos: "34%",
        acosWithNyle: "36%",
        cpc: "$1.30",
      },
      {
        key: "1-3",
        performance: "Sponsored Display",
        spend: "$4,246",
        share: "12%",
        spendWithNyle: "$1,274",
        sales: "$8,119",
        salesWithNyle: "$7,713",
        acos: "52%",
        acosWithNyle: "17%",
        cpc: "$1.32",
      },
    ],
  },
  {
    key: "2",
    performance: "Mid",
    spend: "$2,051",
    share: "22%",
    spendWithNyle: "$4,308",
    sales: "$6,079",
    salesWithNyle: "$12,128",
    acos: "34%",
    acosWithNyle: "36%",
    cpc: "$2.23",
    children: [
      {
        key: "2-1",
        performance: "Sponsored Products",
        spend: "$8,643",
        share: "32%",
        spendWithNyle: "$18,150",
        sales: "$49,892",
        salesWithNyle: "$99,535",
        acos: "17%",
        acosWithNyle: "18%",
        cpc: "$1.42",
      },
      {
        key: "2-2",
        performance: "Sponsored Brands",
        spend: "$2,051",
        share: "24%",
        spendWithNyle: "$4,308",
        sales: "$6,079",
        salesWithNyle: "$12,128",
        acos: "34%",
        acosWithNyle: "36%",
        cpc: "$1.30",
      },
      {
        key: "2-3",
        performance: "Sponsored Display",
        spend: "$4,246",
        share: "12%",
        spendWithNyle: "$1,274",
        sales: "$8,119",
        salesWithNyle: "$7,713",
        acos: "52%",
        acosWithNyle: "17%",
        cpc: "$1.32",
      },
    ],
  },
  {
    key: "3",
    performance: "Poor",
    spend: "$4,246",
    share: "9%",
    spendWithNyle: "$1,274",
    sales: "$8,119",
    salesWithNyle: "$7,713",
    acos: "52%",
    acosWithNyle: "17%",
    cpc: "$2.40",
    children: [
      {
        key: "3-1",
        performance: "Sponsored Products",
        spend: "$8,643",
        share: "32%",
        spendWithNyle: "$18,150",
        sales: "$49,892",
        salesWithNyle: "$99,535",
        acos: "17%",
        acosWithNyle: "18%",
        cpc: "$1.42",
      },
      {
        key: "3-2",
        performance: "Sponsored Brands",
        spend: "$2,051",
        share: "24%",
        spendWithNyle: "$4,308",
        sales: "$6,079",
        salesWithNyle: "$12,128",
        acos: "34%",
        acosWithNyle: "36%",
        cpc: "$1.30",
      },
      {
        key: "3-3",
        performance: "Sponsored Display",
        spend: "$4,246",
        share: "12%",
        spendWithNyle: "$1,274",
        sales: "$8,119",
        salesWithNyle: "$7,713",
        acos: "52%",
        acosWithNyle: "17%",
        cpc: "$1.32",
      },
    ],
  },
  {
    key: "4",
    performance: "No Sales",
    spend: "$308",
    share: "1%",
    spendWithNyle: "$92",
    sales: "$0",
    salesWithNyle: "$0",
    acos: "0%",
    acosWithNyle: "0%",
    cpc: "$1.89",
    children: [
      {
        key: "4-1",
        performance: "Sponsored Products",
        spend: "$8,643",
        share: "32%",
        spendWithNyle: "$18,150",
        sales: "$49,892",
        salesWithNyle: "$99,535",
        acos: "17%",
        acosWithNyle: "18%",
        cpc: "$1.42",
      },
      {
        key: "4-2",
        performance: "Sponsored Brands",
        spend: "$2,051",
        share: "24%",
        spendWithNyle: "$4,308",
        sales: "$6,079",
        salesWithNyle: "$12,128",
        acos: "34%",
        acosWithNyle: "36%",
        cpc: "$1.30",
      },
      {
        key: "4-3",
        performance: "Sponsored Display",
        spend: "$4,246",
        share: "12%",
        spendWithNyle: "$1,274",
        sales: "$8,119",
        salesWithNyle: "$7,713",
        acos: "52%",
        acosWithNyle: "17%",
        cpc: "$1.32",
      },
    ],
  },
  {
    key: "5",
    performance: "Grand Total",
    spend: "$15,605",
    share: "100%",
    spendWithNyle: "$23,932",
    sales: "$64,090",
    salesWithNyle: "$119,752",
    acos: "24%",
    acosWithNyle: "20%",
    cpc: "$1.35",
  },
];

export const targetData = [
  {
    key: "1",
    targeting: "asin = “B0B5HN65QQ”",
    spend: {
      value: "$2,343",
      percentage: "-3.2%",
    },
    share: {
      value: "34%",
      percentage: "-3.2%",
    },
    spendWithNyle: {
      value: "$4,920",
      percentage: "-3.2%",
    },
    sales: {
      value: "$18,776",
      percentage: "+3.4%",
    },
    salesWithNyle: {
      value: "$37,459",
      percentage: "+3.4%",
    },
    acos: {
      value: "12%",
      percentage: "-3.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-3.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "+0.3%",
    },

    children: [
      {
        key: "1-1",
        targeting: "asin = “B0B5HN65QQ”",
        spend: {
          value: "$2,343",
          percentage: "-3.2%",
        },
        share: {
          value: "34%",
          percentage: "-3.2%",
        },
        spendWithNyle: {
          value: "$4,920",
          percentage: "-3.2%",
        },
        sales: {
          value: "$18,776",
          percentage: "+3.4%",
        },
        salesWithNyle: {
          value: "$37,459",
          percentage: "+3.4%",
        },
        acos: {
          value: "12%",
          percentage: "-3.2%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-3.2%",
        },
        cpc: {
          value: "1.06",
          percentage: "+0.3%",
        },
      },
    ],
  },
  {
    key: "2",
    targeting: "asin= “B0B2HN33KQ”",
    spend: {
      value: "$2,100",
      percentage: "+0.8%",
    },
    share: {
      value: "22%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$4,409",
      percentage: "+0.8%",
    },
    sales: {
      value: "$13,472",
      percentage: "+0.8%",
    },
    salesWithNyle: {
      value: "$26,877",
      percentage: "+0.8%",
    },
    acos: {
      value: "16%",
      percentage: "+0.8%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "+0.8%",
    },
    cpc: {
      value: "1.06",
      percentage: "+0.8%",
    },

    children: [
      {
        key: "2-1",
        targeting: "asin= “B0B2HN33KQ”",
        spend: {
          value: "$2,343",
          percentage: "-3.2%",
        },
        share: {
          value: "34%",
          percentage: "-3.2%",
        },
        spendWithNyle: {
          value: "$4,920",
          percentage: "-3.2%",
        },
        sales: {
          value: "$18,776",
          percentage: "+3.4%",
        },
        salesWithNyle: {
          value: "$37,459",
          percentage: "+3.4%",
        },
        acos: {
          value: "12%",
          percentage: "-3.2%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-3.2%",
        },
        cpc: {
          value: "1.06",
          percentage: "+0.3%",
        },
      },
    ],
  },
  {
    key: "3",
    targeting: "theeth whiting strips",
    spend: {
      value: "$1,861",
      percentage: "-7.1%",
    },
    share: {
      value: "17%",
      percentage: "-7.1%",
    },
    spendWithNyle: {
      value: "$3,908",
      percentage: "-7.1%",
    },
    sales: {
      value: "$6,588",
      percentage: "-7.1%",
    },
    salesWithNyle: {
      value: "$13,143",
      percentage: "-7.1%",
    },
    acos: {
      value: "28%",
      percentage: "-7.1%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-7.1%",
    },
    cpc: {
      value: "1.06",
      percentage: "-7.1%",
    },

    children: [
      {
        key: "3-1",
        targeting: "theeth whiting strips",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "4",
    targeting: "category = ”Theeth whiting strips”",
    spend: {
      value: "$1,071",
      percentage: "+0.8%",
    },
    share: {
      value: "15%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$3,908",
      percentage: "-7.1%",
    },
    sales: {
      value: "$3,073",
      percentage: "-8.2%",
    },
    salesWithNyle: {
      value: "$6,131",
      percentage: "-8.2%",
    },
    acos: {
      value: "35%",
      percentage: "-8.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-8.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "-8.2%",
    },

    children: [
      {
        key: "4-1",
        targeting: "category = ”Theeth whiting strips”",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "5",
    targeting: "Whiting Strip",
    spend: {
      value: "$910",
      percentage: "+0.8%",
    },
    share: {
      value: "12%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$1,911",
      percentage: "-7.1%",
    },
    sales: {
      value: "$3,060",
      percentage: "-8.2%",
    },
    salesWithNyle: {
      value: "$6,106",
      percentage: "-8.2%",
    },
    acos: {
      value: "30%",
      percentage: "-8.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-8.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "-8.2%",
    },

    children: [
      {
        key: "5-1",
        targeting: "Whiting Strip",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "6",
    targeting: "asin= “B0B5II01OQ”",
    spend: {
      value: "$910",
      percentage: "+0.8%",
    },
    share: {
      value: "12%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$1,911",
      percentage: "-7.1%",
    },
    sales: {
      value: "$3,060",
      percentage: "-8.2%",
    },
    salesWithNyle: {
      value: "$6,106",
      percentage: "-8.2%",
    },
    acos: {
      value: "30%",
      percentage: "-8.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-8.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "-8.2%",
    },

    children: [
      {
        key: "6-1",
        targeting: "asin= “B0B5II01OQ”",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "7",
    targeting: "asin= “B0B5II01OQ”",
    spend: {
      value: "$1,071",
      percentage: "+0.8%",
    },
    share: {
      value: "15%",
      percentage: "+0.8%",
    },
    spendWithNyle: {
      value: "$3,908",
      percentage: "-7.1%",
    },
    sales: {
      value: "$3,073",
      percentage: "-8.2%",
    },
    salesWithNyle: {
      value: "$6,131",
      percentage: "-8.2%",
    },
    acos: {
      value: "35%",
      percentage: "-8.2%",
    },
    acosWithNyle: {
      value: "13%",
      percentage: "-8.2%",
    },
    cpc: {
      value: "1.06",
      percentage: "-8.2%",
    },

    children: [
      {
        key: "7-1",
        targeting: "asin= “B0B5II01OQ”",
        spend: {
          value: "$1,861",
          percentage: "-7.1%",
        },
        share: {
          value: "17%",
          percentage: "-7.1%",
        },
        spendWithNyle: {
          value: "$3,908",
          percentage: "-7.1%",
        },
        sales: {
          value: "$6,588",
          percentage: "-7.1%",
        },
        salesWithNyle: {
          value: "$13,143",
          percentage: "-7.1%",
        },
        acos: {
          value: "28%",
          percentage: "-7.1%",
        },
        acosWithNyle: {
          value: "13%",
          percentage: "-7.1%",
        },
        cpc: {
          value: "1.06",
          percentage: "-7.1%",
        },
      },
    ],
  },
  {
    key: "8",
    targeting: "Total 7 Targets",
    spend: "$9,728",
    share: "100%",
    spendWithNyle: "$17,298",
    sales: "$17,298",
    salesWithNyle: "$45,829",
    acos: "24%",
    acosWithNyle: "24%",
    cpc: "1.06",
  },
];
