import React from "react";
import { ImageUploader } from "@components/ImageUploader";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { useCallback, useEffect, useRef, useState } from "react";
import { WidgetProps } from "react-jsonschema-form";
import { WithContext as ReactTags, Tag } from "react-tag-input";
import { convertTextToTag, convertTagToText } from "./lib/tagDataConverter";

import "./tag-input.less";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// @see https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-widgets-fields/
export const TagInputWidget = (props: WidgetProps) => {
  const { onChange, value, disabled, options, rawErrors } = props;

  const ref = useRef<HTMLLabelElement>(null);
  const [tags, setTags] = useState<Tag[]>(convertTextToTag(value));

  const _onChange = useCallback(
    (newTags) => {
      onChange(convertTagToText(newTags));
    },
    [onChange],
  );

  const onTagsExtracted = useCallback(
    (imageTags: string[]) => {
      if (imageTags.length) {
        const newTags = imageTags.map((item) => ({ id: item, text: item }));

        setTags((prev) => {
          const mergedTags = [...prev, ...newTags];

          _onChange(mergedTags);

          return mergedTags;
        });
      }
    },
    [_onChange],
  );

  const handleAddition = (tag: Tag) => {
    _onChange([...tags, tag]);
  };

  const handleDelete = (i: number) => {
    _onChange(tags.filter((_, index) => index !== i));
  };

  const preventSubmittingForm = useCallback((event: KeyboardEvent) => {
    // hack to change default behavior of rjsf
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      const tagInput = ref.current.querySelector("input");
      if (tagInput) {
        tagInput.addEventListener("keydown", preventSubmittingForm);

        return () => {
          tagInput.removeEventListener("keydown", preventSubmittingForm);
        };
      }
    }
  }, [preventSubmittingForm]);

  useEffect(() => {
    setTags(convertTextToTag(value ?? ""));
  }, [value]);

  return (
    <>
      <label ref={ref} className="tag-input">
        <ReactTags
          readOnly={disabled}
          delimiters={delimiters}
          tags={tags}
          handleAddition={handleAddition}
          handleDelete={handleDelete}
          inputFieldPosition="inline"
          inline={true}
          allowDragDrop={false}
          placeholder=""
          classNames={{
            tagInput: "tag-input__input",
            tag: "ant-tag white-tag",
            remove: "ant-tag-close-icon",
          }}
          removeComponent={RemoveComponent}
        />
      </label>

      {Boolean(rawErrors?.length) && Boolean(value?.length) && (
        <span className="tag-input__error">
          {capitalizeFirstLetter(rawErrors[0].toLowerCase())}
        </span>
      )}

      {options?.hasImageUploader && (
        <ImageUploader onTagsExtracted={onTagsExtracted} disabled={disabled} />
      )}
    </>
  );
};

export const RemoveComponent = (props: {
  onRemove: (e: React.MouseEvent) => void;
}) => {
  const { onRemove } = props;
  return (
    <button className="ant-tag-close-icon" onClick={onRemove}>
      <SvgIcon type="close" />
    </button>
  );
};
