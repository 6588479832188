import React from "react";
import styles from "./styles.module.scss";

interface ProductCardProps {
  headerText: string;
  rating: number;
  improvementText?: string;
  scoreIncrease?: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  rating,
  improvementText,
  scoreIncrease = "Score",
}) => {
  return (
    <div className={styles.productContainer}>
      <div className={styles.improvementText}>
        {improvementText}
        <span className={styles.star}>★</span>
      </div>

      <div className={styles.scoreBox}>
        <p className={styles.scoreIncrease}>{scoreIncrease}</p>
        <span className={styles.ratingValue}>{rating}</span>
      </div>
    </div>
  );
};

export default ProductCard;
