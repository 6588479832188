import PageWrapper from "@components/PageWrapper/PageWrapper";
import { Outlet } from "react-router-dom";

function CROModule() {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
}

export default CROModule;
