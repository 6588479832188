import classNames from "classnames";
import { tooltipData } from "./mock";
import styles from "./styles.module.scss";
import productImg from "@assets/img/product1.png";

const getAsin = (title: string) => {
  if (title.startsWith("asin")) {
    return title.substring(7, 17);
  } else if (title.startsWith("Nyl")) {
    return title.substring(11, 21);
  }
};

export const Tooltip = ({
  title,
  withKeys,
}: {
  title: string;
  withKeys?: boolean;
}) => {
  const asin = getAsin(title);

  return (
    <div className={classNames(styles.tooltip, !withKeys && styles.small)}>
      <h3 className={styles.title}>
        Product <span>({asin})</span>
      </h3>
      <div className={styles.info}>
        <img src={productImg} alt="product" />
        <span>{tooltipData.info}</span>
      </div>
      {withKeys && (
        <div className={styles.keys__container}>
          <h3 className={styles.title}>Key Words</h3>
          <div className={styles.keys}>
            {tooltipData.keys.map((key) => (
              <span key={key}>{key}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
