import { useEffect, useRef } from "react";

export const useOutsideClick = (
  onOutsideClick: (event?: MouseEvent) => void,
  actionType: string = "mousedown",
) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick(event);
      }
    };

    document.addEventListener(actionType, handleClickOutside);

    return () => {
      document.removeEventListener(actionType, handleClickOutside);
    };
  }, [onOutsideClick]);

  return ref;
};
