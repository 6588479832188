import { Dropdown, Menu, Button } from "antd";

import SvgIcon from "@components/svg-icon/SvgIcon";
import { FILE_TYPE } from "../Template";

interface Props {
  onSaveDocument: () => void;
  onDownload: (fileType: FILE_TYPE) => void;
}

const TYPES: FILE_TYPE[] = ["PDF", "CSV", "HTML"];

export const TemplateResultCTA = ({ onSaveDocument, onDownload }: Props) => {
  return (
    <div className="action-btns">
      <Dropdown
        trigger={["click"]}
        className="integrated-drp"
        overlayClassName="integrated-drp-menu"
        placement="bottomCenter"
        overlay={
          <Menu>
            {TYPES.map((type) => (
              <Menu.Item key={type} onClick={() => onDownload(type)}>
                Save as {type}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button
          ghost
          type="primary"
          className="pill-btn black-btn"
          shape="round"
          size="large"
        >
          Save file
          <SvgIcon type="down" />
        </Button>
      </Dropdown>
      <Button
        type="primary"
        className="pill-btn black-btn"
        shape="round"
        size="large"
        onClick={onSaveDocument}
      >
        Save to documents
        <SvgIcon type="arrow-right" />
      </Button>
    </div>
  );
};
