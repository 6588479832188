export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M13.3519 12.6464L10.2226 9.51764C11.1296 8.42871 11.5819 7.03201 11.4853 5.6181C11.3888 4.20419 10.7509 2.88193 9.70428 1.92638C8.65768 0.970822 7.28297 0.455551 5.86613 0.487751C4.4493 0.519951 3.09942 1.09714 2.0973 2.09926C1.09519 3.10137 0.517998 4.45125 0.485798 5.86808C0.453598 7.28492 0.968869 8.65963 1.92442 9.70623C2.87997 10.7528 4.20224 11.3908 5.61615 11.4873C7.03006 11.5838 8.42676 11.1315 9.51569 10.2245L12.6444 13.3539C12.6909 13.4003 12.746 13.4372 12.8067 13.4623C12.8674 13.4875 12.9325 13.5004 12.9982 13.5004C13.0639 13.5004 13.1289 13.4875 13.1896 13.4623C13.2503 13.4372 13.3055 13.4003 13.3519 13.3539C13.3984 13.3074 13.4352 13.2523 13.4604 13.1916C13.4855 13.1309 13.4985 13.0658 13.4985 13.0001C13.4985 12.9344 13.4855 12.8694 13.4604 12.8087C13.4352 12.748 13.3984 12.6928 13.3519 12.6464ZM1.49819 6.00014C1.49819 5.11013 1.76211 4.2401 2.25658 3.50008C2.75104 2.76006 3.45385 2.18328 4.27611 1.84268C5.09838 1.50209 6.00318 1.41298 6.8761 1.58661C7.74901 1.76024 8.55083 2.18883 9.18017 2.81816C9.80951 3.4475 10.2381 4.24932 10.4117 5.12224C10.5854 5.99515 10.4962 6.89995 10.1556 7.72222C9.81505 8.54449 9.23828 9.24729 8.49826 9.74176C7.75824 10.2362 6.88821 10.5001 5.99819 10.5001C4.80512 10.4988 3.6613 10.0243 2.81767 9.18066C1.97404 8.33704 1.49951 7.19321 1.49819 6.00014Z"
        fill="#A6A7A8"
      />
    </svg>
  );
};
