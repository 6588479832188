export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5211 2.32468C6.697 1.89181 7.29494 1.89181 7.47083 2.32468L8.39731 4.82857C8.47204 5.01258 8.64736 5.13245 8.84175 5.13245H11.4961C11.9441 5.13245 12.1685 5.68789 11.8517 6.01274L9.66316 8.03354C9.53951 8.16035 9.49266 8.34592 9.54066 8.51836L10.3221 11.3232C10.454 11.7968 9.93911 12.1823 9.53946 11.9091L7.26306 10.3527C7.10132 10.2422 6.89061 10.2422 6.72887 10.3528L4.45625 11.9065C4.05534 12.1806 3.5387 11.7939 3.67107 11.3188L4.45126 8.51836C4.49931 8.34592 4.45241 8.16035 4.32876 8.03354L2.14996 6.02273C1.8296 5.69419 2.05649 5.13245 2.50955 5.13245H5.15017C5.34456 5.13245 5.51988 5.01258 5.59464 4.82857L6.5211 2.32468Z"
        fill="#C8A857"
      />
    </svg>
  );
};
