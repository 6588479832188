export default () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.207 10C20.207 10.5523 19.7593 11 19.207 11L4.20703 11C3.65475 11 3.20703 10.5523 3.20703 10C3.20703 9.44772 3.65475 9 4.20703 9L19.207 9C19.7593 9 20.207 9.44772 20.207 10Z"
        fill="#5295E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7068 3.50008C7.09733 3.10955 7.73049 3.10955 8.12102 3.50008C8.51154 3.8906 8.51154 4.52377 8.12102 4.91429L3.74234 9.29297C3.35182 9.68349 3.35182 10.3167 3.74234 10.7072L8.12102 15.0859C8.51154 15.4764 8.51154 16.1096 8.12102 16.5001C7.7305 16.8906 7.09733 16.8906 6.70681 16.5001L0.913912 10.7072C0.523388 10.3167 0.523388 9.68349 0.913912 9.29297L6.7068 3.50008Z"
        fill="#5295E0"
      />
    </svg>
  );
};
