import React, { useEffect, useState } from "react";
import OptimisationPageWrapper from "../../components/OptimisationPageWrapper";
import { videoCardMock } from "./mock";
import ButtonsSection from "../../components/OptimisationInfoSection/ButtonSection";
import { VisibleDescriptions } from "../../components/OptimisationInfoSection/types";
import InfoSection from "../../components/OptimisationInfoSection";
import VideoSection from "./components/VideoSection";
import VideoParams from "./components/VideoParams";
import styles from "./styles.module.scss";
import { ProductMaterials } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductsStore } from "src/store/product.state";

const VideoOptimisation: React.FC = () => {
  const [isHypothesisVisible, setIsHypothesisVisible] = useState(false);

  const [visibleDescriptions, setVisibleDescriptions] =
    useState<VisibleDescriptions>({
      description: false,
      critique: false,
      hypothesis: false,
      howToTest: false,
      percentageImpact: false,
    });

  const toggleHypothesisVisibility = () => {
    setIsHypothesisVisible((prevState) => !prevState);
  };

  const toggleVisibility = (field: keyof VisibleDescriptions) => {
    setVisibleDescriptions((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const { selectedProduct } = useProductStore();
  const { productMaterials, setProductMaterials } = useProductsStore();

  useEffect(() => {
    const fetchProductMaterialsByAsin = async () => {
      if (!selectedProduct) return;

      try {
        const [imagesData, videosData] = await Promise.all([
          AmazonService.products.getProductDataByAsinImgs([
            selectedProduct.asin,
          ]),
          AmazonService.products.getProductDataByAsinVideos([
            selectedProduct.asin,
          ]),
        ]);

        setProductMaterials({
          images: imagesData as any,
          videos: videosData as any,
        });
      } catch (error) {
        console.error("Error fetching product materials by ASIN:", error);
      }
    };

    fetchProductMaterialsByAsin();
  }, [selectedProduct]);

  return (
    <OptimisationPageWrapper>
      <div className={styles.container}>
        <VideoSection video={productMaterials.videos} />
        <VideoParams asin={selectedProduct.asin} />
      </div>
      {isHypothesisVisible && (
        <InfoSection
          type="video"
          description={videoCardMock.videoDescription}
          critique={videoCardMock.videoCritique}
          hypothesis={videoCardMock.hypothesis}
          howToTest={videoCardMock.howToTest}
          percentageImpact={videoCardMock.percentageImpact}
          visibleDescriptions={visibleDescriptions}
          toggleVisibility={toggleVisibility}
        />
      )}
      {/* <ButtonsSection
        type="video"
        toggleHypothesisVisibility={toggleHypothesisVisibility}
        isHypothesisVisible={isHypothesisVisible}
      /> */}
    </OptimisationPageWrapper>
  );
};

export default VideoOptimisation;
