import "./AmazonOnboardingComplete.css";
import Logo from "@assets/img/logo.tsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes.ts";
import CustomCheckIcon from "@pages/AmazonOnboardingComplete/CustomCheckIcon.tsx";
import { LS } from "src/constants/localStorage.constants.ts";
import { Button } from "@components/ui-kit/Button/Button.tsx";

export const AmazonOnboardingComplete = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onContinue = () => {
    localStorage.removeItem(LS.AuthProvider);
    localStorage.removeItem(LS.AmazonOnboardingStatus);
    navigate(ROUTES.ROOT);
  };

  return (
    <div className="amazon-onboarding-complete__wrapper">
      <div className="amazon-onboarding-complete">
        <Logo />

        <div className="amazon-onboarding-complete__icon">
          <CustomCheckIcon />
        </div>

        <h1 className="amazon-onboarding-complete__title heading-1">
          {t("label.all_set")}
        </h1>
        <h2 className="amazon-onboarding-complete__subtitle heading-2">
          {t("label.your_account_is_now_connected")}
        </h2>
        <div className="amazon-onboarding-complete__actions">
          <Button onClick={onContinue}>{t("action.go_to_dashboard")}</Button>
        </div>
      </div>
    </div>
  );
};
