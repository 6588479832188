import { useState } from "react";
import classNames from "classnames";
import ChevronDown from "@assets/icons/chevron-down.tsx";
import { IUserState, useUserStore } from "src/store/user.state.ts";
import { Skeleton } from "@components/ui-kit/Skeleton";
import { UserPopup } from "./UserPopup/UserPopup";
import { useOutsideClick } from "src/utils/useOutsideClick";

export const HeaderAvatar = () => {
  const user = useUserStore((state: IUserState) => state.user);
  const userLoaded = useUserStore((state: IUserState) => state.userLoaded);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const popupRef = useOutsideClick(() => setMenuOpen(false));

  const closeMenu = () => {
    setMenuOpen(false);
  };

  if (!userLoaded) {
    return (
      <div className="app-header__avatar">
        <Skeleton width="160px" height="40px" shape="round" />
      </div>
    );
  }

  const initials = `${user.first_name?.[0] ?? ""}${user.last_name?.[0] ?? ""}`;

  return (
    <div
      className={classNames("app-header__avatar", { "active-menu": menuOpen })}
      ref={popupRef}
    >
      <div className={classNames("nyle-avatar", { "active-avatar": menuOpen })}>
        <button
          className="nyle-avatar__image text-large"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {initials}
        </button>
        <button
          className="nyle-avatar__details"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div
            className={classNames("nyle-avatar__details-text body-large", {
              "active-name": menuOpen,
            })}
          >
            {user.first_name}
          </div>
          <div
            className={classNames("nyle-avatar__details-subtext body-small", {
              "active-cto": menuOpen,
            })}
          >
            CEO
          </div>
        </button>
        <button
          className="nyle-avatar__icon"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <ChevronDown />
        </button>
        {menuOpen && <UserPopup closeMenu={closeMenu} />}
      </div>
    </div>
  );
};
