import { Select } from "antd";
import { ADS_ANALITICS_ROUTES } from "src/router/routes";

export const { Option } = Select;

export const formatOptionLabel = ({ label, value }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      fontSize: "16px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
  >
    <img
      src={`https://flagcdn.com/w40/${value.toLowerCase()}.png`}
      alt=""
      style={{ marginRight: 10, width: 24, height: 18 }}
    />
    {label}
  </div>
);

export const getDefaultCountry = (options) => {
  return options.find((option) => option.label === "Canada");
};

export const Routes = [
  {
    title: "Portfolios",
    link: ADS_ANALITICS_ROUTES.PORTFOLIO,
  },
  {
    title: "Campaigns",
    link: ADS_ANALITICS_ROUTES.CAMPAIGNS,
  },
  {
    title: "Products",
    link: ADS_ANALITICS_ROUTES.PRODUCTS,
  },
  {
    title: "Sankey",
    link: ADS_ANALITICS_ROUTES.SANKEY,
  },
  {
    title: "Heatmap",
    link: ADS_ANALITICS_ROUTES.HEATMAP,
  },
  {
    title: "Placement",
    link: ADS_ANALITICS_ROUTES.PLACEMENT,
  },
];
