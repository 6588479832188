import React from "react";
import styles from "./style.module.scss";
import SearchIcon from "@assets/icons/SearchIcon.svg";

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className={styles.searchBox}>
      <input
        type="text"
        placeholder="Search by product name or ASIN"
        className={styles.searchInput}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button className={styles.searchButton}>
        <img src={SearchIcon} alt="Search" />
      </button>
    </div>
  );
};

export default SearchBar;
