import { Link } from "react-router-dom";
import "./index.css";
import { ROUTES } from "src/router/routes.ts";
import Logo from "@assets/img/logo.tsx";
import { HeaderNav } from "@components/AppHeader/HeaderNav.tsx";
import { HeaderActions } from "@components/AppHeader/HeaderActions.tsx";
import { HeaderAvatar } from "@components/AppHeader/HeaderAvatar";

export const AppHeader = () => {
  return (
    <header className="app-header">
      <div className="app-header__inner">
        <Link to={ROUTES.ROOT} className="app-header__logo-link">
          <Logo />
        </Link>
        <HeaderNav />
        <div className="app-header__right">
          <HeaderActions />
          <HeaderAvatar />
        </div>
      </div>
    </header>
  );
};
