import { ExpandableTable } from "@components/ExpandebleTable";
import { useState } from "react";
import styles from "./styles.module.scss";
import { TabsTypeList } from "./Tabs";
import ProductInput from "@components/ProductInput";
import { getColumns, getData } from "./utils";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { campaignsData, performanceData, targetData } from "./mock";

export const WhatIfTable = () => {
  const { keys, setKeys, sankeyTab, setSankeyTab } = useAdsAnaliticsStore();
  const [selectedCampaignsKeys, setSelectedCampaignsKeys] = useState([]);
  const [selectedPerformanceKeys, setSelectedPerformanceKeys] = useState([]);
  const [selectedTargetingKeys, setSelectedTargetingKeys] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    let data;
    let colName;
    switch (sankeyTab) {
      case "Campaign":
        data = campaignsData;
        colName = "campaign";
        break;
      case "Performance":
        data = performanceData;
        colName = "performance";
        break;
      case "Targeting":
        data = targetData;
        colName = "targeting";
        break;
    }

    const flattenedArray = data.flatMap((item) => [
      item,
      ...(item.children || []),
    ]);

    const keysNames = flattenedArray
      .filter((item) => {
        return selectedRowKeys.includes(item.key) && item.key.includes("-");
      })
      .map((item) => item[colName]);

    setKeys({ ...keys, [colName]: keysNames });

    switch (sankeyTab) {
      case "Campaign":
        setSelectedCampaignsKeys(selectedRowKeys);
        break;
      case "Performance":
        setSelectedPerformanceKeys(selectedRowKeys);
        break;
      default:
        setSelectedTargetingKeys(selectedRowKeys);
    }
  };

  console.log(keys);

  const rowSelection = {
    selectedRowKeys: (() => {
      switch (sankeyTab) {
        case "Campaign":
          return selectedCampaignsKeys;
        case "Performance":
          return selectedPerformanceKeys;
        default:
          return selectedTargetingKeys;
      }
    })(),
    onChange: onSelectChange,
  };

  return (
    <div className={styles.container}>
      <ProductInput label="Product" />
      <TabsTypeList selectedTab={sankeyTab} setSelectedTab={setSankeyTab} />
      <ExpandableTable
        withCustomScroll
        columns={getColumns(sankeyTab)}
        data={getData(sankeyTab)}
        rowSelection={rowSelection}
      />
    </div>
  );
};
