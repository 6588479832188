import Logo from "@assets/img/logo.tsx";
import { Link } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import CheckIcon from "@assets/icons/check-circle-blue.svg";
import "./styles.scss";

const CongratulationConnect = () => {
  return (
    <>
      <Link to={ROUTES.ROOT} className="logo">
        <Logo />
      </Link>

      <section className="section section--center s-congratulation-connect">
        <div className="ml-auto mr-auto" style={{ maxWidth: 570 }}>
          <div className="s-congratulation-connect__icon">
            <img src={CheckIcon} alt="" />
          </div>
          <h1 className="h1 s-congratulation-connect__title">
            Congratulations, you’re all set!
          </h1>
          <h3 className="h3 s-congratulation-connect__subtitle">
            Your account is now connected
          </h3>
          <Link to={ROUTES.ROOT} className="button">
            Go to dashboard <AuthButtonArrow />
          </Link>
        </div>
      </section>
    </>
  );
};

export default CongratulationConnect;
