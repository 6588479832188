export interface Checks {
  impressions: boolean;
  CVR: boolean;
  sales: boolean;
  adCTR: boolean;
  adOrders: boolean;
  adSpend: boolean;
  organicClicks: boolean;
  organicUnitsSold: boolean;
  subscriptionSales: boolean;
  CTR: boolean;
  orders: boolean;
  spend: boolean;
  adClicks: boolean;
  adUnitsSold: boolean;
  organicImpressions: boolean;
  organicCVR: boolean;
  organicSales: boolean;
  ntbSales: boolean;
  clicks: boolean;
  unitSolds: boolean;
  adImpressions: boolean;
  adCVR: boolean;
  adSales: boolean;
  organicCTR: boolean;
  organicOrders: boolean;
  organicSpend: boolean;
  contributionProfit: boolean;
}

export const initialChecks: Checks = {
  impressions: false,
  CVR: false,
  sales: true,
  adCTR: true,
  adOrders: false,
  adSpend: true,
  organicClicks: false,
  organicUnitsSold: false,
  subscriptionSales: false,
  CTR: false,
  orders: false,
  spend: false,
  adClicks: true,
  adUnitsSold: true,
  organicImpressions: false,
  organicCVR: false,
  organicSales: false,
  ntbSales: false,
  clicks: false,
  unitSolds: false,
  adImpressions: true,
  adCVR: false,
  adSales: false,
  organicCTR: false,
  organicOrders: false,
  organicSpend: false,
  contributionProfit: false,
};
