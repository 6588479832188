import { OptimisationItem } from "../OptimisationItem";
import info from "@assets/icons/i.svg";
import styles from "./styles.module.scss";
import CustomInput from "@components/СustomInput";
import { useState } from "react";
import classNames from "classnames";

const BrandTitle = () => (
  <div className={styles.title}>
    Brand tailored promotions
    <img src={info} alt="" />
  </div>
);

export const PriceOptimisation = ({ isWhatIf }) => {
  const [discount, setDiscount] = useState(18);
  const [subscribeDiscount, setSubscribeDiscount] = useState(0);
  const [coupon, setCoupon] = useState(0);
  const [isCouponPercent, setIsCouponPercent] = useState(true);
  const [saveCoupon, setSaveCoupon] = useState(0);
  const [isSaveCouponPercent, setIsSaveCouponPercent] = useState(true);

  const handleDiscountChange = (event) => {
    setDiscount(event.target.value);
  };

  const handleSubscribeDiscountChange = (event) => {
    setSubscribeDiscount(event.target.value);
  };

  const handleCouponChange = (event) => {
    setCoupon(event.target.value);
  };

  const handleSaveCouponChange = (event) => {
    setSaveCoupon(event.target.value);
  };
  return (
    <OptimisationItem title="Price optimization">
      <div className={styles.container}>
        {!isWhatIf ? (
          <>
            <div className={styles.card}>
              <div className={styles.card__title}>
                <span>Discount</span>
              </div>
              <div className={styles.card__value}>
                <span>{discount}%</span>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.card__title}>
                <span>Coupon</span>
              </div>
              <div className={styles.card__value}>
                <span>
                  {coupon}
                  {isCouponPercent ? "%" : "$"}
                </span>
                <div className={styles.buttons}>
                  <button
                    onClick={() => {
                      setIsCouponPercent(true);
                    }}
                    className={classNames(styles.button, {
                      [styles.active]: isCouponPercent,
                    })}
                  >
                    %
                  </button>
                  <button
                    onClick={() => {
                      setIsCouponPercent(false);
                    }}
                    className={classNames(styles.button, {
                      [styles.active]: !isCouponPercent,
                    })}
                  >
                    $
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.card__title}>
                <span>"Subscribe and Save" Discount</span>
              </div>
              <div className={styles.card__value}>
                <span>{subscribeDiscount}%</span>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.card__title}>
                <span>"Subscribe and Save" Coupon</span>
              </div>
              <div className={styles.card__value}>
                <span>
                  {saveCoupon}
                  {isSaveCouponPercent ? "%" : "$"}
                </span>
                <div className={styles.buttons}>
                  <button
                    onClick={() => {
                      setIsSaveCouponPercent(true);
                    }}
                    className={classNames(styles.button, {
                      [styles.active]: isSaveCouponPercent,
                    })}
                  >
                    %
                  </button>
                  <button
                    onClick={() => {
                      setIsSaveCouponPercent(false);
                    }}
                    className={classNames(styles.button, {
                      [styles.active]: !isSaveCouponPercent,
                    })}
                  >
                    $
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.card}>
              <CustomInput
                label="Discount , %"
                value={discount}
                onChange={handleDiscountChange}
                className={styles.input}
                blue
              />
            </div>
            <div className={styles.card}>
              <CustomInput
                label="Coupon"
                value={coupon}
                onChange={handleCouponChange}
                className={styles.input}
                select={["%", "$"]}
                activeSelectIndex={isCouponPercent ? 0 : 1}
                onSelectChange={(id: number) => {
                  setIsCouponPercent(id === 0);
                }}
                blue
              />
            </div>
            <div className={styles.card}>
              <CustomInput
                label='"Subscribe and Save" Discount'
                value={subscribeDiscount}
                onChange={handleSubscribeDiscountChange}
                className={styles.input}
                possibleValues={[0, 5, 10]}
                blue
              />
            </div>
            <div className={styles.card}>
              <CustomInput
                label='"Subscribe and Save" Coupon'
                value={saveCoupon}
                onChange={handleSaveCouponChange}
                className={styles.input}
                select={["%", "$"]}
                activeSelectIndex={isSaveCouponPercent ? 0 : 1}
                onSelectChange={(id: number) => {
                  setIsSaveCouponPercent(id === 0);
                }}
                blue
              />
            </div>
            <OptimisationItem title={<BrandTitle />}>
              Brand tailored promotions
            </OptimisationItem>
          </>
        )}
      </div>
    </OptimisationItem>
  );
};
