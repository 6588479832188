export default () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7832 2.35938H10.2995V0.359375H15H16V1.35938V6.05982H14V3.97104L9.82572 8.14531L9.11861 8.85241L8.41151 8.14531L5.80909 5.54289L1.71305 9.63896L0.298828 8.22476L5.10198 3.42157L5.80909 2.71446L6.5162 3.42157L9.11861 6.02399L12.7832 2.35938Z"
        fill="#5295E0"
      />
    </svg>
  );
};
