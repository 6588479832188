import axios from "axios";

const BASE_URL = "/api/v1";

const getAllEntities = (entity) => {
  return axios.get(`${BASE_URL}/${entity}`).then((response) => response.data);
};

const getEntityDetails = (entity, id) => {
  return axios
    .get(`${BASE_URL}/${entity}/${id}`)
    .then((response) => response.data);
};

const createNewEntity = (entity, data) => {
  return axios
    .post(`${BASE_URL}/${entity}`, data)
    .then((response) => response.data);
};

const updateEntity = (entity, id, data) => {
  return axios
    .put(`${BASE_URL}/${entity}/${id}`, data)
    .then((response) => response.data);
};

const deleteEntity = (entity, id) => {
  return axios
    .delete(`${BASE_URL}/${entity}/${id}`)
    .then((response) => response.data);
};

export const CRUDService = {
  updateEntity,
  deleteEntity,
  getAllEntities,
  createNewEntity,
  getEntityDetails,
};
