import classNames from "classnames";
import styles from "./styles.module.scss";
import { CSSProperties } from "react";

export type CheckWidgetData = {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  color: string;
};

interface CheckWidgetProps {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  isChecked: boolean;
  onChange: (value: string) => void;
  color: string;
}

interface CustomCSSProperties extends CSSProperties {
  "--checkbox-color"?: string;
}

const hexToRgba = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, 0.1)`;
};

export const getAdsLabel = (title: string) => {
  switch (title.toLowerCase()) {
    case "cpc":
      return null;
    case "acos":
      return null;
    case "roas":
      return null;
    default:
      return "Ad ";
  }
};

export const CheckWidget = ({
  title,
  value,
  increase,
  increaseDirection,
  isChecked,
  onChange,
  color,
}: CheckWidgetProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.header}>
          <label
            className={classNames(
              styles.checkbox,
              isChecked ? styles.checked : "",
            )}
            style={
              {
                "--checkmark-color": isChecked ? color : "transparent",
              } as CustomCSSProperties
            }
          >
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                if (title === "Orders") {
                  return;
                }
                onChange(title);
              }}
            />
            <span></span>
          </label>
          <h3>
            {getAdsLabel(title)}
            {title}
          </h3>
          <div
            className={classNames(
              styles.increase,
              increaseDirection === "up" ? styles.up : styles.down,
            )}
          >
            {increase}
          </div>
        </div>
        <div className={styles.value}>
          <span>{value}</span>
        </div>
      </div>
    </div>
  );
};
