import React from "react";
import styles from "./styles.module.scss";

interface ProductHeaderTitleProps {
  title: string;
}

const ProductHeaderTitle: React.FC<ProductHeaderTitleProps> = ({ title }) => {
  return <h4 className={styles.title}>{title}</h4>;
};

export default ProductHeaderTitle;
