import yourProductImage from "@assets/img/product1.png";
import competitorProductImage from "@assets/img/product2.png";

export const mockData = [
  {
    type: "Your product",
    name: "Zephyr Presrv 24 Inch Wine Fridge & Beverage Refrigerator Dual Zone Und...",
    price: "16.95",
    rating: 3.0,
    reviews: {
      "5_star": 38,
      "4_star": 7,
      "3_star": 7,
      "2_star": 8,
      "1_star": 39,
    },
    image: yourProductImage,
    code: "BO0CHTZLQU",
  },
  {
    type: "Competitor",
    name: "Schmecké 12 Bottle Compressor Wine Cooler Refrigerator w/Lock - Large...",
    price: "29.95",
    rating: 4.0,
    reviews: {
      "5_star": 46,
      "4_star": 20,
      "3_star": 12,
      "2_star": 5,
      "1_star": 17,
    },
    image: competitorProductImage,
    code: "BOBSLYB69D",
  },
];

export const mockDataMultySelect = [
  {
    type: "Your product",
    name: "Zephyr Presrv 24 Inch Wine Fridge & Beverage Refrigerator Dual Zone Und...",
    price: "16.95",
    rating: 3.0,
    reviews: {
      "5_star": 38,
      "4_star": 7,
      "3_star": 7,
      "2_star": 8,
      "1_star": 39,
    },
    image: yourProductImage,
    code: "BO0CHTZLQU",
  },
];
