import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { RadarChart } from "@components/Spider/RadarChart";
import { datasets, headerTexts } from "./mock";
import SwiperNavigation from "@components/ProductSwiper";
import DynamicComparisonBlock from "./ComparisonBlock";
import ProblemSolution from "./ProblemSolution";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";
import { GraphsService } from "@services/amazon/graph/graph.service";
import { AmazonService } from "@services/amazon/amazon.service";
import { useInsightsStore } from "src/store/insights/insights.state";

export interface ProductComparisonProps {
  productOneTitles: string[];
  productTwoTitles: string[];
  productOneValues: number[];
  productTwoValues: number[];
}

const ProductComparison: React.FC<ProductComparisonProps> = ({
  productOneTitles,
  productTwoTitles,
  productOneValues,
  productTwoValues,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { selectedProduct, selectedProductToCompare } = useProductStore();
  const [spiderDots, setSpiderDots] = useState([]);
  const [problems, setProblems] = useState([]);
  const [activeProblemIndex, setActiveProblemIndex] = useState(0);
  const [potentialSalesIncrease, setPotentialSalesIncrease] = useState(null);

  const handleNext = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex < headerTexts.length - 1 ? prevIndex + 1 : 0;
      setActiveProblemIndex(newIndex);
      return newIndex;
    });
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : headerTexts.length - 1;
      setActiveProblemIndex(newIndex);
      return newIndex;
    });
  };

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  };

  const handlePredictionInsightClick = () => {
    if (!isDragEnabled) {
      setIsTooltipVisible(true);
    } else {
      setIsTooltipVisible(false);
    }
    setIsDragEnabled(!isDragEnabled);
  };

  const dynamicRatingProduct1 = productOneValues?.[activeIndex];
  const dynamicRatingProduct2 = productTwoValues?.[activeIndex];
  const difference = (dynamicRatingProduct1 - dynamicRatingProduct2).toFixed(1);
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  const [productOneData, setProductOneData] = useState(productOneValues);
  const [productTwoData, setProductTwoData] = useState(productTwoValues);

  const productOneTitleText = productOneTitles?.[activeIndex];
  const productTwoTitleText = productTwoTitles?.[activeIndex];
  const combinedHeaderText =
    productOneTitleText && productTwoTitleText
      ? productOneTitleText === productTwoTitleText
        ? productOneTitleText
        : `${productOneTitleText}, ${productTwoTitleText}`
      : productOneTitleText || productTwoTitleText;

  const dataValue = [{ productOneValues }, { productTwoValues }];
  const getDateRange = () => {
    const today = new Date();
    const date_end = today.toISOString().split("T")[0];

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 8);
    const date_start = oneMonthAgo.toISOString().split("T")[0];

    return { date_start, date_end };
  };

  const handleProductSelect = async () => {
    const spider = new SpiderService();
    const spiderDotsResponse = await spider.getProfuctInfo(
      selectedProduct.asin,
    );

    setSpiderDots(Array.isArray(spiderDotsResponse) ? spiderDotsResponse : []);
    setProblems(Array.isArray(spiderDotsResponse) ? spiderDotsResponse : []);
  };

  useEffect(() => {
    handleProductSelect();
  }, []);

  const handleHight = async () => {
    const spider = new SpiderService();

    const allAsin = Array.isArray(selectedProductToCompare[0])
      ? selectedProductToCompare.flatMap((innerArray) =>
          Array.isArray(innerArray)
            ? innerArray.map((product) => product.asin)
            : [],
        )
      : selectedProductToCompare.map((product) => product.asin).filter(Boolean);

    const mainAsin = selectedProduct?.asin;
    try {
      const differencePotentialHeight = await spider.getPotentialHeight(
        allAsin,
        mainAsin,
      );
      const roundedSalesIncrease = Math.round(
        differencePotentialHeight.total_potential_sales_increase ?? 0,
      );
      setPotentialSalesIncrease(roundedSalesIncrease);
    } catch (error) {
      console.error("Error fetching potential sales increase:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct && selectedProductToCompare) {
      handleHight();
    }
  }, [selectedProduct, selectedProductToCompare]);
  const handlePotentialIncreaseOverallScor = async () => {
    try {
      const spiderService = new SpiderService();
      const mainAsin = selectedProduct.asin;

      const productData = {};
      productOneTitles.forEach((title, index) => {
        const key = title.toLowerCase().replace(/\s+/g, "");
        productData[key] = productOneData[index];
      });

      const spiderData = productData;
      const response = await spiderService.postPotentialIncreaseOverallScore(
        mainAsin,
        spiderData,
      );
    } catch (error) {
      console.error("Error in handleProd:", error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handlePotentialIncreaseOverallScor();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [productOneData]);

  const handleUpdate = (newValues) => {
    setProductOneData(newValues);
  };
  return (
    <div className={styles.container}>
      <div className={styles.headContent}>
        <div className={styles.swiperBox}>
          <SwiperNavigation
            headerText={combinedHeaderText}
            onNext={handleNext}
            onPrev={handlePrev}
            showText={true}
            improvementValue={difference}
          />
        </div>

        <DynamicComparisonBlock
          productScore={dynamicRatingProduct1}
          competitorScore={dynamicRatingProduct2}
          difference={difference}
          potentialSalesIncrease={potentialSalesIncrease}
        />
      </div>

      <div className={styles.comparison}>
        <ProblemSolution
          problems={spiderDots}
          onPredictionInsightClick={handlePredictionInsightClick}
          activeProblemIndex={activeProblemIndex}
        />
        <div className={styles.radarBox}>
          <RadarChart
            productOneTitles={productOneTitles}
            productTwoTitles={productTwoTitles}
            productOneValues={productOneData}
            productTwoValues={productTwoData}
            data={dataValue}
            datasets={datasets}
            activeIndex={activeIndex}
            onDotClick={handleDotClick}
            isDragEnabled={isDragEnabled}
            showTooltipWithArrow={isTooltipVisible}
            showTooltip={true}
            blockChanging={false}
            update={handleUpdate}
            width={550}
            height={500}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductComparison;
