import instVideo from "@assets/video/templates/instagram-ideas.mp4";

export const TEMPLATES_LIST = [
  {
    id: "product-listing",
    descriptionResult:
      "Get your product description automatically generated with Nyle's AI Language Generation to create a written content for you.",
  },
  {
    id: "promotion-ad",
    descriptionResult:
      "Get the output of high-converting sales text to use it for promoting your products and services to your target audience",
  },
  {
    id: "blog-post",
    descriptionResult:
      "Get the summaries of articles generated and select the most relevant one to then have a full article get created, which is going to complete the ideas described in the summary.",
  },
  {
    id: "blog-post-summaries",
    descriptionResult: "",
  },
  {
    id: "rewriting",
    descriptionResult:
      "Get your original text automatically rewritten (5 Examples) while maintaining the same meaning with the help of Nyle's Artificial Intelligence technology",
  },
  {
    id: "grammar-correction",
    descriptionResult:
      "Get your original text automatically grammatically corrected with the help of Nyle's Artificial Intelligence technology",
  },
  {
    id: "brand-names",
    descriptionResult:
      "Get the output of high-converting sales text to use it for promoting your products and services to your target audience",
  },
  {
    id: "business-insert-card",
    descriptionResult:
      "Insert a short description of your product using tags or upload an image and it will automatically identify the necessary tags.",
  },
  // {
  //   id: 'frameworks',
  //   descriptionResult: "",
  // },
  {
    id: "instagram-ideas",
    descriptionResult:
      "Add a caption to your social media images to get more engagement with your audience and keep their attention",
    videosrc: instVideo,
  },
  {
    id: "instagram-post",
    descriptionResult: "",
  },
  {
    id: "facebook-ideas",
    descriptionResult: "",
  },
  {
    id: "facebook-post",
    descriptionResult: "",
  },
  {
    id: "product-review",
    descriptionResult: "",
  },
  {
    id: "email",
    descriptionResult: "",
  },
  {
    id: "cold-email",
    descriptionResult:
      "Quickly generate new cold email templates to engage with your clients and offer your products and services with highest response rate",
  },
  {
    id: "text-summary",
    descriptionResult: "",
  },
  {
    id: "sales-text-aeo",
    descriptionResult:
      "Get your text of sales promotion using AEO (Address-Pain-Excite-Offer Solution) framework automatically generated with Nyle’s Artificial Intelligence Natural Language Generation (NLG) technology",
  },
  {
    id: "sales-text-aida",
    descriptionResult:
      "Get your text of sales promotion using FAB (Attention-Interest-Desire-Action) framework automatically generated with Nyle’s Artificial Intelligence Natural Language Generation (NLG) technology",
  },
  {
    id: "sales-text-bab",
    descriptionResult:
      "Get your text of sales promotion using FAB (Before-After-Bridge) framework automatically generated with Nyle’s Artificial Intelligence Natural Language Generation (NLG) technology",
  },
  {
    id: "sales-text-fab",
    descriptionResult:
      "Get your text of sales promotion using FAB (Feature-Advantage-Benefit) framework automatically generated with Nyle’s Artificial Intelligence Natural Language Generation (NLG) technology",
  },
  {
    id: "sales-text-pppp",
    descriptionResult:
      "Get your text of sales promotion using PPPP (Problem-Promise-Proof-Proposal) framework automatically generated with Nyle’s Artificial Intelligence Natural Language Generation (NLG) technology",
  },
  {
    id: "sentence-audience-adjustment",
    descriptionResult: "",
  },
  {
    id: "sentence-length-adjustment",
    descriptionResult: "",
  },
  {
    id: "tonality-voice-adjustment",
    descriptionResult: "",
  },
  {
    id: "tagline",
    descriptionResult: "",
  },
  {
    id: "cold-email-approaching-suppliers",
    descriptionResult: "",
  },
  {
    id: "video-script",
    descriptionResult: "",
  },
];

export const MAPPED_TEMPLATES_LIST = TEMPLATES_LIST.reduce(
  (acc, template) => ({ ...acc, [template.id]: template }),
  {},
);
