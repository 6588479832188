import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import SwiperNavigation from "@components/ProductSwiper";
import { RadarChart } from "@components/Spider/RadarChart";
import { useInsightsStore } from "src/store/insights/insights.state";
import { setRadarData } from "src/utils/setRadarData";
import { OptimisationItem } from "../OptimisationItem";
import DynamicComparisonBlock from "@pages/CROModule/components/CROPage/components/Sections/AspectOptimisation/ComparisonBlock";
import { Select } from "@components/Select";
import { asinMock } from "@pages/AdsAnalitics/components/SanKey/mock";

export const AspectOptimization = ({ isWhatIf }) => {
  const { productOne } = useInsightsStore();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [currentDataSet, setCurrentDataSet] = useState(
    setRadarData(productOne.spiderValues),
  );
  const [product, setProduct] = useState([]);
  const [_, forceUpdate] = useState(false);
  const [isNewData, setIsNewData] = useState(false);

  useEffect(() => {
    handleReset();
  }, [productOne.spiderValues]);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < productOne.spiderTitles.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : productOne.spiderTitles.length - 1,
    );
  };

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  };

  const handleReset = () => {
    setCurrentDataSet(setRadarData(productOne.spiderValues));
    setIsNewData(false);
    setActiveIndex(0);
  };

  const emptyArray = useMemo(() => [], []);
  const [yourSet, competitorsSet] = currentDataSet;
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  return (
    <OptimisationItem title="Aspect optimization">
      <div className={styles.container}>
        <div className={styles.top}></div>
        <div className={styles.radarBox}>
          <RadarChart
            datasets={currentDataSet}
            activeIndex={activeIndex}
            onDotClick={handleDotClick}
            isDragEnabled={true}
            showTooltipWithArrow={isTooltipVisible}
            blockChanging={false}
            width={316}
            height={316}
            labelFontSize={11}
            update={() => {
              if (!isWhatIf) return;
              setIsNewData(true);
              forceUpdate((p) => !p);
            }}
            productOneValues={emptyArray}
            productTwoValues={emptyArray}
            productOneTitles={productOne.spiderTitles}
            productTwoTitles={productOne.spiderTitles}
          />
        </div>
        <div className={styles.headContent}>
          <div className={styles.select__container}>
            <h3 className={styles.select__title}>Competitors</h3>
            <Select
              value={product}
              placeholder="Top 5 performers"
              options={asinMock}
              onChange={(value: string[]) => {
                setProduct(value);
              }}
              className={styles.select}
              isMultiSelect
            />
          </div>
          <div className={styles.comparation}>
            {isWhatIf && (
              <SwiperNavigation
                headerText={"Capacity"}
                onNext={handleNext}
                onPrev={handlePrev}
                showText={true}
                improvementValue={improvementValue}
              />
            )}
            <DynamicComparisonBlock
              productScore={yourSet?.data[activeIndex] ?? 0}
              competitorScore={competitorsSet?.data[activeIndex] ?? 0}
              competitorsCount={product.length}
            />
          </div>
        </div>
      </div>
    </OptimisationItem>
  );
};
