import classNames from "classnames";
import styles from "./styles.module.scss";

export type TypeTab = "Missing targets" | "Current target";

const typeTabs: TypeTab[] = ["Missing targets", "Current target"];

export const TabButton = ({
  label,
  isSelected,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: React.ElementType;
}) => (
  <button
    className={classNames(styles.tab, isSelected && styles.active)}
    onClick={onClick}
  >
    {label}
  </button>
);

export const TabsTypeList = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: TypeTab;
  setSelectedTab: (tab: TypeTab) => void;
}) => {
  return (
    <div className={styles.tabs}>
      {typeTabs.map((tab) => (
        <TabButton
          key={tab}
          label={tab}
          isSelected={selectedTab === tab}
          onClick={() => setSelectedTab(tab)}
        />
      ))}
    </div>
  );
};
