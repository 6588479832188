import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { Review } from "src/store/insights/types";

export class ReviewsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getOwnProducts(
    asin: string,
    offset: number = 0,
    limit: number = 10,
  ): Promise<[Review]> {
    return this.get("/reviews", { offset, limit, asin });
  }
}
