import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface CustomCheckboxProps {
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  lightCheckbox?: boolean;
  blueBorder?: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  lightCheckbox = false,
  blueBorder = false,
}) => {
  return (
    <label
      className={classNames(styles.checkboxContainer, {
        [styles.lightCheckbox]: lightCheckbox,
        [styles.blueBorder]: blueBorder,
      })}
    >
      <input
        type="checkbox"
        checked={checked}
        className={styles.checkbox}
        onChange={onChange}
      />
      <span className={styles.checkmark}></span>
    </label>
  );
};

export default CustomCheckbox;
