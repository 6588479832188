import * as XLSX from "xlsx";
import jsPDF from "jspdf";

export const exportAsCSV = (title: string, data: Record<any, any>) => {
  const dataList = Object.entries(data).reduce(
    (acc, [key, value]) => [
      ...acc,
      {
        Title: key,
        Value: value,
      },
    ],
    [],
  );

  // perform sheet/file title/name
  const fileName = `${title}.csv`;

  // create excel
  const workSheet = XLSX.utils.json_to_sheet(dataList);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, title);

  // export
  XLSX.writeFile(workBook, fileName);
};

export const exportAsPDF = (title: string, data: Record<any, any>) => {
  // perform pdf content
  const pdf = new jsPDF();

  const textH = 7;
  const titleH = 9;
  const maxWith = 240;
  const maxHeight = 300;
  const mgBot = 10;
  const pdTop = 20;
  const pdLeft = 10;
  let y = pdTop;

  Object.entries(data).forEach(([key, value]: [string, string]) => {
    // check break page requirement
    if (y + titleH > maxHeight) {
      pdf.addPage();
      y = pdTop;
    }

    // append title
    pdf
      .setFontSize(18)
      .setFont(undefined, "normal", "bold")
      .text(key, pdLeft, y);
    y += titleH;

    const splitText = pdf.splitTextToSize(value, maxWith);
    pdf.setFontSize(14).setFont(undefined, "normal", "normal");
    splitText.forEach((text) => {
      // check break page requirement
      if (y + textH > maxHeight) {
        pdf.addPage();
        y = pdTop;
      }

      // append on line of text
      pdf.text(text, pdLeft, y);
      y += textH;
    });

    // add margin bottom to each key-value block
    y += mgBot;
  });

  // naming end export
  pdf.save(`${title}.pdf`);
};

export const exportAsHTML = (title: string, selector: string) => {
  // get content
  const content = window.document.querySelector(selector);

  // create new doc with result contnet
  const doc = window.document.implementation.createHTMLDocument(title);
  doc.body.innerHTML = content.outerHTML;

  // export data
  const result = new XMLSerializer().serializeToString(doc);

  // create blobl
  const htmlContent = [result];
  const blob = new Blob(htmlContent, { type: "text/html" });

  // create link
  const link = window.document.createElement("a");
  link.href = URL.createObjectURL(blob);

  // naming and export
  const filename = title;
  link.download = `${filename}.html`;
  link.click();
};
