import { Progress } from "antd";
import "./quota.less";

type Props = {
  quotaUsed: number;
  quotaMax: number;
};

export const Quota = ({ quotaUsed, quotaMax }: Props) => {
  return (
    <div className="quota">
      <p className="quota__text">
        The word limit used this month:
        <span>
          {" "}
          {quotaUsed} out of {quotaMax} words
        </span>
      </p>
      <Progress
        className="quota__progress"
        percent={(quotaUsed * 100) / Math.max(quotaMax, 1)}
        showInfo={false}
        size="small"
      />
    </div>
  );
};
