export default () => {
  return (
    <svg
      width="17"
      height="27"
      viewBox="0 0 17 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1114 25.7434C3.37012 26.4476 2.20713 26.4476 1.46585 25.7434C0.668745 24.9862 0.668743 23.7157 1.46585 22.9585L9.8963 14.95C10.7264 14.1615 10.7264 12.8385 9.8963 12.05L1.46585 4.04151C0.668743 3.2843 0.668743 2.01377 1.46585 1.25656C2.20713 0.552389 3.37012 0.552387 4.11139 1.25656L15.4735 12.05C16.3036 12.8385 16.3036 14.1615 15.4736 14.95L4.1114 25.7434Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};
