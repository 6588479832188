import cn from "classnames";

import SvgIcon from "@components/svg-icon/SvgIcon";

interface Props {
  isFetching?: boolean;
  className?: string;
}

export const TemplateResultPlaceholder = ({ isFetching, className }: Props) => {
  return (
    <div
      className={cn("result-placeholder", className, {
        "is-loading": isFetching,
      })}
    >
      <div className={"result-placeholder__text"}>
        {isFetching ? (
          <>
            <SvgIcon type="pencil" className="result-placeholder__pencil" />
            <p>The content is being generated</p>
          </>
        ) : (
          <>
            <SvgIcon type="arrow-left" />
            <p>Fill in the fields</p>
          </>
        )}
      </div>
    </div>
  );
};
