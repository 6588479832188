import PageWrapper from "@components/PageWrapper/PageWrapper";
import { CustomersApi } from "@services/customers/customers.api";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "antd";
import { CustomerProfile } from "@entities/CustomerProfile";
import { ExpandableTable } from "@components/ExpandebleTable";
import { useNavigate } from "react-router-dom";

const getColumns = (onSelect: (record: CustomerProfile) => void) => {
  return [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Action",
      key: "action",
      width: 140,
      render: (_: any, record: CustomerProfile) => (
        <Button
          onClick={() => {
            onSelect(record);
          }}
          type="primary"
        >
          Select
        </Button>
      ),
    },
  ];
};

export const Customers = () => {
  const [customers, setCustomers] = useState<CustomerProfile[]>([]);
  const nav = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      const customers = await new CustomersApi().getCustomers();
      setCustomers(customers);
    };

    fetchCustomers();
  }, []);

  const handleSelect = (record: CustomerProfile) => {
    new CustomersApi().selectCustomer(record.uuid).then((tokens) => {
      localStorage.setItem("access_token", tokens.access);
      localStorage.setItem("refresh_token", tokens.refresh);
      nav("/");
    });
  };

  return (
    <PageWrapper className={styles.container}>
      <h1>Customers</h1>
      <div className={styles.table}>
        <ExpandableTable data={customers} columns={getColumns(handleSelect)} />
      </div>
    </PageWrapper>
  );
};
