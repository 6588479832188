import { useTitle } from "@hooks/useTitle";
import { UserCheckboxGroup } from "@components/UserCheckbox/UserCheckboxGroup";
import { Row, Col } from "antd";
import { AddUsers } from "./ui/AddUsers";

const TITLE = "Team";

const OPTIONS = [
  {
    id: "0001",
    username: "Mtimshin",
  },
  {
    id: "0002",
    username: "Olga-piter",
  },
  {
    id: "0003",
    username: "Alex-228",
  },
  {
    id: "0004",
    username:
      "Kirill-mihailov super long name Kirill-mihailov Kirill-mihailov Kirill-mihailov Kirill-mihailov Kirill-mihailov",
    avatarSrc: "https://joeschmoe.io/api/v1/random",
  },
];

export const Team = () => {
  useTitle(`Nyle - ${TITLE}`);

  return (
    <div className="settings-team-page">
      <h2 className="page-main-title">{TITLE}</h2>

      <Row gutter={30} style={{ width: 804, margin: "0 auto" }}>
        <Col className="gutter-row" span={12}>
          <UserCheckboxGroup title="Added users" options={OPTIONS} />
        </Col>
        <Col className="gutter-row" span={12}>
          <AddUsers />
        </Col>
      </Row>
    </div>
  );
};
