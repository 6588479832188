import { MathService } from "@services/math/math.service";

export const fetchWidgets = async (startDate: string, endDate: string) => {
  try {
    const widgetsData = await Promise.all([
      MathService.getAov(startDate, endDate),
      MathService.getSalesTotal(startDate, endDate),
      MathService.getAdsImpressions(startDate, endDate),
      MathService.getAdsClicks(startDate, endDate),
      MathService.getAdsOrders(startDate, endDate),
      MathService.getAdsUnitsSold(startDate, endDate),
      MathService.getAdsSales(startDate, endDate),
      MathService.getAdsSpend(startDate, endDate),
      MathService.getAdsAcos(startDate, endDate),
      MathService.getAdsRoas(startDate, endDate),
      MathService.getAdsCpc(startDate, endDate),
      MathService.getAdsCvr(startDate, endDate),
      MathService.getAdsCtr(startDate, endDate),
      MathService.getOrganicImpressions(startDate, endDate),
      MathService.getOrganicClicks(startDate, endDate),
      MathService.getOrganicCartAdds(startDate, endDate),
      MathService.getOrganicUnitsSold(startDate, endDate),
      MathService.getOrganicCvr(startDate, endDate),
      MathService.getOrganicCtr(startDate, endDate),
      // MathService.getTotalSales(startDate, endDate),
    ]);
    return widgetsData;
  } catch (error) {
    console.error("An error occurred while fetching widgets data:", error);
  }
};
