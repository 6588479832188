const keys = ["COPR", "STRIPS", "NOT", "EXP", "HIGN", "EXACT", "MID", "COOL"];

const info =
  "WEEM Biotin Gummies for Hair, Skin and Nails - Vegan Vitamins for Men & Women, Supports Faster Hair Growth and Stronger Nails - Extra Strength 10,000mcg";

type TooltipData = {
  keys: string[];
  info: string;
};

export const tooltipData: TooltipData = {
  keys,
  info,
};
