import Axios from "axios";

class ResetPasswordService {
  public static async resetPassword(email: string): Promise<any> {
    const { data } = await Axios.post(
      "/api/v1/auth/users/reset_password/",
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return data;
  }
}

export default ResetPasswordService;
