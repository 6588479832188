export const data = [
  {
    date: new Date().getTime() - 5 * 30 * 24 * 3600 * 1000,
    reviewScore: 50,
    positiveReviews: 20,
    negativeReviews: 10,
    neutralReviews: 20,
  },
  {
    date: new Date().getTime() - 4 * 30 * 24 * 3600 * 1000,
    reviewScore: 55,
    positiveReviews: 25,
    negativeReviews: 12,
    neutralReviews: 18,
  },
  {
    date: new Date().getTime() - 3 * 30 * 24 * 3600 * 1000,
    reviewScore: 60,
    positiveReviews: 30,
    negativeReviews: 15,
    neutralReviews: 15,
  },
  {
    date: new Date().getTime() - 2 * 30 * 24 * 3600 * 1000,
    reviewScore: 58,
    positiveReviews: 28,
    negativeReviews: 14,
    neutralReviews: 18,
  },
  {
    date: new Date().getTime() - 30 * 24 * 3600 * 1000,
    reviewScore: 54,
    positiveReviews: 26,
    negativeReviews: 12,
    neutralReviews: 16,
  },
  {
    date: new Date().getTime(),
    reviewScore: 52,
    positiveReviews: 24,
    negativeReviews: 10,
    neutralReviews: 18,
  },
];
