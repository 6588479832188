// @ts-nocheck
import cn from "classnames";
import { Radio, RadioChangeEvent } from "antd";
import type { WidgetProps } from "react-jsonschema-form";

import "./radio-group-widget.less";

type EnumOptions = Array<{ label: string; value: string }>;

export const RadioGroupWidget = (props: WidgetProps) => {
  const onChange = (event: RadioChangeEvent) => {
    props.onChange(event.target.value);
  };

  return (
    <div
      className={cn("radio-group-widget", {
        "radio-group-widget_space": Boolean(
          props.schema.examples && props.schema.examples.length === 2,
        ),
      })}
    >
      <Radio.Group value={props.value} onChange={onChange}>
        {((props?.options?.enumOptions as EnumOptions) ?? []).map(
          ({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ),
        )}
      </Radio.Group>
      {props.schema.examples && props.schema.examples.length === 2 && (
        <div className="radio-group-widget__level-description">
          <span>{props.schema.examples[0]}</span>
          <span>{props.schema.examples[1]}</span>
        </div>
      )}
    </div>
  );
};
