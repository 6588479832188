import { ArrowDown, ArrowUp } from "lucide-react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import classNames from "classnames";

interface MetricCardProps {
  metric: IWidget;
  metricColor?: string;
}

export const getDollarLabel = (name: string) => {
  switch (name) {
    case "Ads Spend":
    case "Ads Sales":
    case "Sales Total":
    case "Ads CPC":
    case "Daily Ad Spend":
    case "Daily Ad Sales":
      return "$ ";
    default:
      "";
  }
};

export const getProcentLabel = (name: string) => {
  switch (name) {
    case "Ads Acos":
    case "Ads CVR":
    case "ACOS":
      return " %";
    default:
      "";
  }
};

export const formatNumber = (value: number): string => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(value);
  return formattedValue;
};

export const formatName = (name: string): string => {
  switch (name) {
    case "Ads Roas":
      return "ROAS";
    case "Ads Acos":
      return "ACOS";
    case "Ads Cvr":
      return "CVR";
    case "Ads CPC":
      return "CPC";
  }
  return name;
};

export const MetricCard = ({ metric, metricColor }: MetricCardProps) => {
  const metricValue = metric.originalValue;
  const maxMetricValue = metricValue * 1.2; // for test only, need change later
  const placeholderValue = maxMetricValue - metricValue;
  let color = metricColor;
  const pieWidth = 190;
  const strokeWidth = 30;

  const profit = metric.difference && metric.difference.startsWith("+");
  if (profit && !color) {
    color = profit ? "#80C67A" : "#FE5858";
  }

  return (
    <div
      className={classNames(
        styles.metricCard,
        !profit && styles.metricCardSmall,
      )}
    >
      <div className={styles.metricHeader}>{formatName(metric.name)}</div>
      <div className={styles.metricBody}>
        <PieChart
          width={pieWidth}
          height={pieWidth / 2}
          className={styles.metricPie}
        >
          <Pie
            data={[{ value: 100 }]} // any value > 0, the value is not important
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={pieWidth / 2 - strokeWidth}
            outerRadius={pieWidth / 2}
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
            fill={"#2E2E2E"}
            stroke="none"
          ></Pie>
          <Pie
            data={[{ value: metricValue }, { value: placeholderValue }]}
            cx="50%"
            cy="90"
            startAngle={180}
            endAngle={0}
            innerRadius={pieWidth / 2 - strokeWidth}
            outerRadius={pieWidth / 2}
            paddingAngle={0}
            dataKey="value"
            cornerRadius={32}
          >
            <Cell fill={color} stroke="none" />
            <Cell fill="none" stroke="none" />
          </Pie>
        </PieChart>
        <div className={styles.metricValuesBox}>
          {metric.difference && (
            <div className={styles.metricChange} style={{ color }}>
              {metric.difference}%
              {profit ? <ArrowUp size={12} /> : <ArrowDown size={12} />}
            </div>
          )}
          <h4 className={styles.metricValue}>
            {getDollarLabel(metric.name)}
            {formatNumber(metric.originalValue)}
            {getProcentLabel(metric.name)}
          </h4>
        </div>
        <div className={styles.metricCircle}>
          <svg viewBox="0 0 100 52" version="1.1">
            <circle
              cx="50px"
              cy="50px"
              r="48"
              stroke="#C8A857"
              strokeWidth="2"
              strokeDasharray="0 10"
              strokeLinecap="round"
              fill="transparent"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
