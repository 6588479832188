import { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import SvgIcon from "@components/svg-icon/SvgIcon";
import "./create-folder-modal.less";

export default function CreateFolderModal(props) {
  const { visible, onClose, onCreate } = props;

  const defaultState = {
    folderName: "",
  };

  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (!visible) {
      setState(defaultState);
    }
  }, [visible]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    setState((prev) => ({
      ...prev,
      folderName: value,
    }));
  };

  const handleCreate = () => {
    if (onCreate) {
      onCreate(state.folderName);
    }
  };

  return (
    <Modal
      width={520}
      footer={null}
      visible={visible}
      onCancel={handleClose}
      className="create-folder-modal"
    >
      <h4 className="save-doc-modal-title">Create a new folder</h4>

      <label className="field-label">Enter the folder name</label>
      <Input
        size="large"
        value={state.folderName}
        onChange={handleInputChange}
      />

      <Button
        size="large"
        shape="round"
        type="primary"
        className="black-btn"
        onClick={handleCreate}
      >
        To create
        <SvgIcon type="arrow-right" />
      </Button>
    </Modal>
  );
}
