import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { videoCardMock } from "../../mock";

interface VideoSectionProps {
  video: { position: number; media_link: string }[] | null;
}

const VideoSection: React.FC<VideoSectionProps> = ({ video }) => {
  const { videoUrl, videoDescription } = videoCardMock;
  const textRef = useRef<HTMLParagraphElement>(null);
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string>(videoUrl);

  useEffect(() => {
    if (video && video.length > 0 && video[0].media_link !== currentVideoUrl) {
      setCurrentVideoUrl(video[0].media_link);
    }
  }, [video]);

  return (
    <div className={styles.container}>
      <video key={currentVideoUrl} className={styles.video} controls>
        <source src={currentVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <p className={styles.text} ref={textRef}>
        {videoDescription}
      </p> */}
    </div>
  );
};

export default VideoSection;
