import { AgentApiResponse } from "@components/ChatBot/types";
import { Api } from "src/utils/api";
import { INSTATHINK_API_BASE_URL } from "./chat.constant";

interface ApiCall<T = unknown> {
  body: T;
}

export class ChatApi extends Api {
  constructor() {
    super(INSTATHINK_API_BASE_URL);
  }
  /**
   * Handles API call and parses the response.
   * @param payload - The payload to send with the API call.
   * @returns The parsed response as AgentApiResponse.
   */
  private async handleApiCall(
    payload: Record<string, unknown>,
  ): Promise<AgentApiResponse> {
    try {
      const response = await this.post<ApiCall<string>>("", payload);
      const data = response?.body;

      if (!data) {
        throw new Error("No data returned from API");
      }

      return JSON.parse(data) as AgentApiResponse;
    } catch (error) {
      const errorMessage = `Error from API call: ${error instanceof Error ? error.message : error}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

  /**
   * Sends a message to the API for processing.
   * @param question - The question/message to send.
   * @returns The response from the API.
   */
  public sendMessage = (question: string): Promise<AgentApiResponse> => {
    const payload = {
      message: question,
      workflowId: "nyle",
      taskId: "nyle_testing_id",
    };

    return this.handleApiCall(payload);
  };
}
