import { useState } from "react";

const useShowMoreElements = (arrayData, showCount) => {
  const maxCount = Math.max(...arrayData.map((item) => item.count));
  const [showElements, setShowElements] = useState(showCount);

  const loadMore = () => setShowElements((prev) => prev + showCount);

  return { maxCount, showElements, loadMore };
};
export default useShowMoreElements;
