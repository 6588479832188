import { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import ChartScatter from "@assets/icons/ChartScatter";
import TableScatter from "@assets/icons/TableScatter";
import { ScatterPlot } from "./ScatterPlot";
import { ExpandableTable } from "@components/ExpandebleTable";
import {
  getScatterDataTable,
  scatterColumns,
  scatterDataTable,
} from "./mock.tsx";

export const DotPlot = () => {
  const [selectedView, setSelectedView] = useState<"Plot view" | "Table view">(
    "Plot view",
  );

  const isPlotView = selectedView === "Plot view";

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          className={classNames(
            styles.switch__container,
            isPlotView && styles.plot,
          )}
        >
          <button
            className={styles.switch}
            onClick={() => setSelectedView("Plot view")}
          >
            <ChartScatter /> Plot view
          </button>
          <button
            className={styles.switch}
            onClick={() => setSelectedView("Table view")}
          >
            <TableScatter /> Table view
          </button>
        </div>
        {isPlotView && (
          <div className={styles.legend}>
            <div className={classNames(styles.legendItem, styles.good)}>
              <span></span> Good
            </div>
            <div className={classNames(styles.legendItem, styles.mid)}>
              <span></span> Mid
            </div>
            <div className={classNames(styles.legendItem, styles.poor)}>
              <span></span> Poor
            </div>
            <div className={classNames(styles.legendItem, styles.nosales)}>
              <span></span> No Sales
            </div>
          </div>
        )}
      </div>
      {isPlotView && <ScatterPlot />}
      {!isPlotView && (
        <ExpandableTable
          columns={scatterColumns}
          data={getScatterDataTable(scatterDataTable)}
          withCustomScroll
          withoutBottomPadding
        />
      )}
    </div>
  );
};
