import { useCallback, useMemo, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Input, Select, Button } from "antd";
import ReactCountryFlag from "react-country-flag";
import { RefSelectProps } from "antd/lib/select";

import { NyleField } from "@components/NyleField";
import { MetaService } from "@services/MetaService";
import { ProfileService } from "@services/ProfileService";

const { Option } = Select;

type EditProfileProps = {
  disabled?: boolean;
  lastName?: string;
  firstName?: string;
  country?: string;
};

export const EditProfile = ({ disabled, ...props }: EditProfileProps) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState(props);
  const selectRef = useRef<RefSelectProps>(null);
  const { data: countriesList } = useQuery(
    ["countriesList"],
    MetaService.getCountries,
  );

  const {
    isLoading,
    isRefetching,
    refetch: updateProfile,
  } = useQuery(["updateProfile"], async () => {
    const data = await ProfileService.update({
      country: formData.country,
      first_name: formData.firstName,
      last_name: formData.lastName,
    });

    // Update account information
    queryClient.setQueryData(["account"], {
      ...data,
      country: {
        code: data.country,
      },
    });
  });

  const onSelectCountry = useCallback((countryCode) => {
    selectRef.current?.blur();

    setFormData((prevData) => ({
      ...prevData,
      country: countryCode,
    }));
  }, []);

  const handleButtonClick = () => {
    updateProfile();
  };

  const isFormDirty = useMemo(() => {
    return Object.entries(formData).some(
      ([key, value]) => props[key] !== value,
    );
  }, [formData, props]);

  const isInputDataValid = useMemo(() => {
    return (
      Boolean(formData.country?.length) &&
      Boolean(formData.firstName.length) &&
      Boolean(formData.lastName.length)
    );
  }, [formData.country?.length, formData.firstName, formData.lastName]);

  return (
    <>
      <NyleField label="First Name">
        <Input
          disabled={disabled}
          placeholder="Enter your name"
          defaultValue={formData.firstName}
          onChange={(e) => {
            setFormData((prevData) => ({
              ...prevData,
              firstName: e.target.value,
            }));
          }}
          autoComplete="off"
          type="search"
        />
      </NyleField>

      <NyleField label="Last Name">
        <Input
          disabled={disabled}
          placeholder="Enter your lastname"
          defaultValue={formData.lastName}
          onChange={(e) => {
            setFormData((prevData) => ({
              ...prevData,
              lastName: e.target.value,
            }));
          }}
          autoComplete="off"
          type="search"
        />
      </NyleField>

      <NyleField label="Country">
        <Select
          disabled={disabled}
          // @ts-ignore
          autoComplete="off"
          type="search"
          ref={selectRef}
          placeholder="Enter your country"
          showSearch
          onSelect={onSelectCountry}
          dropdownAlign={{ offset: [0, 0] }}
          defaultValue={formData.country}
          filterOption={(input, option) =>
            (option.label as string)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {countriesList?.map(({ code, name: countryName }) => (
            <Option value={code} key={code} label={countryName}>
              <ReactCountryFlag
                svg
                countryCode={code}
                style={{ marginRight: 10, width: "1.7em", height: "0.9em" }}
              />
              <span style={{ fontSize: "13px", verticalAlign: "middle" }}>
                {countryName}
              </span>
            </Option>
          ))}
        </Select>
      </NyleField>
      {isFormDirty && (
        <div className="button-wrapper">
          <Button
            shape="round"
            block={true}
            type="primary"
            htmlType="submit"
            onClick={handleButtonClick}
            loading={isLoading || isRefetching}
            disabled={!isInputDataValid}
          >
            UPDATE PROFILE
          </Button>
        </div>
      )}
    </>
  );
};
