export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="5"
      viewBox="0 0 4 5"
      fill="none"
    >
      <circle cx="2" cy="2.5" r="2" fill="#5C5C5A" />
    </svg>
  );
};
