export const tooltipFormatter = (params: any) => {
  const { value, data, color } = params;

  const isMissing = color === "#5C5C5A" || color === "#1F1F20";

  const titleText = isMissing
    ? `<span style="color: #2E2E2E; font-weight: 500; font-size: 18px;">Missing:</span>
       <span style="color: #7C7C78; font-weight: 500; font-size: 18px;">(${data.name})</span>`
    : `<div style="font-weight: 500; font-size: 18px;">${data.name}</div>`;

  const relevanceScoreHtml = isMissing
    ? `<div style="display: flex; justify-content: space-between; padding-top: 8px;">
        <span style="color: #7C7C78; font-weight: 500; font-size: 18px;">Relevance Score:</span>
        <span style="color: #2E2E2E; font-weight: 500; font-size: 18px;">${data.relevanceScore || Math.floor(Math.random() * 10)}</span>
      </div>`
    : "";

  return `
    <div style="width: 228px; position: relative;">
      <div style="
        position: absolute;
        left: -28px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 14px solid #ffffff;
      "></div>
      ${titleText}
      <div style="display: flex; justify-content: space-between; padding-top: 12px;">
        <span style="color: #7C7C78; font-weight: 500; font-size: 18px;">Ad Sales:</span>
        <span style="color: #2E2E2E; font-weight: 500; font-size: 18px;">$${value[1]}</span>
      </div>
      <div style="display: flex; justify-content: space-between; padding-top: 8px;">
        <span style="color: #7C7C78; font-weight: 500; font-size: 18px;">ACOS:</span>
        <span style="color: #2E2E2E; font-weight: 500; font-size: 18px;">${value[0]}%</span>
      </div>
      <div style="display: flex; justify-content: space-between; padding-top: 8px;">
        <span style="color: #7C7C78; font-weight: 500; font-size: 18px;">Ad Spend:</span>
        <span style="color: #2E2E2E; font-weight: 500; font-size: 18px;">$${data.adSpend}</span>
      </div>
      ${relevanceScoreHtml}
      <div style="display: flex; justify-content: space-between; padding-top: 8px;">
        <span style="color: #7C7C78; font-weight: 500; font-size: 18px;">Search Volume:</span>
        <span style="color: #2E2E2E; font-weight: 500; font-size: 18px;">${data.searchVolume}</span>
      </div>
    </div>
  `;
};
