import "./index.scss";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { CustomTooltip } from "./CustomTooltip";
import { formatThousands } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { Checks } from "../types";

interface ChartProps {
  checks: Checks;
  metricsColors: { [key: string]: string };
  data: any[];
}

export const Chart: React.FC<ChartProps> = ({
  checks,
  metricsColors,
  data,
}) => {
  const selectedMetrics = useMemo(
    () => Object.keys(checks).filter((key) => checks[key]),
    [checks],
  );

  const convertDate = useCallback((timestamp: number) => {
    return format(new Date(timestamp), "d MMM");
  }, []);

  const gradientMetric = useMemo(() => {
    if (
      !Array.isArray(selectedMetrics) ||
      selectedMetrics.length === 0 ||
      !Array.isArray(data) ||
      data.length === 0
    ) {
      return null;
    }

    const firstDataPoint = data[0];
    let maxMetricKey = selectedMetrics[0];

    selectedMetrics.forEach((metricKey) => {
      if (firstDataPoint[metricKey] > firstDataPoint[maxMetricKey]) {
        maxMetricKey = metricKey;
      }
    });

    return maxMetricKey;
  }, [selectedMetrics, data]);

  const yAxisIdMap = useMemo(() => {
    const map: { [key: string]: string } = {};
    selectedMetrics.forEach((metric, index) => {
      map[metric] = `yAxis${index + 1}`;
    });
    return map;
  }, [selectedMetrics]);

  return (
    <div className="sales-performance__chart" style={{ zIndex: "0" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 0,
          }}
        >
          <defs>
            {gradientMetric && (
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={metricsColors[gradientMetric]}
                  stopOpacity={0.3}
                />
                <stop
                  offset="100%"
                  stopColor={metricsColors[gradientMetric]}
                  stopOpacity={0}
                />
              </linearGradient>
            )}
          </defs>
          <CartesianGrid stroke="#1F1F20" />
          <XAxis
            dataKey="date"
            tickFormatter={convertDate}
            interval="preserveStartEnd"
            stroke="#7C7C78"
          />

          <Tooltip
            content={({ active, payload, label }) => (
              <CustomTooltip
                active={active}
                payload={payload}
                colorMetric={metricsColors}
                label={label}
              />
            )}
            cursor={{
              fill: "transparent",
              stroke: "white",
              strokeDasharray: 4,
            }}
          />

          {selectedMetrics.map((metricKey, index) => {
            const yAxisId = yAxisIdMap[metricKey];
            const orientation = index % 2 === 0 ? "left" : "right";
            const tickColor = metricsColors[metricKey];
            let tickFormatter;
            if (
              metricKey.toLowerCase().includes("cvr") ||
              metricKey.toLowerCase().includes("ctr")
            ) {
              tickFormatter = (tick) => `${tick}%`;
            } else if (
              metricKey.toLowerCase().includes("sales") ||
              metricKey.toLowerCase().includes("spend")
            ) {
              tickFormatter = (tick) => `$${formatThousands(tick)}`;
            } else {
              tickFormatter = formatThousands;
            }
            return (
              <YAxis
                key={yAxisId}
                yAxisId={yAxisId}
                orientation={orientation}
                stroke={tickColor}
                tickFormatter={tickFormatter}
                axisLine={false}
                tickLine={false}
                domain={[
                  0,
                  (dataMax: number) => {
                    const max = Math.ceil(dataMax / 10) * 10;
                    return max < 10 ? 100 : max;
                  },
                ]}
                tickCount={11}
                tick={{ fill: tickColor }}
              />
            );
          })}

          {selectedMetrics.map((metricKey) => {
            const yAxisId = yAxisIdMap[metricKey];
            if (metricKey === gradientMetric) {
              return (
                <Area
                  key={metricKey}
                  yAxisId={yAxisId}
                  type="monotone"
                  dataKey={metricKey}
                  stroke={metricsColors[metricKey]}
                  fill="url(#colorGradient)"
                  strokeWidth={2}
                  dot={false}
                />
              );
            }
            return (
              <Line
                key={metricKey}
                yAxisId={yAxisId}
                type="monotone"
                dataKey={metricKey}
                stroke={metricsColors[metricKey]}
                strokeWidth={2}
                dot={false}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
