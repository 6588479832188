export const FEATURE_NAME = {
  TEAM: "TEAM",
  HELP: "HELP",
} as const;

/**
 * Features State
 */
const initialFeatures: {
  name: keyof typeof FEATURE_NAME;
  isActive: boolean;
}[] = [{ name: FEATURE_NAME.TEAM, isActive: false }];

const ftStorage = localStorage.getItem("ft") ?? "";

export const features = [...initialFeatures, ...ftStorage.split(",")].reduce(
  (enabledFeatures, feature) => {
    if (typeof feature === "string") {
      enabledFeatures.push(feature);
    } else if (feature.isActive) {
      enabledFeatures.push(feature.name);
    }

    return enabledFeatures;
  },
  [],
);
