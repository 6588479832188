import Axios from "axios";

export interface IConfigBase {
  env_config: string;
  sentry_dsn: string;
  env_run: string;
  commit_hash: string;
  zendesk_key: string;
  version: string;
}

export type IBackendConfig = Pick<
  IConfigBase,
  "env_config" | "sentry_dsn" | "env_run" | "commit_hash" | "zendesk_key"
>;

export type IFrontendConfig = Pick<
  IConfigBase,
  "env_run" | "version" | "sentry_dsn" | "env_config" | "commit_hash"
>;

// Service class for fetching and processing backend configuration.
export class ConfigService {
  public static async fetchBackendConfig(): Promise<IBackendConfig | null> {
    try {
      const response = await Axios.get<IBackendConfig>("/api/v1/config");
      let config = response.data;

      const configArray = Object.entries(config);

      configArray.sort((a, b) => a[0].localeCompare(b[0]));

      config = configArray.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as IBackendConfig);

      return config;
    } catch (error) {
      console.error("Error fetching config:", error);

      return null;
    }
  }
}
