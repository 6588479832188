import { useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface CustomInputProps {
  value: number;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blue?: boolean;
  className?: string;
  select?: string[];
  onSelectChange?: (id: number) => void;
  activeSelectIndex?: number;
  possibleValues?: number[];
}

const CustomInput: React.FC<CustomInputProps> = ({
  value,
  label,
  onChange,
  blue = false,
  className,
  select,
  onSelectChange,
  activeSelectIndex,
  possibleValues,
}) => {
  const rangeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (rangeRef.current) {
      const track = rangeRef.current;
      const val =
        ((value - Number(track.min)) /
          (Number(track.max) - Number(track.min))) *
        100;
      track.style.setProperty("--value", `${val}%`);
    }
  }, [value]);

  useEffect(() => {
    if (possibleValues && possibleValues.length > 0 && rangeRef.current) {
      rangeRef.current.min = possibleValues[0].toString();
      rangeRef.current.max =
        possibleValues[possibleValues.length - 1].toString();
    }
  }, [possibleValues]);

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const handlePossibleValueClick = (newValue: number) => {
    onChange({
      target: { value: newValue.toString() } as any,
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className={classNames(styles.inputContainer, className)}>
      <div className={styles.result}>
        <span>{label}</span>
        {!possibleValues && (
          <div className={styles.input__container}>
            <input
              type="number"
              value={value}
              onChange={onChange}
              className={classNames(styles.inputNumber, {
                [styles.inputNumberBlue]: blue,
              })}
            />
            {select && (
              <div className={styles.selects}>
                {select.map((item, index) => (
                  <button
                    key={item}
                    onClick={() => onSelectChange && onSelectChange(index)}
                    className={classNames(styles.select, {
                      [styles.active]: activeSelectIndex === index,
                    })}
                  >
                    {item}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <input
        type="range"
        min={possibleValues ? possibleValues[0] : "0"}
        max={possibleValues ? possibleValues[possibleValues.length - 1] : "100"}
        value={value}
        step={
          possibleValues && possibleValues.length > 1
            ? possibleValues[1] - possibleValues[0]
            : 1
        }
        onChange={handleRangeChange}
        className={classNames(styles.inputRange, {
          [styles.inputRangeBlue]: blue,
        })}
        ref={rangeRef}
      />

      {possibleValues && (
        <div className={styles.possibleValuesContainer}>
          {possibleValues.map((possibleValue) => (
            <button
              key={possibleValue}
              onClick={() => handlePossibleValueClick(possibleValue)}
              className={classNames(styles.possibleValue, {
                [styles.active]: possibleValue <= value,
              })}
            >
              {possibleValue}%
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomInput;
