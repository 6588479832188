import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { Aspect, Hypothesis, Phrase, Problem } from "src/store/insights/types";

export class InsightsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getAspects(asin: string): Promise<[Aspect]> {
    return this.get(`/insights/aspects/${asin}`, {});
  }

  public async getProblems(asin: string): Promise<[Problem]> {
    return this.get(`/insights/problems/${asin}`, {});
  }

  public async getHypotheses(asin: string): Promise<[Hypothesis]> {
    return this.get(`/insights/hypotheses/${asin}`, {});
  }

  public async getPhrases(asin: string): Promise<[Phrase]> {
    return this.get(`/insights/phrases/${asin}`, {});
  }
}
