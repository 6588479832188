import Axios from "axios";
import { useAuthStore } from "src/store/auth.state";

const API_BASE_URL = import.meta.env.BUILD_API_BASE_URL || "";

class RefreshTokenService {
  public static async refresh(refresh_token: string): Promise<string> {
    try {
      const response = await Axios.post(
        `${API_BASE_URL}/core/api/v1/token/refresh`,
        { refresh: refresh_token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const { access } = response.data;

      localStorage.setItem("access_token", access);
      return access;
    } catch (error) {
      if (
        Axios.isAxiosError(error) &&
        (error.response?.status === 401 || error.response?.status === 403)
      ) {
        useAuthStore.getState().logout();
      }
      throw new Error("Failed to refresh token");
    }
  }

  public static async isTokenActive(token: string): Promise<boolean> {
    try {
      await Axios.post("/api/v1/token/verify", {
        token: token,
      });
      return true;
    } catch (error) {
      if (
        Axios.isAxiosError(error) &&
        (error.response?.status === 401 || error.response?.status === 403)
      ) {
        useAuthStore.getState().logout();
      }
      return false;
    }
  }
}

export default RefreshTokenService;
