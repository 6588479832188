import { useState } from "react";
import styles from "./styles.module.scss";
import aspectsImg from "@assets/img/aspectsImg.png";

function Analysis() {
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleClick = (index) => {
    setClickedIndex(index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Use sentiment analysis insights to get <br /> an{" "}
        <span>edge over competitors</span>
      </div>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <img src={aspectsImg} alt="Aspects" />
        </div>
        <div className={styles.infoBlock}>
          {[
            "Find market signals for marketing content optimization",
            "Do strategic product improvements by listening to customer voice",
            "Launch automatic AB tests and CRO pipeline hypotheses, which drive over 11% sales increase within 3 months",
          ].map((text, index) => (
            <p
              key={index}
              className={`${styles.infoBlockTitle} ${clickedIndex === index ? styles.clicked : ""}`}
              onClick={() => handleClick(index)}
            >
              {text}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Analysis;
