import { DocumentResponse } from "@entities/DocumentResponse";
import axios from "axios";
import qs from "qs";
import { Document } from "src/entities/Document";
import { DocumentRequest } from "src/entities/DocumentRequest";
import { Folder } from "src/entities/Folder";
import { FolderRequest } from "src/entities/FolderRequest";

export class DocumentsService {
  static readonly DOCUMENTS_URL = "/api/v1/documents";
  static readonly FOLDERS_URL = "/api/v1/folders";

  public static async getDocumentsList(params?: {
    folder?: string[];
    order_by?: Array<
      "-created_at" | "-updated_at" | "created_at" | "updated_at"
    >;
    page?: number;
    page_size?: number;
  }) {
    const { data } = await axios.get<DocumentResponse>(
      DocumentsService.DOCUMENTS_URL,
      {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, {
            indices: false,
            encode: false,
            skipNulls: true,
          }),
      },
    );

    return data;
  }

  public static async createDocument(
    requestBody: DocumentRequest,
  ): Promise<Document> {
    const { data } = await axios.post<Document>(
      DocumentsService.DOCUMENTS_URL,
      requestBody,
    );

    return data;
  }

  public static async getDocumentById(id: number | string): Promise<Document> {
    const { data } = await axios.get<Document>(
      DocumentsService.DOCUMENTS_URL + `/${id}`,
    );

    return data;
  }

  public static async updateDocument(
    id: number,
    requestBody: DocumentRequest,
  ): Promise<Document> {
    const { data } = await axios.patch<Document>(
      DocumentsService.DOCUMENTS_URL + `/${id}`,
      requestBody,
    );

    return data;
  }

  public static async deleteDocument(id: number): Promise<void> {
    const { data } = await axios.delete(
      DocumentsService.DOCUMENTS_URL + `/${id}`,
    );

    return data;
  }

  public static async getFoldersList(name?: string): Promise<Folder[]> {
    const { data } = await axios.get<Folder[]>(DocumentsService.FOLDERS_URL, {
      params: {
        name,
      },
    });

    return data;
  }

  public static async createFolder(
    requestBody: FolderRequest,
  ): Promise<Folder> {
    const { data } = await axios.post<Folder>(
      DocumentsService.FOLDERS_URL,
      requestBody,
    );

    return data;
  }

  public static async getFolderById(id: number): Promise<Folder> {
    const { data } = await axios.get<Folder>(
      DocumentsService.FOLDERS_URL + `/${id}`,
    );

    return data;
  }

  public static async updateFolder(
    id: number,
    requestBody: FolderRequest,
  ): Promise<Folder> {
    const { data } = await axios.put<Folder>(
      DocumentsService.FOLDERS_URL + `/${id}`,
      requestBody,
    );

    return data;
  }

  public static async removeFolder(id: number): Promise<void> {
    const { data } = await axios.delete(
      DocumentsService.FOLDERS_URL + `/${id}`,
    );

    return data;
  }
}
