import { RadarChart } from "@components/Spider/RadarChart";
import { useState } from "react";
import { RadarData, Message } from "@components/ChatBot/types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import style from "./style.module.scss";

const getRadarDataset = (radarValues: number[]) => [
  {
    label: "Your Product",
    data: radarValues,
    backgroundColor: "#FFFFFF",
    borderColor: "#5295E0",
    pointBackgroundColor: "transparent",
    pointHoverBackgroundColor: "#5295E0",
    pointBorderColor: "transparent",
    pointHoverBorderColor: "#5295E0",
    pointRadius: 10,
    pointHoverRadius: 6,
    borderWidth: 1,
  },
];

const chartLabels = [
  "Design",
  "Brand",
  "Usability",
  "Quality",
  "Price",
  "Noise",
];

export const renderMessage = (data: Message) => {
  const [activeIndex] = useState(0);

  const { type, text } = data;

  if (typeof text === "string") {
    return (
      <div className={style.message__text}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
      </div>
    );
  }

  if (type !== "get_product_spider") {
    // TODO: ADD Handler here
    return <div>Type not supported</div>;
  }

  const toolData = text as RadarData;
  const radarValues = [
    toolData.design,
    toolData.brand,
    toolData.usability,
    toolData.quality,
    toolData.price,
    toolData.noise,
  ];

  const radarDataset = getRadarDataset(radarValues);

  return (
    <div className={style.message__canva}>
      <RadarChart
        productOneTitles={chartLabels}
        titles={chartLabels}
        datasets={radarDataset}
        activeIndex={activeIndex}
        width={280}
        height={280}
        padding={0}
        productOneValues={radarValues}
        productTwoValues={[]}
        labelFontSize={12}
      />
    </div>
  );
};
