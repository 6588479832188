import axios from "axios";
import { notification } from "antd";
import { useMutation } from "react-query";

import { ImagesService } from "@services/ImagesService";

export const useUploadFile = (options) => {
  return useMutation(
    ({ img }: { img: string | Blob }) => {
      const data = new FormData();
      data.append("img", img);

      return ImagesService.uploadImage(data);
    },
    {
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          notification.error({
            message:
              error?.response?.data?.detail ??
              error?.response?.data?.img?.[0] ??
              "Failed to upload image",
          });
        }
      },
      ...options,
    },
  );
};

export const useDeleteImage = (options) => {
  return useMutation(
    ({ id }: { id: number }) => ImagesService.deleteImage(id),
    {
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          notification.error({
            message: error?.response?.data?.detail ?? "Failed to delete image",
          });
        }
      },
      ...options,
    },
  );
};

export const useExtractTags = (options?) => {
  return useMutation(
    ({ id }: { id: number }) => ImagesService.getImageTags(id),
    {
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          notification.error({
            message:
              error?.response?.data?.detail ??
              "Failed to extract tags from image",
          });
        }
      },
      ...options,
    },
  );
};

export const useEnhanceImage = (options?) =>
  useMutation(
    async (params: { id: number; factor: number }) => {
      const res = await ImagesService.enhanceImage(params.id, {
        factor: params.factor,
      });

      if (res.status !== 200) {
        const data = await res.json();

        throw data.detail;
      }

      return await res.blob();
    },
    {
      onError: (err) => {
        notification.error({
          message: err.toString() || "Failed to enhance image",
        });
      },
      ...options,
    },
  );

export const useRemoveBackground = (options?) =>
  useMutation(
    async (params: { id: number }) => {
      const res = await ImagesService.removeBackground(params.id);

      if (res.status !== 200) {
        const data = await res.json();

        throw data.detail;
      }

      return await res.blob();
    },
    {
      onError: (err) => {
        notification.error({
          message: err.toString() || "Failed to remove background",
        });
      },
      ...options,
    },
  );
