import Card1 from "@assets/img/Card1.png";
import Card2 from "@assets/img/Card2.png";
import Card3 from "@assets/img/Card3.png";
import Card4 from "@assets/img/Card4.png";

export const images = [
  { src: Card1, title: "How to use the AI Copywriting", time: "24:30" },
  { src: Card2, title: "Tour Guide on Nyle Platform", time: "24:30" },
  { src: Card3, title: "How to get the Best Results with Nyle", time: "24:30" },
  { src: Card4, title: "Case Studies with Our Clients", time: "24:30" },
  { src: Card1, title: "How to use the AI Copywriting", time: "24:30" },
  { src: Card2, title: "Tour Guide on Nyle Platform", time: "24:30" },
  { src: Card3, title: "How to get the Best Results with Nyle", time: "24:30" },
  { src: Card4, title: "Case Studies with Our Clients", time: "24:30" },
];
