import { useCallback, useRef, useState } from "react";

import { useTitle } from "@hooks/useTitle";
import "./remove-background-page.less";

import imgPath from "./remove.png";
import { RemoveBackground } from "@components/RemoveBackground";
import { RemoveBackgroundTool } from "@components/RemoveBackground/RemoveBackgroundTool";
import {
  useRemoveBackground,
  useUploadFile,
} from "@components/ImageUploader/hooks/useImageServices";

export const RemoveBackgroundPage = () => {
  useTitle("Nyle - Remove Background");

  const [originalFile, setOriginalFile] = useState<File>(null);
  const [outputFile, setOutputFile] = useState<File>(null);
  const [currentTab, setCurrentTab] = useState<"original" | "output">(
    "original",
  );
  const uploadedImageId = useRef<number>(null);

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile({
    onSuccess: ({ data }) => {
      uploadedImageId.current = data.id;
      removeBackground({
        id: data.id,
      });
    },
  });
  const {
    mutate: removeBackground,
    isLoading: isRemovingBg,
    isError,
  } = useRemoveBackground({
    onSuccess: (data: File) => {
      setOutputFile(data);
      setCurrentTab("output");
    },
  });

  const _onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles?.[0]) {
        setOriginalFile(acceptedFiles[0]);
        uploadFile({ img: acceptedFiles[0] });
      }
    },
    [uploadFile],
  );

  const onRetryClick = useCallback(() => {
    if (uploadedImageId.current) {
      removeBackground({
        id: uploadedImageId.current,
      });
    }
  }, [removeBackground]);

  const clearData = useCallback(() => {
    setOriginalFile(null);
    setOutputFile(null);
    setCurrentTab("original");
    uploadedImageId.current = null;
  }, []);

  return (
    <div className="remove-background-page-container">
      <section>
        <div className="container">
          <h2 className="page-main-title">Remove background</h2>
          <p className="page-main-description">
            Upload an image and remove the background from your image instantly
          </p>
        </div>
      </section>

      <section className="remove-background-page__content">
        <div className="container">
          <RemoveBackground onDrop={_onDrop} />
          {!originalFile && (
            <div className="remove-background-info">
              <img
                className="remove-background-info__img"
                src={imgPath}
                alt="example"
              />
              <div className="remove-background-info__content">
                <div className="remove-background-info__title">
                  Easily Replace and Change Photo Backgrounds
                </div>
                <div className="remove-background-info__text">
                  Our powerful image background remover can work with almost any
                  type of image- portraits, animals, jewelry, cars, real estate,
                  icons, text, signatures, and even graphics.
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {originalFile && (
        <section>
          <div className="container">
            <RemoveBackgroundTool
              isLoading={isUploading || isRemovingBg}
              originalFile={originalFile}
              outputFile={outputFile}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isError={isError}
              onRetryClick={onRetryClick}
              clearData={clearData}
            />
          </div>
        </section>
      )}
    </div>
  );
};
