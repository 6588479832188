import { useCallback, useEffect, useState } from "react";
import { Button, Drawer, List, Tabs } from "antd";
import { useConfigStore } from "src/store/config.state";
import "./nyle-config.less";

export const NyleConfig = () => {
  const { backendConfig, frontendConfig, fetchBackendConfig } =
    useConfigStore();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!backendConfig) {
      fetchBackendConfig();
    }
  }, [backendConfig, fetchBackendConfig]);

  const showDrawer = useCallback(() => setVisible(true), []);
  const onClose = useCallback(() => setVisible(false), []);

  if (
    (!backendConfig && !frontendConfig) ||
    frontendConfig.env_run === "production"
  ) {
    return null;
  }

  return (
    <div className="nyle-config">
      <Button size="small" type="primary" onClick={showDrawer}>
        Debug Info
      </Button>
      {visible && (
        <Drawer
          title="Env params"
          placement="right"
          open={visible}
          onClose={onClose}
        >
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Backend",
                children: (
                  <List
                    bordered
                    dataSource={Object.entries(backendConfig || {}).filter(
                      ([_, value]) => typeof value === "string",
                    )}
                    renderItem={([key, value]) => (
                      <List.Item key={key}>
                        <div>
                          {key} : {value}
                        </div>
                      </List.Item>
                    )}
                  />
                ),
              },
              {
                key: "2",
                label: "Frontend",
                children: (
                  <List
                    bordered
                    dataSource={Object.entries(frontendConfig).filter(
                      ([_, value]) => typeof value === "string",
                    )}
                    renderItem={([key, value]) => (
                      <List.Item key={key}>
                        <div>
                          {key} : {value}
                        </div>
                      </List.Item>
                    )}
                  />
                ),
              },
            ]}
          />
        </Drawer>
      )}
    </div>
  );
};
