import type { AiEntity } from "@entities/AiEntity";

const ignoreList = ["free-write"];

const toneAndVoiceOptions = [
  "Appreciative",
  "Assertive",
  "Awestruck",
  "Candid",
  "Casual",
  "Cautionary",
  "Compassionate",
  "Convincing",
  "Earnest",
  "Enthusiastic",
  "Formal",
  "Funny",
  "Humble",
  "Humorous",
  "Informative",
  "Inspirational",
  "Joyful",
  "Passionate",
  "Thoughtful",
  "Urgent",
  "Witty",
  "Sarcastic",
  "Bold",
  "Dramatic",
  "Secretive",
];

export const mutateInputSchema = (schema: AiEntity[]) => {
  return schema
    .filter(({ id }) => !ignoreList.includes(id))
    .map((tool) => {
      // TODO: REMOVE WHEN BACKEND UPDATE SCHEMA
      switch (tool.id) {
        case "rewriting":
          tool.request_schema.properties.summarize = {
            title: "summarize",
            description: "Select the type (only one option)",
            type: "boolean",
            option: "test",
          };
          tool.request_schema.properties.tone = {
            title: "TONE & VOICE",
            type: "string",
            default: "Assertive",
            enum: toneAndVoiceOptions,
          };
          tool.request_schema.properties.audience = {
            title: "ADAPT TO SPECIFIC AUDIENCE",
            type: "string",
            anyOf: [
              {
                type: "string",
                enum: ["1"],
                title: "18-24 Y.O.",
              },
              {
                type: "string",
                enum: ["2"],
                title: "24-35 Y.O.",
              },
              {
                type: "string",
                enum: ["3"],
                title: "35-50 Y.O.",
              },
              {
                type: "string",
                enum: ["4"],
                title: "50+ Y.O.",
              },
            ],
          };
          tool.request_schema.properties.length_adjustment = {
            title: "TEXT LENGTH ADJUSTMENT",
            type: "string",
            anyOf: [
              {
                type: "string",
                enum: ["1"],
                title: "REDUCE",
              },
              {
                type: "string",
                enum: ["2"],
                title: "INCREASE",
              },
              {
                type: "string",
                enum: ["3"],
                title: "THE SAME",
              },
            ],
          };
          break;
      }

      return tool;
    });
};
