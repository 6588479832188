import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";
import CalendarButton from "@components/CalendarButton";

function Header() {
  return (
    <div className={styles.container}>
      <h1>Comparison mode</h1>
      <div className={styles.buttons}>
        <CalendarButton />
        <NavLink to="/CRO-module" className={styles.button}>
          CRO MODULE
        </NavLink>
      </div>
    </div>
  );
}

export default Header;
