import styles from "./styles.module.scss";
import { Phrase } from "src/store/insights/types";

import useShowMoreElements from "@hooks/useShowMoreElements";
import React from "react";

interface ITopPhrasesComponentProps {
  phrasesYour: Phrase[];
  phrasesCompare: Phrase[];
}

const TopPhrasesComponent: React.FC<ITopPhrasesComponentProps> = ({
  phrasesYour,
  phrasesCompare,
}) => {
  const {
    maxCount: maxCountYour,
    showElements: showElementsYour,
    loadMore: loadMoreYours,
  } = useShowMoreElements(phrasesYour, 10);

  const {
    maxCount: maxCountCompare,
    showElements: showElementsCompare,
    loadMore: loadMoreCompare,
  } = useShowMoreElements(phrasesCompare, 10);

  return (
    <div className={styles.phrasesContainer}>
      <h3>Top Phrases</h3>
      <div className={styles.phrasesList}>
        <div className={styles.phraseColumn}>
          {phrasesYour.slice(0, showElementsYour).map((item, index) => (
            <div key={index} className={styles.phraseItem}>
              <span className={styles.phraseText}>{item.phrase}</span>
              <span className={styles.phraseCount}>{item.count}</span>
              <div
                className={styles.phraseBar}
                style={{ width: `${(item.count / maxCountYour) * 100}%` }}
              />
            </div>
          ))}
          {showElementsYour < phrasesYour.length && (
            <button
              onClick={() => {
                loadMoreYours();
              }}
              className={styles.moreButton}
            >
              +10 MORE
            </button>
          )}
        </div>
        <div className={styles.phraseColumn}>
          {phrasesCompare.slice(0, showElementsCompare).map((item, index) => (
            <div key={index} className={styles.phraseItem}>
              <span className={styles.phraseText}>{item.phrase}</span>
              <span className={styles.phraseCount}>{item.count}</span>
              <div
                className={styles.phraseBar}
                style={{ width: `${(item.count / maxCountCompare) * 100}%` }}
              />
            </div>
          ))}
          {showElementsCompare < phrasesCompare.length && (
            <button
              onClick={() => {
                loadMoreCompare();
              }}
              className={styles.moreButton}
            >
              +10 MORE
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopPhrasesComponent;
