import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useProfile } from "@hooks/useProfile";

const DEFAULT_TIMER_VALUE = 10;

export const SubscriptionModal = () => {
  const { search } = useLocation();
  const { data: profile } = useProfile();
  const navigate = useNavigate();
  const [counter, updateCounter] = useState(DEFAULT_TIMER_VALUE);
  const [hasModal, setHasModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(search);
    // example ?session_id=cs_test_a1ql4TKF7li3ZBnoTBNGY5IhvSC73SHcj3uSzfJbAodf4UTPEX3DolthLe&subscription=1&product_id=2
    const paramsObj = ["subscription", "session_id", "product_id"].reduce<
      Record<string, string>
    >((acc, key) => {
      acc[key] = params.get(key);
      return acc;
    }, {});

    // TODO: maybe we should fetch the subscription info and show it in modal instead
    if (
      profile &&
      paramsObj.subscription &&
      paramsObj.session_id &&
      paramsObj.product_id
    ) {
      const isImageEnhance = (profile?.subscription?.product_name ?? "")
        .toLowerCase()
        .includes("image enhance");

      setHasModal(true);
      navigate(isImageEnhance ? "/image-enhance" : `/settings/plan`);
    }
  }, [navigate, profile, search]);

  useEffect(() => {
    const timer = setInterval(() => {
      updateCounter((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(timer);
      setHasModal(false);
    }, DEFAULT_TIMER_VALUE * 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(timer);
    };
  }, []);

  if (!hasModal) {
    return null;
  }

  return (
    <Modal
      visible
      centered
      title="Subscription was successfully activated!"
      footer={null}
      onCancel={() => {
        setHasModal(false);
      }}
    >
      <span>This modal will be destroyed after {counter} second.</span>
    </Modal>
  );
};
