import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 5.11484C0.5 4.34165 0.835786 3.71484 1.25 3.71484H3.42493C3.83914 3.71484 4.17493 4.34165 4.17493 5.11484C4.17493 5.88804 3.83914 6.51484 3.42493 6.51484H1.25C0.835786 6.51484 0.5 5.88804 0.5 5.11484Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17493 5.11484C7.17493 4.72824 7.51071 4.41484 7.92493 4.41484H21.4249C21.8391 4.41484 22.1749 4.72824 22.1749 5.11484C22.1749 5.50144 21.8391 5.81484 21.4249 5.81484H7.92493C7.51071 5.81484 7.17493 5.50144 7.17493 5.11484Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 10.7148C0.5 9.94165 0.835786 9.31484 1.25 9.31484H3.42493C3.83914 9.31484 4.17493 9.94165 4.17493 10.7148C4.17493 11.488 3.83914 12.1148 3.42493 12.1148H1.25C0.835786 12.1148 0.5 11.488 0.5 10.7148Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17493 10.7148C7.17493 10.3282 7.51071 10.0148 7.92493 10.0148H21.4249C21.8391 10.0148 22.1749 10.3282 22.1749 10.7148C22.1749 11.1014 21.8391 11.4148 21.4249 11.4148H7.92493C7.51071 11.4148 7.17493 11.1014 7.17493 10.7148Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 16.3148C0.5 15.5416 0.835786 14.9148 1.25 14.9148H3.42493C3.83914 14.9148 4.17493 15.5416 4.17493 16.3148C4.17493 17.088 3.83914 17.7148 3.42493 17.7148H1.25C0.835786 17.7148 0.5 17.088 0.5 16.3148Z"
        fill="#7C7C78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17493 16.3148C7.17493 15.9282 7.51071 15.6148 7.92493 15.6148H21.4249C21.8391 15.6148 22.1749 15.9282 22.1749 16.3148C22.1749 16.7014 21.8391 17.0148 21.4249 17.0148H7.92493C7.51071 17.0148 7.17493 16.7014 7.17493 16.3148Z"
        fill="#7C7C78"
      />
    </svg>
  );
};
