import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7549 1.34418C12.2122 0.218709 13.7668 0.218709 14.2242 1.34418L16.633 7.85429C16.8273 8.33271 17.2831 8.64437 17.7885 8.64437H24.69C25.8548 8.64437 26.4381 10.0885 25.6144 10.9331L19.9242 16.1872C19.6027 16.5169 19.4809 16.9994 19.6057 17.4477L21.6375 24.7403C21.9805 25.9717 20.6417 26.974 19.6026 26.2637L13.684 22.2171C13.2634 21.9297 12.7156 21.9297 12.2951 22.2172L6.38625 26.257C5.34389 26.9696 4.00061 25.964 4.34478 24.7288L6.37329 17.4477C6.49819 16.9994 6.37627 16.5169 6.05478 16.1872L0.389893 10.9591C-0.44303 10.1049 0.14688 8.64437 1.32482 8.64437H8.19045C8.69586 8.64437 9.15169 8.33271 9.34608 7.85429L11.7549 1.34418Z"
        stroke="#C8A857"
        fill="currentColor"
      />
    </svg>
  );
};
