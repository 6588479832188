import ProductInput from "@components/ProductInput";
import styles from "./styles.module.scss";
import { mockProductDate } from "./mock";

interface ProductDateItem {
  productLabel: string;
  productValue: string | number;
  calendarTitle: string;
}

function ProductDate({
  setWhatIf,
  isWhatIf,
}: {
  setWhatIf: (value: boolean) => void;
  isWhatIf: boolean;
}) {
  const { productLabel }: ProductDateItem = mockProductDate;

  return (
    <div className={styles.container}>
      <div className={styles.productInputBox}>
        <ProductInput label={productLabel} />
      </div>
      <button
        className={styles.button}
        onClick={() => {
          setWhatIf(!isWhatIf);
        }}
      >
        {isWhatIf ? "Save Changes" : "Switch to what if"}
      </button>
    </div>
  );
}

export default ProductDate;
