import PageWrapper from "@components/PageWrapper/PageWrapper";
import Intro from "./components/Intro";

function ReferralPage() {
  return (
    <PageWrapper>
      <Intro />
    </PageWrapper>
  );
}

export default ReferralPage;
