import { Api } from "src/utils/api";
import { MATH_API_BASE_URL } from "./sankey.constant";
import { TreeNode } from "@components/TreeChart/chart.types";

export class SankeyApi extends Api {
  constructor() {
    super(MATH_API_BASE_URL);
  }

  async getTreeData(
    asin: string,
    date_start: string,
    date_end: string,
  ): Promise<TreeNode> {
    return this.get("ads-tree", {
      asin,
      date_start,
      date_end,
    });
  }
}
