export const Products = [
  {
    title:
      "Zephyr 24 Wine Fridge & Beverage Refrigerator Single Zone Under Counter - Mini Wine Cooler Cellars Small Beer Cabinet Drink Chiller Freestanding with French Glass Door Black, 7 Bottles, 112 Cans",
    asin: "B000123456",
    sessionTotal: 123,
    pageViewsTotal: 456,
    featuredOffer: 78,
    unitsOrdered: 90,
    unitsSessionPercentage: 12,
    orderedProductSales: 3456,
    totalOrderItems: 10,
    topProblems: [
      {
        title: "Formula changes",
        description:
          "Many reviewers have noticed a change in the formula of the vitamins, which has affected their taste, smell, and quality. Some people have reported receiving bottles with different colors or consistencies than expected.",
      },
      {
        title: "Taste complaints",
        description:
          "Several reviewers have expressed disappointment with the new formula's taste, describing it as unpleasant, bitter, or chemical-like. Some people find the old formula more enjoyable to eat.",
      },
      {
        title: "Decreased satisfaction",
        description:
          "Due to the changes in the formula and packaging, some long-time customers have experienced decreased satisfaction with the product. They may be less likely to recommend or order from Sugar Bear Hair again.",
      },
      {
        title: "Consistency issues",
        description:
          "One reviewer noted that some of their hair fell out after taking the vitamins, while another experienced hair loss despite using them regularly. These inconsistencies could indicate a potential issue with the product.",
      },
      {
        title: "Disappointing smell",
        description:
          "A couple of reviewers found the new formula to have an unpleasant smell, which might affect their willingness to continue using the product.",
      },
    ],
  },
  {
    title: "Product 2",
    asin: "B000654321",
    sessionTotal: 98,
    pageViewsTotal: 324,
    featuredOffer: 65,
    unitsOrdered: 120,
    unitsSessionPercentage: 14,
    orderedProductSales: 2789,
    totalOrderItems: 15,
    topProblems: [
      {
        title: "Formula changes",
        description:
          "Many reviewers have noticed a change in the formula of the vitamins, which has affected their taste, smell, and quality. Some people have reported receiving bottles with different colors or consistencies than expected.",
      },
      {
        title: "Taste complaints",
        description:
          "Several reviewers have expressed disappointment with the new formula's taste, describing it as unpleasant, bitter, or chemical-like. Some people find the old formula more enjoyable to eat.",
      },
      {
        title: "Decreased satisfaction",
        description:
          "Due to the changes in the formula and packaging, some long-time customers have experienced decreased satisfaction with the product. They may be less likely to recommend or order from Sugar Bear Hair again.",
      },
      {
        title: "Consistency issues",
        description:
          "One reviewer noted that some of their hair fell out after taking the vitamins, while another experienced hair loss despite using them regularly. These inconsistencies could indicate a potential issue with the product.",
      },
      {
        title: "Disappointing smell",
        description:
          "A couple of reviewers found the new formula to have an unpleasant smell, which might affect their willingness to continue using the product.",
      },
    ],
  },
  {
    title: "Product 3",
    asin: "B000789123",
    sessionTotal: 87,
    pageViewsTotal: 298,
    featuredOffer: 52,
    unitsOrdered: 110,
    unitsSessionPercentage: 10,
    orderedProductSales: 3150,
    totalOrderItems: 8,
    topProblems: [
      {
        title: "Formula changes",
        description:
          "Many reviewers have noticed a change in the formula of the vitamins, which has affected their taste, smell, and quality. Some people have reported receiving bottles with different colors or consistencies than expected.",
      },
      {
        title: "Taste complaints",
        description:
          "Several reviewers have expressed disappointment with the new formula's taste, describing it as unpleasant, bitter, or chemical-like. Some people find the old formula more enjoyable to eat.",
      },
      {
        title: "Decreased satisfaction",
        description:
          "Due to the changes in the formula and packaging, some long-time customers have experienced decreased satisfaction with the product. They may be less likely to recommend or order from Sugar Bear Hair again.",
      },
      {
        title: "Consistency issues",
        description:
          "One reviewer noted that some of their hair fell out after taking the vitamins, while another experienced hair loss despite using them regularly. These inconsistencies could indicate a potential issue with the product.",
      },
      {
        title: "Disappointing smell",
        description:
          "A couple of reviewers found the new formula to have an unpleasant smell, which might affect their willingness to continue using the product.",
      },
    ],
  },
];
