export default () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.164062 7.99993C0.164062 7.5581 0.522235 7.19993 0.964062 7.19993L12.9641 7.19993C13.4059 7.19993 13.7641 7.5581 13.7641 7.99993C13.7641 8.44175 13.4059 8.79993 12.9641 8.79993L0.964062 8.79993C0.522235 8.79993 0.164062 8.44175 0.164062 7.99993Z"
        fill="#141414"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9666 13.2001C10.6542 13.5125 10.1476 13.5125 9.83522 13.2001C9.5228 12.8876 9.5228 12.3811 9.83522 12.0687L13.3382 8.56575C13.6506 8.25333 13.6506 7.7468 13.3382 7.43438L9.83522 3.93143C9.5228 3.61901 9.5228 3.11248 9.83522 2.80006C10.1476 2.48764 10.6542 2.48764 10.9666 2.80006L15.6009 7.43438C15.9133 7.7468 15.9133 8.25333 15.6009 8.56575L10.9666 13.2001Z"
        fill="#141414"
      />
    </svg>
  );
};
