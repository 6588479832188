import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../router/routes";
import { IAuthState, useAuthStore } from "../../store/auth.state.ts";

interface IProps {
  children: ReactNode;
}

export const AuthGuard: React.FC<IProps> = ({ children }: IProps) => {
  const location = useLocation();

  const authenticated = useAuthStore(
    (state: IAuthState) => state.authenticated,
  );

  if (authenticated) {
    return <>{children}</>;
  }

  return <Navigate to={ROUTES.AUTH} state={{ from: location }} replace />;
};
