import { AmazonService } from "@services/amazon/amazon.service";

export const loadInsideProductData = async (product) => {
  const [hypotheses, phrases, problems, reviews] = await Promise.all([
    AmazonService.insights.getHypotheses(product.asin),
    AmazonService.insights.getPhrases(product.asin),
    AmazonService.insights.getProblems(product.asin),
    AmazonService.reviews.getOwnProducts(product.asin),
  ]);
  return { hypotheses, phrases, problems, reviews };
};
