import React from "react";
import styles from "./styles.module.scss";
import { Problem } from "src/store/insights/types";

interface TopProblemsProps {
  problems: Problem[];
}

const TopProblems: React.FC<TopProblemsProps> = ({ problems }) => {
  return (
    <div className={styles.container}>
      <h2>Top 5 Problems</h2>
      <div className={styles.problems}>
        {problems.slice(0, 5).map((problem, index) => (
          <div key={index} className={styles.problem}>
            <h3>{problem.problem}</h3>
            <p>{problem.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopProblems;
