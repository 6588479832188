import styles from "./styles.module.scss";
import { useMemo } from "react";
import { getDateItems } from "./utils";
import classNames from "classnames";

interface DropdownProps {
  handleItemClick: (label: string) => void;
  currentDate: Date;
}

export const DropdownItems = ({
  handleItemClick,
  currentDate,
}: DropdownProps) => {
  const items = useMemo(() => getDateItems(currentDate), [currentDate]);

  return (
    <>
      {items.map((item) => (
        <button
          key={item.label}
          className={styles.dropdownItem}
          onClick={() => {
            handleItemClick(item.label);
          }}
        >
          <span>{item.label}</span>
        </button>
      ))}
      <button
        className={classNames(styles.dropdownItem, styles.custom)}
        onClick={(e) => {
          e.stopPropagation();
          handleItemClick("Custom");
        }}
      >
        <span>Select custom date range</span>
      </button>
    </>
  );
};
