import React from "react";
import { Paper } from "@components/Paper";
import SvgIcon from "@components/svg-icon/SvgIcon";
import { Button, List } from "antd";
import cn from "classnames";
import "./offer.less";
import { Discount } from "@entities/Discount";
import { RecurringIntervalEnum } from "@entities/RecurringIntervalEnum";

type Props = {
  subscriptionId: number;
  priceId: number;
  title: string;
  benefits: string[];
  active?: boolean;
  price: string;
  onButtonClick?: (
    subscriptionId: number,
    priceId: number,
    event: React.MouseEvent,
  ) => void;
  loading?: boolean;
  discount?: Discount;
  interval: RecurringIntervalEnum;
} & React.HTMLAttributes<HTMLDivElement>;

export const Offer = ({
  subscriptionId,
  priceId,
  title,
  benefits,
  active,
  price,
  onButtonClick,
  loading,
  discount,
  interval,
  ...props
}: Props) => {
  const handleButtonClick = (event: React.MouseEvent) => {
    onButtonClick(subscriptionId, priceId, event);
  };

  return (
    <Paper {...props} className={cn("offer", { "is-active": active })}>
      <div className="offer__header">
        <p className="offer__title">{title}</p>
        {discount?.months && (
          <p className="offer__subtitle">
            {discount.months} months free&nbsp;
            {discount?.percent && (
              <span>(or {parseInt(discount.percent, 10)}% Off)</span>
            )}
          </p>
        )}
      </div>
      <div className="offer__body">
        <List
          className="offer__benefit-list"
          itemLayout="horizontal"
          dataSource={benefits}
          renderItem={(benefit) => (
            <List.Item className="offer__benefit">
              <SvgIcon type="checkmark" />
              <span>{benefit}</span>
            </List.Item>
          )}
        />

        <div className="offer__price">
          ${parseInt(price, 10)} / {interval}
          {discount?.amount && (
            <span className="offer__discount-amount">
              ${parseInt(price, 10) + parseInt(discount.amount, 10)}
            </span>
          )}
        </div>

        <div className="offer__action">
          {active ? (
            <Button
              className="offer__current"
              type="primary"
              block
              size="large"
              shape="round"
            >
              <SvgIcon type="checkmark" />
              <span>Your current plan</span>
            </Button>
          ) : (
            <Button
              className="offer__button"
              block
              size="large"
              shape="round"
              onClick={handleButtonClick}
              loading={loading}
            >
              <span>Try Now</span>
              <SvgIcon type="arrow-right" />
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
};
