import { DocumentsService } from "@services/DocumentsService";
import { notification } from "antd";
import { useQuery } from "react-query";

export const FoldersQueryKey = "folders";

export function useFolders() {
  return useQuery([FoldersQueryKey], () => DocumentsService.getFoldersList(), {
    onError: (error: any) => {
      notification.error({
        message: error?.response?.data?.detail ?? "Failed to load folders list",
      });
    },
  });
}
