import React from "react";
import styles from "./styles.module.scss";
import Video from "@assets/video/templates/default.jobtemplate.mp4.480.mp4";
import noVideo from "@assets/img/no-video.webp";

interface Video {
  position: number;
  media_link: string;
}

interface VideosSectionProps {
  videos: Video[];
}

const VideoSection: React.FC<VideosSectionProps> = ({ videos }) => {
  const videoSource = videos?.length > 0 ? videos[0].media_link : Video;

  return (
    <div className={styles.videoSlider}>
      {videos && videos.length > 0 ? (
        <div className={styles.mainVideo}>
          <iframe
            className={styles.video}
            src={videoSource}
            width="640"
            height="300"
            allow="autoplay"
          />
        </div>
      ) : (
        <img src={noVideo} alt="no video" />
      )}
      {videos && videos.length > 1 && (
        <div className={styles.sideVideos}>
          {videos.slice(1).map((video, index) => (
            <div className={styles.sideVideo} key={index}>
              <iframe
                className={styles.video}
                src={video.media_link}
                width="242"
                height="119"
                allow="autoplay"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoSection;
