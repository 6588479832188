import AuthButtonArrow from "@assets/icons/authButtonArrow";
import styles from "./styles.module.scss";
import Slider from "./Sider";
import { useNavigate } from "react-router-dom";

function AiPlatformSlider() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1 className={styles.title}>
          How eCommerce growth <br /> hackers leverage the most <br />{" "}
          <span>powerful AI platform</span>
        </h1>
        <button className={styles.button} onClick={handleNavigate}>
          go to dashboard <AuthButtonArrow />
        </button>
      </div>
      <div>
        <Slider />
      </div>
    </div>
  );
}

export default AiPlatformSlider;
