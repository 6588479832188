import { Button, Divider } from "antd";
import Logo from "@assets/img/logo.tsx";
import "./error-layout.less";
import { ErrorCard } from "./ErrorCard";

const SITEMAP_ROUTES = [
  { title: "Dashboard", to: "/" },
  { title: "Free Form Writing", to: "/free-form-writing" },
  { title: "Documents", to: "/documents" },
  { title: "AI Templates", to: "/ai-templates" },
];

type Props = {
  title?: string;
  code?: string;
  description?: string;
};

export const ErrorLayout = ({ title, code, description }: Props) => {
  return (
    <div className="error-layout">
      <div className="error-layout__header">
        <div className="container">
          <Logo className="error-layout__logo" />
          <p className="error-layout__title">
            {title ?? "Something went wrong."}
          </p>
          {description && (
            <p className="error-layout__description">{description}</p>
          )}
          <span className="error-layout__code">{code ?? "Oops"}</span>
        </div>
        {!description && (
          <>
            <Divider orientationMargin={30} className="error-layout__divider" />
            <div className="container">
              <div className="error-layout__subtitle">
                Dear user, for some reason an error occurred.
              </div>
              <div className="error-layout__text">
                If you are sure that it was our fault, please let us know about
                this problem and do not forget to specify the address from which
                you got here.
              </div>
              <Button
                type="link"
                href="mailto:support@nyle.ai"
                className="error-layout__contact-button"
              >
                support@nyle.ai
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="error-layout__content">
        <div className="container">
          <div className="error-layout__subtitle">Sitemap</div>
          <div className="error-layout__cards">
            {SITEMAP_ROUTES.map((props) => (
              <ErrorCard key={props.to} {...props} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
