import { Button, Modal } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

type LocationState = {
  needToShowPurchaseModal?: boolean;
};

export const PurchaseSubscriptionModal = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  if (!(state as LocationState)?.needToShowPurchaseModal) {
    return null;
  }

  return (
    <Modal
      visible
      centered
      title="To use our service, please purchase a subscription"
      footer={null}
      onCancel={() => {
        navigate(pathname, { replace: true });
      }}
    >
      <NavLink to="/settings/plan" state={{ showErrorMessage: true }}>
        <Button type="primary">Go to Plans</Button>
      </NavLink>
    </Modal>
  );
};
