import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import ModalBox from "@components/ModalBox";
import { useProductsStore } from "src/store/product.state";
import { AmazonService } from "@services/amazon/amazon.service";
import styles from "./styles.module.scss";
import SearchIcon from "@assets/icons/SearchIcon.svg";
import CheckIcon from "@assets/icons/CheckIcon.svg";
import dropDownArrow from "@assets/icons/inputArrow.svg";
import ProductCode from "@components/ProductCode";
import { Product } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { OverviewType } from "@pages/Overview";
import { useLocation } from "react-router-dom";
import { CategoriesService } from "@services/amazon/categories/categories.service";

export type ProductTab = "myProducts" | "allProducts";

interface ProductModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSelect: (product: Product, searchTerm: string) => void;
  productTwo?: boolean;
  type: OverviewType;
  handleApply?: (selectedProducts: Product[]) => void;
}

const ProductSelectionModal: React.FC<ProductModalProps> = ({
  isOpen,
  onClose,
  onSelect,
  productTwo = false,
  type,
  handleApply,
}) => {
  const {
    selectedProduct,
    selectedProductToCompare,
    setSelectedProduct,
    setSelectedProductToCompare,
  } = useProductStore();

  const tabs = productTwo
    ? [
        { tab: "myProducts", label: "Our Products" },
        { tab: "allProducts", label: "Other Products" },
      ]
    : ([{ tab: "myProducts", label: "Our Products" }] satisfies {
        tab: ProductTab;
        label: string;
      }[]);
  const location = useLocation();
  const isCompareRoute = location.pathname.includes("/compare");
  const isMyRoute = location.pathname.includes("/my-product");
  const [activeTab, setActiveTab] = useState<ProductTab>(
    tabs[0].tab as ProductTab,
  );
  const [activeButton, setActiveButton] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    productsOwn = [],
    productsPublic = [],
    setProductDataByAsin,
    setProductMaterials,
  } = useProductsStore();
  const [spiders, setSpiders] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = activeButton;
  const [searchResults, setSearchResults] = useState<Product[]>([]);

  const paginatedItems = selectedItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const params = {
          asin: searchTerm,
          limit: activeButton,
        };
        if (searchTerm) {
          if (activeTab === "myProducts") {
            await AmazonService.products.searchOwnProducts(params);
          } else {
            const response =
              await AmazonService.products.searchPublicProducts(params);
            setSearchResults(response);
          }
        } else {
          setSearchResults([]);
          if (activeTab === "myProducts") {
            await AmazonService.products.getOwnProducts(params);
          } else {
            await AmazonService.products.getPublicProducts(params);
          }
        }
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };

    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen, activeTab, activeButton, searchTerm]);

  const productsToDisplay =
    activeTab === "myProducts" ? productsOwn : productsPublic;

  const handleSelect = (product: Product) => {
    setProductDataByAsin(null);
    setProductMaterials(null);
    if (activeTab === "allProducts") {
      if (isCompareRoute) {
        if (selectedProductToCompare.some((p) => p.asin === product.asin)) {
          setSelectedProductToCompare(
            selectedProductToCompare.filter((p) => p.asin !== product.asin),
          );
        } else {
          setSelectedProductToCompare([...selectedProductToCompare, product]);
        }
      } else {
        setSelectedProductToCompare([product]);

        onSelect(product, searchTerm);

        if (onClose) {
          onClose();
        }
      }
    } else if (activeTab === "myProducts") {
      setSelectedProduct(product);

      onSelect(product, searchTerm);

      if (onClose) {
        onClose();
      }
    }
  };

  const applySelection = () => {
    if (handleApply) {
      handleApply(selectedProductToCompare);

      useProductStore
        .getState()
        .setSelectedProductToCompare(selectedProductToCompare);
    }
    onClose();
  };

  const handleCategoryClick = (index: number) => {
    setSelectedItems(spiders[index].items);
    setIsCategorySelected(true);
  };

  const renderTabButton = (tab: ProductTab, label: string) => (
    <button
      key={tab}
      className={classNames(styles.tabButton, {
        [styles.active]: activeTab === tab,
      })}
      onClick={() => setActiveTab(tab as ProductTab)}
    >
      {label.toUpperCase()}
    </button>
  );

  const renderSwitchButton = (value: number) => (
    <button
      key={value}
      className={classNames(styles.switchButton, {
        [styles.active]: activeButton === value,
      })}
      onClick={() => setActiveButton(value)}
    >
      {value}
    </button>
  );
  const handleBackClick = () => {
    setIsCategorySelected(false);
  };

  const fetchCategories = async () => {
    const spider = new CategoriesService();
    if (isMyRoute && activeTab === "allProducts") {
      const spidersData = await spider.getCategories(selectedProduct?.asin);
      setSpiders(spidersData);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen, activeTab === "allProducts"]);

  return (
    <ModalBox
      isOpen={isOpen}
      onClose={onClose ? onClose : () => {}}
      title="Choose ASIN"
      hideCloseButton={!onClose}
    >
      <div className={styles.tabButtons}>
        <div className={styles.tabButtonsWrapper}>
          {tabs.map(({ tab, label }) =>
            renderTabButton(tab as ProductTab, label),
          )}
        </div>
      </div>

      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search by product name or ASIN"
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className={styles.searchButton}>
          <img src={SearchIcon} alt="Search" />
        </button>
      </div>

      <div className={styles.sortSection}>
        <div className={styles.sortSelect}>
          <span className={styles.sortText}>SORT ALPHABETICALLY</span>
          <img
            src={dropDownArrow}
            alt="Sort"
            className={styles.dropDownArrow}
          />
        </div>
        <div className={styles.switchButtons}>
          {[8, 16, 32, 60].map(renderSwitchButton)}
        </div>
      </div>

      {activeTab === "myProducts" ? (
        <div className={styles.productGridContainer}>
          <div className={styles.productGrid}>
            {Array.isArray(productsToDisplay) &&
              productsToDisplay.map((product, index) => (
                <div
                  key={`${activeTab}-${product.asin}-${index}`}
                  className={styles.productCard}
                >
                  <img
                    src={product.image_link || product.picture_url || ""}
                    alt="Product image"
                    className={styles.productImage}
                    width={124}
                    height={124}
                  />
                  <ProductName name={product.item_name || product.title} />
                  <div className={styles.productDetails}>
                    <ProductCode code={product.asin} />
                  </div>
                  <button
                    className={classNames(styles.selectButton, {
                      [styles.selected]: selectedProductToCompare.some(
                        (p) => p.asin === product.asin,
                      ),
                    })}
                    onClick={() => handleSelect(product)}
                  >
                    {selectedProductToCompare.some(
                      (p) => p.asin === product.asin,
                    ) ? (
                      <>
                        <img
                          src={CheckIcon}
                          alt="Selected"
                          className={styles.checkIcon}
                        />
                        SELECT
                      </>
                    ) : (
                      "SELECT"
                    )}
                  </button>
                </div>
              ))}
          </div>
        </div>
      ) : null}

      {activeTab === "allProducts" && (
        <div className={styles.productGridContainer}>
          <div className={styles.backButton}>
            {isCategorySelected && (
              <button onClick={handleBackClick} className={styles.bcButton}>
                Back to Categories
              </button>
            )}
          </div>
          <div className={styles.categoryListWrapper}>
            {isCategorySelected ? (
              <div className={styles.productGridContainer}>
                <div className={styles.productGrid}>
                  {searchResults.length > 0 ? (
                    searchResults.map((item: Product, itemIndex: number) => (
                      <div
                        key={`${activeTab}-${item.asin}-${itemIndex}`}
                        className={styles.productCard}
                      >
                        <img
                          src={item.picture_url || ""}
                          alt={item.title}
                          className={styles.productImage}
                          width={124}
                          height={124}
                        />
                        <ProductName name={item.title} />
                        <div className={styles.productDetails}>
                          <ProductCode code={item.asin} />
                        </div>
                        <button
                          className={classNames(styles.selectButton, {
                            [styles.selected]: selectedProductToCompare.some(
                              (p) => p.asin === item.asin,
                            ),
                          })}
                          onClick={() => handleSelect(item)}
                        >
                          {selectedProductToCompare.some(
                            (p) => p.asin === item.asin,
                          ) ? (
                            <>
                              <img
                                src={CheckIcon}
                                alt="Selected"
                                className={styles.checkIcon}
                              />
                              SELECT
                            </>
                          ) : (
                            "SELECT"
                          )}
                        </button>
                      </div>
                    ))
                  ) : paginatedItems && paginatedItems.length > 0 ? (
                    paginatedItems.map((item: any, itemIndex: number) => (
                      <div
                        key={`${activeTab}-${item.asin}-${itemIndex}`}
                        className={styles.productCard}
                      >
                        <img
                          src={item.image}
                          alt={item.title}
                          className={styles.productImage}
                          width={124}
                          height={124}
                        />
                        <ProductName name={item.title} />
                        <div className={styles.productDetails}>
                          <ProductCode code={item.asin} />
                        </div>
                        <button
                          className={classNames(styles.selectButton, {
                            [styles.selected]: selectedProductToCompare.some(
                              (p) => p.asin === item.asin,
                            ),
                          })}
                          onClick={() => handleSelect(item)}
                        >
                          {selectedProductToCompare.some(
                            (p) => p.asin === item.asin,
                          ) ? (
                            <>
                              <img
                                src={CheckIcon}
                                alt="Selected"
                                className={styles.checkIcon}
                              />
                              SELECT
                            </>
                          ) : (
                            "SELECT"
                          )}
                        </button>
                      </div>
                    ))
                  ) : (
                    <p>No items available for this category.</p>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.productGridContainer}>
                <div className={styles.categoryListWrapper}>
                  {spiders &&
                    spiders.length > 0 &&
                    spiders.map((spider: any, index: number) => (
                      <div
                        className={styles.categoryItem}
                        key={index}
                        onClick={() => handleCategoryClick(index)}
                      >
                        <span className={styles.categoryText}>
                          {spider.Sub_category || "N/A"}
                        </span>
                        <span className={styles.categoryArrow}>›</span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === "allProducts" && isCompareRoute && (
        <button className={styles.applyButton} onClick={applySelection}>
          Apply
        </button>
      )}
    </ModalBox>
  );
};

export const ProductName: React.FC<{ name: string }> = ({ name }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const productNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const element = productNameRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [name]);

  return (
    <p
      ref={productNameRef}
      className={classNames(styles.productName, {
        [styles.truncated]: isTruncated,
      })}
    >
      {name}
    </p>
  );
};

export default ProductSelectionModal;
