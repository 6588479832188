import { ChartDataset } from "chart.js";

type OneOrTwoDataSet = [
  ChartDataset<"radar", number[]>,
  ChartDataset<"radar", number[]>?,
];

const defaultDatasets: OneOrTwoDataSet = [
  {
    label: "Your product",
    data: [2.9, 3.5, 2.5, 3.5, 1.5, 3.8, 2.8],
    backgroundColor: "rgba(82, 149, 224, 0.08)",
    borderColor: "#5295E0",
    pointBackgroundColor: "transparent",
    pointHoverBackgroundColor: "#5295E0",
    pointBorderColor: "transparent",
    pointHoverBorderColor: "#5295E0",
    pointRadius: 10,
    pointHoverRadius: 6,
    borderWidth: 1,
  },
  {
    label: "Competitors average",
    data: [4.1, 4.7, 3.8, 3.9, 4.8, 4.2, 4.7],
    backgroundColor: "transparent",
    borderColor: "#00EEFF",
    pointBackgroundColor: "transparent",
    pointHoverBackgroundColor: "#00EEFF",
    pointBorderColor: "transparent",
    pointHoverBorderColor: "#00EEFF",
    pointRadius: 10,
    pointHoverRadius: 6,
    borderWidth: 1,
  },
];

const setValuesIfNotEmpty = (
  dataset: ChartDataset<"radar", number[]>,
  values: number[],
) => (values.length ? values : dataset.data);

export const setRadarData = (
  values1: number[] = [],
  values2: number[] = [],
  datasets = defaultDatasets,
): ChartDataset<"radar", number[]>[] => {
  const minLengthFilledValues =
    Math.min(values1.length, values2.length) ||
    values1.length ||
    values2.length;

  return datasets.map((dataset, i) => ({
    ...dataset,
    data: (i === 0
      ? setValuesIfNotEmpty(dataset, values1)
      : setValuesIfNotEmpty(dataset, values2)
    ).slice(0, minLengthFilledValues),
  }));
};
