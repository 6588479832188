import React from "react";
import SvgIcon from "@components/svg-icon/SvgIcon";

import "./social-account.less";

interface Props {
  children: React.ReactNode;
  isConnected?: boolean;
  design: "green" | "blue";
}

export const SocialAccount = ({ children, isConnected, design }: Props) => {
  return (
    <div
      className={`social-account social-account_design-${design} ${
        isConnected ? "social-account_is-connected" : ""
      }`}
    >
      <div className="social-account__btn">{children}</div>
      <div className="social-account__status">
        <SvgIcon type={isConnected ? "checkmark" : "close"} />
        {isConnected ? "Connected" : "Unconnected"}
      </div>
    </div>
  );
};
