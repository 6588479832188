import { Graph } from "./chart.types";

export const colorMap: { [key: string]: string } = {
  BRAND: "#14C5A4",
  BOF: "#14C5A4",
  "Sponsored Products": "#14C5A4",
  COMPETITOR: "#D48997",
  TOF: "#D48997",
  "Sponsored Display": "#D48997",
  GENERIC: "#FDD063",
  MOF: "#FDD063",
  "Sponsored Brands": "#FDD063",
  "Brand KW targeting (BRKW)": "#42EE72",
  "Foreign language KW targeting (FLKW)": "#87E070",
  "Brand product targeting (COPR)": "#B3E99A",
  "Category targeting (CAT)": "#67F1D0",
  "Competitors KW targeting (COKW)": "#CD6D6D",
  "Competitors product targeting (COPR)": "#E58AC1",
  "Video competitor product targeting (SBV COPR)": "#FFADAD",
  "SD competitor product targeting": "#F281AA",
  "Generic HIGH relevancy KW targeting (GEKW HI)": "#FDEE63",
  "Generic MID relevancy KW targeting (GEKW MID)": "#FFE095",
  "Generic LOW relevancy KW targeting (GEKW LOW)": "#F6FF90",
  "Video generic KW targeting (SBV GEKW)": "#C8A857",
};

export class SunkeyChart {
  clearOpacity = (data: Graph): Graph => {
    data.nodes = this.updateNodes(data.nodes, { opacity: 1 });
    data.links = this.updateLinks(data.links, { opacity: 0.4 });
    return data;
  };

  highlightNode = (name: string, data: Graph) => {
    const tooltipData = { nodeName: name, children: {} };

    if (name.includes("--")) {
      const [source, target] = name.split(" -- ").map((n) => n.trim());
      this.highlightSourceTargetNodes(data, source, target);
      this.highlightSourceTargetLinks(data, source, target);
    } else {
      const chosenNodes = this.highlightDirectConnections(data, name);
      if (["BRAND", "COMPETITOR", "GENERIC"].includes(name)) {
        this.buildTooltipData(data, name, chosenNodes, tooltipData);
        this.resetUnrelatedNodes(data.nodes, tooltipData.children, name);
      }
    }

    return { data, tooltipData };
  };

  formatData = (data: Graph): Graph => {
    data.nodes = data.nodes.map((node) => ({
      ...node,
      itemStyle: { ...node.itemStyle, color: this.getColor(node.name) },
    }));

    data.links = data.links.map((link) => {
      if (link.target.startsWith("predict:")) {
        link.lineStyle = {};
      }
      return link;
    });

    return data;
  };

  getOption = (data: Graph) => ({
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",
      formatter: (params: any) => {
        return `
          <div style="border-radius: 5px; color: #000">
            ${params.name}
          </div>`;
      },
      borderColor: "#777",
      textStyle: { color: "#fff" },
    },
    series: {
      type: "sankey",
      draggable: false,
      layoutIterations: 0,
      layout: "none",
      emphasis: {
        focus: "adjacency",
        lineStyle: { width: 2 },
      },
      data: data.nodes,
      links: data.links,
      label: {
        fontSize: 12,
        color: "#fff",
        textBorderColor: "none",
      },
      lineStyle: {
        color: "gradient",
        curveness: 0.5,
      },
    },
  });

  getColor = (name: string): string | null => {
    return colorMap[name] || null;
  };

  private updateNodes = (nodes: any[], itemStyle: object) =>
    nodes.map((node) => ({
      ...node,
      itemStyle: { ...node.itemStyle, ...itemStyle },
      label: { ...node.label, color: "#fff" },
    }));

  private updateLinks = (links: any[], lineStyle: object) =>
    links.map((link) => ({
      ...link,
      lineStyle: { ...link.lineStyle, ...lineStyle },
    }));

  private highlightSourceTargetNodes = (
    data: Graph,
    source: string,
    target: string,
  ) => {
    data.nodes = data.nodes.map((node) => {
      if (node.name === source || node.name === target) {
        node.itemStyle = {
          ...node.itemStyle,
        };
      } else {
        node.itemStyle = { ...node.itemStyle, opacity: 0.1 };
        node.label = { ...node.label, color: "rgba(255, 255, 255, 0.5)" };
      }
      return node;
    });
  };

  private highlightSourceTargetLinks = (
    data: Graph,
    source: string,
    target: string,
  ) => {
    data.links = data.links.map((link) => {
      if (link.source === source && link.target === target) {
        link.itemStyle = {
          ...link.itemStyle,
          borderWidth: 20,
          borderType: "dashed",
        };
      } else {
        link.lineStyle = { ...link.lineStyle, opacity: 0.1 };
      }
      return link;
    });
  };

  private highlightDirectConnections = (data: Graph, name: string) => {
    const chosenNodes: string[] = [];
    data.links = data.links.map((link) => {
      if (link.source === name || link.target === name) {
        chosenNodes.push(link.source, link.target);
        return link;
      }
      link.lineStyle = { opacity: 0.1 };
      return link;
    });

    data.nodes = data.nodes.map((node) => ({
      ...node,
      itemStyle: {
        opacity: chosenNodes.includes(node.name) ? 1 : 0.1,
      },
      label: {
        ...node.label,
        color: chosenNodes.includes(node.name)
          ? "#fff"
          : "rgba(255, 255, 255, 0.5)",
      },
    }));

    return chosenNodes;
  };

  private buildTooltipData = (
    data: Graph,
    name: string,
    chosenNodes: string[],
    tooltipData: any,
  ) => {
    data.links.forEach((link) => {
      if (
        (link.source === name || link.target === name) &&
        link.source !== link.target
      ) {
        const childNode = link.source === name ? link.target : link.source;

        if (childNode !== name) {
          if (!tooltipData.children[childNode]) {
            tooltipData.children[childNode] = {
              valueToChild: 0,
              valueFromChild: 0,
            };
          }

          if (link.source === name) {
            tooltipData.children[childNode].valueToChild += link.value;
          }
        }
      }
    });

    chosenNodes.forEach((nodeName) => {
      data.links.forEach((link) => {
        if (
          link.source === nodeName &&
          link.source !== name &&
          link.source !== link.target
        ) {
          if (!tooltipData.children[nodeName]) {
            tooltipData.children[nodeName] = {
              valueToChild: 0,
              valueFromChild: 0,
            };
          }
          tooltipData.children[nodeName].valueFromChild += link.value;
        }
      });
    });
  };

  private resetUnrelatedNodes = (nodes: any[], children: any, name: string) => {
    nodes.forEach((node) => {
      node.itemStyle = {
        opacity: node.name === name || children[node.name] ? 1 : 0.1,
      };
    });
  };
}
