import Logo from "@assets/img/logo.tsx";
import { Link } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import "./styles.scss";
import { AuthServices } from "@services/auth.services";
import { notification } from "antd";
import { AuthProvider } from "src/constants/auth.constants";
import { useTranslation } from "react-i18next";
import { LS } from "src/constants/localStorage.constants";

const RedirectToAmazon = () => {
  const { t } = useTranslation();

  const onRedirect = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    AuthServices.getRedirectUrl(AuthProvider.AmazonAdsAccount)
      .then(({ url }) => {
        localStorage.setItem(LS.AuthProvider, AuthProvider.AmazonAdsAccount);
        url && window.location.replace(url);
      })
      .catch((error) => {
        notification.error({
          message:
            error?.response?.data?.detail ??
            t("message.get_amazon_seller_redirect_url_fail_message"),
        });
      });
  };

  return (
    <>
      <Link to={ROUTES.ROOT} className="logo">
        <Logo />
      </Link>

      <section className="section section--center s-redirect-to-amazon">
        <div className="ml-auto mr-auto" style={{ maxWidth: 700 }}>
          <h1 className="h1 s-redirect-to-amazon__title">
            You will be redirected to Amazon page
          </h1>
          <h3 className="h3 s-redirect-to-amazon__subtitle">
            To authorize Nyle access to your Amazon account
          </h3>

          <div className="s-redirect-to-amazon__text">
            <p>
              <a onClick={onRedirect}>Click here</a>
              &nbsp;if a new tab hasn’t opened
            </p>
          </div>

          <button className="button" onClick={onRedirect}>
            Click here <AuthButtonArrow />
          </button>
        </div>
      </section>
    </>
  );
};

export default RedirectToAmazon;
