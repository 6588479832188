import classNames from "classnames";
import styles from "./styles.module.scss";

interface InputProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  icon?: React.ReactNode;
  label?: string;
  className?: string;
}

export const Input = ({
  value,
  placeholder,
  onChange,
  icon,
  label,
  className,
}: InputProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      {label && <label>{label}</label>}
      <div className={styles.input}>
        <span className={styles.icon}>{icon}</span>
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
