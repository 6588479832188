import { Api } from "src/utils/api";
import { ADDS_SALES_API_BASE_URL } from "./adds-sales.constant";

export class AddsSalesApi extends Api {
  constructor() {
    super(ADDS_SALES_API_BASE_URL);
  }

  public getImpressions = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("impressions", {
      date_start,
      date_end,
    });
  };

  public getClicks = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("clicks", {
      date_start,
      date_end,
    });
  };

  public getOrders = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("orders", {
      date_start,
      date_end,
    });
  };

  public getUnitsSold = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("units-sold", {
      date_start,
      date_end,
    });
  };

  public getSales = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("sales", {
      date_start,
      date_end,
    });
  };

  public getSpend = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("spend", {
      date_start,
      date_end,
    });
  };

  public getAcos = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("acos", {
      date_start,
      date_end,
    });
  };

  public getRoas = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("roas", {
      date_start,
      date_end,
    });
  };

  public getCpc = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("cpc", {
      date_start,
      date_end,
    });
  };

  public getCvr = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("cvr", {
      date_start,
      date_end,
    });
  };

  public getCtr = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("ctr", {
      date_start,
      date_end,
    });
  };
}
