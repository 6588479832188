import React, { ReactNode, useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import ProductDetails from "./ProductDetails";
import ProductImage from "./ProductImage";
import ProductCard from "./ProductCard";
import ProductHeaderTitle from "./components/ProductHeaderTitle";
import VideoSection from "./VideoSection";
import DefaultOptimization from "./Optimisation";
import classNames from "classnames";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductsStore } from "src/store/product.state";
import { useProductStore } from "src/store/overviewProduct.state";
import Flag from "@assets/img/flag.svg";
import { content } from "./components/AContent/mock";
import AContent from "./components/AContent";
import OptimisationArrow from "@assets/icons/optimisationArrow";
import { useLocation, useNavigate } from "react-router-dom";

interface ProductDetailPageProps {
  sideSlot?: ReactNode;
  isWhatIf: boolean;
}

const ProductDetailPage: React.FC<ProductDetailPageProps> = ({
  sideSlot,
  isWhatIf,
}) => {
  const [showAContent, setShowAContent] = useState(false);
  const { productDataByAsin, productMaterials, setProductMaterials } =
    useProductsStore();
  const { selectedProduct } = useProductStore();
  const [isUpdated, setIsUpdated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
      if (!selectedProduct?.asin) return;

      try {
        if (!productDataByAsin) {
          await AmazonService.products.getProductDataByAsin([
            selectedProduct.asin,
          ]);
        }

        if (!productMaterials) {
          const [imagesData, videosData] = await Promise.all([
            AmazonService.products.getProductDataByAsinImgs([
              selectedProduct.asin,
            ]),
            AmazonService.products.getProductDataByAsinVideos([
              selectedProduct.asin,
            ]),
          ]);

          setProductMaterials({
            images: imagesData as any,
            videos: videosData as any,
          });
        }
      } catch (error) {
        console.error(
          "Error fetching product data and materials by ASIN:",
          error,
        );
      }
    };

    fetchProductData();
  }, [selectedProduct]);

  const handleAContent = () => {
    setShowAContent(!showAContent);
  };

  useEffect(() => {
    setIsUpdated(false);
  }, [selectedProduct]);

  useEffect(() => {
    if (location.state && location.state.isUpdated !== undefined) {
      setIsUpdated(location.state.isUpdated);
    }
  }, [location.state]);

  useEffect(() => {
    if (isUpdated) {
      navigate(location.pathname, { replace: true });
    }
  }, [isUpdated, navigate, location.pathname]);

  return (
    <div className={styles.productDetailBox}>
      {productDataByAsin && (
        <div className={styles.productCode}>
          <img
            src={Flag}
            alt="USA Flag"
            className={classNames(styles.flag)}
            width={13}
            height={11}
          />
          <span className={classNames(styles.code)}>
            {!productDataByAsin ? "" : productDataByAsin.asin}
          </span>
        </div>
      )}
      <div className={styles.container}>
        <div className={classNames(styles.leftColumn, styles.sticky)}>
          <ProductCard
            title={productDataByAsin?.item_name}
            price={productDataByAsin?.price}
            image={productDataByAsin?.image_link}
            isUpdated={isUpdated}
          />
        </div>

        <div className={classNames(styles.middleColumn, styles.sticky)}>
          <div className={styles.productDetailPageWrapper}>
            <ProductHeaderTitle title="Product detail page" />
            <div className={styles.productDetailPage}>
              <div className={styles.imageAndDetails}>
                <ProductImage images={productMaterials?.images} />
                <ProductDetails
                  name={productDataByAsin?.item_name}
                  points={productDataByAsin?.bullet_points}
                  isUpdated={isUpdated}
                />
              </div>
              <VideoSection videos={productMaterials?.videos} />
              <div className={styles.aPlusContent}>
                <button type="button" onClick={handleAContent}>
                  A+ Content
                  <div
                    className={`${styles.arrow} ${showAContent ? styles.rotated : ""}`}
                  >
                    <OptimisationArrow />
                  </div>
                </button>
                {showAContent && <AContent data={content} />}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.rightColumn, styles.sticky)}>
          <div className={styles.optimisation}>
            {sideSlot}
            <DefaultOptimization isWhatIf={isWhatIf} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPage;
