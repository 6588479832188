import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";
import { Spin } from "antd";

interface WidgetsListProps {
  widgets: IWidget[];
}

const WidgetsList: React.FC<WidgetsListProps> = ({ widgets }) => {
  const { setWidgets, setModalWidgets } = useDashboardStore((state) => state);

  const [widgetList, setWidgetList] = useState<IWidget[]>(widgets);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setWidgetList(widgets.slice(0, 10));
    if (widgets.length > 0) {
      const isSomeNaN = widgets.some((widget) => widget.value === "N/A");
      if (!isSomeNaN) {
        setIsLoading(false);
      }
    }
  }, [widgets]);

  useEffect(() => {
    setWidgets(widgetList);
    setModalWidgets(widgetList);
  }, [widgetList, setWidgets, setModalWidgets]);

  const handleDragStart = (index: number) => {
    setDraggingIndex(index);
  };

  const handleDrop = (dropIndex: number) => {
    if (draggingIndex !== null && draggingIndex !== dropIndex) {
      const updatedList = [...widgetList];
      const draggedWidget = updatedList[draggingIndex];

      updatedList.splice(draggingIndex, 1);
      updatedList.splice(dropIndex, 0, draggedWidget);

      setWidgetList(updatedList);
    }

    setDraggingIndex(null);
    setHoverIndex(null);
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    e.preventDefault();
    setHoverIndex(index);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
    setHoverIndex(null);
  };

  return (
    <div className={styles.widgetsList}>
      <div className={styles.flexBox}>
        {isLoading ? (
          <div className={styles.loader}>
            <Spin size="large" />
          </div>
        ) : (
          widgetList.map((widget, index) => (
            <div
              key={widget.id}
              className={`${styles.widgetItem} ${
                draggingIndex === index ? styles.dragging : ""
              } ${hoverIndex === index ? styles.placeholder : ""}`}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDrop={() => handleDrop(index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnd={handleDragEnd}
            >
              <Widget widget={widget} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default React.memo(WidgetsList);
