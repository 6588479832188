export const initializeCalendarData = () => {
  const today = new Date();
  const monday = new Date(today);
  monday.setDate(today.getDate() - 7);

  return {
    today,
    monday,
  };
};
