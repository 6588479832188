export default () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66118 6.85501C8.65731 7.76408 8.66118 8.6731 8.66511 9.58212C10.2198 9.63179 11.7783 9.60883 13.333 9.63179C12.6472 13.012 7.956 14.1082 5.474 11.9006C2.92183 9.96409 3.04262 5.71683 5.69608 3.91785C7.55077 2.46643 10.1886 2.82545 12.0433 4.08208C12.7719 3.42132 13.4537 2.7147 14.1122 1.98136C12.5693 0.774403 10.6756 -0.0849935 8.66118 0.00669913C4.45702 -0.130814 0.591803 3.4786 0.521688 7.59985C0.25281 10.9686 2.51273 14.2725 5.70389 15.4642C8.88332 16.6635 12.9589 15.8461 14.989 13.0464C16.3293 11.278 16.6176 9.0016 16.4618 6.86266C13.859 6.84355 11.2601 6.84735 8.66118 6.85501Z"
        fill="#80C67A"
      />
    </svg>
  );
};
