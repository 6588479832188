export default () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5013 3C8.03174 3 8.54044 3.21071 8.91552 3.58579C9.29059 3.96086 9.5013 4.46957 9.5013 5C9.5013 5.53043 9.29059 6.03914 8.91552 6.41421C8.54044 6.78929 8.03174 7 7.5013 7C6.97087 7 6.46216 6.78929 6.08709 6.41421C5.71202 6.03914 5.5013 5.53043 5.5013 5C5.5013 4.46957 5.71202 3.96086 6.08709 3.58579C6.46216 3.21071 6.97087 3 7.5013 3ZM7.5013 0C10.8346 0 13.6813 2.07333 14.8346 5C13.6813 7.92667 10.8346 10 7.5013 10C4.16797 10 1.3213 7.92667 0.167969 5C1.3213 2.07333 4.16797 0 7.5013 0ZM1.6213 5C2.16014 6.1002 2.99684 7.02717 4.03629 7.6755C5.07574 8.32384 6.27623 8.66755 7.5013 8.66755C8.72637 8.66755 9.92687 8.32384 10.9663 7.6755C12.0058 7.02717 12.8425 6.1002 13.3813 5C12.8425 3.8998 12.0058 2.97283 10.9663 2.3245C9.92687 1.67616 8.72637 1.33245 7.5013 1.33245C6.27623 1.33245 5.07574 1.67616 4.03629 2.3245C2.99684 2.97283 2.16014 3.8998 1.6213 5Z"
        fill="#141414"
      />
    </svg>
  );
};
