import { generateDays } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { SalesPerformanceApi } from "@services/sales-performance/sales-performance.api";
import { Checks } from "../features/SalesPerformance/types";

export interface MetricData {
  date: number;
  [key: string]: number;
}

export const generateData = (
  startDate: number,
  endDate: number,
): MetricData[] => {
  const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
  const totalDays = Math.floor((endDate - startDate) / MILLISECONDS_IN_DAY);

  const data: MetricData[] = [];

  for (let i = 0; i <= totalDays; i++) {
    const date = startDate + i * MILLISECONDS_IN_DAY;

    const totalImpressions = Math.floor(Math.random() * 2_000_000) + 1_000_000;

    const adImpressions = Math.round(totalImpressions * 0.7);
    const organicImpressions = totalImpressions - adImpressions;

    const totalMetrics = generateMetrics("Total", totalImpressions);
    const adMetrics = generateMetrics("Ad", adImpressions);
    const organicMetrics = generateMetrics("Organic", organicImpressions);

    const subscriptionSales = Math.round(Math.random() * 1000 + 500);
    const ntbSales = Math.round(Math.random() * 1000 + 500);
    const contributionProfit = Math.round(Math.random() * 1000 + 500);
    // const totalSales = totalSalesData[i]

    data.push({
      date,
      ...totalMetrics,
      ...adMetrics,
      ...organicMetrics,
      SubscriptionSales: subscriptionSales,
      NtbSales: ntbSales,
      ContributionProfit: contributionProfit,
      // TotalSales: totalSales,
    });
  }

  return data;
};

const generateMetrics = (prefix: string, impressions: number) => {
  const ctr = Math.random() * (0.012 - 0.008) + 0.008;
  const clicks = Math.round(impressions * ctr);

  const cvr = Math.random() * (0.052 - 0.038) + 0.038;
  const orders = Math.round(clicks * cvr);

  const unitsSold = Math.round(orders * (1 - (Math.random() * 0.02 + 0.05)));

  const sales = Math.round(unitsSold * (Math.random() * 2 + 29));

  const spend = Math.round(sales * 0.3);

  return {
    [`${prefix}Impressions`]: impressions,
    [`${prefix}CTR`]: ctr * 100,
    [`${prefix}Clicks`]: clicks,
    [`${prefix}CVR`]: cvr * 100,
    [`${prefix}Orders`]: orders,
    [`${prefix}UnitsSold`]: unitsSold,
    [`${prefix}Sales`]: sales,
    [`${prefix}Spend`]: spend,
  };
};

export interface Metric {
  name: string;
  key: string;
  checked: boolean;
}

export interface SelectedMetrics {
  sales?: boolean;
  spend?: boolean;
  impressions?: boolean;
  ctr?: boolean;
  cpr?: boolean;
  cpc?: boolean;
  acos?: boolean;
  roas?: boolean;
  orders?: boolean;
  ntborders?: boolean;
}

export const availableMetrics: Metric[] = [
  { name: "Total Impressions", key: "TotalImpressions", checked: false },
  { name: "Total CTR", key: "TotalCTR", checked: false },
  { name: "Total Clicks", key: "TotalClicks", checked: false },
  { name: "Total CVR", key: "TotalCVR", checked: false },
  { name: "Total Orders", key: "TotalOrders", checked: false },
  { name: "Total Units Sold", key: "TotalUnitsSold", checked: false },
  { name: "Total Sales", key: "TotalSales", checked: true },
  { name: "Total Spend", key: "TotalSpend", checked: true },
  { name: "Ad Impressions", key: "AdImpressions", checked: false },
  { name: "Ad CTR", key: "AdCTR", checked: false },
  { name: "Ad Clicks", key: "AdClicks", checked: false },
  { name: "Ad CVR", key: "AdCVR", checked: false },
  { name: "Ad Orders", key: "AdOrders", checked: false },
  { name: "Ad Units Sold", key: "AdUnitsSold", checked: false },
  { name: "Ad Sales", key: "AdSales", checked: true },
  { name: "Ad Spend", key: "AdSpend", checked: true },
  { name: "Organic Impressions", key: "OrganicImpressions", checked: false },
  { name: "Organic CTR", key: "OrganicCTR", checked: false },
  { name: "Organic Clicks", key: "OrganicClicks", checked: false },
  { name: "Organic CVR", key: "OrganicCVR", checked: false },
  { name: "Organic Orders", key: "OrganicOrders", checked: false },
  { name: "Organic Units Sold", key: "OrganicUnitsSold", checked: false },
  { name: "Organic Sales", key: "OrganicSales", checked: true },
  { name: "Organic Spend", key: "OrganicSpend", checked: false },
  { name: "Subscription Sales", key: "SubscriptionSales", checked: false },
  { name: "NTB Sales", key: "NtbSales", checked: false },
  { name: "Contribution Profit", key: "ContributionProfit", checked: true },
];

export const transformDataToObject = (data) => {
  const keys = Object.keys(data);
  const length = Math.max(...keys.map((key) => data[key].length));
  const result = [];

  for (let i = 0; i < length; i++) {
    const entry = {};
    keys.forEach((key) => {
      entry[key] = data[key][i] !== undefined ? data[key][i] : 0;
    });
    result.push(entry);
  }

  return result;
};

export const getData = async (
  dateRange: {
    startDate: Date;
    endDate: Date;
  },
  checks: Checks,
) => {
  const { startDate, endDate } = dateRange;
  const startDateString = startDate.toISOString().split("T")[0];
  const endDateString = endDate.toISOString().split("T")[0];

  const salesPerformanceApi = new SalesPerformanceApi();

  const promises = [].filter(Boolean);
  const keys = [].filter((_, index) => promises[index]);

  if (checks.unitSolds) {
    promises.push(
      salesPerformanceApi.getTotalUnitsSold(startDateString, endDateString),
    );
    keys.push("unitSolds");
  }

  if (checks.adUnitsSold) {
    promises.push(
      salesPerformanceApi.getAdUnitsSold(startDateString, endDateString),
    );
    keys.push("adUnitsSold");
  }

  if (checks.organicUnitsSold) {
    promises.push(
      salesPerformanceApi.getOrganicUnitsSold(startDateString, endDateString),
    );
    keys.push("organicUnitsSold");
  }

  Object.entries(checks).forEach(([key, value]) => {
    let formattedkey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
    let promiseName = `getTotal${formattedkey}`;

    if (key.startsWith("ad")) {
      const key = formattedkey.slice(2);
      formattedkey = key.charAt(0).toUpperCase() + key.slice(1);
      promiseName = `getAd${formattedkey}`;
    }

    if (key.startsWith("organic")) {
      const key = formattedkey.slice(7);
      formattedkey = key.charAt(0).toUpperCase() + key.slice(1);
      promiseName = `getOrganic${formattedkey}`;
    }

    if (key.startsWith("contribution")) {
      const key = formattedkey.slice(12);
      formattedkey = key.charAt(0).toUpperCase() + key.slice(1);
      promiseName = `getContribution${formattedkey}`;
    }

    if (key.startsWith("subscription")) {
      const key = formattedkey.slice(12);
      formattedkey = key.charAt(0).toUpperCase() + key.slice;
      promiseName = `getSubscription${formattedkey}`;
    }

    if (key.includes("adUnitsSold")) {
      promiseName = "getAdUnitsSold";
    }

    if (value) {
      if (salesPerformanceApi[promiseName]) {
        promises.push(
          salesPerformanceApi[promiseName](startDateString, endDateString),
        );
        keys.push(key);
        return;
      } else {
        promises.push(Promise.resolve([]));
        keys.push(key);
      }
    }
  });

  const results = await Promise.all(promises);

  const result: { [key: string]: any } = {
    date: generateDays(startDate, endDate),
  };

  keys.forEach((key, index) => {
    result[key] = results[index] || [];
  });

  return result;
};

export const mapMetricKeyToCheckKey = (metricKey: string): string => {
  if (metricKey.startsWith("Total")) {
    const key = metricKey.slice("Total".length);
    return key.charAt(0).toLowerCase() + key.slice(1);
  } else if (metricKey.startsWith("Ad")) {
    const key = metricKey.slice("Ad".length);
    return "ad" + key;
  } else if (metricKey.startsWith("Organic")) {
    const key = metricKey.slice("Organic".length);
    return "organic" + key;
  }
  // Add other prefixes as needed
  return metricKey.charAt(0).toLowerCase() + metricKey.slice(1);
};

export const mapCheckKeyToMetricKey = (checkKey: string): string => {
  let key = checkKey;
  if (checkKey.includes("UnitsSold") || checkKey.includes("unitSolds")) {
    key = key
      .replace("UnitsSold", "Units Sold")
      .replace("unitSolds", "Units Sold");
  }
  if (checkKey.includes("ntbSales")) {
    key = key.replace("ntbSales", "NTB Sales");
  }
  if (key.startsWith("ad")) {
    return "Ad " + key.slice(2);
  }
  if (key.startsWith("organic")) {
    return "Organic " + key.charAt(7).toUpperCase() + key.slice(8);
  }
  if (key.startsWith("contributionProfit")) {
    return "Contribution " + key.charAt(12).toUpperCase() + key.slice(13);
  }
  if (key.startsWith("subscription")) {
    return "Subscription " + key.charAt(12).toUpperCase() + key.slice(13);
  }
  return "Total " + key.charAt(0).toUpperCase() + key.slice(1);
};
