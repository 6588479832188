export const notifications = [
  {
    id: 1,
    title: "Decline in the Taste Aspect Detected",
    message:
      "Attention! We have detected a trend of declining Taste scores. Please conduct an analysis and take measures to improve this aspect.",
    time: "5 minutes ago",
  },
  {
    id: 2,
    title: "Decline in the Effectiveness Aspect Detected",
    message:
      "Attention! We have detected a trend of declining Effectiveness scores. Please conduct an analysis and take measures to improve this aspect.",
    time: "12 days ago",
  },
];
